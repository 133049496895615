import { Component, ViewChild } from '@angular/core';
import { NgbAccordion } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { first, map } from 'rxjs';
import { ListItemRendererComponent } from 'gung-list';
import { CartsService } from '../../services/carts/carts.service';
import { ProductService } from '../../services/products/product.service';
import { CartService } from '../../services/cart/cart.service';
import { GungModalService } from '../../services/gung-modal/gung-modal.service';
import { Cart } from '../../models/cart';
import { Product } from '../../models/product';
import { DateUtilService } from 'gung-common';
import { Observable } from 'rxjs';
import { SavedQuoteTableService } from '../../services/saved-quote-table/saved-quote-table.service';

@Component({
  selector: 'lib-saved-quote-table',
  templateUrl: './saved-quote-table.component.html',
  styleUrls: ['./saved-quote-table.component.css']
})
export class SavedQuoteTableComponent extends ListItemRendererComponent<Cart[]> {
  mapData: {
    [id: string]: {
      qty: number;
      product: Product;
      productId: string;
    }[];
  } = {};

  @ViewChild('acc') accordion: NgbAccordion;

  constructor(
    protected translateService: TranslateService,
    protected cartsService: CartsService,
    protected productService: ProductService,
    protected cartService: CartService,
    protected modalService: GungModalService,
    public dateUtilService: DateUtilService,
    protected savedQuoteTableService: SavedQuoteTableService
  ) {
    super();
  }

  protected fetchData(cart: Cart): Observable<{ qty: number; product: Product; productId: string }[]> {
    const ids = cart.data.rows.map(row => row.productId || row.id);
    return this.productService.getProductsByIds(ids).pipe(
      first(),
      map(products => {
        const data: { qty: number; product: Product; productId: string }[] = [];

        cart.data.rows.map(row => {
          const qty = row.qty || row.quantity;
          // in case product was not presented in the response
          // it means the product is excluded (due to blocked, not belong to flow, removed)
          const product: Product = products.find(p => p.id === (row.productId || row.id));

          if (product) {
            data.push({
              qty,
              product,
              productId: row.productId || row.id
            });
          } else {
            // To add the productId to list of excluded ids when loading cart
            data.push({
              qty,
              product: undefined,
              productId: row.productId || row.id
            });
          }
        });

        return data;
      })
    );
  }

  fetchProductData(cart: Cart): void {
    this.fetchData(cart).subscribe(data => {
      this.setMappedData(cart.id, data);
    });
  }

  deleteCart(cart: Cart) {
    const loadedCardId = this.cartsService.getLoadedCardId();
    const ref = this.modalService.openDeleteSavedCart(cart);
    ref.result.then(
      result => {
        if (result) {
          const loadedCardId = this.cartsService.getLoadedCardId();
          if (loadedCardId && loadedCardId === cart.id + '#' + cart.name) {
            this.cartsService.removeLoadedCardId();
          }
          this.cartsService
            .deleteCart(cart.id)
            .pipe()
            .subscribe(() => {
              this.cartsService.updateSavedQuotesSubject();
            });
        }
      },
      () => undefined
    );
    /* this.cartsService
      .deleteCart(cart.id)
      .pipe()
      .subscribe(isRemoved => {
        this.cartsService.updateSavedQuotesSubject();
      }); */
  }
  

  loadCart(cart: Cart) {
    this.modalService
      .openConfirmYesNoModal(undefined, this.translateService.instant('LOAD_SAVED_CART_CONFIRMATION'), { size: 'sm' })
      .then(
        result => {
          if (result) {
            // In case panel is closed => open it
            if (!this.accordion.isExpanded(cart.id)) {
              this.accordion.expand(cart.id);
            }

            this.fetchData(cart).subscribe(data => {
              this.setMappedData(cart.id, data);

              this.cartService.clearCart();

              const excludedProductIds = data.filter(d => !d.product).map(d => d.productId);

              if (excludedProductIds.length > 0) {
                this.modalService
                  .openConfirmYesNoModal(
                    this.translateService.instant('EXCLUDED_PRODUCTS'),
                    this.translateService.instant('EXCLUDED_PRODUCTS_MESSAGE', {
                      products: excludedProductIds.join(', ')
                    }),
                    null,
                    'OK',
                    null
                  )
                  .then(proceed => {
                    if (proceed) {
                      this.addToCart(cart, excludedProductIds);
                    }
                  });
              } else {
                this.addToCart(cart, excludedProductIds);
              }
            });

            this.cartsService.setLoadedCardId(cart.id + '#' + cart.name);
          } else {
            // Keep panel closed
            this.accordion.collapse(cart.id);
          }
        },
        reason => {
          // Keep panel closed
          this.accordion.collapse(cart.id);
        }
      );
  }

  protected addToCart(cart: Cart, excludedProductIds: string[]): void {
    // do not include all the excluded products
    const bulkData = cart.data.rows
      .filter(row => !excludedProductIds.includes(row.productId || row.id))
      .map(row => {
        return {
          productId: row.productId || row.id,
          targetStockId: row.targetStockId,
          qty: row.qty || row.quantity,
          extra: row.extra
        };
      });

    this.cartService.bulkAddToCart(bulkData);
    this.cartService.bulkSetExtra(bulkData);
  }

  protected setMappedData(cartId: string, data: { qty: number; product: Product; productId: string }[]): void {
    // include only all the row whose product is not excluded
    if (!this.mapData[cartId]) {
      this.mapData[cartId] = data
        .filter(row => !!row.product)
        .map(row => {
          return {
            qty: row.qty,
            product: row.product,
            productId: row.productId
          };
        });
    }
  }

  donwloadQuotePDF(cart: Cart): void {
    // Keep panel closed
    this.accordion.collapse(cart.id);
    this.savedQuoteTableService.downloadQuotePdf(cart);
  }
}
