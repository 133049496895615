import { Component, OnInit } from '@angular/core';
import { HlDeliveryDateService } from '../../services/hl-delivery-date.service';

@Component({
  selector: 'app-hl-display-delivery-days',
  templateUrl: './hl-display-delivery-days.component.html',
  styleUrls: ['./hl-display-delivery-days.component.css']
})
export class HlDisplayDeliveryDaysComponent implements OnInit {
  constructor(private hlDeliveryDateService: HlDeliveryDateService) {}

  transportTime: number;

  ngOnInit() {
    this.hlDeliveryDateService
      .getTransportTimeForSelectedCustomer()
      .subscribe(transportElement => (this.transportTime = transportElement?.transporttime || 0));
  }
}
