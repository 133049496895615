import { Component, OnInit, ComponentFactoryResolver, ChangeDetectorRef, OnDestroy } from '@angular/core';
import {
  CheckoutWrapperComponent,
  CheckoutConfigService,
  CartService,
  OrderSubmitService,
  GungModalService,
  CartKeyService,
  SelectedCustomerService,
  CartTotalsService,
  TotalObject,
  Customer
} from 'gung-standard';
import { first, forkJoin, mergeMap, of, Subject, takeUntil } from 'rxjs';
import { JeevesCheckCreditService } from 'gung-standard-jeeves';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { HlDisplayCreditBlockedService } from '../../services/hl-display-credit-blocked.service';

@Component({
  selector: 'app-hl-checkout-wrapper',
  templateUrl: './hl-checkout-wrapper.component.html',
  styleUrls: ['./hl-checkout-wrapper.component.css']
})
export class HlCheckoutWrapperComponent extends CheckoutWrapperComponent implements OnInit, OnDestroy {
  isCreditHardBlock: boolean = false;
  isCreditSoftBlock: boolean = false;
  customer: Customer;
  creditLeft: number = 0;
  totals: TotalObject = { totalQuantity: 0, totalValue: 0, totalRows: 0 };
  protected modalOpen: boolean = false;
  protected unsubscribeTotals: Subject<void> = new Subject();

  constructor(
    protected orderSubmitService: OrderSubmitService,
    protected checkoutConfig: CheckoutConfigService,
    componentFactoryResolver: ComponentFactoryResolver,
    protected cartService: CartService,
    changeDetectorRef: ChangeDetectorRef,
    gungModalService: GungModalService,
    cartKeyService: CartKeyService,
    protected selectCustomerService: SelectedCustomerService,
    protected cartTotalsService: CartTotalsService,
    protected jeevesCheckCreditService: JeevesCheckCreditService,
    protected translateService: TranslateService,
    protected creditBlockedService: HlDisplayCreditBlockedService
  ) {
    super(
      orderSubmitService,
      checkoutConfig,
      componentFactoryResolver,
      cartService,
      changeDetectorRef,
      gungModalService,
      cartKeyService
    );
  }

  ngOnInit(): void {
    this.creditBlockedService.checkCreditBlockedSelectedCustomer().pipe(first()).subscribe(result => {
      this.customer = result.customer;

      if (result.status === 'CREDIT_HARD_BLOCK') {
        this.isCreditHardBlock = true;
        this.isCreditSoftBlock = false;
      }

      if (result.status === 'CREDIT_SOFT_BLOCK' && result.credit) {  
        this.creditLeft = Number(result.credit?.creditLeft) || 0;

        this.cartTotalsService
          .getTotals()
          .pipe(takeUntil(this.unsubscribeTotals))
          .subscribe(totalsObject => {
            this.totals = totalsObject;
            
            if (this.creditLeft < this.totals.totalValue && !this.modalOpen) {
              // Open Modal
              this.modalOpen = true;
              const options: NgbModalOptions = {
                backdrop: 'static',
                size: 'sm',
                keyboard: true
              };

              const translation = this.creditBlockedService.getPortalTranslationCart(result.customer, this.creditLeft, this.totals.totalValue);

              this.gungModalService.openBasicModal(translation.title, translation.body, options).then(
                result => {
                  this.isCreditSoftBlock = true;
                },
                reason => {
                  this.isCreditSoftBlock = true;
                }
              );
            }

            if (this.creditLeft >= this.totals.totalValue) {
              this.modalOpen = false;
              this.isCreditSoftBlock = false;
            }
          });
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribeTotals.next();
    this.unsubscribeTotals.complete();
  }
}
