<div class="row">
  <div class="col-4">
    <h3 translate>REMAINS_TO_INCLUDE</h3>
    <div class="list-group">
      <button
        *ngFor="let assortment of remainingAssortment"
        (click)="assortmentManageAdd(assortment)"
        type="button"
        class="list-group-item list-group-item-action btn-link d-flex justify-content-between align-items-center"
      >
        <span class="badge badge-primary badge-pill"><i class="fas fa-plus"></i></span>
        {{ assortment.id }}
        <span>{{ assortment.name }}</span>
      </button>
    </div>
  </div>
  <div class="col-4">
    <h3 translate>ALREADY_INCLUDED</h3>
    <div class="list-group">
      <button
        *ngFor="let assortment of includedAssortment"
        (click)="assortmentManageRem(assortment)"
        type="button"
        class="list-group-item list-group-item-action btn-link d-flex justify-content-between align-items-center"
      >
        <span class="badge badge-primary badge-pill"><i class="fas fa-minus"></i></span>
        {{ assortment.id }}
        <span>{{ assortment.name }}</span>
      </button>
    </div>
    <div *ngIf="!includedAssortment || includedAssortment.length === 0">
      <p>No subassortments selected.</p>
      <p>User will see all, even those added later.</p>
    </div>
  </div>
  <div class="col-4">
    <p>
      Use this function if you want a user to just see a subset of the assigned assortment (currently
      <b>{{ currentUser?.assortment }}</b
      >).
    </p>

    <p>If you want a user to see everything you can leave the list empty.</p>

    <p>Changes to this will reflect after users next login.</p>
  </div>
</div>
