<div *ngIf="order">
  <div class="d-flex flex-row justify-content-between align-items-center mb-4 mt-4">
    <div>
      <h2 *ngIf="order.extra.oh.ordstat !== 0">Order {{ order.id }}</h2>
      <h2 *ngIf="order.extra.oh.ordstat === 0">Quote {{ order.id }}</h2>
      <h6 *ngIf="order.extra.deliveryCustomer">{{ order.extra.deliveryCustomer.name }}</h6>
    </div>
    <div class="form-group">
      <lib-action-btn [item]="order" [config]="configService"></lib-action-btn>
    </div>
  </div>

  <div class="row mb-3">
    <div class="col">
      <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0">
        <ngb-panel [title]="'DETAILS_AND_CONDITIONS' | translate">
          <ng-template ngbPanelContent>
            <div class="row">
              <div class="col col-xs-12 col-md-6">
                <h5 translate>DETAILS</h5>
                <lib-gung-text-input-horizontal
                  renderStatic="true"
                  gungTranslate="ORDER_NUMBER"
                  [(ngModel)]="order.extra.oh.ordernr"
                >
                </lib-gung-text-input-horizontal>
                <lib-gung-text-input-horizontal
                  renderStatic="true"
                  gungTranslate="YOUR_REFERENCE_NO"
                  [(ngModel)]="order.extra.oh.kundbestnr"
                ></lib-gung-text-input-horizontal>
                <lib-gung-meta-output
                  [value]="order.extra.oh.ordstat"
                  [gungTranslate]="'ORDER_STATUS'"
                  metadataTable="x7"
                  metaOptionName="ordstatbeskr"
                  [horizontal]="true"
                ></lib-gung-meta-output>
                <lib-gung-date-output
                  [horizontal]="true"
                  [gungTranslate]="'ORDER_DATE'"
                  [value]="order.extra.oh.orddatum"
                >
                </lib-gung-date-output>
                <lib-gung-date-output
                  [horizontal]="true"
                  [gungTranslate]="'ESTIMATED_DELIVERY_DATE'"
                  [value]="order.extra.oh.ordberlevdat"
                >
                </lib-gung-date-output>
              </div>
              <div class="col col-xs-12 col-md-6">
                <h5 translate>CONDITIONS</h5>
                <lib-gung-meta-output
                  [horizontal]="true"
                  [gungTranslate]="'PAYMENT_TERMS'"
                  [value]="order.extra.oh.betkod"
                  metadataTable="x2"
                  metaOptionName="betvillbeskr"
                >
                </lib-gung-meta-output>
                <lib-gung-meta-output
                  [horizontal]="true"
                  [gungTranslate]="'DELIVERY_TERMS'"
                  [value]="order.extra.oh.levvillkkod"
                  metadataTable="x2e"
                  metaOptionName="levvillkbeskr"
                >
                </lib-gung-meta-output>
                <lib-gung-meta-output
                  [horizontal]="true"
                  [gungTranslate]="'DELIVERY_METHOD'"
                  [value]="order.extra.oh.levsattkod"
                  metadataTable="x2f"
                  metaOptionName="levsattbeskr"
                >
                </lib-gung-meta-output>
              </div>
            </div>
          </ng-template>
        </ngb-panel>
        <ngb-panel [title]="'SHIPPING_INFORMATION' | translate">
          <ng-template ngbPanelContent>
            <div class="row">
              <div class="col col-12">
                <h5 translate>ADDRESS</h5>
              </div>
              <div class="col col-xs-12 col-md-6">
                <lib-gung-text-input-horizontal
                  renderStatic="true"
                  gungTranslate="CUSTOMER_REFERENCE"
                  [(ngModel)]="order.extra.oh.kundref2"
                ></lib-gung-text-input-horizontal>
                <lib-gung-text-input-horizontal
                  renderStatic="true"
                  gungTranslate="ADDRESS1"
                  [(ngModel)]="order.extra.oh.ordlevadr2"
                ></lib-gung-text-input-horizontal>
                <lib-gung-text-input-horizontal
                  renderStatic="true"
                  gungTranslate="ADDRESS2"
                  [(ngModel)]="order.extra.oh.ordlevadr3"
                ></lib-gung-text-input-horizontal>
              </div>
              <div class="col col-xs-12 col-md-6">
                <lib-gung-text-input-horizontal
                  renderStatic="true"
                  gungTranslate="CITY"
                  [(ngModel)]="order.extra.oh.ordlevadr4"
                ></lib-gung-text-input-horizontal>
                <lib-gung-text-input-horizontal
                  *ngIf="order.extra.oh.ftgpostnr"
                  renderStatic="true"
                  gungTranslate="ZIP"
                  [(ngModel)]="order.extra.oh.ftgpostnr"
                >
                </lib-gung-text-input-horizontal>
                <lib-gung-meta-output
                  [horizontal]="true"
                  [gungTranslate]="'COUNTRY'"
                  [value]="order.extra.oh.ordlevadrlandskod"
                  metadataTable="xr"
                  metaOptionName="land"
                >
                </lib-gung-meta-output>
              </div>
            </div>
          </ng-template>
        </ngb-panel>
        <ngb-panel [title]="'ADDITIONAL_INFORMATION' | translate">
          <ng-template ngbPanelContent>
            <div class="form-group row">
              <label for="oh.editext" class="col-form-label text-right-sm text-muted col-xs-12 col-sm-4" translate
                >MESSAGE</label
              >
              <div class="col">
                <textarea
                  class="form-control"
                  type="text"
                  name="editext"
                  id="oh.editext"
                  [disabled]="isOrderEditable(order, isSales) ? undefined : true"
                  [(ngModel)]="order.extra.oh.editext"
                ></textarea>
                <button
                  *ngIf="isOrderEditable(order, isSales)"
                  type="button"
                  class="btn btn-primary float-right mb-2"
                  (click)="saveOrderMessage($event)"
                  translate
                >
                  SAVE
                </button>
              </div>
            </div>
            <div class="form-group row" *ngIf="isSales">
              <label for="oh.edit" class="col-form-label text-right-sm text-muted col-xs-12 col-sm-4" translate
                >INTERNAL_TEXT</label
              >
              <div class="col">
                <textarea
                  class="form-control"
                  type="text"
                  name="edit"
                  id="oh.edit"
                  disabled="true"
                  [(ngModel)]="order.extra.oh.edit"
                ></textarea>
              </div>
            </div>
          </ng-template>
        </ngb-panel>
      </ngb-accordion>
    </div>
  </div>

  <div class="row">
    <div class="col-12 table-responsive gung-responsive">
      <table class="table table-striped">
        <thead>
          <tr>
            <th translate>PRODUCT_ID</th>
            <th translate>NAME</th>
            <th translate>ESTIMATED_DELIVERY_DATE</th>
            <th translate>QUANTITY</th>
            <th translate>QUANTITY_DELIVERED</th>
            <th translate>QUANTITY_BACKORDER</th>
            <th translate>UNIT_PRICE</th>
            <th translate>DISCOUNT</th>
            <th translate>TOTAL_PRICE</th>
            <th *ngIf="isOrderEditable(order, isSales)"></th>
          </tr>
        </thead>
        <tbody *ngIf="order">
          <tr *ngFor="let row of order.rows" [ngClass]="row.extra.orp.ordradst === 90 ? 'deleted' : ''">
            <td [attr.data-label]="'PRODUCT_ID' | translate">
              <a [routerLink]="row.extra._path ? row.extra._path : ['/product/', row.productId]">{{ row.productId }}</a>
            </td>
            <td [attr.data-label]="'NAME' | translate">
              <a [routerLink]="row.extra._path ? row.extra._path : ['/product/', row.productId]">
                <span *ngIf="row.extra.orp.artbeskr">
                  {{ row.extra.orp.artbeskr }}
                </span>
                <span *ngIf="this.products && this.products[row.productId] && !row.extra.orp.artbeskr">
                  {{ this.products[row.productId].name }}
                </span>
              </a>
            </td>
            <td [attr.data-label]="'ESTIMATED_DELIVERY_DATE' | translate">
              {{ row.extra.orp.ordberlevdat | date: dateUtilService.dateFormat }}
            </td>
            <td [attr.data-label]="'QUANTITY' | translate">
              {{ row.quantity }}
            </td>
            <td [attr.data-label]="'QUANTITY_DELIVERED' | translate">
              {{ row.extra.orp.ordlevantal }}
            </td>
            <td [attr.data-label]="'QUANTITY_BACKORDER' | translate">
              {{ row.extra.orp.ordrestant }}
            </td>
            <td [attr.data-label]="'UNIT_PRICE' | translate">
              <lib-price [price]="row.extra.orp.vb_pris" [currency]="order.extra.oh.valkod"></lib-price>
            </td>
            <td [attr.data-label]="'DISCOUNT' | translate">
              <span>{{ row.extra.orp.rabatt1 | number: '1.0-2' }}%</span>
              <!-- Show separate discounts if non zero -->
              <span *ngIf="row.extra.orp.rabatt2"><br/>{{ row.extra.orp.rabatt2 | number: '1.0-2' }}%</span>
              <span *ngIf="row.extra.orp.rabatt3"><br/>{{ row.extra.orp.rabatt3 | number: '1.0-2' }}%</span>
              <span *ngIf="row.extra.orp.kundrabatt"><br/>{{ row.extra.orp.kundrabatt | number: '1.0-2' }}%</span>
            </td>
            <td [attr.data-label]="'TOTAL_PRICE' | translate">
              <lib-price [price]="row.extra.orp.vbordradsum" [currency]="order.extra.oh.valkod"></lib-price>
            </td>
            <td *ngIf="isOrderEditable(order, isSales)">
              <gung-jeeves-edit-orderrow-modal (orderRowChange)="orderUpdated($event)" [orderRow]="row">
              </gung-jeeves-edit-orderrow-modal>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="col-12" *ngIf="isOrderEditable(order, isSales)">
      <button class="btn btn-primary btn-block" (click)="addRow()" translate>ADD_ROW</button>
    </div>
  </div>
</div>
