<div class="row">
  <div *ngFor="let news of data" class="col-6 col-md-4 col-lg-3 mb-2">
    <div class="card-body h-100 border d-flex flex-column">
      <img
        class="card-img-top"
        *ngIf="news.extra && news.extra.cover as image; else noimage"
        src="{{ image }}"
        alt=""
      />
      <ng-template #noimage>
        <img
          [src]="'images/no-image.jpg' | gungImageUrl"
          alt="NO IMAGE"
          class="card-img-top"
        />
      </ng-template>
      <div class="card-body d-flex flex-column justify-content-end">
        <h5 class="card-title">{{ news.name }}</h5>
        <!-- <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p> -->
        <a [routerLink]="['/news/', news.id]" class="btn btn-primary" translate>GO_TO_NEWS</a>
      </div>
    </div>
  </div>
</div>
