import { Component, OnInit } from '@angular/core';
import { CheckoutStepComponent, SelectedCustomerService, AuthService, gungValidateEmail } from 'gung-standard';
import { first, forkJoin, mergeMap, of } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { DeliveryLocationsJeevesComponent, DeliveryLocationsService } from 'gung-standard-jeeves';
import { FormBuilder } from '@angular/forms';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-shipping-step',
  templateUrl: './shipping-step.component.html',
  styleUrls: ['./shipping-step.component.css']
})
export class ShippingStepComponent extends DeliveryLocationsJeevesComponent implements OnInit {
  public loading = true;
  public emailTemporaryInvalidError: boolean = false;

  fieldError: { [s: string]: boolean } = {};

  public isPortalBenelux =
    environment.mainCountry === 'fr' && environment.quoteConfirmationEmailAddress === 'OrdersBenelux@hl-display.com';
  public isCloettaPortal =
    environment.bolag === '1210' &&
    !environment.sales &&
    environment.managers &&
    environment.mainCountry === 'en' &&
    environment.mainCustomer === 'cloetta';

  constructor(
    protected selectedCustomerService: SelectedCustomerService,
    protected authService: AuthService,
    deliveryLocationsService: DeliveryLocationsService,
    formBuilder: FormBuilder
  ) {
    super(selectedCustomerService, deliveryLocationsService, formBuilder, authService);
  }

  isCoopPortal = environment.mainCustomer === 'coop';
  isManagersNisa = environment.managers && environment.mainCountry === 'en' && environment.mainCustomer === '';

  getDisableEmail() {
    return environment.bolag === '1710';
  }

  ngOnInit() {
    super.ngOnInit();
    this.onNextBtnClicked.subscribe(_ => this.handleNextButtonClicked());
    this.selectedCustomerService
      .getSelectedCustomer()
      .pipe(
        first(),
        mergeMap(selectedCustomer =>
          forkJoin([
            of(selectedCustomer),
            this.deliveryLocationsService.getDeliveryLocationsForCustomer(selectedCustomer.id)
          ])
        )
      )
      .subscribe(([selectedCustomer, deliveryLocations]) => {
        this.selectedCustomer = selectedCustomer;
        this.initForm();
        this.deliveryLocations = [...deliveryLocations];

        if (this.selectedCustomer.extra.kus.ordlevplats1) {
          const deliveryLocation = this.deliveryLocations.find(location => {
            return location.extra.lp.ordlevplats1 === this.selectedCustomer.extra.kus.ordlevplats1;
          });
          this.deliveryLocationId = deliveryLocation.id;
        } else {
          this.deliveryLocations.unshift({
            id: this.selectedCustomer.id,
            name: this.selectedCustomer.name,
            extra: this.selectedCustomer.extra,
            timestamp: new Date()
          });
          this.deliveryLocationId = this.deliveryLocations[0].id;
        }

        const dl = this.deliveryLocations.find(dl => dl.id === this.deliveryLocationId);
        if (dl) {
          (dl as any).selected = true;
        }
      });
    if (!this.getDisableEmail()) {
      this.checkout.extra.confirmationEmails = this.checkout.extra.confirmationEmails
        ? this.checkout.extra.confirmationEmails
        : [''];
      if (this.checkout.extra.confirmationEmails.length === 0) {
        this.checkout.extra.confirmationEmails.push('');
      }
    }

    this.authService.getCurrentUser().subscribe(user => {
      if (user.roles.indexOf('SALES') >= 0) {
        this.checkout.extra.confirmationEmails.push(user.email);
      }
    });
  }

  protected handleNextButtonClicked(): void {
    if (!!this.checkout.extra._emailQuoteTemporary && this.checkout.extra._emailQuoteTemporary.includes(';')) {
      if (this.checkout.extra._emailQuoteTemporary.split(';').findIndex(m => !gungValidateEmail(m.trim())) > -1) {
        this.emailTemporaryInvalidError = true;
        return;
      } else {
        this.emailTemporaryInvalidError = false;
      }
    } else if (
      !!this.checkout.extra._emailQuoteTemporary &&
      !gungValidateEmail(this.checkout.extra._emailQuoteTemporary.trim()) &&
      this.isCoopPortal
    ) {
      this.emailTemporaryInvalidError = true;
      return;
    } else {
      this.emailTemporaryInvalidError = false;
    }

    this.fieldError['kundref2'] = false;
    if (!this.checkout.extra.oh.kundref2 && this.isManagersNisa) {
      this.fieldError['kundref2'] = true;
    }

    this.fieldError['kundbestnr'] = false;
    if (!this.checkout.extra.oh.kundbestnr && this.isManagersNisa) {
      this.fieldError['kundbestnr'] = true;
    }

    if (Object.keys(this.fieldError).filter(k => this.fieldError[k]).length > 0) {
      return;
    }
    this.stepDone.emit(this.checkout);
  }

  public getLabel(): string {
    return 'SHIPPING';
  }

  getIsSalesPortal() {
    return environment.sales;
  }

  protected initForm() {
    super.initForm();
    this.form.addControl('ordlevplats1', this.formBuilder.control(this.checkout.extra.procargs.ordlevplats1 || ''));
    this.checkout.extra.oh.q_hl_sendttlinkemail = this.selectedCustomer.extra.kus.q_hl_sendttlinkemail
      ? '1'
      : undefined;
    this.checkout.extra.oh.q_hl_emailtt = this.selectedCustomer.extra.kus.q_hl_sendttlinkemail
      ? this.selectedCustomer.extra.kus.q_hl_emailtt
      : undefined;
  }

  emailttChange(table, value) {
    if (!value || value === '') {
      this.checkout.extra[table].q_hl_emailtt = undefined;
      this.checkout.extra[table].q_hl_sendttlinkemail = undefined;
      return;
    }

    this.checkout.extra[table].q_hl_sendttlinkemail = '1';
  }

  protected afterDeliveryLocationSet(deliveryLocationId: string): void {
    super.afterDeliveryLocationSet(deliveryLocationId);

    if (this.selectedDeliveryLocation.extra.fr.ftgnr) {
      this.checkout.extra.procargs.ordlevplats1 = this.selectedDeliveryLocation.extra.fr.ftgnr;
    }
  }
}
