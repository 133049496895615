import { Component, OnInit } from '@angular/core';
import { CheckoutStepComponent, SelectedCustomerService, TableRecord } from 'gung-standard';
import { first } from 'rxjs';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-sales-terms-step',
  templateUrl: './sales-terms-step.component.html',
  styleUrls: ['./sales-terms-step.component.css']
})
export class SalesTermsStepComponent extends CheckoutStepComponent implements OnInit {
  isSalesNordicPortal = environment.sales && environment.bolag === '1210';

  constructor(private selectedCustomerService: SelectedCustomerService) {
    super();
  }
  ngOnInit() {
    this.selectedCustomerService
      .getSelectedCustomer()
      .pipe(first())
      .subscribe(customer => {
        this.checkout.extra.oh.dellevtillaten = customer.extra.kus.dellevtillaten;
        this.checkout.extra.oh.transportorskod = customer.extra.kus.transportorskod;

        if (environment.orderOurReference !== '') {
          this.checkout.extra.oh.vref = environment.orderOurReference;
        }
      });
    this.onNextBtnClicked.subscribe(_ => this.stepDone.emit(this.checkout));
  }

  public getLabel(): string {
    return 'TERMS';
  }

  filterCarrierCodes(option: { key: string; value: string | TableRecord }): boolean {
    return !(option.value + '').toLocaleLowerCase().startsWith('zz');
  }

  changeCarrierCode(value: string): void {
    if (this.isSalesNordicPortal) {
      if (value === '4') {
        this.checkout.extra.oh.godsmarke1 = 'Express';
      } else {
        delete this.checkout.extra.oh.godsmarke1;
      }
    }
  }
}
