import { Component, OnDestroy, OnInit, Optional } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth/auth.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { map, filter, tap, takeUntil, first, mergeMap } from 'rxjs/operators';
import { ScannedActionsSubject, Action } from '@ngrx/store';
import { forkJoin, of, Subject } from 'rxjs';
import { ActionTypes } from '../../state/auth/actions';
import { LoginCheckService } from '../../services/login-check.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { AuthBackendService } from '../../services/auth-backend/auth-backend.service';

interface LoginParams extends Params {
  redirectUrl?: string;
}
@Component({
  selector: 'lib-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  redirectUrl: string;
  wasAnonymous = false;
  badLogin = false;
  internalServerError = false;

  error: Action = null;
  unsubscribe: Subject<void> = new Subject();

  get ActionTypes() {
    return ActionTypes;
  }

  constructor(
    protected authService: AuthService,
    protected formBuilder: FormBuilder,
    protected route: ActivatedRoute,
    protected router: Router,
    protected actions$: ScannedActionsSubject,
    protected loginCheckService: LoginCheckService,
    @Optional()
    protected oauth2Service: OAuthService
  ) {
    this.route.queryParams
      .pipe(
        map(params => params as LoginParams),
        map(params => params.redirectUrl)
      )
      .subscribe(url => (this.redirectUrl = url ? url : ''));
    this.authService.getIsBadAuth().subscribe(badAuth => {
      this.loginDone();
      this.badLogin = badAuth;
    });
    this.authService.getInternalServerError().subscribe(internalServerError => {
      this.internalServerError = internalServerError;
      this.loginDone();
    });

    this.authService
      .getIsAuthenticated()
      .pipe(
        mergeMap(isAuthenticated => {
          if (isAuthenticated) {
            return forkJoin([of(isAuthenticated), this.authService.getHasRoles('ANONYMOUS').pipe(first())]);
          } else {
            return forkJoin([of(isAuthenticated), of(false)]);
          }
        })
      )
      .subscribe(([isAuthenticated, isAnonymous]) => {
        if (isAuthenticated && !isAnonymous) {
          this.loginDone();
          router.navigateByUrl(this.redirectUrl);
        } else if (isAuthenticated && isAnonymous) {
          this.wasAnonymous = true;
        }
        this.loginCheckService.initPoll();
      });

    this.actions$
      .pipe(
        takeUntil(this.unsubscribe),
        filter(action => action.type === ActionTypes.BadLogin || action.type === ActionTypes.BackendDown || action.type === ActionTypes.InternalServerError)
      )
      .subscribe(action => {
        this.error = action;
        this.loginDone();
      });
  }

  loginDone() {
    this.loading = false;
  }
  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
    sessionStorage.clear();
  }

  get f() {
    return this.loginForm.controls;
  }

  loginOauth() {
    // Initializes the login towards the oauth2 authentication provider.
    this.oauth2Service.initCodeFlow();
  }

  onSubmit() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    const { username, password } = this.loginForm.controls;
    this.loading = true;
    this.authService.loginUser(username.value, password.value, this.wasAnonymous);
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
