<div *ngIf="!isLoading; else loading">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      <span translate>{{ metadata ? 'EDIT_METADATA' : 'CREATE_METADATA' }}</span
      >&nbsp;
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="row">
      <div class="col-12" [formGroup]="form">
        <div class="form-group row">
          <label class="col-form-label col-12 col-sm-4 font-weight-bold" for="SEGMENT" translate>SEGMENT</label>
          <div class="col">
            <select
              name="SEGMENT"
              id="SEGMENT"
              class="form-control"
              formControlName="segment"
              [ngClass]="{
                'border border-danger':
                  form.get('segment').invalid && (form.get('segment').dirty || form.get('segment').touched)
              }"
            >
              <option value=""></option>
              <option [value]="option" *ngFor="let option of segments">{{ option }}</option>
            </select>
          </div>
        </div>
        <div class="form-group row">
          <label for="ID" class="col-form-label col-12 col-sm-4 align-self-center font-weight-bold" translate>ID</label>
          <div class="col">
            <input
              type="text"
              id="ID"
              name="ID"
              formControlName="id"
              class="form-control"
              [ngClass]="{
                'border border-danger': form.get('id').invalid && (form.get('id').dirty || form.get('id').touched)
              }"
            />
          </div>
        </div>
        <div formGroupName="i18n">
          <div *ngFor="let lang of translateLangs" [formGroupName]="lang">
            <lib-gung-text-input-horizontal
              [gungTranslate]="this.translateField.toUpperCase() + '_'"
              [translateParams]="{ lang: lang }"
              [formControlName]="this.translateField"
            ></lib-gung-text-input-horizontal>
          </div>
        </div>
        <div class="form-group row">
          <label for="name" class="col-form-label col-12 col-sm-4 align-self-center font-weight-bold" translate
            >METADATA_DESCRIPTION</label
          >
          <div class="col">
            <input
              type="text"
              id="name"
              name="name"
              formControlName="name"
              class="form-control"
              [ngClass]="{
                'border border-danger': form.get('name').invalid && (form.get('name').dirty || form.get('name').touched)
              }"
            />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-sm btn-default btn-block btn-primary" (click)="onSave()" translate>
      SAVE
    </button>
  </div>
</div>

<ng-template #loading> LOADING... </ng-template>
