<div *ngIf="items; else noPreviousOrders" class="row mx-5">
  <div class="col-12">
    <div class="card">
      <div class="card-body order-card-header">
        <div class="row">
          <div class="col text-nowrap" translate>ORDER_NUMBER</div>
          <div class="col text-center text-nowrap" translate>PRICE</div>
          <div class="col text-center text-nowrap" translate>DATE</div>
          <div class="col-2 text-center text-nowrap" translate>ORDER_AGAIN</div>
          <div class="col-2 text-center text-nowrap" translate>DELIVERY_TRACKING</div>
        </div>
        <!-- <div class="btn-group float-right">
              <a class="btn btn-primary" routerLink="/orders/{{data.id}}">More info</a>
              <a class="btn btn-secondary" routerLink="/products/by-order/{{data.id}}">Shop items again</a>
            </div> -->
      </div>
    </div>
  </div>
  <div *ngFor="let item of items" class="col-12 striped">
    <ng-template libCustomHost></ng-template>
  </div>
</div>

<ng-template #noPreviousOrders class="row mx-5">
  <div class="col-12">
    <div class="container d-flex justify-content-center">
      <h1 class="" translate>NO_PREVIOUS_ORDERS</h1>
    </div>
  </div>
</ng-template>
