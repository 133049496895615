<div class="row">
  <div class="col-12 text-center">
    <h3 class="page-title" translate>CACHES</h3>
  </div>
</div>
<div class="row mb-2">
  <div class="col-12 text-right">
    <span class="font-weight-bold" translate>TOTAL_COUNT</span>: {{ cachesList.length }}
    <button type="button" class="btn btn-secondary btn-sm mx-1" (click)="clearAllCache()" translate>CLEAR_ALL</button>
    <button type="button" class="btn btn-primary btn-sm" (click)="loadCache()" translate>RELOAD</button>
  </div>
</div>
<div class="table-responsive gung-responsive">
  <!-- table-responsive -->
  <table class="table table-striped table-hover">
    <thead class="thead-dark">
      <tr>
        <th scope="col" translate>NAME</th>
        <th scope="col" translate>SIZE</th>
        <th scope="col" translate>DISC</th>
        <th scope="col" translate>MEM</th>
        <th scope="col" translate>TTL</th>
        <th scope="col" translate>TTI</th>
        <th scope="col" translate>TOOLS</th>
        <th scope="col" translate>DETAILS</th>
      </tr>
    </thead>
    <tbody *ngFor="let cache of cachesList">
      <tr>
        <th scope="row" [attr.data-label]="'NAME' | translate">{{ cache.name }}</th>
        <td [attr.data-label]="'SIZE' | translate">{{ cache.extra.size }}</td>
        <td [attr.data-label]="'DISC' | translate">{{ cache.extra.diskStoreSize }}</td>
        <td [attr.data-label]="'MEM' | translate">{{ cache.extra.memoryStoreSize }}</td>
        <td [attr.data-label]="'TTL' | translate">
          {{ cache.extra.eternal ? ('ETERNAL' | translate) : cache.extra.timeToLiveSeconds }}
        </td>
        <td [attr.data-label]="'TTI' | translate">
          {{ cache.extra.eternal ? ('ETERNAL' | translate) : cache.extra.timeToIdleSeconds }}
        </td>
        <td [attr.data-label]="'TOOLS' | translate">
          <button type="button" class="btn btn-secondary" (click)="clearCache(cache.name)" translate>CLEAR</button>
        </td>
        <td [attr.data-label]="'DETAILS' | translate" >
          <button *ngIf="cache.extra.keys && cache.extra.keys.length>0" type="button" class="btn btn-info" (click)="toggleKeysVisibility(cache.name)"  >
            <span *ngIf="!keysShownMap[cache.name]" translate>SHOW_KEYS</span>
            <span *ngIf="keysShownMap[cache.name]" translate>HIDE_KEYS</span>
          </button>
        </td>
      </tr>
      <tr *ngIf="keysShownMap[cache.name]">
        <td colspan="8">
          <table class="w-100">
            <tr *ngFor="let key of cache.extra.keys">
              <th>{{key}}</th>
              <td class="text-right" >
                <button type="button" class="btn btn-sm btn-secondary" (click)="deleteCacheKey(cache.name, key)" translate>DELETE</button>
              </td>
            </tr> 
          </table>
        </td>
      </tr>
    </tbody>
  </table>
</div>
