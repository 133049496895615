<div class="container">
  <div class="mb-4">
    <h2 class="text-center">
      <span translate>THANK_YOU_HEADER</span>
    </h2>
    <p class="text-center" translate>THANK_YOU_EMAIL_CONFIRMATION</p>
  </div>

  <div class="mb-4">
    <span translate>THANK_YOU_ORDER_SUMMARY</span>
  </div>

  <div class="row mb-2" *ngFor="let row of rows">
    <div class="col">
      <p class="text-left">{{ row.id }} - {{ row.name }}</p>
    </div>
    <div class="col">
      <p class="text-right">
        {{ row.quantity }}
      </p>
    </div>
    <div class="col-xl-1 col-sm-2">
      <p class="text-right">
        {{ row.price * row.quantity 
        | gungCurrency
        : row.currency
        : (isPortalNo ? 'code' : 'symbol-narrow')
        : '1.2-2' : (isCurrysPortal ? 'en-US' : 'sv_SE')  }}
      </p>
    </div>
  </div>
  <div class="row mb-4" style="border-bottom: none">
    <div class="col">
      <p class="text-left" translate>TOTAL_PRICE</p>
    </div>
    <div class="col">
      <p class="text-right">{{currencyCode}}
        {{ grandTotal
              | gungCurrency
                : currencyCode
                : (isPortalNo ? 'code' : 'symbol-narrow')
                : '1.2-2' : (isCurrysPortal ? 'en-US' : 'sv_SE') 
        }}
      </p>
    </div>
  </div>

  <div class="mb-4" *ngIf="!!replyTo">
    <p class="text-center small" translate>
      THANK_YOU_QUESTIONS<a [href]="'mailto:' + replyTo"> {{ replyTo }}</a>
    </p>
  </div>
</div>
