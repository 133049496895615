import { Component, OnInit } from '@angular/core';
import { ListItemRendererComponent } from 'gung-list';
import { Product } from '../../../../models/product';

@Component({
  selector: 'lib-whiteboard-product-list-view',
  templateUrl: './whiteboard-product-list-view.component.html',
  styleUrls: ['./whiteboard-product-list-view.component.css']
})
export class WhiteboardProductListViewComponent extends ListItemRendererComponent<Product[]> implements OnInit {
  constructor() {
    super();
  }

  ngOnInit(): void {}
}
