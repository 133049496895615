<div class="table-responsive">
  <table class="table table-borderless table-striped table-hover">
    <thead>
      <tr>
        <th scope="col" class="hidden-ipad-down" translate>PRODUCT_ID</th>
        <th scope="col" class="hidden-ipad-up" translate>ID</th>
        <th scope="col" translate>PRODUCT_NAME</th>
        <th scope="col" class="hidden-ipad-down" translate>DESCRIPTION</th>
        <th scope="col" translate>PRICE</th>
        <th scope="col" translate *ngIf="isSales">AVAILABILITY</th>
        <th scope="col" class="hidden-ipad-down" translate>ORDERED</th>
        <th scope="col" class="hidden-ipad-down" translate>STATUS</th>
        <th scope="col"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let customerPurchasedProduct of customerPurchasedProducts">
        <td>
          {{ customerPurchasedProduct.purchasedProduct.id }}
        </td>
        <td>
          {{ getName(customerPurchasedProduct.purchasedProduct) }}
        </td>
        <td class="hidden-ipad-down">
          {{ getDescription(customerPurchasedProduct.purchasedProduct) }}
        </td>
        <td>
          {{
            customerPurchasedProduct.price.customerNetPrice.value
              | gungCurrency
                : customerPurchasedProduct.price.customerNetPrice.currencyCode
                : (isPortalNo ? 'code' : 'symbol-narrow')
                : '1.2-2'
          }}
        </td>
        <td *ngIf="isSales">
          <app-hl-display-availability [availability]="customerPurchasedProduct.availability">
          </app-hl-display-availability>
        </td>
        <td class="hidden-ipad-down">
          {{ customerPurchasedProduct.purchasedProduct.total }}
        </td>
        <td class="hidden-ipad-down">
          {{ customerPurchasedProduct.purchasedProduct.status | translate }}
        </td>
        <td class="btn-buy d-table-cell">
          <app-hl-display-buy-btn
            [stepAmount]="customerPurchasedProduct.purchasedProduct.extra.stepAmount"
            *ngIf="
              !customerPurchasedProduct.purchasedProduct.extra.oldProduct &&
                customerPurchasedProduct.purchasedProduct.status !== 'BLOCKED';
              else blockedButton
            "
            [minimumOrderQuantity]="getMinimumOrderQuantity(customerPurchasedProduct.purchasedProduct.extra)"
            [id]="customerPurchasedProduct.purchasedProduct.id"
          >
          </app-hl-display-buy-btn>
          <ng-template #blockedButton>
            <button type="button" class="btn btn-sm btn-primary w-100 disabled" disabed translate>ADD</button>
          </ng-template>
        </td>
      </tr>
    </tbody>
  </table>
</div>
