import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OrderService, SelectedCustomerService, Order, MetadataService, CustomerService } from 'gung-standard';
import { JeevesOrderListConfigService } from '../jeeves-order-list-config/jeeves-order-list-config.service';
import { Observable } from 'rxjs';
import { ConfigBaseFilter, ListLayout, ListLayoutMultipleComponent } from 'gung-list';
import { map } from 'rxjs';
import { JeevesOpenOrderTableComponent } from '../../components/jeeves-open-order-table/jeeves-open-order-table.component';
import { OpenOrdersConfigService } from 'gung-standard';
import { OpenOrderTableComponent } from 'gung-standard';

@Injectable({
  providedIn: 'root'
})
export class JeevesOpenOrdersListConfigService extends OpenOrdersConfigService {
  constructor(
    protected translationService: TranslateService,
    protected orderService: OrderService,
    protected selectedCustomerService: SelectedCustomerService,
    protected metadataService: MetadataService,
    protected customerService: CustomerService
  ) {
    super(translationService, orderService, selectedCustomerService, metadataService, customerService);
  }

  getLayouts(): ListLayout<Order>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => OpenOrderTableComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'Product grid'
      }
    ];
  }
}
