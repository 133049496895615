import { Component, HostListener, ViewChild } from '@angular/core';
import { debounceTime, distinctUntilChanged, filter, first, forkJoin, map, mergeMap, of, OperatorFunction, switchMap } from 'rxjs';
import { Observable } from 'rxjs';
import { NgbAccordion, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ListItemRendererComponent, SearchRequest } from 'gung-list';
import { Cart } from '../../models/cart';
import { CartService } from '../../services/cart/cart.service';
import { ProductService } from '../../services/products/product.service';
import { Product } from '../../models/product';
import { CartsService } from '../../services/carts/carts.service';
import { GungModalService } from '../../services/gung-modal/gung-modal.service';
import { DateUtilService } from 'gung-common';
import { PriceService } from '../../services/price/price.service';
import { LoadSavedCartModalComponent } from '../load-saved-cart-modal/load-saved-cart-modal.component';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { OnInit } from '@angular/core';
import { OnChanges } from '@angular/core';
import { gungAddRemoveSpinner, gungComparatorHelper } from '../../utils/gung-utils';
import { DeleteSavedCartModalComponent } from '../delete-saved-card/delete-saved-card.component';
import { SavedCart } from '../../services/saved-cart-config/saved-cart-config.service';
import { Assortment, AssortmentService } from '../../services/assortment.service';
import { ProductInputQuantityConfigService } from '../../services/product-input-quantity-config/product-input-quantity-config.service';
import { CartRow } from '../../state/cart/types';

@Component({
  selector: 'lib-saved-cart-table',
  templateUrl: './saved-cart-table.component.html',
  styleUrls: ['./saved-cart-table.component.scss']
})
export class SavedCartTableComponent extends ListItemRendererComponent<Cart[]> {
  mapData: {
    [id: string]: {
      qty: number;
      product: Product;
      productId: string;
    }[];
  } = {};
  totalPrice = 0; // Should not be used, use totalPriceByCart
  totalPriceByCart: {[cartId: string]: number} = {};
  currencyCode: string;

  @ViewChild('acc') accordion: NgbAccordion;

  public modalRef: Map<string, NgbModalRef> = new Map<string, NgbModalRef>();
  saveData = {};
  assortment: Assortment;
  minQty = 1;
  step = 1;
  allowManualQtyInputOutsideStepSizes = false;
  public quantity: number;
  public product: Product;

  constructor(
    protected translateService: TranslateService,
    protected cartsService: CartsService,
    protected productService: ProductService,
    protected cartService: CartService,
    protected modalService: GungModalService,
    public dateUtilService: DateUtilService,
    protected priceService: PriceService,
    protected ngbModal: NgbModal,
    protected productInputQuantityConfigService: ProductInputQuantityConfigService,
    protected assortmentService: AssortmentService,
  ) {
    super();

    this.assortmentService
      .getUserAssortment()
      .pipe(first())
      .subscribe(assortment => {
        this.assortment = assortment;
      });
  }



  protected fetchData(cart: Cart): Observable<{ qty: number; product: Product; productId: string }[]> {
    if (!this.saveData[cart.id]) {
      this.saveData[cart.id] = false;
    }
    this.totalPrice = 0; // Should not be used, use totalPriceByCart
    this.totalPriceByCart[cart.id] = 0;
    const ids = cart.data.rows.map(row => row.productId || row.id);
    return this.productService.getProductsByIds(ids).pipe(
      first(),
      mergeMap(products => {
        const productsIds = products.map(p => p.id);
        return forkJoin({
          products: of(products),
          prices: this.priceService.getCurrentCustomerPrices(productsIds).pipe(first())
        });
      }),
      map(({ products, prices }) => {
        const data: { qty: number; product: Product; productId: string }[] = [];

        cart.data.rows = cart.data.rows.sort((c1, c2) => gungComparatorHelper(c1?.extra.cartOrder, c2?.extra.cartOrder, 1));
        for (const [i, row] of cart.data.rows.entries()) {
          if (!row.extra.cartOrder) {
            row.extra.cartOrder = i + 1;
          }
        }

        cart.data.rows.map(row => {
          const qty = row.qty || row.quantity;
          // in case product was not presented in the response
          // it means the product is excluded (due to blocked, not belong to flow, removed)
          const product: Product = products.find(p => p.id === (row.productId || row.id));

          if (product) {
            const price = prices.find(pr => pr.productId === product.id);
            if (price) {
              product.extra.price = price.customerNetPrice;
              if (this.currencyCode == null) {
                this.currencyCode = price.customerNetPrice.currencyCode;
              }
              this.totalPrice += price.customerNetPrice.value * qty; // Should not be used, use totalPriceByCart
              this.totalPriceByCart[cart.id] += price.customerNetPrice.value * qty;
            }
            data.push(this.mapDataProduct(qty, product, row));
          } else {
            // To add the productId to list of excluded ids when loading cart
            data.push(this.mapDataProduct(qty, undefined, row));
          }
        });

        return data;
      })
    );
  }

  fetchProductData(cart: Cart, forceUpdate?: boolean): void {
    this.fetchData(cart).subscribe(data => {
      this.setMappedData(cart.id, data, forceUpdate);
    });
  }

  deleteCart(cart: SavedCart) {
    const ref = this.modalService.openDeleteSavedCart(cart);
    ref.result.then(
      result => {
        if (result) {
          const loadedCardId = this.cartsService.getLoadedCardId();
          if (loadedCardId && loadedCardId === cart.id + '#' + cart.name) {
            this.cartsService.removeLoadedCardId();
          }
          this.cartsService
            .deleteCart(cart.id)
            .pipe()
            .subscribe(() => {
              this.cartsService.updateSavedCartsSubject();
            });
        }
      },
      () => undefined
    );
  }

  loadCart(cart: Cart) {
    this.fetchData(cart).subscribe(() => {
      const id = LoadSavedCartModalComponent.name;
      const ref = this.ngbModal.open(LoadSavedCartModalComponent, {
        size: 'md'
      });
      ref.componentInstance.delegate = this;
      ref.result.then(
        result => {
          if (result) {
            if (!this.accordion.isExpanded(cart.id)) {
              this.accordion.expand(cart.id);
            }
            if (result === 'replace') {
              this.loadProductToCart(cart, true);
            } else if (result === 'add') {
              this.loadProductToCart(cart, false);
            }
          } else {
            this.accordion.collapse(cart.id);
          }
        },
        reason => {
          // Keep panel closed
          this.accordion.collapse(cart.id);
        }
      );
    });
  }

  loadProductToCart(cart: Cart, replace: boolean) {
    this.fetchData(cart).subscribe(data => {
      this.setMappedData(cart.id, data);
      if (replace) {
        this.cartService.clearCart();
      }
      console.log(data.filter(d => !d.product));
      const excludedProductIds = data.filter(d => !d.product).map(d => d.productId);


      if (excludedProductIds.length > 0) {
        this.modalService
          .openConfirmYesNoModal(
            this.translateService.instant('EXCLUDED_PRODUCTS'),
            this.translateService.instant('EXCLUDED_PRODUCTS_MESSAGE', { products: excludedProductIds.join(', ') }),
            null,
            'OK',
            null
          )
          .then(proceed => {
            if (proceed) {
              this.addToCart(cart, excludedProductIds);
            }
          });
      } else {
        this.addToCart(cart, excludedProductIds);
      }
    });

    this.cartsService.setLoadedCardId(cart.id + '#' + cart.name);
  }

  protected addToCart(cart: Cart, excludedProductIds: string[]): void {
    // do not include all the excluded products
    const bulkData = cart.data.rows
      .filter(row => !excludedProductIds.includes(row.productId || row.id))
      .map(row => {
        return {
          productId: row.productId || row.id,
          targetStockId: row.targetStockId,
          qty: row.qty || row.quantity,
          extra: row.extra
        };
      });

    this.cartService.bulkAddToCart(bulkData);
    this.cartService.bulkSetExtra(bulkData);
  }

  protected setMappedData(cartId: string, data: { qty: number; product: Product; productId: string }[], forceUpdate?: boolean): void {
    // include only all the row whose product is not excluded
    if (!this.mapData[cartId] || forceUpdate) {
      this.mapData[cartId] = data
        .filter(row => !!row.product)
        .map(row => {
          return {
            qty: row.qty,
            product: row.product,
            productId: row.productId
          };
        });
    }
  }

  protected mapDataProduct(qty, product, row) {
    return {
      qty,
      product,
      productId: row.productId || row.id
    };
  }

  saveCartOrder(cartPosition: number, { target }) {
    gungAddRemoveSpinner(target);
    if (this.saveData[this.data[cartPosition].id] === true) {
      this.cartsService.updateSavedCart(this.data[cartPosition]).subscribe(cart => {
        this.saveData[this.data[cartPosition].id] = false;
        gungAddRemoveSpinner(target);
      });
    }
  }

  drop(cartPosition: number, event: CdkDragDrop<string[]>) {
    if (event.currentIndex !== event.previousIndex) {
      moveItemInArray(this.data[cartPosition].data.rows, event.previousIndex, event.currentIndex);
      for (const [i, row] of this.data[cartPosition].data.rows.entries()) {
        row.extra.cartOrder = i + 1;
      }
      this.fetchProductData(this.data[cartPosition], true);
      this.saveData[this.data[cartPosition].id] = true;
    }
  }

  deleteRow(cartPosition: number, row: CartRow, cart: SavedCart) {
    cart.data.rows = cart.data.rows.filter(r => r.productId !== row.productId);
    this.updateMappedData(cart.id, cart.data.rows);
    this.fetchProductData(cart, true);
    this.saveData[this.data[cartPosition].id] = true;
  }

  refreshQty(row: CartRow, cart: SavedCart) {
    this.totalPrice = 0; // Should not be used, use totalPriceByCart
    this.totalPriceByCart[cart.id] = 0;
    const mapDataCart = this.mapData[cart.id];
    for (const c of mapDataCart) {
      const price = c.product.extra.price.value;
      this.totalPrice += c.qty * price; // Should not be used, use totalPriceByCart
      this.totalPriceByCart[cart.id] += c.qty * price;
    }
    cart.extra._total = this.totalPrice;
  }

  protected updateMappedData(cartId: string, data: { qty: number; product: Product; productId: string }[]): void {
    // include only all the row whose product is not excluded
    if (this.mapData[cartId]) {
      this.mapData[cartId] = data
        .filter(row => !!row.product)
        .map(row => {
          return {
            qty: row.qty,
            product: row.product,
            productId: row.productId
          };
        });
    }
  }

  sub(cartPosition: number, row: CartRow, cart: SavedCart, event?) {
    const cartRow = cart.data.rows.find(r => r.productId === row.productId);
    const cartRow2 = this.mapData[cart.id].find(r => r.productId === row.productId);
    let { stepAmount, decimals, minimumOrderQuantity, recomendedInitOrderQuantity } = this.getProductValues(
      cartRow2.product as Product
    );
    let qty = cartRow2.qty;
    if (qty - stepAmount >= minimumOrderQuantity) {
      qty -= stepAmount;
    } else {
      qty = 0;
    }
    this.updateQuantity(qty, row, cart, event);
    this.saveData[this.data[cartPosition].id] = true;
    this.fetchProductData(cart);
  }

  add(cartPosition: number, row: CartRow, cart: SavedCart, event?) {
    const cartRow = cart.data.rows.find(r => r.productId === row.productId);
    const cartRow2 = this.mapData[cart.id].find(r => r.productId === row.productId);
    let { stepAmount, decimals, minimumOrderQuantity, recomendedInitOrderQuantity } = this.getProductValues(
      cartRow2.product as Product
    );
    let qty = cartRow2.qty;
    if (!qty) {
      qty = Math.max(minimumOrderQuantity, stepAmount, recomendedInitOrderQuantity);
    } else {
      qty += stepAmount;
    }
    this.updateQuantity(qty, row, cart, event);
    this.saveData[this.data[cartPosition].id] = true;
    this.fetchProductData(cart);
  }

  updateQuantity(qty, row: CartRow, cart: SavedCart, event?) {
    const cartRow = cart.data.rows.find(r => r.productId === row.productId);
    const cartRow2 = this.mapData[cart.id].find(r => r.productId === row.productId);
    let { stepAmount, decimals, minimumOrderQuantity, recomendedInitOrderQuantity } = this.getProductValues(
      cartRow2.product as Product
    );
    if (typeof qty === 'string') {
      qty = Number(qty);
    }
    const checkModulus = this.checkModulus(qty, stepAmount);
    if (qty >= 0 && !checkModulus.multiple) {
      if (cartRow2.qty - Math.floor(cartRow2.qty) !== 0 && cartRow2.product?.extra?.ar?.artfsgforp <= 0) {
        qty = +qty.toFixed(decimals);
      } else {
        qty = checkModulus.remainder;
      }
    } else if (qty >= 0 && checkModulus.remainder >= stepAmount) {
      qty = checkModulus.remainder;
    }
    qty = +qty.toFixed(decimals);
    cartRow.qty = qty;
    cartRow2.qty = qty;
    cartRow.quantity = qty;

    event.value = qty;
  }

  checkModulus(value: number, precision: number): { multiple: boolean; remainder: number } {
    value = Number(value);
    precision = Number(precision || 1);
    const result = { multiple: false, remainder: 0 };
    // result.multiple = Math.round(value / precision) / (1 / precision) === value;
    result.multiple = Number.isInteger(value / precision);
    let remainder = Math.round(value / precision) / (1 / precision);
    if (remainder < value) {
      remainder = remainder + precision;
    }
    result.remainder = remainder;
    return result;
  }

  getProductValues(product: Product) {
    let stepAmount = this.productInputQuantityConfigService.getStepAmount(product.id, product);
    const decimals = product?.extra?.ar?.antdec || 0;
    if (decimals && !stepAmount) {
      let stepAmount2 = '0.0000000001';
      stepAmount2 = stepAmount2.substring(0, 2) + stepAmount2.substring(stepAmount2.length - decimals);
      stepAmount = +parseFloat(stepAmount2);
    }
    return {
      stepAmount,
      decimals,
      minimumOrderQuantity: this.productInputQuantityConfigService.getMinimumOrderQuantity(product.id, product),
      recomendedInitOrderQuantity: this.productInputQuantityConfigService.getRecomendedInitOrderQuantity(
        product.id,
        product
      )
    };
  }

  addProductToCart(cart: SavedCart, cartPosition: number) {
    this.modalService.openSavedCartProductModal(cart).then(
      result => {
        if (!!result && !!result.changed) {
          this.fetchProductData(cart, true);
          this.fetchData(cart);
          this.saveData[this.data[cartPosition].id] = true;
        }
      },
      reason => { }
    );
  }
}
