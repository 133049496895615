import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Customer } from '../../models';
import { CustomerService } from '../../services/customers/customer.service';
import { first } from 'rxjs';

@Component({
  selector: 'lib-customer-details',
  templateUrl: './customer-details.component.html',
  styleUrls: ['./customer-details.component.css']
})
export class CustomerDetailsComponent implements OnInit {
  customer: Customer;

  constructor(protected route: ActivatedRoute, protected customerService: CustomerService) {}

  ngOnInit() {
    const routeParams = this.route.snapshot.params;
    if (routeParams.customerId != null) {
      this.customerService
        .getCustomer(routeParams.customerId)
        .pipe(first())
        .subscribe(customer => {
          this.customer = customer;
        });
    }
  }
}
