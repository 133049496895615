import { NgModule } from '@angular/core';
import { FilterListComponent } from './filter-list/filter-list.component';
import { GridLayoutComponent } from './grid-layout/grid-layout.component';
import { CustomHostDirective } from './custom-host/custom-host.directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FilterListSideFilterComponent } from './filter-list-side-filter/filter-list-side-filter.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FilterListSearchComponent } from './filter-list-search/filter-list-search.component';
import { FilterListBatchComponent } from './filter-list-batch/filter-list-batch.component';
import { FilterListTopFilterComponent } from './filter-list-top-filter/filter-list-top-filter.component';
import { TopFilterListComponent } from './top-filter-list/top-filter-list.component';
import { TranslateModule } from '@ngx-translate/core';
import { SelectedFilterComponent } from './selected-filter/selected-filter.component';
import { ListLayoutMultipleComponent } from './list-layout-multiple/list-layout-multiple.component';
import { ListLayoutSingleComponent } from './list-layout-single/list-layout-single.component';
import { PaginationListComponent } from './pagination-list/pagination-list.component';
import { FastsearchComponent } from './fastsearch/fastsearch.component';
import { FastsearchLayoutComponent } from './fastsearch-layout/fastsearch-layout.component';
import { SelectionBarComponent } from './selection-bar/selection-bar.component';
import { ItemSelectionButtonComponent } from './item-selection-button/item-selection-button.component';
import { BackendFilterListComponent } from './backend-filter-list/backend-filter-list.component';
import { RangeSliderComponent } from './range-slider/range-slider.component';
import { InlineSearchComponent } from './inline-search/inline-search.component';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { InlineSearchLayoutComponent } from './inline-search-layout/inline-search-layout.component';
import { InlineSearchNavbarComponent } from './inline-search-navbar/inline-search-navbar.component';
import { InlineSearchLayoutMultipleComponent } from './inline-search-layout-multiple/inline-search-layout-multiple.component';
import { FilterListSideFilterPanelComponent } from './filter-list-side-filter-panel/filter-list-side-filter-panel.component';
import { SliderComponent } from './slider/slider.component';
import { RouterModule } from '@angular/router';
import { CustomHostDynamicDirective } from './custom-host-dynamic/custom-host-dynamic.directive';
import { FilterDateRangeComponent } from './filter-date-range/filter-date-range.component';
import { CommonModule } from '@angular/common';
import { SimpleListComponent } from './simple-list/simple-list.component';
import { NgxSliderModule } from 'ngx-slider-v2';

@NgModule({
  declarations: [
    CustomHostDirective,
    CustomHostDynamicDirective,
    FilterListComponent,
    GridLayoutComponent,
    ListLayoutSingleComponent,
    FilterListSideFilterComponent,
    FilterListSearchComponent,
    FilterListBatchComponent,
    FilterListTopFilterComponent,
    TopFilterListComponent,
    SelectedFilterComponent,
    ListLayoutMultipleComponent,
    PaginationListComponent,
    FastsearchComponent,
    FastsearchLayoutComponent,
    SelectionBarComponent,
    ItemSelectionButtonComponent,
    BackendFilterListComponent,
    RangeSliderComponent,
    InlineSearchComponent,
    SafeHtmlPipe,
    InlineSearchLayoutComponent,
    InlineSearchNavbarComponent,
    InlineSearchLayoutMultipleComponent,
    FilterListSideFilterPanelComponent,
    SliderComponent,
    FilterDateRangeComponent,
    SimpleListComponent
  ],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, RouterModule, NgbModule, TranslateModule, NgxSliderModule],
  exports: [
    FilterListComponent,
    CustomHostDirective,
    CustomHostDynamicDirective,
    ListLayoutSingleComponent,
    PaginationListComponent,
    TopFilterListComponent,
    FilterListSearchComponent,
    FilterListSideFilterComponent,
    FastsearchComponent,
    FastsearchLayoutComponent,
    ItemSelectionButtonComponent,
    SelectionBarComponent,
    SelectedFilterComponent,
    FilterListBatchComponent,
    FilterListTopFilterComponent,
    BackendFilterListComponent,
    InlineSearchComponent,
    SafeHtmlPipe,
    InlineSearchNavbarComponent,
    FilterListSideFilterPanelComponent,
    SliderComponent,
    RangeSliderComponent,
    SimpleListComponent
  ]
})
export class GungListModule {}
