<!-- <app-hl-display-sales-cart-row [productId]="data.productId"></app-hl-display-sales-cart-row> -->
<!--  <div *ngFor="let row of mappedData; trackBy: trackByFn" class="pt-2 pb-2">
  <app-hl-display-sales-cart-row [id]="row.productId" [name]="row.name" [quantity]="row.quantity"
    [price]="row.overridePrice || row.originalPrice"
    [discount]="row.discountPercent || 0"
    [total]='calculateTotal(row) | gungCurrency: row.currency'
    [delivery]="row.deliveryMethod"
    [row]="row.cartRow"
    [initialDate]="row.deliveryDate"
    (deleteClicked)='removeRow(row)' (discountChanged)='updateDiscountPercentage(row, $event)' (priceChanged)='updateOverridePrice(row, $event)' (quantityChanged)='updateQty(row, $event)' (dateChanged)='onDeliveryDateSelect(row, $event)'>
  </app-hl-display-sales-cart-row>
</div>    -->

<div class="row sales-cart-row header-row pb-2 pt-2 d-none d-md-flex">
  <div class="col-1 order-md-0"></div>
  <div class="d-none d-md-block col-md-2 order-md-1" translate>NAME</div>
  <div class="col-4 col-md-1 order-md-1" translate>QUANTITY</div> 
  <div class="col-4 col-md-2 order-md-1" translate>PRICE</div>
  <div class="col-4 col-md-2 order-md-4" translate>AVAILABLE_SHORT</div>
  <div class="col-4 col-md-1 order-md-5" translate>TOTAL</div>
  <div class="col-md-3 order-md-6"></div>
</div>

<div class="row sales-cart-row pb-2 pt-2 align-items-center" *ngFor="let row of mappedData; trackBy: trackByFn">
  <div class="col-2 col-md-1 d-flex align-items-center">
    <button class="btn btn-secondary delete-btn" type="button" (click)="removeRow(row)">
      <i class="fas fa-times"></i>
    </button>
  </div>
  <div class="col-10 col-md-2 order-md-1">
    <h5 class="d-inline-block d-md-none">
      <a routerLink="/product/{{ row.productId }}"
        >{{ row.productId }}
        -
        <span *ngIf="row.cartRow.extra.orp && row.cartRow.extra.orp.artbeskr">
          {{ row.cartRow.extra.orp.artbeskr }}
        </span>
        <span *ngIf="!row.cartRow.extra.orp || !row.cartRow.extra.orp.artbeskr">
          <span *ngIf="isSalesPortal">{{ getName(row.product) }}</span>
          <span *ngIf="!isSalesPortal">{{ row.name }}</span>
        </span>
      </a>
    </h5>
    <div class="d-none d-md-inline-block">
      <a routerLink="/product/{{ row.productId }}">{{ row.productId }}</a>
      -
      <span *ngIf="row.cartRow.extra.orp && row.cartRow.extra.orp.artbeskr">
        {{ row.cartRow.extra.orp.artbeskr }}
      </span>
      <span *ngIf="!row.cartRow.extra.orp || !row.cartRow.extra.orp.artbeskr">
        <span *ngIf="isSalesPortal">{{ getName(row.product) }}</span>
        <span *ngIf="!isSalesPortal">{{ row.name }}</span>
      </span>
    </div>
  </div>
  <div class="col-6 col-md-1 order-md-2">
    <div class="row">
      <div class="col-4 d-flex d-md-none">
        <span translate>QUANTITY</span>
      </div>
      <div class="col-8 d-flex col-md-12">
        <input
          type="text"
          class="form-control"
          [ngModel]="row.quantity | number: '1.0-0'"
          (blur)="updateQty(row, $event.target.value)"
        />
      </div>
    </div>
  </div>
  <div class="col-6 col-md-2 order-md-3">
    <div class="row">
      <div class="col-4 d-flex d-md-none">
        <span translate>PRICE</span>
      </div>
      <div class="col-8 d-flex col-md-12">
        <input
          type="text"
          class="form-control"
          [placeholder]="row.originalPrice | number: '1.2-2'"
          [ngModel]="row.overridePrice"
          (blur)="updateOverridePrice(row, $event.target.value)"
          (ngModelChange)="(false)"
          [readonly] = readOnlyPrice
        />
      </div>
    </div>
  </div>
  <div class="col-6 col-md-2 order-md-4">
    <div class="row">
      <div class="col-4 d-flex d-md-none">
        <span translate>AVAILABLE_SHORT</span>
      </div>
      <div class="col-8 d-flex col-md-12">
        <div class="sales1210" *ngIf="is1210Bolag && isSalesPortal; else no1210">
          <span
            *ngIf="row.product.extra.availabilities[0].maxFutureStock === 100000; else showMaxFutureStock" translate>STOCK_REPLENISHMENT
          </span>
          <ng-template #showMaxFutureStock>
            {{ row.product.extra.availabilities[0].dateMaxFutureStock }} /
            {{ row.product.extra.availabilities[0].maxFutureStock || 0 | number }}
          </ng-template>
        </div>
        <ng-template #no1210>
          <span>
            {{ row.currentAvailability - row.quantity >= 0 ? row.currentAvailability - row.quantity : 0 }}
          </span>
        </ng-template>
      </div>
    </div>
  </div>
  <div class="col-6 col-md-1 order-md-5">
    <div class="row">
      <div class="col-4 d-flex d-md-none">
        <span translate>TOTAL</span>
      </div>
      <div class="col-8 col-md-12">
        <span>
          {{ row.overridePrice * row.quantity || row.originalPrice * row.quantity | number: '1.2-2' }}
        </span>
      </div>
    </div>
  </div>
  <div class="col-12 col-md-3 order-md-6">
    <div class="row d-flex">
      <div class="col-2 d-md-none d-flex">
        <span translate>DATE</span>
      </div>
      <div class="col-10 col-md-12">
        <div class="row">
          <div *ngIf="!isCurrysPortal" class="col-10">
            <app-date-picker
              [deliveryMethod]="row.deliveryMethod"
              [row]="row.cartRow"
              class="flex-grow-1"
              [initialDate]="parseDate( row.deliveryDate )"
              (selectedDate)="overrideDate(row, $event)"
            >
            </app-date-picker>
          </div>
          <div class="col-2 p-0">
            <button
              *ngIf="showComments"
              type="button"
              class="btn btn-link"
              [ngClass]="{ 'text-primary': row.cartRow.extra.orp && row.cartRow.extra.orp.Editext }"
              (click)="openCommentsModal(row)"
            >
              <i class="far fa-file-alt fa-2x"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="pt-3" *ngIf="isSalesPortal">
  <button *ngIf="isSalesPortal" class="btn btn-primary btn-circle" type="button" (click)="addNewOrderLine()">
    <i class="fas fa-plus"></i>
  </button>
  <button
    *ngIf="isPortalDe"
    class="btn btn-primary btn-circle mx-2"
    type="button"
    (click)="addInstallationCharge(0.10)"
  >
    10%
  </button>
  <button *ngIf="isPortalDe" class="btn btn-primary btn-circle" type="button" (click)="addInstallationCharge(0.08)">
    8%
  </button>
</div>
