import { ChangeDetectorRef, Component, ComponentFactoryResolver } from '@angular/core';
import { ListLayoutComponent, ListLayoutSingleComponent } from 'gung-list';

@Component({
  selector: 'app-hl-display-order-list-layout',
  templateUrl: './hl-display-order-list-layout.component.html',
  styleUrls: ['./hl-display-order-list-layout.component.scss']
})
export class HlDisplayOrderListLayoutComponent<T> extends ListLayoutSingleComponent<T> {
  constructor(public componentFactoryResolver: ComponentFactoryResolver, public changeDetectorRef: ChangeDetectorRef) {
    super(componentFactoryResolver, changeDetectorRef);
  }
}
