<div class="pb-4 border shadow-sm bg-white">
  <div class="row">
    <div class="col-12">
      <div class="image-container p-2">
        <h1 class="page-label text-center" [innerHTML]="'SELECT_ORDER' | translate"></h1>
      </div>
    </div>
  </div>
  <div class="row my-5">
    <div class="col-12">
      <lib-filter-list #orderList [configService]="orderListConfigService"> </lib-filter-list>
    </div>
  </div>
</div>
