import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ListItemRendererComponent } from 'gung-list';
import { first } from 'rxjs';
import { News } from '../../../models/news';
import { GungModalService } from '../../../services/gung-modal/gung-modal.service';
import { NewsService } from '../../../services/news/news.service';
import { DateUtilService } from 'gung-common';

@Component({
  selector: 'lib-news-editor-table',
  templateUrl: './news-editor-table.component.html',
  styleUrls: ['./news-editor-table.component.css']
})
export class NewsEditorTableComponent extends ListItemRendererComponent<News[]> implements OnInit {
  constructor(
    protected gungModalService: GungModalService,
    protected newsService: NewsService,
    protected translationService: TranslateService,
    public dateUtilService: DateUtilService
  ) {
    super();
  }

  ngOnInit(): void {}

  openEditNewsModal(news: News): void {
    this.gungModalService.openEditorNewsModal(news);
  }

  removeNews(news: News): void {
    this.gungModalService
      .openConfirmYesNoModal(undefined, this.translationService.instant('CONFIRM_DELETION', { value: '' }) + '?', {
        size: 'sm'
      })
      .then(
        result => {
          if (result) {
            this.sendDeleteRequest(news);
          }
        },
        reason => {}
      );
  }

  sendDeleteRequest(news: News): void {
    this.newsService.deleteNews(news.id).pipe(first()).subscribe();
  }
}
