import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { gungGetUrlFormated } from 'gung-standard';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { filter } from 'rxjs';
import { environment } from '../../../environments/environment';

interface SliderPromotion {
  id: number;
  title?: string;
  description?: string;
  innerHTML?: string;
  button: string;
  link: string;
  background: string;
  mainCustomer: string;
  mainCountry: string;
  customClass?: string;
  queryParams?: any;
  subCustomer?: string;
}

@Component({
  selector: 'app-hl-display-slider-promotion',
  templateUrl: './hl-display-slider-promotion.component.html',
  styleUrls: ['./hl-display-slider-promotion.component.scss'],
})
export class HlDisplaySliderPromotionComponent implements OnInit {
  public sliderContent: SliderPromotion[] = [
    {
      id: 0,
      title: 'Shelf automation',
      description:
        'Reducing time spent on fronting, refilling and planogram changes is a key factor for today’s retailers.',
      innerHTML: '',
      button: 'See products',
      link: '/products?filters=CATEGORY__:__PriceCommunication',
      background: './assets/Image_1.jpg',
      customClass: '',
      mainCustomer: '',
      mainCountry: 'General',
      subCustomer: 'Inter'
    }
    // {
    //   id: 0,
    //   title: 'Welcome to the customer portal of HL Display',
    //   description: 'The better shopping experience',
    //   innerHTML: '',
    //   button: 'GO_TO_ALL_PRODUCTS',
    //   link: '/products',
    //   background: './assets/header-image.jpg',
    //   customClass: ''
    // },
  ];

  mainCustomer: string = environment.mainCustomer;
  mainCountry: string = environment.mainCountry;
  subCustomer: string = environment.subCustomer;

  customOptions: OwlOptions = {
    loop: true,
    autoplay: true,
    center: true,
    dots: true,
    autoHeight: true,
    autoWidth: true,
    margin:10,
    items: 1,
    autoplayTimeout:4000,
    autoplayHoverPause:false,
    responsive:{
      0:{
          items:1,
      },
    }
  }

  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    this.getSliderPromotions()
      .pipe(filter(sliders => !!sliders))
      .subscribe(sliders => {
        this.mapData(sliders);
      });
  }

  mapData(sliderContent: SliderPromotion[]) {
    const optionsMainCustomer = [...new Set(sliderContent.map(s => s.mainCustomer))];
    const optionsMainCountry = [...new Set(sliderContent.map(s => s.mainCountry))];
    const optionsSubCustomer = [...new Set(sliderContent.map(s => s.subCustomer))];
    this.sliderContent = sliderContent
      .filter(slider => {
        // Filter by mainCustomer
        let flagMainCustomer = slider.mainCustomer === '' && !this.mainCustomer;
        let flagMainCountry = slider.mainCountry === '' && !this.mainCountry;
        let flagSubCustomer = !slider.subCustomer && !this.subCustomer;
        if (!!this.mainCustomer && !!slider.mainCustomer) {
          flagMainCustomer = slider.mainCustomer === this.mainCustomer;
        }
        if (!!this.mainCountry && !!slider.mainCountry) {
          flagMainCountry = slider.mainCountry === this.mainCountry;
        }
        if (!!this.subCustomer && !!slider.subCustomer) {
          flagSubCustomer = slider.subCustomer === this.subCustomer;
        }
        
        return flagMainCustomer && flagMainCountry && flagSubCustomer;
      })
      .map(slider => {
        // Map data
        this.formatUrl(slider);
        return slider;
      });
  }

  getSliderPromotions() {
    const url = `json/hl-display/slider`;
    const headers = {
      maxAge: '-1'
    };
    return this.http.get<SliderPromotion[]>(url, { headers });
  }

  formatUrl(slider: SliderPromotion) {
    const link = gungGetUrlFormated({ url: slider.link });
    slider.queryParams = link.queryParams;
    slider.link = link.url;
  }
}
