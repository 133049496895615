<div class="row">
  <div class="col-12 text-center">
    <h3>{{ 'PRODUCT_EXPORT_CATEGORY_MAPPING' | translate | uppercase }}</h3>
  </div>
</div>
<div class="row mt-4">
  <div class="col-12 text-right">
    <small *ngIf="unsaved" class="text-danger mr-2"
      ><i class="fas fa-exclamation-triangle mr-1"></i><span translate>UNSAVED</span></small
    >
    <button type="button" class="btn btn-primary" (click)="onSave()" translate>SAVE</button>
    <button type="button" class="btn btn-secondary" (click)="onCreate()" translate>CREATE_NEW</button>
  </div>
</div>
<div class="row mt-4">
  <div class="col-12">
    <table class="table table-striped">
      <thead class="thead-dark">
        <tr>
          <th scope="col" id="idImage" translate>IMAGE</th>
          <th scope="col" id="idPosition" translate>POSITION_IN_EXPORT</th>
          <th scope="col" id="idSeason" translate>SEASON</th>
          <th scope="col" id="idCategory" translate>CATEGORY_VALUE</th>
          <th scope="col" id="idDelete" translate>DELETE</th>
          <th scope="col" class="text-center" id="idMove" translate>MOVE</th>
        </tr>
      </thead>
      <tbody cdkDropList (cdkDropListDropped)="drop($event)">
        <tr *ngFor="let row of sheets; index as i" cdkDrag>
          <td colspan="7" class="p-0" *cdkDragPlaceholder>
            <div class="row-ist-placeholder"></div>
          </td>
          <td headers="idImage">
            <img
              [src]="row.image.s3Uri | gungImageUrl
                :'type:thumbnail'
                :'etag:'+row.image.s3ETag
              "
              (click)="onEditImagesClick(i)"
            />
          </td>
          <td headers="idPosition" scope="row">{{ i + 1 }}</td>
          <td headers="idSeason">{{ row.season }}</td>
          <td headers="idCategory">{{ row.category }}</td>
          <td headers="idDelete">
            <button type="button" class="btn btn-danger" (click)="onDelete(row.id)" translate>DELETE</button>
          </td>
          <td
            headers="idMove"
            class="text-center cursor-move"
            width="10%"
            cdkDragHandle
            [cdkDragHandleDisabled]="loader"
            [class.cdk-drag-handle-disabled]="loader"
          >
            <i class="fas fa-arrows-alt"></i>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
