<nav
  class="navbar navbar-expand-lg fixed-top"
  [ngClass]="{ 'logged-out': !isSignedIn(), 'navbar-shadow': displayShadow }"
>
  <button class="navbar-toggler" (click)="toggleMobileMenu()">
    <i class="fas fa-bars fa-lg"></i>
  </button>
  <a
    class="navbar-brand mr-lg-3 p-3"
    routerLink="/"
    [ngClass]="{ 'd-none d-sm-block': isSales, 'customer-portal-nav-bar-top-left': isCustomerPortal }"
  >
    <img src="./assets/logo.png" alt="" style="height: 100%" />
  </a>
  <div
    *ngIf="isSales"
    class="d-sm-none py-2 mr-lg-3 customer-mobile-name w-auto gung-flex-1"
    routerLink="/customers/{{ selectedCustomer?.id }}"
  >
    <div class="text-center">
      <span class="border-top-0">{{ isSignedIn() ? selectedCustomer.name : '&nbsp;' }}</span>
    </div>
  </div>
  <!-- <div class="collapse navbar-collapse">
    <span class="navbar-text"> The better shopping experience </span>
  </div> -->
  <ul class="navbar-nav flex-fill justify-content-end" *ngIf="!isSignedIn()">
    <li  [ngClass]="{ 'logged-out text-center': !isSignedIn()}" class="nav-item">
      <app-hl-lang-select></app-hl-lang-select>
    </li>
  </ul>
  <ul
    class="navbar-nav cart-nav"
    [ngClass]="{ 'customer-portal-nav-bar-top-right': isCustomerPortal }"
    *ngIf="isSignedIn()"
  >
    <li class="nav-item">
      <app-hl-display-navbar-cart></app-hl-display-navbar-cart>
    </li>
  </ul>
  <div class="collapse navbar-collapse" id="navbarNavDropdown" [ngClass]="baseCollapseClasses" *ngIf="isSignedIn()">
    <ul class="navbar-nav ml-auto">
      <li class="nav-item" *ngIf="selectedCustomer != undefined">
        <app-hl-display-customer-select-dropdown></app-hl-display-customer-select-dropdown>
      </li>
      <li class="nav-item" *ngIf="!isSales">
        <a class="nav-link" routerLink="/contact" routerLinkActive="active">
          <span class="d-none d-lg-block"><i class="fa fa-headset fa-lg"></i></span>
          <span class="d-lg-none"><span translate>CONTACT</span></span>
        </a>
      </li>
      <li class="nav-item" *ngIf="isSales && !isManagersPortal">
        <a class="nav-link" [href]="formLink" target="_blank" rel="noopener noreferrer">
          <span class="d-none d-lg-block"><i class="fas fa-file-signature fa-lg"></i></span>
          <span class="d-lg-none"><span translate>FORM</span></span>
        </a>
      </li>

      <li class="nav-item favourites" *ngIf="showFavourites">
        <a class="nav-link" routerLink="/favourites" routerLinkActive="active">
          <span class="d-none d-lg-block">
            <i class="fas fa-heart fa-lg"></i>
            <span class="badge badge-primary nav-bar-icon-badge">{{ favouritesCount }}</span>
          </span>
          <span class="d-lg-none"><span translate>FAVOURITES</span></span>
        </a>
      </li>

      <li class="nav-item" *ngIf="isSales">
        <a class="nav-link" routerLink="/customers" routerLinkActive="active">
          <span class="d-none d-lg-block"><i class="fa fa-users fa-lg"></i></span>
          <span class="d-lg-none"><span translate>CUSTOMERS</span></span>
        </a>
      </li>
      <!-- MARK: User Dropdown-->
      <li class="nav-item" ngbDropdown placement="left">
        <button class="nav-link btn btn-block" id="userDropDown" ngbDropdownToggle>
          <span class="d-none d-lg-block"><i class="fa fa-user fa-lg"></i></span>
          <span class="d-lg-none"><span translate>USER</span></span>
        </button>
        <div ngbDropdownMenu>
          <a
            *ngIf="selectedCustomer != undefined"
            routerLink="/customers/{{ selectedCustomer.id }}"
            routerLinkActive="active"
            ngbDropdownItem
          >
            <i class="fa fa-user mr-2"></i>
            <span *ngIf="!isSales" translate>MY_PAGES</span>
            <span *ngIf="isSales">{{ selectedCustomer.name }}</span>
          </a>
          <a routerLink="" (click)="logout()" ngbDropdownItem>
            <i class="fas fa-sign-out-alt mr-2"></i>
            <span translate>LOGOUT</span>
          </a>
        </div>
      </li>
      <li class="nav-item">
        <app-hl-lang-select></app-hl-lang-select>
      </li>
    </ul>
  </div>
  <div *ngIf="isSales" class="row d-none d-sm-block d-lg-none customer-mobile-name">
    <div class="col-12 text-center">
      <span [ngClass]="{ 'border-top-0': !isSignedIn() }">{{ isSignedIn() ? selectedCustomer.name : '&nbsp;' }}</span>
    </div>
  </div>
  <div class="navbar-top-extra-menu" [class.customer]="!isSales" *ngIf="isSignedIn()">
    <a
      *ngFor="let link of getExtraMenuLinks()"
      class="extra-menu-item"
      routerLinkActive="active"
      [routerLink]="link.routerLink"
      translate
      >{{ link.translateTag }}</a
    >
  </div>
  <!--
    Mini-sak, går det att lägga till en grå header på inloggningssidan?
    Den ser så naken ut nu. Exempel nedan.
    Ska vara på båda sälj och kundportal.
  -->
  <div class="navbar-top-extra-menu" [class.customer]="!isSales" *ngIf="!isSignedIn()">
    <span class="extra-menu-item">&nbsp;</span>
  </div>
</nav>
