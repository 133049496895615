import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable, Subject, of } from 'rxjs';
import { Type, Input, Directive, EventEmitter, Output, HostBinding } from '@angular/core';
import { FastsearchLayoutComponent } from './fastsearch-layout/fastsearch-layout.component';

export interface ConfigBaseFilter<T> {
  type: string;
  isActiveFromStart(): boolean;
  isPostFilter(): boolean;
  getName(): string;
  sort?(a, b): number;
  getSortField?(): string;
  getCustomClass?(): string;
  hideItemCounter?(); //Show numbe of products on filter
  disableSearch?: boolean;
  conceptGroupedPath?: string;
  showTodayDate?: boolean;
}

export interface ConfigFilterGroup {
  mainFilter: string | RenderFilter;
  subFilter: string | RenderFilter;
}

export abstract class SimpleConfigBaseFilter<T> implements ConfigBaseFilter<T> {
  type: 'SimpleConfigBaseFilter' | 'RangeConfigBaseFilter' | 'ColorConfigBaseFilter' | 'dateRangeFilter' | 'IconConfigBaseFilter' | string = 'SimpleConfigBaseFilter';
  isActiveFromStart = () => false;
  isPostFilter = () => true;
  abstract getName(): string;
  abstract getOptionIds(item: T): string[];
  abstract getOptionName(key: string): string;
  getSortingIndex?(optionId?: string): string;
  sort?(a, b): number;
  getSortField?(): string;
  hideItemCounter = () => false; //Show numbe of products on filter
  showTodayDate?: boolean;
}

export abstract class ConceptGroupedConfigBaseFilter<T> extends SimpleConfigBaseFilter<T> {
  type: 'ConceptGroupedConfigBaseFilter' | string = 'ConceptGroupedConfigBaseFilter';
}

export abstract class SubSimpleConfigBaseFilter<T> extends SimpleConfigBaseFilter<T> {
  type: 'SubSimpleConfigBaseFilter' | string = 'SubSimpleConfigBaseFilter';
  abstract subFilter: SimpleConfigBaseFilter<T>;
}

export abstract class IconConfigBaseFilter<T> extends SimpleConfigBaseFilter<T> {
  type: 'IconConfigBaseFilter' | string = 'IconConfigBaseFilter';
  abstract getIcon(key: string, item: T): string;
  abstract getOptionName(key: string, item?: T): string;
}

export interface RenderFilter {
  type: string;
  name: string;
  active: boolean;
  isPostFilter: boolean;
  excludedIds: string[];
  valueList: RenderFilterValueList[];
  hideItemCounter?: boolean;
  sortField?: string;
  customClass?: string;
  sort?(a, b): number;
  maxValue?: number;
  minValue?: number;
  showTodayDate?: boolean;
  disableSearch?: boolean;
  conceptGroupedPath?: string;
  conceptGroupedChoiceMap?: { [s: string]: any }
}

export interface SubRenderFilter extends RenderFilter {
  subName: string;
  valueList: RenderFilterSubValueList[];
}

export interface RenderFilterValueList {
  valueId: string;
  valueName: string;
  itemsIncluded: string[];
  selected: boolean;
  itemCountTotal: number;
  itemCountAfterFilter: number;
  hide: boolean;
  sortIndex?: string;
}

export interface RenderFilterSubValueList extends RenderFilterValueList{
  subValueList: RenderFilterValueList[];
}

export interface RenderFilterIconValueList extends RenderFilterValueList{
  icon: string;
}

export interface FilterSelectionAction {
  filterName: string;
  optionId: string;
  selected: boolean;
  optionIdRange?: string[];
}

export interface SubFilterSelectionAction extends FilterSelectionAction {
  subFilterName?: string;
  subOptionId?: string;
}

export interface Pagination {
  totalPages: number;
  currentPage: number;
}

type Comparator<T> = (item1: T, item2: T) => number;
export interface ListSortOption<T> {
  getComparator(): Comparator<T>;
  getLabel(): string;
}

export interface ListLayout<T> {
  getIconClass(): string;
  getName(): string;
  getListLayoutComponent(): Type<ListLayoutComponent<T>>;
  getListItemComponent(): Type<ListItemRendererComponent<T | T[]>>;
}
export interface SearchField<T> {
  getSearchTerms(item: T): string[];
  // this is the key of the search field. It needs to be unique for each list on the same page.
  getKey(): string;
  getComponent(): Type<SearchComponent>;
  mapSearchTerm?(query: string): string[];
  filterBySearchTerms?(queryTerms: string[], itemTerms: string[]): boolean;
  getPlaceHolder?(): string;
  componentClass?(): string;
}
export interface ConfigService<T> {
  topFilter?: boolean;
  paginationList?: boolean;
  selectedFilterTop?: boolean;
  searchDisabled?: boolean;
  searchAboveFilters?: boolean;
  showHeaderBanner?: boolean;
  itemsPerRow?: number;
  showFilterTitle?: 'minimal' | 'group';

  getItems(route?: ActivatedRouteSnapshot): Observable<T[]>;
  getFilters(route?: ActivatedRouteSnapshot): ConfigBaseFilter<T>[];
  getSortOptions(route?: ActivatedRouteSnapshot): ListSortOption<T>[];
  getBatchSizes(route?: ActivatedRouteSnapshot): number[] | undefined;
  getLayouts(route?: ActivatedRouteSnapshot): ListLayout<T>[];
  getPreviousLayout?(): number;
  setPreviousLayout?(val: number): void;
  getSearchTerms(item: T): string[];
  getItemId(item: T): string;
  getLoadMoreCss?(): string;
  getFilterGroupCss?(): string;
  getSearchGroupCss?(): string;
  getNothingFoundTranslateTag?(): string;
  getSearchPlaceholderTranslate?(): string;
  getSearchFields?(): SearchField<T>[];
  hideZeroOptions?(): boolean;
  getSelectionActions?(): Observable<SelectionAction<T>[]>;
  getSelectionActionsButtonTitle?(): string;
  getSelectionMarkingActions?(): Observable<SelectionAction<T>[]>;
  getAddQueryParameterLimitToUrl?(): boolean;
  getDynamicColumns?(route?: ActivatedRouteSnapshot): PimTemplateProperties[];
  getPimOptions?(route?: ActivatedRouteSnapshot): { [s: string]: any };
  getCurrentFlow?();
  getFilterGroups?(): ConfigFilterGroup[];
  getBootstrapCols?(route?: ActivatedRouteSnapshot): number[] | undefined;
  getLimit?(route?: ActivatedRouteSnapshot): number | undefined;
  getParentClass?(): string;
  getChildColClassCss?(parentClass?: string): {[colClassCss: string]: boolean};
}

export interface ExportSelection<T> {
  selectedItems: { [itemId: string]: T };
  selectedItemCount: number;
}

export interface SelectionAction<T> {
  label: string;
  performAction(selection: ExportSelection<T>): Observable<any>;
}

export interface SelectionService<T> {
  getSelection(): Observable<ExportSelection<T>>;
  isItemSelected(item: T): Observable<boolean>;
  select(items: T | T[]): void;
  deselect(items: T | T[]): void;
  clearSelection(): void;
  getItemId(item: T): string;
  setSelectionsEnabled(selectionsEnabled: boolean): void;
  getSelectionsEnabled(): boolean;
}

export interface PaginationConfigService<T> extends ConfigService<T> {
  getPagedItems(request: SearchRequest): Observable<SearchResult<T>>;
  getSearchButtonEnabled?(): boolean;
  getSearchButtonText?(): string;
}

export interface SearchResult<T> {
  items: T[];
  totalItems: number;
  skipped: number;
}

export interface SearchRequest {
  terms: string[];
  skip: number;
  limit: number;
  assortment: string;
  multiStocks?: string[];
  flowId?: string;
  customerId?: string;
  skuLevel?: boolean;
  requireAvailability?: boolean;
  additionalTerms?: { [s: string]: string[] };
  sortFields?: SearchSortField[]; // If sortFields exists, it uses that in backend, otherwise single sortField
  sortField?: string;
  sortOrder?: string;
}

export interface SearchSortField {
  sortField?: string;
  sortOrder?: string;
}

export interface IdBasedSubscriptionConfigService<T> extends ConfigService<T> {
  setSubscriptionIds(ids: string[]);
}

@Directive()
export abstract class ListItemRendererComponent<T> {
  @Input()
  public data: T;

  @Input()
  public dynamicColumns?: PimTemplateProperties[];

  @Input()
  public pimOptions?: { [s: string]: any };

  @Input()
  public itemsPerRow?: number;

  @Input()
  public parentClassCss?: string;
  @Input()
  public childColClassCss?: {[colClassCss: string]: boolean};
}

@Directive()
export class ListLayoutComponent<T> {
  @Input()
  public listItemRenderer: Type<ListItemRendererComponent<T | T[]>>;

  @Input()
  public renderItems: Observable<T[]>;

  @Input()
  public dynamicColumns?: PimTemplateProperties[];

  @Input()
  public pimOptions?: { [s: string]: any };

  @Input()
  public itemsPerRow?: number;

  @Input()
  public parentClassCss?: string;
  @Input()
  public childColClassCss?: {[colClassCss: string]: boolean};
}

@Directive()
export abstract class SearchComponent {
  @Input()
  placeholder = 'SEARCH';

  @Output()
  searchUpdated = new EventEmitter<string>();

  @Output()
  searchEnter = new EventEmitter<string>();

  @Input()
  initSearchTerm: string;

  @HostBinding('class') classes: string;
}

export interface PimTemplateProperties {
  isDisplay: boolean;
  isDisplayGrid: boolean;
  isDisplayList: boolean;
  isDisplayDetails: boolean;
  isFilter: boolean;
  path: string;
  translationKey: string;
  type: string;
  metaReference: any;
  metadata: string;
  sort?: 'asc' | 'desc';
}

/**
 * MARK: Fast-search
 */

@Directive()
export abstract class FastsearchItemRendererComponent<T> {
  @Input()
  public data: T;

  protected dataSelection = new Subject<T>();
  public dataSelection$ = this.dataSelection.asObservable();

  public select(product: T) {
    this.dataSelection.next(product);
  }
}

@Directive()
export class FastSearchListLayoutComponent<T> {
  @Input()
  public itemListRenderer: Type<FastsearchItemRendererComponent<T | T[]>>;

  @Input()
  public itemDetailRenderer: Type<FastsearchItemRendererComponent<T>>;

  @Input()
  public renderItems: Observable<T[]>;
}

export interface FastSearchLayout<T> {
  getLayout(): Type<FastsearchLayoutComponent<T>>;
  getListItemComponent(): Type<FastsearchItemRendererComponent<T | T[]>>;
  getDetailItemComponent(): Type<FastsearchItemRendererComponent<T>>;
}

export interface FastSearchConfigService<T> {
  getItems(terms: string[], skip: number, limit: number): Observable<SearchResult<T>>;
  getLayout(): FastSearchLayout<T>;
  getShowMoreProductsRoute?(): string;
  getSearchPlaceholderTranslate?(): string;
}

/*
  BACKEND LIST INTERFACES
*/

export interface BackendFilterOption {
  /**
   * Preferably uppercase as translate tag
   * Where metadata is used, resolve in backend.
   */
  optionName: string;
  translatedOptionNames?: {
    [lang: string]: string;
  };
  includedIds: string[];
}

export interface BackendFilter {
  /**
   * The id is used to be able to fetch one specific index (generally defined by element type, i.e. products, orders etc)
   */
  backendFilterIndexId: string;
  /**
   * Preferably uppercase as translate tag
   */
  filterName: string;
  options: BackendFilterOption[];
}

export interface BackendFilterSearchTerms {
  /**
   * The id is used to be able to fetch one specific index (generally defined by element type, i.e. products, orders etc)
   */
  backendFilterIndexId: string;
  filterName: string;
  /**
   * includedIn: checks each searchTerm if it includes the entered search query value (allows partial matches)
   * startsWith: same as includedIn but requires a match to start with the query value (allows partial matches)
   * exact: requires each query value to have an exact match for each item to include it in the result (no partial matches)
   */
  matchType: 'includedIn' | 'startsWith' | 'exact';
  /**
   * Split search terms by space, should be used togeather with includedIn at least
   */
  splitSearchTerms: boolean;
  freeTextSearchTerms?: {
    [itemIds: string]: string[];
  };
}

export interface BackendFilterIndex {
  /**
   * The id is used to be able to fetch one specific index (generally defined by element type, i.e. products, orders etc)
   */
  backendFilterIndexId: string;
  /**
   * references to the filter names
   */
  filters: string[];
  freeTextFilters: string[];
  includedIds: string[];
  totalItemCount: number;
}

export interface BackendFilterRequest {
  backendFilterIndexId: string;
  filteredIds: string[];
  freeTextSearchTerms: { [filterName: string]: string[] };
  skip: number;
  limit: number;
  /**
   * lexicographic sort
   * prefix sort with - to get descending order
   */
  sort: string;
}

export interface BackendFilterResponse<T> extends BackendFilterRequest {
  items: T[];
  excludedIdsBySearch: string[];
  allIncludedIds: string[];
  totalItemCount: number;
  filteredItemCount: number;
}

export interface BackendFilterListConfigService<T> {
  getBackendFilterIndex(route?: ActivatedRouteSnapshot): Observable<BackendFilterIndex>;
  getBackendFilters(route?: ActivatedRouteSnapshot): Observable<BackendFilter[]>;
  getItems(filterRequest: BackendFilterRequest, route?: ActivatedRouteSnapshot): Observable<BackendFilterResponse<T>>;
  getLimit(): number;
  getLayouts(route?: ActivatedRouteSnapshot): Observable<ListLayout<T>[]>;
  getItemId(item: T): string;
  getSelectionActions?(route?: ActivatedRouteSnapshot): Observable<SelectionAction<string>[]>;
  setSelectedLayout?(index: number): void;
  getSelectedLayout?(): Observable<number>;
}
