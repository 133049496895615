import { Component, OnInit } from '@angular/core';
import { AuthService, OrderService } from 'gung-standard';
import { JeevesEditOrderrowModalComponent } from 'gung-standard-jeeves';
import { first } from 'rxjs';
import { environment } from '../../../environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-hl-display-edit-orderrow-modal',
  templateUrl: './hl-display-edit-orderrow-modal.component.html',
  styleUrls: ['./hl-display-edit-orderrow-modal.component.css']
})
export class HlDisplayEditOrderrowModalComponent extends JeevesEditOrderrowModalComponent implements OnInit {
  public readOnlyPrice: boolean = false;
  public isCurrysPortal =
    environment.bolag === '1710' &&
    !environment.sales &&
    !environment.managers &&
    environment.mainCountry === 'en' &&
    environment.mainCustomer === '';

  constructor(
    protected modalService: NgbModal,
    protected orderService: OrderService,
    protected authService: AuthService
  ) {
    super(modalService, orderService);
  }

  ngOnInit(): void {
    this.authService.getCurrentUser().pipe(first()).subscribe(user => {
      if (user.extra.readOnlyPrice && this.isCurrysPortal) {
        this.readOnlyPrice = user.extra.readOnlyPrice;
      }
    });
  }
}
