import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { ListItemRendererComponent } from 'gung-list';
import { Order } from '../../models/order';
import { LocationConfigService } from '../../services/location-config/location-config.service';
import { DateUtilService } from 'gung-common';

@Component({
  selector: 'lib-open-order-table',
  templateUrl: './open-order-table.component.html',
  styleUrls: ['./open-order-table.component.css']
})
export class OpenOrderTableComponent extends ListItemRendererComponent<Order[]> implements OnInit {
  adjustedScroll = false;
  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected locationConfigService: LocationConfigService,
    protected router: Router,
    public dateUtilService: DateUtilService
  ) {
    super();
  }

  ngOnInit() {

  }

  goToDetail(order: Order): void {
    const url = `/orders/${order.id}`;
    this.router.navigate([url]);
  }

  classSelector(key: number) {
    if (key === 13) {
      return {
        registered: true
      };
    }
    if (key === 11) {
      return {
        quote: true
      };
    }
    if (key === 10) {
      return {
        registrationInProgress: true
      };
    }
    if (key === 12) {
      return {
        blocked: true
      };
    }
    if (key === 5) {
      return {
        webRegistrationInProgress: true
      };
    }

    return {};
  }
}
