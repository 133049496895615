<div class="card mb-4">
  <div class="card-body">
    <div class="card-title row">
      <div class="col">
        <a [routerLink]="'/orders/' + data.id">
          <h5>{{ data.id }}</h5>
        </a>
      </div>
      <div class="col-auto ml-auto">
        <lib-action-btn [item]="data" [config]="configService"></lib-action-btn>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12 col-md-4">
        <lib-gung-date-output [gungTranslate]="'ORDER_DATE'" [value]="data.extra.oh.orddatum"></lib-gung-date-output>
        <lib-gung-date-output [gungTranslate]="currentLang === 'en' || currentLang === 'se' ? 'EXPECTED_DISPATCH_DATE' : 'EXPECTED_DELIVERY_DATE'" [value]="data.extra.oh.ordberlevdat">
        </lib-gung-date-output>
      </div>
      <div class="col-xs-12 col-md-4">
        <lib-gung-meta-output
          [value]="data.extra.oh.ordstat"
          [gungTranslate]="'ORDER_STATUS'"
          metadataTable="x7"
          metaOptionName="ordstatbeskr"
        ></lib-gung-meta-output>
        <lib-gung-text-input
          [gungTranslate]="'TOTAL'"
          [renderStatic]="true"
          [ngModel]="
            data.extra.oh.ohvbordsum
              | gungCurrency: data.extra.oh.valkod:(isPortalNo ? 'code' : 'symbol-narrow'):'1.2-2'
          "
        >
        </lib-gung-text-input>
      </div>
      <div class="col col-xs-6 col-md-4">
        <div *ngIf="data.extra.pod">
          <!-- POD -->
          <lib-gung-text-input [gungTranslate]="'NAME'" [renderStatic]="true" [ngModel]="data.extra.pod.ftgnamn">
          </lib-gung-text-input>
        </div>
        <div *ngIf="!data.extra.pod">
          <!-- POD -->

          <lib-gung-text-input
            [gungTranslate]="'NAME'"
            [renderStatic]="true"
            [ngModel]="
              data.extra.oh.ordlevadr1 ||
              (data.extra.deliveryCustomer ? data.extra.deliveryCustomer.name : data.extra.customerName)
            "
          >
          </lib-gung-text-input>
        </div>

        <lib-gung-text-input
          [gungTranslate]="'GOODS_LABEL'"
          [renderStatic]="true"
          [ngModel]="data.extra.oh.godsmarke2"
          *ngIf="isSalesPortal"
        >
        </lib-gung-text-input>
      </div>
    </div>
  </div>
</div>
