import { EdiGungMessages } from './../../../models/edi';
import { Component, OnInit } from '@angular/core';
import { ListItemRendererComponent } from 'gung-list';
import { EdiMessageActionConfigService } from '../../../services/edi-message-action-config/edi-message-action-config.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GungModalService } from '../../../services/gung-modal/gung-modal.service';
import { JsonViewModal } from 'gung-common';

@Component({
  selector: 'lib-edi-messages-table-view',
  templateUrl: './edi-messages-table-view.component.html',
  styleUrls: ['./edi-messages-table-view.component.css']
})
export class EdiMessagesTableViewComponent extends ListItemRendererComponent<EdiGungMessages[]> implements OnInit {
  constructor(public actionConfig: EdiMessageActionConfigService, protected modalService: NgbModal, protected gungModalService: GungModalService) {
    super();
  }

  ngOnInit() {}

  trackByFn(index: any, item: any) {
    return index;
  }

  viewError(item: EdiGungMessages) {
    const jsonView: JsonViewModal = {
      title: item.id + ' - Exception details',
      json: item.errorStackTrace,
      typeString: true
    };
    this.gungModalService.openJSONModal(jsonView, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg'
    });
  }
}
