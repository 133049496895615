import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ActuatorService {
  constructor(private http: HttpClient) {}

  actuatorLinks(): Observable<any> {
    const headers = new HttpHeaders();
    const url = `actuator`;
    return this.http.get<any>(url,{headers:headers});
  }

  reloadConfig(): Observable<any> {
    const url = `actuator/refresh`;
    return this.http.post<any>(url, {});
  }

  restartApp(): Observable<any> {
    const url = `json/restart-actuator`;
    return this.http.post<any>(url, {});
  }

  sendOrderContext(context): Observable<any> {
    const url = `json/order-contexts/resend/` + context;
    return this.http.post<any>(url, {});
  }
  changeLogLevel(service:string,logLevel:string): Observable<any> {
    const headers = new HttpHeaders();
    const url = 'actuator/loggers/'+service;
    return this.http.post<any>(url,{configuredLevel: logLevel});
  }

  getLogLevel(): Observable<any> {
    const url = 'actuator/loggers/'
    return this.http.get<any>(url);
  }
  getSteps(context: string): Observable<any> {
    const url = 'json/order-contexts/channel/steps/'+context
    return this.http.get<any>(url);
  }
  sendOrderContextWithStep(context,step): Observable<any> {
    const url = `json/order-contexts/resubmit/steps/` + context;
    return this.http.post<any>(url, {step});
  }
}
