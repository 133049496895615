import { Component, OnInit, OnChanges, OnDestroy } from '@angular/core';
import { ListItemRendererComponent } from 'gung-list';
import {
  Product,
  CustomerProductPrice,
  PriceService,
  AvailabilityService,
  AuthService,
  Availability,
  MetadataService,
  SelectedCustomerService,
  Customer
} from 'gung-standard';
import { Subject, Subscription, forkJoin, of, fromEventPattern } from 'rxjs';
import { first, switchMap, takeUntil } from 'rxjs';
import { environment } from '../../../environments/environment';
import { langExists } from '../../services/hl-product.service';
import { portals } from '../../portal-flags';

interface ProductGrid extends Product {
  price: CustomerProductPrice;
}

@Component({
  selector: 'app-hl-display-product-card-list-grid-view',
  templateUrl: './hl-display-product-card-list-grid-view.component.html',
  styleUrls: ['./hl-display-product-card-list-grid-view.component.css']
})
export class HlDisplayProductCardListGridViewComponent
  extends ListItemRendererComponent<Product[]>
  implements OnInit, OnChanges, OnDestroy
{
  showImage = true;
  isSales: boolean = environment.sales;
  isPortalNo = environment.mainCountry === 'no';
  showFavourites = environment.showFavourites;

  private unsubscribe: Subject<boolean> = new Subject<boolean>();
  private subscriptions: Subscription[] = [];
  public mappedData: ProductGrid[] = [];
  private keyedMapData: { [id: string]: ProductGrid } = {};
  isCoopPortal = environment.mainCustomer === 'coop';
  isManagersNisa = environment.managers && environment.mainCountry === 'en';
  public isCloettaPortal = portals.isCloettaPortal;
  public isCurrysPortal =
    environment.bolag === '1710' &&
    !environment.sales &&
    !environment.managers &&
    environment.mainCountry === 'en' &&
    environment.mainCustomer === '';
  public isMousquetairesPortal =
    environment.bolag === '1600' &&
    environment.sales === false &&
    environment.managers === false &&
    environment.mainCountry === 'fr' &&
    environment.mainCustomer === '' &&
    environment.subCustomer === '';

  productSize = [];
  stockId: string;
  currentCustomer: Customer;
  hasArticlesOutsidePriceLists = environment.getArticlesOutsidePriceLists || false;

  constructor(
    protected priceService: PriceService,
    protected availabilityService: AvailabilityService,
    protected authService: AuthService,
    protected metadataService: MetadataService,
    protected selectedCustomerService: SelectedCustomerService
  ) {
    super();
  }

  public ngOnInit() {
    this.selectedCustomerService.getSelectedCustomer().pipe(takeUntil(this.unsubscribe)).subscribe(customer => this.currentCustomer = customer);

    this.subscribeToProducts();

    if (this.isSales) {
      const mediaQuery = matchMedia('screen and (max-width: 576px), screen and (max-height: 576px)');
      this.showImage = !mediaQuery.matches;

      const mediaObserver = fromEventPattern<MediaQueryListEvent>(
        mediaQuery.addListener.bind(mediaQuery),
        mediaQuery.removeListener.bind(mediaQuery)
      );

      mediaObserver
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(mediaQueryResult => (this.showImage = !mediaQueryResult.matches));
    }
  }

  ngOnChanges() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.subscribeToProducts();
  }

  public ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());

    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  protected subscribeToProducts() {
    const newIds = this.data.map(p => p.id).filter(id => !Object.keys(this.keyedMapData).includes(id));
    if (newIds.length === 0) {
      this.readMappedDataFromCache();
      return;
    }
    const subscription = this.authService
      .getCurrentUser()
      .pipe(
        first(),
        switchMap(user =>
          forkJoin([
            of(this.data),
            this.priceService.getCurrentCustomerPrices(newIds).pipe(first()),
            forkJoin([
              ...newIds.map(id => this.availabilityService.getAvailability(id, user.managedMultistockIds[0]))
            ]).pipe(first()),
            of(user)
          ])
        )
      )
      .subscribe(data => {
        const productData = data[0];
        const prices = data[1];
        const avs = data[2];
        const user = data[3];
        if (!this.stockId  && user?.managedMultistockIds?.length > 0) {
          this.stockId = user.managedMultistockIds[0];
        }
        this.keyedMapData = {
          ...this.keyedMapData,
          ...newIds.reduce((acc, curr) => {
            const product = productData.filter(p => p.id === curr)[0];
            if (!product) {
              throw new Error('No product found');
            }
            const price = prices.filter(p => p.productId === curr)[0];
            if (!price) {
              throw new Error('No price found');
            }
            const av = avs.filter(p => p.productId === curr)[0];
            if (!av) {
              throw new Error('No availability found');
            }

            const item: ProductGrid = this.mapItem(curr, product, price, av);
            return {
              ...acc,
              [curr]: item
            };
          }, {})
        };
        this.readMappedDataFromCache();
      });
    this.subscriptions.push(subscription);
  }

  private readMappedDataFromCache() {
    const ids = this.data.map(d => d.id);
    this.mappedData = ids.map(id => this.keyedMapData[id]);
  }

  private mapItem(id: string, product: Product, price: CustomerProductPrice, availability: Availability): ProductGrid {
    this.productSize[id] = this.getProductSize(product);
    if (this.isCurrysPortal) {
      const customerId = this.currentCustomer?.id.includes('@') ? this.currentCustomer.id.slice(0, this.currentCustomer.id.indexOf('@')) : this.currentCustomer?.id;
      product.extra._kuar = product?.extra.kuar?.find(kuar => kuar.customerId === customerId)?.data?.artnrkund;
    }
    if (this.isCloettaPortal) {
      product.extra._kuar = this.currentCustomer?.extra.kuarMapped?.[product.id]?.artnrkund;
    }
    return {
      ...product,
      id,
      name: this.getName(product),
      price
      // availability,
    };
  }

  getName(product: Product): string {
    if (!environment.sales) {
      return product.name;
    }

    const id = product.id;

    // const itemGroup = this.metadataService.getMetadataValue('vg', 'varugruppbeskr', product.extra.ar.varugruppkod);
    const itemProductSystem = product.extra.hlPimProduct?.ProductSystem;
    let itemDesc = product.extra.ar.artbeskr;
    if (langExists(environment.mainCountry)(product)) {
      itemDesc = product.extra.i18n[environment.mainCountry].artbeskr || itemDesc;
    }
    return `${id}${itemProductSystem ? ' - ' + itemProductSystem : ''} - ${itemDesc}`;
  }

  getMinimumOrderQuantity(extra: any): number {
    if (!this.isSales) {
      return extra.stepAmount;
    }

    return extra.minimumOrderQuantity;
  }

  getProductSize(product: Product): string {
    let productSize: string;
    if (product.extra.hlPimItem.Size && product.extra.hlPimItem.Size !== '-') {
      productSize = product.extra.hlPimItem.Size;
    } else if (
      product.extra.hlPimProduct.ProductExternalCategory === 'PriceCommunication' &&
      product.extra.hlPimItem.LabelHeightmm
    ) {
      productSize = '';
      if (product.extra.hlPimItem.LabelHeightmm && product.extra.hlPimItem.LabelHeightmm !== '-') {
        productSize += `H:${product.extra.hlPimItem.LabelHeightmm} mm`;
      }
      if (product.extra.hlPimItem.Widthmm && product.extra.hlPimItem.Widthmm !== '-') {
        if (productSize) {
          productSize += ` x `;
        }
        productSize += `W:${product.extra.hlPimItem.Widthmm}mm`;
      }
    } else {
      productSize = '';
      if (product.extra.hlPimItem.Widthmm && product.extra.hlPimItem.Widthmm !== '-') {
        productSize += `W:${product.extra.hlPimItem.Widthmm}mm`;
      }
      if (product.extra.hlPimItem.Depthmm && product.extra.hlPimItem.Depthmm !== '-') {
        if (productSize) {
          productSize += ` x `;
        }
        productSize += `D:${product.extra.hlPimItem.Depthmm}mm`;
      }
      if (product.extra.hlPimItem.Heightmm && product.extra.hlPimItem.Heightmm !== '-') {
        if (productSize) {
          productSize += ` x `;
        }
        productSize += `H:${product.extra.hlPimItem.Heightmm}mm`;
      }
    }

    return productSize;
  }

  public getAssortmentType(extra: any): string {
    if (extra.assortmentType === 'C') {
      return '';
    }

    return '(' + extra.assortmentType + ')';
  }
}
