import { Component, OnInit } from '@angular/core';
import {
  CartItemComponent,
  CartService,
  PriceService,
  Product,
  ProductInputQuantityConfigService,
  ProductService
} from 'gung-standard';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-hl-display-cart-item',
  templateUrl: './hl-display-cart-item.component.html',
  styleUrls: ['./hl-display-cart-item.component.css']
})
export class HlDisplayCartItemComponent extends CartItemComponent implements OnInit {
  isPortalNo = environment.mainCountry === 'no';
  isSales = environment.sales;
  is1210Bolag = environment.bolag === '1210';
  public isManagersNisa = environment.managers && environment.mainCountry === 'en';
  public isCurrysPortal =
    environment.bolag === '1710' &&
    !environment.sales &&
    !environment.managers &&
    environment.mainCountry === 'en' &&
    environment.mainCustomer === '';

  constructor(
    protected productInputQuantityConfigService: ProductInputQuantityConfigService,
    public productService: ProductService,
    protected cartService: CartService,
    protected priceService: PriceService
  ) {
    super(productService, cartService, priceService);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  getMinimumOrderQuantity(product: Product): number {
    return this.productInputQuantityConfigService.getMinimumOrderQuantity(product.id, product);
  }
}
