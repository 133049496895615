import { Component } from '@angular/core';
import { ListItemRendererComponent } from 'gung-list';
import { EanNumbers } from '../../../services/ean-numbers/ean-numbers.service';

@Component({
  selector: 'lib-ean-repository-card-list',
  templateUrl: './ean-repository-card-list.component.html',
  styleUrls: ['./ean-repository-card-list.component.css']
})
export class EanRepositoryCardListComponent extends ListItemRendererComponent<EanNumbers[]> {}
