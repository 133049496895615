import { Component, OnInit } from '@angular/core';
import { ListItemRendererComponent } from 'gung-list';
import { Activity, Customer, MetadataService, SelectedCustomerService } from 'gung-standard';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HlDisplayCustomerMeetingModalComponent } from '../hl-display-customer-meeting-modal/hl-display-customer-meeting-modal.component';
import { environment } from '../../../environments/environment';
import { first } from 'rxjs';

@Component({
  selector: 'app-hl-display-meeting-table',
  templateUrl: './hl-display-meeting-table.component.html',
  styleUrls: ['./hl-display-meeting-table.component.css']
})
export class HlDisplayMeetingTableComponent extends ListItemRendererComponent<Activity[]> implements OnInit {
  isSales = environment.sales;
  customer: Customer;

  constructor(
    private metadataService: MetadataService,
    private modalService: NgbModal,
    public selectCustomerService: SelectedCustomerService
  ) {
    super();
  }

  ngOnInit() {
    this.selectCustomerService
      .getSelectedCustomer()
      .pipe(first())
      .subscribe(customer => (this.customer = customer));
  }

  getOptionName(table: string, value: string, key: string): string {
    const result = this.metadataService.getMetadataValue(table, value, key);
    if (result) {
      return result;
    }
    return '';
  }

  openEditMeetingModal(meeting: Activity): void {
    const modalReference = this.modalService.open(HlDisplayCustomerMeetingModalComponent, {
      backdrop: true || 'static',
      size: 'lg',
      keyboard: true
    });

    modalReference.componentInstance.meeting = meeting;
    modalReference.componentInstance.customer = this.customer;

    modalReference.result.then(
      result => {
        // console.log('result: ', result);
      },
      error => {
        // console.log('error: ', error);
      }
    );
  }

  openMeeting() {
    const modalReference = this.modalService.open(HlDisplayCustomerMeetingModalComponent, {
      backdrop: true || 'static',
      size: 'lg',
      keyboard: true
    });

    modalReference.componentInstance.customer = this.customer;

    modalReference.result.then(
      result => {
        console.log('result: ', result);
      },
      error => {
        console.log('error: ', error);
      }
    );
  }
}
