<div *ngIf="options && selectedRange" class="w-100 custom-slider-range" data-legendnum="2">
  <div class="values d-none">
    <span class="lower value">{{ selectedRange.min }}</span
    >-<span class="upper value">{{ selectedRange.max }}</span>
  </div>
  <div class="values d-flex">
    <span class="lower value input">
      <input
        class="w-100 text-left"
        type="number"
        [(ngModel)]="selectedRange.min"
        [ngModelOptions]="{ updateOn: 'blur' }"
        (ngModelChange)="updateSelectedRange($event, 'min')"
      /> </span
    >&nbsp;-&nbsp;
    <span class="upper value input">
      <input
        class="w-100 text-right"
        type="number"
        [(ngModel)]="selectedRange.max"
        [ngModelOptions]="{ updateOn: 'blur' }"
        (ngModelChange)="updateSelectedRange($event, 'max')"
      />
    </span>
  </div>
  <ngx-slider
    [options]="options"
    [(value)]="selectedRange.min"
    [(highValue)]="selectedRange.max"
    (userChangeEnd)="update($event)"
  ></ngx-slider>
</div>
