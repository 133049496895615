import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, of, switchMap, tap, throwError, first } from 'rxjs';
import { GungBase } from '../../models/base';
import { S3Image } from '../../models/s3';
import { GungDownloadUrl, GungImageUrlService } from 'gung-common';

export interface GungCompany extends GungBase {
  address1: string;
  address2: string;
  zipCode: string;
  city: string;
  organizationNumber: string;
  email: string;
  telephone: string;
  webAddress: string;
  logo: S3Image;
  loginBackgroundImage: S3Image;
  logoLogin: S3Image;
  logoOrderConfirm?: S3Image;
  colors: { [s: string]: string } | null;
}

@Injectable({
  providedIn: 'root'
})
export class GungCompanyService {
  protected serviceUrl = `json/gung-company`;
  private companyCached: GungCompany;

  protected myCompany: string[] = [];

  constructor(
    protected http: HttpClient,
    protected gungImageUrlService: GungImageUrlService
  ) {}

  getGungCompany(): Observable<GungCompany> {
    return this.http.get<GungCompany>(this.serviceUrl);
  }

  postGungCompany(gungCompany: GungCompany): Observable<GungCompany> {
    return this.http.post<GungCompany>(this.serviceUrl, gungCompany);
  }

  getPublicGungCompany(nocache?: boolean): Observable<GungCompany> {
    if (!nocache || !this.companyCached) {
      const headers = {
        maxAge: nocache ? '-1' : '60'
      };
      return this.http
        .get<GungCompany>(`public/${this.serviceUrl}`, { headers })
        .pipe(/* catchError(err => of({} as any)), */ tap(company => (this.companyCached = company)));
    }
    return of(this.companyCached);
  }

  getColors(noCache?: boolean): Observable<{ [s: string]: string } | null> {
    return this.getPublicGungCompany(noCache).pipe(
      catchError(err => throwError(err)),
      catchError(error => of(null)),
      first(),
      map(company => {
        return company?.colors;
      })
    );
  }

  getExtraCustomization(noCache?: boolean): Observable<{ [s: string]: string } | null> {
    return this.getPublicGungCompany(noCache).pipe(
      catchError(err => throwError(err)),
      catchError(error => of(null)),
      first(),
      map(company => {
        return company?.extra?.customization;
      })
    );
  }

  public getLogoUrlObservable(noCache?: boolean): Observable<string> {
    return this.getPublicGungCompany(noCache).pipe(
      catchError(err => throwError(err)),
      catchError(error => of(null)),
      first(),
      map(company => {
        return this.getLogoFromCompany(company);
      })
    );
  }

  private getLogoFromCompany(company: GungCompany): string {
    if (company?.logo?.s3Uri && !company.logo.s3Uri.startsWith('https://')) {
      if (company.logo.s3Uri.endsWith('.gif')) {
        return GungDownloadUrl(company.logo.s3Uri);
      } else {
        return this.gungImageUrlService.getUrl(company.logo.s3Uri);
      }
    }
    return null; // get default
  }

  public getLogoLoginUrlObservable(noCache?: boolean): Observable<string> {
    return this.getPublicGungCompany(noCache).pipe(
      catchError(err => throwError(err)),
      catchError(error => of(null)),
      first(),
      map(company => {
        if (company?.logoLogin?.s3Uri && !company.logoLogin.s3Uri.startsWith('https://')) {
          if (company.logoLogin.s3Uri.endsWith('.gif')) {
            return GungDownloadUrl(company.logoLogin);
          } else {
            return this.gungImageUrlService.getUrl(company.logoLogin.s3Uri);
          }
        } else {
          return this.getLogoFromCompany(company);
        }
      })
    );
  }

  public getBackgroundLoginUrlObservable(noCache?: boolean): Observable<string> {
    return this.getPublicGungCompany(noCache).pipe(
      catchError(err => throwError(err)),
      catchError(error => of(null)),
      first(),
      map(company => {
        if (company?.loginBackgroundImage?.s3Uri && !company.loginBackgroundImage.s3Uri.startsWith('https://')) {
          if (company.loginBackgroundImage.s3Uri.endsWith('.gif')) {
            return GungDownloadUrl(company.loginBackgroundImage);
          } else {
            return this.gungImageUrlService.getUrl(company.loginBackgroundImage.s3Uri);
          }
        }
        return null; // get default
      })
    );
  }
}
