import { HttpEvent, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularEditorConfig, UploadResponse } from '@kolkov/angular-editor';
import { first, Observable, of, switchMap } from 'rxjs';
import { S3UploadService } from '../s3-upload/s3-upload.service';
import { TranslateService } from '@ngx-translate/core';
import { GungImageUrlService } from '../gung-image-url/gung-image-url.service';

@Injectable({
  providedIn: 'root'
})
export class AngularEditorConfigService {
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: this.translateService.instant('Enter text here...'),
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    customClasses: [
      // {
      //   name: 'quote',
      //   class: 'quote',
      // },
      // {
      //   name: 'redText',
      //   class: 'redText'
      // },
      // {
      //   name: 'titleText',
      //   class: 'titleText',
      //   tag: 'h1',
      // },
    ],
    // uploadUrl: 'v1/image',
    upload: (file: File): Observable<HttpEvent<UploadResponse>> => {
      return this.s3UploadService.postImage(file, 'uploads/custom-page/').pipe(
        first(),
        switchMap(s3Image => {
          return of<HttpEvent<UploadResponse>>(
            new HttpResponse({
              body: { imageUrl: this.gungImageUrlService.getUrl(s3Image.s3Uri, {etag: s3Image.s3ETag}) }
            })
          );
        })
      );
    },
    // uploadWithCredentials: false,
    sanitize: false, // FIX align issue
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      // [
      //   'undo',
      //   'redo',
      //   'bold',
      //   'italic',
      //   'underline',
      //   'strikeThrough',
      //   'subscript',
      //   'superscript',
      //   'justifyLeft',
      //   'justifyCenter',
      //   'justifyRight',
      //   'justifyFull',
      //   'indent',
      //   'outdent',
      //   'insertUnorderedList',
      //   'insertOrderedList',
      //   'heading',
      //   'fontName'
      // ],
      // [
      //   'fontSize',
      //   'textColor',
      //   'backgroundColor',
      //   'customClasses',
      //   'link',
      //   'unlink',
      //   'insertImage',
      //   'insertVideo',
      //   'insertHorizontalRule',
      //   'removeFormat',
      //   'toggleEditorMode'
      // ]
    ]
  };

  constructor(
    protected s3UploadService: S3UploadService, 
    protected translateService: TranslateService,
    protected gungImageUrlService: GungImageUrlService
  ) {}

  // <angular-editor id="editor2" formControlName="htmlContent2" [config]="editorConfig"></angular-editor>

  getDefaultEditorConfig(): AngularEditorConfig {
    return this.editorConfig;
  }
}
