<div *ngIf="product !== null; else loading">
  <!-- <div class="row pt-2">
    <div class="col-12">
      <lib-back-button class="ml-5"></lib-back-button>
    </div>
  </div> -->

  <div class="row pt-1 product-detail-data-section">
    <!-- Product img -->
    <div class="col-xs-12 col-sm-6 container">
      <div class="row">
        <div class="col-auto">
          <img
            *ngIf="product?.extra?.images && product?.extra?.images[selectedImageIndex] as image; else noimage"
            [src]="image.s3Uri | gungImageUrl
              :'type:list'
              :'etag:'+image.s3ETag
            "
            alt="{{ image.description }}"
            class="img-fluid"
          />
          <ng-template #noimage>
            <img
              class="card-img-top img-fluid w-100"
              [src]="'images/no-image.jpg' | gungImageUrl"
              alt="NO IMAGE"
              class="img-fluid mx-auto d-block"
            />
          </ng-template>
        </div>
      </div>

      <!-- Product Images carousel -->
      <div *ngIf="!!carousel">
        <div *ngIf="carousel.images.length > 1" class="row pt-2">
          <button
            type="button"
            class="btn col-1"
            [disabled]="carousel.startIndex === 0"
            (click)="scrollImages('left')"
            *ngIf="!carousel.containsAll"
          >
            <i class="fa fa-chevron-left product-images-carousel"></i>
          </button>

          <div *ngFor="let image of carousel.images; trackBy: trackByFn" class="col-3 pb-1 product-detail-image">
            <img
              [src]="image.s3Uri | gungImageUrl
                :'type:detail'
                :'etag:'+image.s3ETag
              "
              alt="{{ image.description }}"
              class="img-fluid" style="max-width: 100px;"
              (click)="setSelectedImage(image.s3Uri)"
            />
          </div>

          <button
            type="button"
            class="btn col-1"
            [disabled]="carousel.endIndex + 1 === carousel.countAll"
            (click)="scrollImages('right')"
            *ngIf="!carousel.containsAll"
          >
            <i class="fa fa-chevron-right product-images-carousel"></i>
          </button>
        </div>
      </div>
    </div>

    <!-- Product data -->
    <div class="col-xs-12 col-sm-6">
      <div class="row">
        <div class="col-12">
          <h5 class="mb-0">
            <!-- <span>{{ 'ITEM_NO' | translate }}</span>:  -->
            {{ product?.id }}
          </h5>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <h1>{{ product?.name }}</h1>
        </div>
      </div>

      <div class="row" *ngIf="getProductDescription() | async as productDescription">
        <div class="col-12">
          <p>{{ productDescription }}</p>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <i class="fas fa-box"></i>&nbsp;
          <lib-availability [availability]="availability" [isSales]="isSales"></lib-availability>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col">
          <h4>
            <!-- <span>{{ 'NET_PRICE' | translate }}</span>: -->
            <strong>
              <lib-price [price]="price" type="NET"></lib-price>
            </strong>
          </h4>
        </div>
      </div>

      <!-- <div class="row" *ngIf="price.backendPriceLevel.extra.ApproximatePrice">
        <div class="col">
          <h5>
            <span>{{ 'RRP' | translate }}</span>:
            <strong>{{price.backendPriceLevel.extra.ApproximatePrice | gungCurrency: price.backendPriceLevel.extra.CurrencyCode:'code' }}</strong>
          </h5>
        </div>
      </div> -->

      <div class="row" *ngIf="!isOnlyDisplayFlow">
        <div class="col-12 col-md-6">
          <lib-buy-btn [id]="product?.id" [product]="product"></lib-buy-btn>
        </div>
      </div>

      <!-- Product Jeeves Details -->
      <div class="pt-2">
        <ngb-accordion #acc="ngbAccordion" [destroyOnHide]="false" class="accordion-jeeves-product-details">
          <ngb-panel id="jeeves-product-details">
            <ng-template ngbPanelHeader>
              <div class="d-flex flex-direction-row align-items-center accordion-jeeves-product-details-panel">
                <button ngbPanelToggle class="btn btn-link text-dark pl-2 font-weight-bold">
                  {{ 'PRODUCT_DETAILS' | translate }}
                </button>
                <button ngbPanelToggle class="btn btn-link ml-auto pr-1">
                  <i
                    *ngIf="!acc.isExpanded('jeeves-product-details')"
                    class="fa fa-chevron-down accordion-jeeves-product-details-panel-icons"
                  ></i>
                  <i
                    *ngIf="acc.isExpanded('jeeves-product-details')"
                    class="fa fa-chevron-up accordion-jeeves-product-details-panel-icons"
                  ></i>
                </button>
              </div>
            </ng-template>
            <ng-template ngbPanelContent>
              <div class="table-responsive">
                <table class="table table-borderless table-striped table-sm">
                  <tbody>
                    <tr *ngIf="product?.extra?.ar?.enhetskod">
                      <th translate>UNIT</th>
                      <td>
                        {{ product.extra.ar.enhetskod }}
                      </td>
                    </tr>
                    <tr *ngIf="product?.extra?.ar?.artfsgforp">
                      <th translate>PACKAGE_SIZE</th>
                      <td>
                        {{ product.extra.ar.artfsgforp }}
                      </td>
                    </tr>
                    <tr *ngIf="product?.extra?.ar?.artvikt">
                      <th translate>NET_WEIGHT</th>
                      <td>
                        {{ product.extra.ar.artvikt }}
                      </td>
                    </tr>
                    <tr *ngIf="product?.extra?.ar?.artbtotvikt">
                      <th translate>GROSS_WEIGHT</th>
                      <td>
                        {{ product.extra.ar.artbtotvikt }}
                      </td>
                    </tr>
                    <tr *ngIf="product?.extra?.ar?.artstatnr">
                      <th translate>TARIC_CODE</th>
                      <td>
                        {{ product.extra.ar.artstatnr }}
                      </td>
                    </tr>
                    <tr *ngIf="product?.extra?.ar?.artnrean">
                      <th translate>EAN</th>
                      <td>
                        {{ product.extra.ar.artnrean }}
                      </td>
                    </tr>
                    <tr *ngIf="product?.extra?.ar?.artvolym">
                      <th translate>VOLUME</th>
                      <td>
                        {{ product.extra.ar.artvolym }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </ng-template>
          </ngb-panel>

          <ngb-panel id="jeeves-product-documents" *ngIf="product?.extra?.pdm && product?.extra?.pdm?.length > 0">
            <ng-template ngbPanelHeader>
              <div class="d-flex flex-direction-row align-items-center accordion-jeeves-product-details-panel">
                <button ngbPanelToggle class="btn btn-link text-dark pl-2 font-weight-bold">
                  {{ 'DOCUMENTS' | translate }}
                </button>
                <button ngbPanelToggle class="btn btn-link ml-auto pr-1">
                  <i
                    *ngIf="!acc.isExpanded('jeeves-product-documents')"
                    class="fa fa-chevron-down accordion-jeeves-product-details-panel-icons"
                  ></i>
                  <i
                    *ngIf="acc.isExpanded('jeeves-product-documents')"
                    class="fa fa-chevron-up accordion-jeeves-product-details-panel-icons"
                  ></i>
                </button>
              </div>
            </ng-template>
            <ng-template ngbPanelContent>
              <div class="table-responsive">
                <table class="table table-hover table-borderless table-striped table-sm">
                  <thead>
                    <tr>
                      <th translate>NAME</th>
                      <th class="text-right" translate>DOWNLOAD_DOCUMENT</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let document of product.extra.pdm">
                      <td>
                        {{ document.docdescr }}
                      </td>
                      <td class="text-right">
                        <a
                          target="_NEW"
                          [href]="('/BUCKET/' + document.pdmdummyuniqueid + document.documentext) | gungDownloadUrl"
                        >
                          {{ document.pdmdummyuniqueid }}{{ document.documentext }}
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
      </div>
    </div>
  </div>

  <div class="row mt-3 product-detail-data-section">
    <div class="col-12">
      <lib-gung-tabset *ngIf="addonIds.length > 0 || pimRelatedProductsIds.length > 0">
        <lib-gung-tab *ngIf="addonIds.length > 0" [title]="'ADDONS' | translate">
          <ng-template libGungTabContent>
            <lib-product-list-by-ids [productIds]="addonIds"></lib-product-list-by-ids>
          </ng-template>
        </lib-gung-tab>
        <lib-gung-tab *ngIf="pimRelatedProductsIds.length > 0" [title]="'PRODUCT_RELATED_PRODUCTS' | translate">
          <ng-template libGungTabContent>
            <lib-product-list-by-ids [productIds]="pimRelatedProductsIds"></lib-product-list-by-ids>
          </ng-template>
        </lib-gung-tab>
      </lib-gung-tabset>
    </div>
  </div>
</div>

<ng-template #loading> LOADING... </ng-template>
