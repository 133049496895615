<div class="form-group row">
  <label [for]="inputId" *ngIf="!suppressLabel" class="col-form-label" [ngClass]="labelClass">
    {{ translateTag | translate: translateParams }}
    <i *ngIf="tooltip" class="fal fa-question-circle" ngbTooltip="{{ tooltip | translate }}"></i>
  </label>
  <div class="col">
    <select
      [class]="extraInputClass"
      [ngClass]="renderStatic ? 'form-control-plaintext' : 'form-control'"
      [id]="inputId"
      [name]="inputId"
      [required]="required"
      [disabled]="disabled"
      [(ngModel)]="value"
    >
      <option *ngFor="let option of optionsList" [ngValue]="option.id">
        {{ showId ? option.id + ' - ' : '' }}{{ option.name | translate }}
      </option>
    </select>
  </div>
</div>
