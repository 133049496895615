import { Component, OnInit } from '@angular/core';
import { ListItemRendererComponent } from 'gung-list';
import { Invoice } from '../../models/invoice';
import { DateUtilService } from 'gung-common';

@Component({
  selector: 'lib-invoice-table',
  templateUrl: './invoice-table.component.html',
  styleUrls: ['./invoice-table.component.css']
})
export class InvoiceTableComponent extends ListItemRendererComponent<Invoice[]> implements OnInit {
  constructor(public dateUtilService: DateUtilService) {
    super();
  }

  ngOnInit() {}
}
