import { environment } from './../../../../../environments/environment';
import { Component, OnInit } from '@angular/core';
import { CheckoutStepComponent } from 'gung-standard';

@Component({
  selector: 'app-email-step',
  templateUrl: './email-step.component.html',
  styleUrls: ['./email-step.component.css']
})
export class EmailStepComponent extends CheckoutStepComponent implements OnInit {
  constructor() {
    super();
  }

  ngOnInit() {
    super.ngOnInit();

    this.checkout.extra.confirmationEmails = this.checkout.extra.confirmationEmails || [];
    const email = environment.quoteConfirmationEmailAddress;
    if (email) {
      if (this.checkout.extra.confirmationEmails.indexOf(email) < 0) {
        this.checkout.extra.confirmationEmails.push(email);
      }
    }
    console.log('this.checkout.extra.confirmationEmails', this.checkout.extra.confirmationEmails);

    this.onNextBtnClicked.subscribe(_ => this.stepDone.emit(this.checkout));
  }

  isVisible() {
    return false;
  }
}
