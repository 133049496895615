<div class="modal-header" *ngIf="!!title">
  <h4 class="modal-title" id="modal-basic-title" translate>{{ title }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div class="col-12">
    <div class="form-group">
      <label *ngIf="labelType === 'text'; else htmlLabel" for="value" class="control-label {{labelClass}}" translate>{{ label }}</label>
      <ng-template #htmlLabel>
        <div [innerHTML]="label | safeHtml" class="{labelClass}"></div>
      </ng-template>
      <ng-container [ngSwitch]="type">
        <ng-template [ngSwitchCase]="'text'">
            <input
                
                class="form-control"
                type="text"
                name="value"
                id="value"
                [(ngModel)]="value"
                ngbAutofocus
            />
        </ng-template>
        <ng-template [ngSwitchCase]="'textarea'">
            <textarea
               
                class="form-control"
                type="text"
                name="value"
                id="value"
                [(ngModel)]="value"
                ngbAutofocus
            ></textarea>
        </ng-template>
        <ng-template [ngSwitchDefault]><!-- BLANK --> </ng-template>
      </ng-container>
    </div>
  </div>
</div>

<div class="modal-footer" *ngIf="btnOk || btnCancel">
  <div class="row w-100">
    <div class="col-12">
      <div class="btn-group btn-group-justified btn-block">
        <button class="btn btn-sm btn-outline-secondary btn-default" (click)="activeModal.dismiss()" translate>{{btnCancel}}</button>
        <button class="btn btn-sm btn-primary btn-default" (click)="activeModal.close(value)" translate>{{btnOk}}</button>
      </div>
    </div>
  </div>
</div>
