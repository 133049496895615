<h3 translate class="text-center">TOP_PRODUCTS</h3>
<!--
<div class="row">
    <div class="col">
        <div class="float-right">
            <div class="form-group row">
                <div class="col-auto"><label for="season" class="control-label" translate>SEASON</label></div>
                <div class="col">
                    <select class="form-control" id="season" (change)="onChangeSeason($event.target.value)">
                        <option [value]="item" *ngFor="let item of topProductListConfig.seasonList">{{item}}</option>
                    </select>
                </div>

            </div>
        </div>
    </div>

</div>
-->
<lib-filter-list [configService]="topProductListConfig"></lib-filter-list>
