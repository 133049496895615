import { Component } from '@angular/core';
import { BackendFeatureService, CheckoutStepComponent, SelectedCustomerService } from 'gung-standard';
import { HlDisplayAdditionalCostsService } from 'projects/hl-display/src/app/services/hl-display-additional-costs.service';
import { first, forkJoin } from 'rxjs';

@Component({
  selector: 'app-hl-display-additional-costs-step',
  templateUrl: './hl-display-additional-costs-step.component.html',
  styleUrls: ['./hl-display-additional-costs-step.component.css']
})
export class HlDisplayAdditionalCostsStepComponent extends CheckoutStepComponent {
  private additionalCostsFeatureFlagId = 'trade-policy';

  constructor(
    private backendFeatureService: BackendFeatureService,
    private additionalCostsService: HlDisplayAdditionalCostsService,
    private selectedCustomerService: SelectedCustomerService
  ) {
    super();
  }

  isVisible(): boolean {
    return false;
  }

  ngOnInit(): void {
    super.ngOnInit();

    forkJoin({
      isFeatureActive: this.backendFeatureService.isActivated(this.additionalCostsFeatureFlagId).pipe(first()),
      selectedCustomer: this.selectedCustomerService.getSelectedCustomer().pipe(first())
    }).subscribe(({ isFeatureActive, selectedCustomer }) => {
      // When we don't have the feature active, we don't need to do anything
      if (!isFeatureActive) {
        this.onNextBtnClicked.subscribe(_ => this.stepDone.emit(this.checkout));
      } else {
        let additionalCostsTermsObject = this.additionalCostsService.getAdditionalCostTerms(selectedCustomer);
        let currencyCode = '';

        if (!!selectedCustomer.extra && selectedCustomer.extra.kus) {
          currencyCode = selectedCustomer.extra.kus.valkod || '';
        }
        this.additionalCostsService.addFreightChargeToCheckout(this.checkout, additionalCostsTermsObject, currencyCode);
        this.additionalCostsService.addAdminFeeToCheckout(this.checkout, additionalCostsTermsObject, currencyCode);
        this.onNextBtnClicked.subscribe(x => this.stepDone.emit(this.checkout));
      }
    });
  }
}
