import { Injectable } from '@angular/core';
import { Product } from 'gung-standard';
import { ListLayout, ListSortOption, ConfigService, ConfigBaseFilter, ListLayoutMultipleComponent } from 'gung-list';
import { Observable } from 'rxjs';
import { HlDisplayCustomerOrderedProductsTableComponent } from '../components/hl-display-customer-ordered-products-table/hl-display-customer-ordered-products-table.component';
import { HlProductService, langExists } from './hl-product.service';
import { HlDisplayPurchasedProduct } from '../models/purchased-product';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HlDisplayCustomerOrderedProductsLastXMonthsListService implements ConfigService<Product> {
  constructor(protected hlProductService: HlProductService) {}

  getItems(): Observable<HlDisplayPurchasedProduct[]> {
    return this.hlProductService.getCustomerPurchasedProducts();
  }

  getFilters(): ConfigBaseFilter<Product>[] {
    return [];
  }

  getSortOptions(): ListSortOption<Product>[] {
    return [];
  }

  getBatchSizes(): number[] {
    return [20];
  }

  getLayouts(): ListLayout<Product>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => HlDisplayCustomerOrderedProductsTableComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'ProductTable'
      }
    ];
  }

  getSearchTerms(item: Product): string[] {
    return [item.id, item.name, this.getDescription(item)];
  }

  getDescription(product: Product): string {
    if (!environment.sales) {
      return product.description;
    }

    let desc1 = '';
    if (langExists(environment.mainCountry)(product)) {
      desc1 = product.extra.i18n[environment.mainCountry].artbeskrspec || desc1;
    }
    const desc2 = product.extra.ar.artbeskrspec;
    if (desc2) {
      return `${desc1} (${desc2})`;
    }
    return `${desc1}`;
  }

  getItemId(item: Product): string {
    return item.id;
  }
}
