<div class="container">
  <div class="login-container">

    <div *ngIf="isOauth2" class="mb-2">
      <h5 style="font-weight: bold; font-size: 22px;" translate>KESKO_SSO_LOGIN</h5>

      <div class="d-flex">
        <button *ngIf="!loading && isOauth2" class="btn btn-primary" (click)="loginOauth()" translate>CLICK_HERE_TO_LOGIN</button>
      </div>

      <p class="mt-4" translate>OR_USE_INTERNAL_LOGIN</p>
    </div>


    <h5 class="text-primary"><strong translate>LOGIN</strong></h5>
    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
      <div class="form-group">
        <label for="username" translate>USERNAME</label>
        <input
          type="text"
          formControlName="username"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.username.errors }"
        />
        <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
          <div *ngIf="f.username.errors.required" translate>USERNAME_REQUIRED</div>
        </div>
      </div>
      <div class="form-group">
        <label for="password" translate>PASSWORD</label>
        <input
          type="password"
          formControlName="password"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
        />
        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
          <div *ngIf="f.password.errors.required" translate>PASSWORD_REQUIRED</div>
        </div>
      </div>
      <div
        *ngIf="error && (error.type === ActionTypes.BadLogin || error.type === ActionTypes.BackendDown)"
        class="alert alert-danger"
      >
        <span *ngIf="error.type === ActionTypes.BadLogin" translate>LOGIN_FAILED</span>
        <span *ngIf="error.type === ActionTypes.BackendDown" translate>BACKEND_DOWN</span>
      </div>

      <div class="form-group d-flex justify-content-between align-items-center">
        <a routerLink="/forgot-password" class="forgot-password" translate>FORGOT_PASSWORD</a>
        <button *ngIf="!loading" class="btn btn-primary" translate>LOGIN</button>
        <i *ngIf="loading" class="fa fa-spinner fa-spin fa-lg"></i>
      </div>

      <div
        class="form-group d-flex justify-content-between align-items-center warning-no-use-explorer"
        *ngIf="isCustomerPortal"
      >
        <div class="p-2 d-flex">
          <div class="pr-2">
            <i class="fas fa-exclamation-circle warning-no-use-explorer-icon"></i>
          </div>
          <div translate>WARNING_NO_USE_EXPLORER</div>
        </div>
      </div>
    </form>
  </div>
</div>
