import { Component, OnInit, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { Customer } from './../../../../../models/customer';
import { ListItemRendererComponent } from 'gung-list';
import { UsersService } from './../../../../../services/users/users.service';
import { takeUntil } from 'rxjs';
import { Subject } from 'rxjs';

@Component({
  selector: 'lib-user-details-customers-table',
  templateUrl: './user-details-customers-table.component.html',
  styleUrls: ['./user-details-customers-table.component.css']
})
export class UserDetailsCustomersTableComponent
  extends ListItemRendererComponent<Customer[]>
  implements OnInit, OnDestroy, OnChanges
{
  private isSelectedKey = 'isSelected';
  private cacheSelectedCustomers: Customer[] = [];
  private initialSelectedCustomerCompleted = false;

  unsubscribe: Subject<void> = new Subject();

  constructor(private usersService: UsersService) {
    super();
  }

  ngOnInit() {
    this.usersService
      .getUserUnselectedCustomerIdFromSubject()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(unselectedCustomerId => {
        if (!!unselectedCustomerId) {
          this.toggleSelection(unselectedCustomerId);
        }
      });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // initial customers selected
    if (!this.initialSelectedCustomerCompleted) {
      this.data
        .filter(customer => customer[this.isSelectedKey])
        .forEach(customer => {
          this.cacheSelectedCustomers.push(customer);
        });

      this.sendSelectedCustomers();
      this.initialSelectedCustomerCompleted = true;
    } else {
      // searching or filterting
      this.data.forEach(customer => {
        if (this.cacheSelectedCustomers.map(c => c.id).includes(customer.id)) {
          customer[this.isSelectedKey] = true;
        }
      });
    }
  }

  isCustomerSelected(customerId): boolean {
    return this.data.find(c => c.id === customerId)[this.isSelectedKey];
  }

  toggleSelection(customerId) {
    if (!!this.data.find(c => c.id === customerId)) {
      const isCustomerSelected = this.data.find(c => c.id === customerId)[this.isSelectedKey];
      this.data.find(c => c.id === customerId)[this.isSelectedKey] = !isCustomerSelected;

      if (isCustomerSelected) {
        const indexToRemove = this.cacheSelectedCustomers.findIndex(customer => customer.id === customerId);
        if (indexToRemove > -1) {
          this.cacheSelectedCustomers.splice(indexToRemove, 1);
        }
      } else {
        this.cacheSelectedCustomers.push(this.data.find(customer => customer.id === customerId));
      }

      // new customers selection
      this.sendSelectedCustomers();
    }
  }

  private sendSelectedCustomers(): void {
    this.usersService.setUserSelectedCustomerIdsSubject(this.cacheSelectedCustomers);
  }
}
