import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, switchMap } from 'rxjs';

export interface FilterListLocation {
  path: string,
  scrollX: number,
  scrollY: number,
  pagination: number,
  layout: number
}

@Injectable({
  providedIn: 'root'
})
export class FilterListLocationConfigService {
  protected savedFilterListLocationsSubject: BehaviorSubject<FilterListLocation[]> = new BehaviorSubject<FilterListLocation[]>([]);

  constructor() { }

  public getSavedLocation(path: string): Observable<FilterListLocation> {
    return this.savedFilterListLocationsSubject.pipe(
      switchMap(savedLocations => {
        const savedLocation = savedLocations.find(loc => loc.path === path);
        return of(savedLocation);
      })
    );
  }

  public setSavedLocation(path: string, scrollX: number, scrollY: number, pagination: number, layout: number) {
    const currentSavedLocations = this.savedFilterListLocationsSubject.value;
    const newSavedLocation: FilterListLocation = {
      path,
      scrollX,
      scrollY,
      pagination,
      layout
    };

    const locationExistsId: number =  currentSavedLocations.findIndex(loc => loc.path === newSavedLocation.path);
    if (locationExistsId > -1) {
      currentSavedLocations.splice(locationExistsId, 1, newSavedLocation);
    } else {
      currentSavedLocations.push(newSavedLocation);
    }

    this.savedFilterListLocationsSubject.next(currentSavedLocations);
  }

  public removeSavedLocation(path: string) {
    const currentSavedLocations = this.savedFilterListLocationsSubject.value.filter(loc => loc.path !== path);
    this.savedFilterListLocationsSubject.next(currentSavedLocations);
  }

  public clearAllSavedLocations() {
    this.savedFilterListLocationsSubject.next([]);
  }
}
