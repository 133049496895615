<div class="form-group" *ngIf="loaded; else loading">
  <label for="dp" class="control-label" translate>DELIVERY_DATE</label>
  <div class="input-group">
    <input
      class="form-control"
      placeholder="yyyy-mm-dd"
      name="dp"
      ngbDatepicker
      #d="ngbDatepicker"
      [markDisabled]="markDisabled"
      (dateSelect)="dateSelected($event)"
      [minDate]="firstDatePickerDate"
      [startDate]="firstDatePickerDate"
      [(ngModel)]="ngModel"
      [readonly]="true"
    />
    <div class="input-group-append">
      <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button">
        <i class="fas fa-calendar"></i>
      </button>
    </div>
  </div>
</div>

<ng-template #loading> LOADING... </ng-template>
