<div class="navbar-search">
  <div class="input-group navbar-search-input-group" (keydown.enter)="showMoreProducts(searchInput)">
    <input
      type="text"
      class="form-control"
      [ngClass]="globalSearchConfig.globalInputExtraClasses + ' ' + inputExtraClasses"
      [placeholder]="globalSearchConfig.searchInputPlaceholder | translate"
      [(ngModel)]="searchInputValue"
      (ngModelChange)="searchTerms.next($event)"
      (focus)="inputInFocus = true; onFocusInput();"
      (blur)="inputInFocus = false;"
      #searchInput
    >
    <button class="btn btn-outline-secondary" (click)="onInputButtonClick()"><i [class]="getInputButtonIcon()"></i></button>
  </div>

  <div class="navbar-search-results" [ngClass]="{'no-borders': (currentResultProducts?.count || 0) <= 0 && (currentResultCategories?.count || 0) <= 0, 'd-flex': globalSearchConfig.searchCategories}">
    <div class="navbar-search-results-column" [ngClass]="{'w-50 navbar-search-separator-right': globalSearchConfig.searchCategories}">
      <div *ngIf="globalSearchConfig.searchCategories && (((currentResultCategories?.count || 0) > 0) || ((currentResultProducts?.count || 0) > 0))" class="navbar-search-results-column-title">
        {{ globalSearchConfig.productsColumnTitle | translate }}
      </div>
      <ng-template #hostViewProducts></ng-template>
      <div *ngIf="(currentResultProducts?.count || 0) > globalSearchConfig.maxResultShow" class="w-100 show-more-button-container">
        <button
          class="btn btn-light btn-block btn-sm"
          [routerLink]="globalSearchConfig.viewMoreLink"
          [queryParams]="globalSearchConfig.getViewMoreQueryParams(searchInputValue)"
          (click)="globalSearchConfig.closeSubject.next()"
        >
          <small>{{ 'GUNG_PRODUCT_LIST_LOAD_MORE' | translate: { limit: globalSearchConfig.maxResultShow, total: (currentResultProducts?.count || 0) } }}</small>
        </button>
      </div>
    </div>

    <div *ngIf="globalSearchConfig.searchCategories" class="navbar-search-results-column w-50">
      <div *ngIf="globalSearchConfig.searchCategories && (((currentResultCategories?.count || 0) > 0) || ((currentResultProducts?.count || 0) > 0))" class="navbar-search-results-column-title">
        {{ globalSearchConfig.categoriesColumnTitle | translate }}
      </div>
      <ng-template #hostViewCategories></ng-template>
      <div *ngIf="(currentResultCategories?.count || 0) > globalSearchConfig.maxResultShow" class="w-100 show-more-button-container">
        <button
          class="btn btn-light btn-block btn-sm"
          [routerLink]="globalSearchConfig.viewMoreLink"
          [queryParams]="globalSearchConfig.getViewMoreCategoriesQueryParams(searchInputValue)"
          (click)="globalSearchConfig.closeSubject.next()"
        >
          <small>{{ 'GUNG_PRODUCT_LIST_LOAD_MORE' | translate: { limit: globalSearchConfig.maxResultShow, total: (currentResultCategories?.count || 0) } }}</small>
        </button>
      </div>
    </div>
  </div>

  <div *ngIf="!isLoading && inputInFocus && !!searchInputValue && showNoResults" class="navbar-search-results">
    <div class="navbar-search-results-column no-search-results">
      {{ 'NO_RESULTS_FOUND' | translate }}
    </div>
  </div>
</div>
