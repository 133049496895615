import { Injectable } from '@angular/core';
import { GungLanguageConfigService, Language } from 'gung-common';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HlDisplayGungLanguageConfigService extends GungLanguageConfigService {

  showAllLang = false && !environment.production;

  // https://support.gung.se/a/tickets/295703
  private languagesPortal: { portal: string; url: string; languages: string[], enabled: boolean }[] = [
    { // 	customer-no-1210-prod
      portal: 'rema',
      url: 'rema.hl-display.com',
      languages: ['no', 'fi'], // 'Norwegian'
      enabled: environment.bolag === '1210'
        && environment.sales === false
        && environment.managers === false
        && environment.mainCountry === 'no'
        && environment.subCustomer === ''
    },
    { // managers-no-rema
      portal: 'rema-pm',
      url: 'rema-pm.hl-display.com',
      languages: ['no', 'se', 'fi'], // 'Norwegian'
      enabled: environment.bolag === '1210'
        && environment.sales === false
        && environment.managers === true
        && environment.mainCountry === 'no'
        && environment.subCustomer === ''
    },
    { // customer-nl-superunie-1600
      portal: 'sparnl',
      url: 'sparnl.hl-display.com',
      languages: ['nl'], // 'Dutch'
      enabled: environment.bolag === '1600'
        && environment.sales === false
        && environment.managers === false
        && environment.mainCountry === 'nl'
        && environment.mainCustomer === 'spar'
        && environment.subCustomer === ''
    },
    { // customer-be-carrefour
      portal: 'carrefour',
      url: 'carrefour.hl-display.com',
      languages: ['fr', 'nl'], // 'French',
      enabled: environment.bolag === '1600'
        && environment.sales === false
        && environment.managers === false
        && environment.mainCountry === 'fr'
        && environment.mainCustomer === 'General'
        && environment.subCustomer === 'carrefour'
    },
    { // customer-be-intermarche
      portal: 'intermarche',
      url: 'intermarche.hl-display.com',
      languages: ['fr', 'nl'], // 'French'
      enabled: environment.bolag === '1600'
        && environment.sales === false
        && environment.managers === false
        && environment.mainCountry === 'fr'
        && environment.mainCustomer === 'General'
        && environment.subCustomer === 'intermarche'
    },
    { // sales-fr-1600
      portal: 'sales-fr',
      url: 'sales-fr.hl-display.com',
      languages: ['fr', 'nl', 'en'], // 'French'
      enabled: environment.bolag === '1600'
        && environment.sales === true
        && environment.managers === false
        && environment.mainCountry === 'fr'
        && environment.mainCustomer === ''
        && environment.subCustomer === ''
        && environment.quoteConfirmationEmailAddress === '',
    },
    { // sales-1210
      portal: 'sales-se',
      url: 'sales-se.hl-display.com',
      languages: ['en', 'fr', 'dk'], // 'English'
      enabled: environment.bolag === '1210'
        && environment.sales === true
        && environment.managers === false
        && environment.mainCountry === 'se'
        && environment.mainCustomer === ''
        && environment.subCustomer === ''
    },
    { // sales-no-1210
      portal: 'sales-no',
      url: 'sales-no.hl-display.com',
      languages: ['en', 'fr'], // 'English'
      enabled: environment.bolag === '1210'
        && environment.sales === true
        && environment.managers === false
        && environment.mainCountry === 'no'
        && environment.mainCustomer === ''
        && environment.subCustomer === ''
    },
    { // sales-fi-1210
      portal: 'sales-fi',
      url: 'sales-fi.hl-display.com',
      languages: ['en', 'fr'], // 'English'
      enabled: environment.bolag === '1210'
        && environment.sales === true
        && environment.managers === false
        && environment.mainCountry === 'fi'
        && environment.mainCustomer === ''
        && environment.subCustomer === ''
    },
    { // sales-benelux-1600
      portal: 'sales-benelux',
      url: 'sales-benelux.hl-display.com',
      languages: ['en', 'fr', 'nl'], // 'English'
      enabled: environment.bolag === '1600'
        && environment.sales === true
        && environment.managers === false
        && environment.mainCountry === 'fr'
        && environment.mainCustomer === ''
        && environment.subCustomer === ''
        && environment.quoteConfirmationEmailAddress === 'OrdersBenelux@hl-display.com'
    },
    { // sales-de-1810
      portal: 'sales-de',
      url: 'sales-de.hl-display.com',
      languages: ['de', 'fr', 'en'], // 'Germany'
      enabled: environment.bolag === '1810'
        && environment.sales === true
        && environment.managers === false
        && environment.mainCountry === 'de'
        && environment.mainCustomer === ''
        && environment.subCustomer === ''
    },
    { // managers-uk-nisa
      portal: 'nisa',
      url: 'nisa.hl-display.com', // nisa.hl-display.co.uk  ??
      languages: ['en'], // 'English'
      enabled: environment.bolag === '1710'
        && environment.sales === false
        && environment.managers === true
        && environment.mainCountry === 'en'
        && environment.mainCustomer === ''
        && environment.subCustomer === ''
    },
    { // 	managers-ch-coop
      portal: 'coop',
      url: 'coop.hl-display.ch',
      languages: ['de', 'fr'], // 'German'
      enabled: environment.bolag === '2400'
        && environment.sales === false
        && environment.managers === true
        && environment.mainCountry === 'ch'
        && environment.mainCustomer === 'coop'
        && environment.subCustomer === ''
    },
    { // managers-en-cloetta-1210
      portal: 'cloetta',
      url: 'cloetta.hl-display.com',
      languages: ['en'], // 'English'
      enabled: environment.bolag === '1210'
        && environment.sales === false
        && environment.managers === true
        && environment.mainCountry === 'en'
        && environment.mainCustomer === 'cloetta'
        && environment.subCustomer === ''
    },
    { // customer-dsg-1710
      // https://support.gung.se/a/tickets/321361
      portal: 'currys',
      url: 'currys.hl-display.co.uk',
      languages: ['en'], // 'English'
      enabled: environment.bolag === '1710'
        && environment.sales === false
        && environment.managers === false
        && environment.mainCountry === 'en'
        && environment.mainCustomer === ''
        && environment.subCustomer === ''
    },
    { // customer-1600
      portal: 'mousquetaires',
      url: 'mousquetaires.hl-display.fr',
      languages: ['fr', 'en'], // 'French'
      enabled: environment.bolag === '1600'
        && environment.sales === false
        && environment.managers === false
        && environment.mainCountry === 'fr'
        && environment.mainCustomer === ''
        && environment.subCustomer === ''
    },
    { // customer-fr-pharmacies-1600
      portal: 'pharmacie',
      url: 'pharmacie.hl-display.fr',
      languages: ['fr', 'en'], // 'French'
      enabled: environment.bolag === '1600'
        && environment.sales === false
        && environment.managers === false
        && environment.mainCountry === 'fr'
        && environment.mainCustomer === 'Pharmacies'
        && environment.subCustomer === ''
    },
    { // customer-fr
      portal: 'portail',
      url: 'portail.hl-display.fr',
      languages: ['fr', 'en'], // 'French'
      enabled: environment.bolag === '1600'
        && environment.sales === false
        && environment.managers === false
        && environment.mainCountry === 'fr'
        && environment.mainCustomer === 'General'
        && environment.subCustomer === ''
    },
    { // customer-no-1210
      portal: 'coop',
      url: 'coop.hl-display.com',
      languages: ['no', 'fi'], // 'Norwegian'
      enabled: environment.bolag === '1210'
        && environment.sales === false
        && environment.managers === false
        && environment.mainCountry === 'no'
        && environment.mainCustomer === ''
        && environment.subCustomer === ''
    },
    { // customer-no-1210
      portal: 'kiwi',
      url: 'kiwi.hl-display.com',
      languages: ['no', 'fi'], // 'Norwegian'
      enabled: environment.bolag === '1210'
        && environment.sales === false
        && environment.managers === false
        && environment.mainCountry === 'no'
        && environment.mainCustomer === ''
        && environment.subCustomer === ''
    },
    { // customer-no-1210
      portal: 'joker',
      url: 'joker.hl-display.com',
      languages: ['no', 'fi'], // 'Norwegian'
      enabled: environment.bolag === '1210'
        && environment.sales === false
        && environment.managers === false
        && environment.mainCountry === 'no'
        && environment.mainCustomer === ''
        && environment.subCustomer === ''
    },
    { // customer-no-1210
      portal: 'meny',
      url: 'meny.hl-display.com',
      languages: ['no', 'fi'], // 'Norwegian'
      enabled: environment.bolag === '1210'
        && environment.sales === false
        && environment.managers === false
        && environment.mainCountry === 'no'
        && environment.mainCustomer === ''
        && environment.subCustomer === ''
    },
    { // customer-no-1210
      portal: 'spar',
      url: 'spar.hl-display.com',
      languages: ['no', 'fi'], // 'Norwegian'
      enabled: environment.bolag === '1210'
        && environment.sales === false
        && environment.managers === false
        && environment.mainCountry === 'no'
        && environment.mainCustomer === ''
        && environment.subCustomer === ''
    },
    { // customer-no-1210
      portal: 'portal',
      url: 'portal.hl-display.com',
      languages: ['no', 'fi'], // 'Norwegian'
      enabled: environment.bolag === '1210'
        && environment.sales === false
        && environment.managers === false
        && environment.mainCountry === 'no'
        && environment.mainCustomer === ''
        && environment.subCustomer === ''
    },
    { // customer-kesko
      portal: 'kesko',
      url: 'kesko.hl-display.com',
      languages: ['fi', 'fr', 'en'], // 'Finnish'
      enabled: environment.bolag === '1210'
        && environment.sales === false
        && environment.managers === false
        && environment.mainCountry === 'fi'
        && environment.mainCustomer === 'General'
        && environment.subCustomer === ''
    }
  ]

  selectLanguage(forceRun?: boolean) {
    if (!forceRun) {
      return;
    }
    const langFromOldVisit = localStorage.getItem('selectedLanguage');
    const enabledLangs = this.getLanguages().filter(lang => lang.enabled);
    if (!enabledLangs.some(lang => lang.short === langFromOldVisit) && !!enabledLangs.length) {
      const defaultLanguage = this.getDefaultLanguage();
      if (enabledLangs.some(lang => lang.short === defaultLanguage)) {
        this.translateService.use(defaultLanguage);
        localStorage.setItem('selectedLanguage', defaultLanguage);
      } else {
        this.translateService.use(enabledLangs[0].short);
        localStorage.setItem('selectedLanguage', enabledLangs[0].short);
      }
    } else {
      this.translateService.use(langFromOldVisit);
    }
  }

  public getLanguages(): Language[] {
    const portal = this.languagesPortal.find(l => l.enabled);
    return [
      {
        language: 'finnish',
        short: 'fi',
        image: 'finland-flag-round-icon-256.png',
        enabled: this.showAllLang || portal.languages.indexOf('fi') > -1
      },
      {
        language: 'french',
        short: 'fr',
        image: 'france-flag-round-icon-256.png',
        enabled: this.showAllLang || portal.languages.indexOf('fr') > -1
      },
      {
        language: 'dutch',
        short: 'nl',
        image: 'germany-flag-round-icon-256.png',
        enabled: this.showAllLang || portal.languages.indexOf('nl') > -1
      },
      {
        language: 'german',
        short: 'de',
        image: 'germany-flag-round-icon-256.png',
        enabled: this.showAllLang || portal.languages.indexOf('de') > -1
      },
      {
        language: 'norwegian',
        short: 'no',
        image: 'norway-flag-round-icon-256.png',
        enabled: this.showAllLang || portal.languages.indexOf('no') > -1
      },
      {
        language: 'portuguese',
        short: 'pt',
        image: 'portugal-flag-round-icon-256.png',
        enabled: this.showAllLang || portal.languages.indexOf('pt') > -1
      },
      {
        language: 'swedish',
        short: 'se',
        image: 'sweden-flag-round-icon-256.png',
        enabled: this.showAllLang || portal.languages.indexOf('se') > -1
      },
      {
        language: 'english',
        short: 'en',
        image: 'united-states-of-america-flag-round-icon-256.png',
        enabled: this.showAllLang || portal.languages.indexOf('en') > -1
      },
      {
        language: 'denmark',
        short: 'dk',
        image: 'denmark-flag-round-icon-256.png',
        enabled: this.showAllLang || portal.languages.indexOf('dk') > -1
      },
    ];
  }

  // https://support.gung.se/a/tickets/271027
  public getDefaultLanguage() {
    const portal = this.languagesPortal.find(l => l.enabled);
    if (portal) {
      return portal.languages[0];
    }
    return this.defaultLanguage;
  }
}
