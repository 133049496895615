import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, filter, first, forkJoin, mergeMap, of, switchMap, takeUntil, tap } from 'rxjs';
import { News } from '../../../models/news';
import { NewsService } from '../../../services/news/news.service';
import { Product } from '../../../models';
import { AvailabilityService } from '../../../services/availability/availability.service';
import { PriceService } from '../../../services/price/price.service';
import { SelectedCustomerService } from '../../../services/selected-customer/selected-customer.service';


@Component({
  selector: 'lib-news-page-single',
  templateUrl: './news-page-single.component.html',
  styleUrls: ['./news-page-single.component.css']
})
export class NewsPageSingleComponent implements OnInit {
  news: News;
  loading = true;
  unsubscribe: Subject<boolean> = new Subject<boolean>();
  productListIds: string[];
  constructor(
    protected route: ActivatedRoute, 
    protected newsService: NewsService,
    protected router: Router
    ) {}

  ngOnInit(): void {
    this.route.params
      .pipe(
        takeUntil(this.unsubscribe),
        filter(params => !!params?.id),
        switchMap(params => this.newsService.getUserNewsByRolesAndGroupsById(params.id))
      )
      .subscribe(data => {
        this.news = data;
      });

    if (!!this.news?.extra?.productList) {
      this.initSlideshow();
    } else {
      this.loading = false;
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next(true);
    this.unsubscribe.complete();
  }

  initSlideshow(): void {
    this.loading = true;

    const productList: Product[] = this.news?.extra?.productList;
    this.productListIds = productList.map(p => p.id);

    if (!productList?.length) {
      this.loading = false;
      return;
    }

  }

  goToDetail(product: Product): void {
    if (product?.productType === 'concept') {
      this.router.navigate(['/concept/', product.id]);
    } else {
      if (product?.extra?.conceptId) {
        this.router.navigate(['/concept/', product.extra.conceptId], {
          queryParams: {
            productid: product.id
          }
        });
      } else {
        this.router.navigate(['/product/', product.id]);
      }
    }
  }
}
