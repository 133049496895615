import { Component, OnInit } from '@angular/core';
import { NgbDate, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { format, parse } from 'date-fns';
import {
  AuthService,
  AvailabilityService,
  CalendarDate,
  CartService,
  CartTotalsService,
  GungFlowService,
  GungModalService,
  MetadataService,
  PriceService,
  Product,
  ProductInputQuantityConfigService,
  ProductService,
  SelectedCustomerService
} from 'gung-standard';
import { DateUtilService } from 'gung-common';
import { JeevesSalesCartListComponent, JeevesSalesCartListRow } from 'gung-standard-jeeves';
import { forkJoin } from 'rxjs';
import { first, switchMap, tap } from 'rxjs';
import { HlCalendarDate } from '../../services/hl-delivery-date.service';
import { langExists } from '../../services/hl-product.service';
import { HlDisplayCheckoutAddRowModalComponent } from '../hl-display-checkout-add-row-modal/hl-display-checkout-add-row-modal.component';
import { environment } from './../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-hl-display-sales-cart-list',
  templateUrl: './hl-display-sales-cart-list.component.html',
  styleUrls: ['./hl-display-sales-cart-list.component.scss']
})
export class HlDisplaySalesCartListComponent extends JeevesSalesCartListComponent implements OnInit {
  private orderLineItemNotInJeevesProductId = '155035';
  is1210Bolag = environment.bolag === '1210';
  isSalesPortal = environment.sales;
  isPortalDe = environment.mainCountry === 'de';
  isPortalFr = environment.mainCountry === 'fr';
  isPortalManagersCh = environment.managers === true && environment.mainCountry === 'ch';
  isPortalManagers = environment.managers === true;
  showComments = false;
  readOnlyPrice: boolean = false;
  productIdInstallationCharge = this.isPortalDe ? '900070' : (this.isPortalManagersCh ? '183215' : undefined);
  public isCurrysPortal =
    environment.bolag === '1710' &&
    !environment.sales &&
    !environment.managers &&
    environment.mainCountry === 'en' &&
    environment.mainCustomer === '';

  updated = true; // flag to not add multiple installation
  oldPrice;
  hasChaged = false;
  constructor(
    protected productInputQuantityConfigService: ProductInputQuantityConfigService,
    protected productService: ProductService,
    protected cartService: CartService,
    protected priceService: PriceService,
    protected availabilityService: AvailabilityService,
    protected selectedCustomerService: SelectedCustomerService,
    protected dateUtilService: DateUtilService,
    private modalService: NgbModal,
    private metadataService: MetadataService,
    private gungModalService: GungModalService,
    private cartTotalsService: CartTotalsService,
    protected flowService: GungFlowService,
    protected translateService: TranslateService,
    protected authService: AuthService
  ) {
    super(productService, cartService, priceService, availabilityService, selectedCustomerService, dateUtilService, flowService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.showComments = this.isSalesPortal && this.isPortalFr;

    this.authService
      .getCurrentUser()
      .pipe(first())
      .subscribe(user => {
        if(user.extra.readOnlyPrice && (this.isPortalManagers || this.isCurrysPortal)) {
          this.readOnlyPrice = user.extra.readOnlyPrice;
        }
      });
  }

  parseDate(dateString: string): Date {
    return parse(dateString, 'yyyy-MM-dd', new Date());
  }

  onDeliveryDateSelect(row: JeevesSalesCartListRow, selectedDate: HlCalendarDate) {
    const oldVal = row.deliveryDate || row.minDate;

    const deliveryDate = new NgbDate(
      selectedDate.estimatedShippingDate.getFullYear(),
      selectedDate.estimatedShippingDate.getMonth() + 1,
      selectedDate.estimatedShippingDate.getDate()
    );

    if (oldVal === deliveryDate) {
      return;
    }

    if (!deliveryDate) {
      this.cartService.removeExtraField(row.productId, 'orp.ordberlevdat', row.targetStockId);
      return;
    }

    this.cartService.setExtra(
      row.productId,
      {
        estDeliveryDate: format(selectedDate.date, 'yyyy-MM-dd'),
        orp: {
          ordberlevdat: format(new Date(deliveryDate.year, deliveryDate.month - 1, deliveryDate.day), 'yyyy-MM-dd')
        }
      },
      row.targetStockId,
      row.productPartialId
    );
  }

  public updateQty(row: JeevesSalesCartListRow, value: any) {
    this.hasChaged = false;
    let newQty = Number.parseInt(value.replace(/[^0-9]/g, ''), 10);
    const oldQty = row.quantity;
    const minimumOrderQuantity = this.productInputQuantityConfigService.getMinimumOrderQuantity(
      row.product.id,
      row.product
    );

    if (this.isSalesPortal) {
      // for sales portal when adjust the value, ignore the stepAmount
      if (newQty < minimumOrderQuantity) {
        newQty = minimumOrderQuantity;
      }
    } else {
      if (newQty % minimumOrderQuantity === 0) {
      } else {
        const valueRoundedUp = Math.trunc(newQty / minimumOrderQuantity) * minimumOrderQuantity + minimumOrderQuantity;
        newQty = valueRoundedUp;
      }
    }
    // check qty
    if (oldQty === newQty) {
      return;
    }
    // value has changed, propagate throught the cart
    this.cartService.setProductQuantity(row.productId, newQty, row.targetStockId, row.productPartialId);

    if (!!row.productPartialId && row.productId === this.orderLineItemNotInJeevesProductId) {
      this.cartService.setExtra(
        row.productId,
        {
          orp: {
            ordantal: newQty
          }
        },
        row.targetStockId,
        row.productPartialId
      );
    }
  }

  updateOverridePrice(row: JeevesSalesCartListRow, value: any) {
    this.hasChaged = false;
    const stringValue = value as string;
    const formattedValue = stringValue.replace(',', '.');
    const numValue = parseFloat(formattedValue);
    super.updateOverridePrice(row, numValue);
  }

  overrideDate(row: JeevesSalesCartListRow, value: CalendarDate) {
    if (this.dateUtilService.getFormattedDateString(value?.date) === row?.deliveryDate) {
      // Don't change if it is the same date
      return;
    }
    const hl: HlCalendarDate = value as HlCalendarDate;
    this.onDeliveryDateSelect(row, hl);
  }

  addNewOrderLine(): void {
    this.modalService.open(HlDisplayCheckoutAddRowModalComponent, { size: 'lg', backdrop: 'static' });
  }

  getName(product: Product): string {
    const itemGroup = this.metadataService.getMetadataValue('vg', 'varugruppbeskr', product.extra.ar.varugruppkod);
    let itemDesc = product.extra.ar.artbeskr;
    if (langExists(environment.mainCountry)(product)) {
      itemDesc = product.extra.i18n[environment.mainCountry].artbeskr || itemDesc;
    }
    return `${itemGroup} - ${itemDesc}`;
  }

  openCommentsModal(row: JeevesSalesCartListRow) {
    this.gungModalService.openEditNoteModal((row.cartRow.extra.orp && row.cartRow.extra.orp.Editext) || '').then(
      result => {
        if (!result) {
          return;
        }
        if (result.note.trim() !== '') {
          this.cartService.setExtra(
            row.productId,
            {
              orp: {
                ...row.cartRow.extra.orp,
                Editext: result.note
              }
            },
            row.targetStockId,
            row.productPartialId
          );
        } else {
          this.cartService.removeExtraField(row.productId, 'orp.Editext', row.targetStockId, row.productPartialId);
        }
      },
      dissmissReason => {
        console.log(dissmissReason);
      }
    );
  }

  updateMappedData(d: JeevesSalesCartListRow[]) {
    super.updateMappedData(d);
    this.updated = true;
    if (this.is1210Bolag && this.isSalesPortal) {
      this.mappedData.map(av => {
        const firstDate = Object.keys(av.product.extra.availabilities[0].extra.availabilities).filter(key => {
          return av.product.extra.availabilities[0].extra.availabilities[key] > 0;
        })[0];
  
        if (av.product.extra.availabilities[0].extra.availabilities[firstDate] != 100000) {
          av.product.extra.availabilities[0].maxFutureStock = av.product.extra.availabilities[0].extra.availabilities[firstDate]
          av.product.extra.availabilities[0].dateMaxFutureStock = this.dateUtilService.changeDateFormat(firstDate, 'yyMMdd')
        }      
      })
    }
    if(this.isPortalManagersCh) {
      this.addInstallationChargeFixed(0.10)
    }
  }

  addInstallationChargeFixed(value: number) {
    const productId = this.productIdInstallationCharge;
    const installationRow = this.mappedData?.find(d => d.productId === productId);

    forkJoin([
      this.selectedCustomerService.getSelectedCustomer().pipe(first()),
      this.cartTotalsService.getTotals().pipe(first())
    ]).subscribe(([customer, totals]) => {
      const customerFinancialGroup = customer.extra.kus.q_financialgrpcode;
      const discountFactor = environment.discountCustomerFinancialGroup[customerFinancialGroup]
        ? environment.discountCustomerFinancialGroup[customerFinancialGroup]
        : 0;

      let price: string;
      if(installationRow) {
        price = ((totals.totalValue - installationRow.price.cartRowTotalPriceInclRowDiscount.value) * value).toFixed(2);
      } else {
        price = (totals.totalValue * value).toFixed(2);
      }
      
      const deliveryDate = (this.mappedData.length > 0 && this.mappedData[0].deliveryDate) || format(new Date(), 'yyyy-MM-dd');
      
      const theOrp = {
        vb_pris: price,
        ordantal: 1,
        rabatt1: undefined,
        artbeskr: this.translateService.instant('INSTALLATION_COST'),
        ordberlevdat: deliveryDate
      };
    
      if (!installationRow && this.mappedData.length > 0) {
          this.oldPrice = price;
          this.cartService.addToCart(productId, 1);
        
          this.cartService.setExtra(productId, {
            orp: theOrp,
            _discountCustomerFinancialGroupInfo: {
              appliedFactor: discountFactor,
              appliedTo: price,
              appliedFrom: 'HlDisplaySalesCartListComponent - addInstallationCharge()'
            }
          });
        } 

        if (this.oldPrice !== price && !this.hasChaged) {
          this.hasChaged = true;
          this.cartService.setExtra(productId, {
            orp: theOrp,
            _discountCustomerFinancialGroupInfo: {
              appliedFactor: discountFactor,
              appliedTo: price,
              appliedFrom: 'HlDisplaySalesCartListComponent - addInstallationCharge()'
            }
          });
          
        }
        
        if(installationRow && this.mappedData.length === 1){
          this.cartService.removeRow(installationRow.cartRow);
        }
    });
    
    
  }

  addInstallationCharge(value: number) {
    if (!this.updated) {
      return;
    }
    this.updated = false;
    const productId = this.productIdInstallationCharge;
    const installationRow = this.mappedData?.find(d => d.productId === productId);
    if (installationRow) {
      this.cartService.removeRow(installationRow.cartRow);
    }
    forkJoin([
      this.selectedCustomerService.getSelectedCustomer().pipe(first()),
      this.cartTotalsService.getTotals().pipe(first())
    ]).subscribe(([customer, totals]) => {
      const customerFinancialGroup = customer.extra.kus.q_financialgrpcode;
      const discountFactor = environment.discountCustomerFinancialGroup[customerFinancialGroup]
        ? environment.discountCustomerFinancialGroup[customerFinancialGroup]
        : 0;

      const price = (totals.totalValue * value).toFixed(2);
      const deliveryDate =
        (this.mappedData.length > 0 && this.mappedData[0].deliveryDate) || format(new Date(), 'yyyy-MM-dd');

      const theOrp = {
        vb_pris: price,
        ordantal: 1,
        rabatt1: undefined,
        artbeskr: this.translateService.instant('INSTALLATION_COST'),
        ordberlevdat: deliveryDate
      };
      this.cartService.addToCart(productId, 1);

      // This doesn't call SalesCartCartService, thats why the workaround above
      this.cartService.setExtra(productId, {
        orp: theOrp,
        _discountCustomerFinancialGroupInfo: {
          appliedFactor: discountFactor,
          appliedTo: price,
          appliedFrom: 'HlDisplaySalesCartListComponent - addInstallationCharge()'
        }
      });
    });
  }
}
