<div class="container faq-container">
  <div class="row justify-content-center">
    <div class="col-12 col-lg-8 col-xl-7">
      <div class="mb-4">
        <h1>FAQ</h1>
      </div>

      <h3 class="faq-header" translate>CUSTOMER_ACCOUNT</h3>
      <!-- CUSTOMER ACCOUNT -->
      <ngb-accordion>
        <ngb-panel id="1">
          <ng-template ngbPanelTitle>
            <span translate>FAQ_ACCESS_PORTAL_QUERY</span>
          </ng-template>
          <ng-template ngbPanelContent>
            <ul>
              <li
                *ngIf="!(isFRCustomerPortalPharma || isFRCustomerPortal1600General)"
                [innerHTML]="'FAQ_ACCESS_PORTAL_RESPONSE' | translate"
              ></li>
              <li
                *ngIf="isFRCustomerPortalPharma || isFRCustomerPortal1600General"
                [innerHTML]="'FAQ_ACCESS_PORTAL_RESPONSE_PHARMA' | translate"
              ></li>
            </ul>
          </ng-template>
        </ngb-panel>
        <ngb-panel id="2">
          <ng-template ngbPanelTitle>
            <span translate>FAQ_ACCOUNT_INFO_QUERY</span>
          </ng-template>
          <ng-template ngbPanelContent>
            <ul>
              <li>
                {{ 'FAQ_ACCOUNT_INFO_RESPONSE' | translate }}
                <a [routerLink]="['/customers/', customerId]">{{ 'FAQ_MES_DONNEES' | translate }}</a>
              </li>
            </ul>
          </ng-template>
        </ngb-panel>
        <ngb-panel id="3">
          <ng-template ngbPanelTitle>
            <span translate>FAQ_FORGOT_PASSWORD_QUERY</span>
          </ng-template>
          <ng-template ngbPanelContent>
            <ul>
              <li [innerHTML]="'FAQ_FORGOT_PASSWORD_RESPONSE' | translate"></li>
            </ul>
          </ng-template>
        </ngb-panel>
      </ngb-accordion>
      <!-- ORDER INFOMRATION -->
      <h3 class="faq-header" translate>ORDER_INFO</h3>
      <ngb-accordion>
        <ngb-panel id="1">
          <ng-template ngbPanelTitle>
            <span translate>FAQ_ORDER_PLACEMENT_QUERY</span>
          </ng-template>
          <ng-template ngbPanelContent>
            <ul>
              <li [innerHTML]="'FAQ_ORDER_PLACEMENT_RESPONSE' | translate"></li>
            </ul>
          </ng-template>
        </ngb-panel>

        <ngb-panel id="2">
          <ng-template ngbPanelTitle>
            <span translate>FAQ_ORDER_TRACKING_QUERY</span>
          </ng-template>
          <ng-template ngbPanelContent>
            <ul>
              <li>
                {{ 'FAQ_ORDER_TRACKING_RESPONSE' | translate }}
                <a [routerLink]="['/customers/', customerId]">{{ 'FAQ_MES_DONNEES' | translate }}</a>
              </li>
            </ul>
          </ng-template>
        </ngb-panel>

        <ngb-panel id="3">
          <ng-template ngbPanelTitle>
            <span translate>FAQ_ORDER_MODIFICATION_QUERY</span>
          </ng-template>
          <ng-template ngbPanelContent>
            <ul>
              <li [innerHTML]="'FAQ_ORDER_MODIFICATION_RESPONSE' | translate"></li>
            </ul>
          </ng-template>
        </ngb-panel>

        <ngb-panel id="4">
          <ng-template ngbPanelTitle>
            <span translate>FAQ_ORDER_HISTORY_QUERY</span>
          </ng-template>
          <ng-template ngbPanelContent>
            <ul>
              <li>
                {{ 'FAQ_ORDER_HISTORY_RESPONSE' | translate }}
                <a [routerLink]="['/customers/', customerId]">{{ 'FAQ_MES_DONNEES' | translate }}</a>
              </li>
            </ul>
          </ng-template>
        </ngb-panel>
        <ngb-panel id="5">
          <ng-template ngbPanelTitle>
            <span translate>FAQ_PRINT_ORDER_CONFIRMATION_QUERY</span>
          </ng-template>
          <ng-template ngbPanelContent>
            <ul>
              <li [innerHTML]="'FAQ_PRINT_ORDER_CONFIRMATION_RESPONSE' | translate"></li>
            </ul>
          </ng-template>
        </ngb-panel>
      </ngb-accordion>
      <!-- DELIVERY / RETURNS -->
      <h3 class="faq-header" translate>DELIVERY_RETURNS</h3>
      <ngb-accordion>
        <ngb-panel id="1">
          <ng-template ngbPanelTitle>
            <span translate>FAQ_TIME_EST_QUERY</span>
          </ng-template>
          <ng-template ngbPanelContent>
            <ul>
              <li [innerHTML]="'FAQ_TIME_EST_RESPONSE' | translate"></li>
            </ul>
          </ng-template>
        </ngb-panel>
        <ngb-panel id="2">
          <ng-template ngbPanelTitle>
            <span translate>FAQ_RETURNS_QUERY</span>
          </ng-template>
          <ng-template ngbPanelContent>
            <ul>
              <li [innerHTML]="'FAQ_RETURNS_RESPONSE' | translate"></li>
            </ul>
          </ng-template>
        </ngb-panel>
        <ngb-panel id="3">
          <ng-template ngbPanelTitle>
            <span [innerHTML]="'FAQ_INVOICE_QUERY' | translate"></span>
          </ng-template>
          <ng-template ngbPanelContent>
            <ul>
              <li [innerHTML]="'FAQ_INVOICE_RESPONSE' | translate"></li>
            </ul>
          </ng-template>
        </ngb-panel>
        <ngb-panel id="4" *ngIf="!(isFRCustomerPortalPharma || isFRCustomerPortal1600General)">
          <ng-template ngbPanelTitle>
            <span translate>FAQ_CATALOUGE_QUERY</span>
          </ng-template>
          <ng-template ngbPanelContent>
            <ul>
              <li [innerHTML]="'FAQ_CATALOUGE_RESPONSE' | translate"></li>
            </ul>
          </ng-template>
        </ngb-panel>
      </ngb-accordion>
      <!-- TECH ADVICE -->
      <h3 class="faq-header" translate>TECH_ADVICE</h3>
      <ngb-accordion>
        <ngb-panel id="1">
          <ng-template ngbPanelTitle>
            <span translate *ngIf="!(isFRCustomerPortalPharma || isFRCustomerPortal1600General)"
              >FAQ_TECH_FITTING_QUERY</span
            >
            <span translate *ngIf="isFRCustomerPortalPharma || isFRCustomerPortal1600General"
              >FAQ_TECH_FITTING_QUERY_PHARMA</span
            >
          </ng-template>
          <ng-template ngbPanelContent>
            <ul>
              <li
                *ngIf="!(isFRCustomerPortalPharma || isFRCustomerPortal1600General)"
                [innerHTML]="'FAQ_TECH_FITTING_RESPONSE' | translate"
              ></li>
              <li
                *ngIf="isFRCustomerPortalPharma || isFRCustomerPortal1600General"
                [innerHTML]="'FAQ_TECH_FITTING_RESPONSE_PHARMA' | translate"
              ></li>
            </ul>
          </ng-template>
        </ngb-panel>
        <ngb-panel id="2" *ngIf="isFRCustomerPortalPharma || isFRCustomerPortal1600General">
          <ng-template ngbPanelTitle>
            <span translate>FAQ_PAYMENT_SAMPLE_QUERY</span>
          </ng-template>
          <ng-template ngbPanelContent>
            <ul>
              <li [innerHTML]="'FAQ_PAYMENT_SAMPLE_RESPONSE' | translate"></li>
            </ul>
          </ng-template>
        </ngb-panel>
        <!-- <ngb-panel id="2">
            <ng-template ngbPanelTitle>
              <span translate>FAQ_TECH_MEASUREMENT_QUERY</span>
            </ng-template>
            <ng-template ngbPanelContent>
              <ul>
                <li translate>
                  FAQ_TECH_MEASUREMENT_RESPONSE
                </li>
              </ul>
            </ng-template>
          </ngb-panel> -->
      </ngb-accordion>
      <!-- PAYMENT -->
      <h3 class="faq-header" translate>PAYMENT</h3>
      <ngb-accordion>
        <ngb-panel id="1">
          <ng-template ngbPanelTitle>
            <span translate>FAQ_PAYMENT_TERMS_QUERY</span>
          </ng-template>
          <ng-template ngbPanelContent>
            <ul>
              <li *ngIf="idIntermarcheOrCarrefourFR; else others" [innerHTML]="'FAQ_PAYMENT_TERMS_RESPONSE_' | translate"></li>
              <ng-template #others>
                <li [innerHTML]="'FAQ_PAYMENT_TERMS_RESPONSE' | translate"></li>
              </ng-template>
            </ul>
          </ng-template>
        </ngb-panel>
        <ngb-panel id="2">
          <ng-template ngbPanelTitle>
            <span translate>FAQ_PAYMENT_POLICY_QUERY</span>
          </ng-template>
          <ng-template ngbPanelContent>
            <ul>
              <li
                *ngIf="idIntermarcheOrCarrefourNL"
              >
              De verkoopvoorwaarden zijn identiek aan de voorwaarden die zijn vermeld in onze onderlinge overeenkomst. 
              </li>
              <li
                *ngIf="!(isFRCustomerPortalPharma || isFRCustomerPortal1600General) && !isNOCustomerPortal && !idIntermarcheOrCarrefourNL"
                [innerHTML]="'FAQ_PAYMENT_POLICY_RESPONSE' | translate"
              ></li>
              <li
                *ngIf="(isFRCustomerPortalPharma || isFRCustomerPortal1600General) && !idIntermarcheOrCarrefourNL "
                [innerHTML]="
                  'FAQ_PAYMENT_POLICY_RESPONSE_PHARMA_VALUE'
                    | translate
                      : {
                          minimumFreight: minimumFreight,
                          freightCost: freightCost,
                          minimumOrder: minimumOrder,
                          adminCost: adminCost
                        }
                "
              ></li>
              <li
                *ngIf="isNOCustomerPortal && isNOCustomerPortalCoop"
                [innerHTML]="'FAQ_PAYMENT_POLICY_RESPONSE_COOP' | translate"
              ></li>
              <li
                *ngIf="isNOCustomerPortal && isNOCustomerPortalNorgesGroupAndSpar"
                [innerHTML]="'FAQ_PAYMENT_POLICY_RESPONSE_NORGE_GROUP_SPAR' | translate"
              ></li>
              <li
                *ngIf="isNOCustomerPortal && isNOCustomerPortalReitanGroup"
                [innerHTML]="'FAQ_PAYMENT_POLICY_RESPONSE_REITA_GROUP' | translate"
              ></li>
            </ul>
          </ng-template>
        </ngb-panel>
        <ngb-panel id="3" *ngIf="!(isFRCustomerPortalPharma || isFRCustomerPortal1600General)">
          <ng-template ngbPanelTitle>
            <span translate>FAQ_PAYMENT_SAMPLE_QUERY</span>
          </ng-template>
          <ng-template ngbPanelContent>
            <ul>
              <li [innerHTML]="'FAQ_PAYMENT_SAMPLE_RESPONSE' | translate"></li>
            </ul>
          </ng-template>
        </ngb-panel>
        <ngb-panel id="4" *ngIf="isFRCustomerPortalPharma || isFRCustomerPortal1600General">
          <ng-template ngbPanelTitle>
            <span translate>FAQ_PAYMENT_ONLINE_QUERY_PHARMA</span>
          </ng-template>
          <ng-template ngbPanelContent>
            <ul>
              <li [innerHTML]="'FAQ_PAYMENT_ONLINE_RESPONSE_PHARMA' | translate"></li>
            </ul>
          </ng-template>
        </ngb-panel>
      </ngb-accordion>
    </div>
  </div>
</div>
