import { Component, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { ListItemRendererComponent } from 'gung-list';
import { Subject, Subscription } from 'rxjs';
import { Pim } from '../../../models/pim';
import { PimService } from '../../../services/pim/pim.service';

@Component({
  selector: 'lib-pim-grid-view',
  templateUrl: './pim-grid-view.component.html',
  styleUrls: ['./pim-grid-view.component.css']
})
export class PimGridViewComponent extends ListItemRendererComponent<Pim[]> implements OnInit, OnDestroy, OnChanges {
  private subscriptions: Subscription[] = [];
  protected unsubscribe: Subject<void> = new Subject();

  constructor() {
    super();
  }

  ngOnInit() {}

  ngOnChanges() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());

    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
