import { Component, OnChanges, OnInit } from '@angular/core';
import { ListItemRendererComponent } from 'gung-list';
import { ReplenishmentCustomer, ReplenishmentProductStatus } from '../../models/replenishment';
import { ReplenishmentCustomerService } from '../../services/replenishment-customer.service';
import { debounceTime, distinctUntilChanged, first, map, Observable } from 'rxjs';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { CustomerService } from '../../../../services/customers/customer.service';
import { Customer } from '../../../../models/customer';
import { keyBy } from 'lodash-es';

@Component({
  selector: 'lib-replenishment-customer-view',
  templateUrl: './replenishment-customer-view.component.html',
  styleUrls: ['./replenishment-customer-view.component.scss']
})
export class ReplenishmentCustomerViewComponent
  extends ListItemRendererComponent<ReplenishmentCustomer[]>
  implements OnInit, OnChanges
{
  loaded = false;
  eligibleForOrder: Map<string, boolean>;
  keyedCustomers: Map<string, Customer>;
  customers: Customer[];
  loadedCustomers = new Set<string>();

  constructor(
    protected replenishmentCustomerService: ReplenishmentCustomerService,
    protected customerService: CustomerService
  ) {
    super();
  }

  ngOnInit() {
    this.customerService
      .getCustomers()
      .pipe(first())
      .subscribe(c => {
        this.customers = c;
        this.keyedCustomers = keyBy(c, 'id');
      });
  }

  ngOnChanges(): void {
    for (const c of this.data) {
      this.loadedCustomers.add(c.customerId);
    }

    this.loaded = true;
  }

  changeActiveState(item: ReplenishmentCustomer, newState: boolean) {
    item.active = newState;
    this.replenishmentCustomerService
      .saveReplenishmentCustomer(item)
      .pipe(first())
      .subscribe(_ => undefined);
  }

  onSelectCustomer(event: NgbTypeaheadSelectItemEvent) {
    event.preventDefault();
    const customer = event.item as Customer;

    const replenishmentCustomer = {
      customerId: customer.id,
      active: true
    } as ReplenishmentCustomer;

    this.replenishmentCustomerService.saveReplenishmentCustomer(replenishmentCustomer).subscribe(_ => undefined);
  }

  searchCustomers = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term =>
        !this.customers
          ? []
          : (!term
              ? this.customers
              : this.customers.filter(
                  item =>
                    (item.id.toLowerCase().indexOf(term.toLowerCase()) > -1 ||
                      item.name.toLowerCase().indexOf(term.toLowerCase()) > -1) &&
                    !this.loadedCustomers.has(item.id)
                )
            ).slice(0, 10)
      )
    );

  formatterCustomer = (result: Customer): string => result.id + ' - ' + result.name;
}
