import { Component } from '@angular/core';
import { OrderCardListComponent } from 'gung-standard';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-hl-display-order-card-list',
  templateUrl: './hl-display-order-card-list.component.html',
  styleUrls: ['./hl-display-order-card-list.component.scss']
})
export class HlDisplayOrderCardListComponent extends OrderCardListComponent {
  is1210Bolag = environment.bolag === '1210';
  isUkBolag = environment.bolag === '1710';

  constructor() {
    super();
  }
}
