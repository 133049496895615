<div class="row">
  <div class="col">
    <canvas #objectPreviewCanvas style="display: none"></canvas>
    <div class="d-flex justify-content-around">
      <div [ngStyle]="{ width: width + 'px' }" style="margin-right: 20px">
        <canvas
          #logoApplicator
          (mousedown)="mouseDown($event)"
          (mouseup)="mouseUp($event)"
          (mousemove)="mouseMove($event)"
          (mouseleave)="mouseLeave()"
        ></canvas>
        <div class="d-flex justify-content-between">
          <div
            (click)="selectProfile(profile)"
            *ngFor="let profile of profiles"
            class="logo-applicator-profile clickable"
            [ngClass]="profile == selectedProfile ? 'selectedProfile' : ''"
          >
            <img class="img-fluid" [src]="profile.background.src" alt="" />
          </div>
        </div>
      </div>

      <div style="width: 20%" class="d-flex flex-column justify-content-around">
        <div class="mb-3">
          <h1 style="margin-bottom: 10px; font-size: 1.5rem" translate>CUSTOMIZER</h1>
          <p translate style="text-align: justify">CUSTOMIZER_INSTRUCTIONS</p>
          <small translate style="font-style: italic">SUPPORTED_FILE_FORMATS</small>
          <br />
          <small style="font-style: italic" translate>MAX_FILE_SIZE</small>

          <div class="custom-file">
            <input
              type="file"
              class="custom-file-input"
              id="logo-input"
              accept=".png,.jpg,.jpeg,.svg"
              (change)="logoInputChange($event)"
              onclick="this.value=null;"
            />
            <label class="custom-file-label" for="logo-input" translate>CHOOSE_FILE</label>
          </div>
        </div>

        <div>
          <div class="input-group">
            <input
              [(ngModel)]="textInput"
              id="text-input"
              class="form-control logo-applicator-text-input"
              type="text"
              placeholder="{{ 'FREE_TEXT' | translate }}"
            />
            <div class="input-group-btn">
              <button (click)="addText()" [disabled]="!textInput" class="btn btn-primary add-text-button" type="button">
                +
              </button>
            </div>
          </div>
          <div class="input-group" style="width: 100%">
            <select [(ngModel)]="selectedFont" class="form-control" name="font" id="font">
              <option value="Comic Sans MS">Comic Sans</option>
              <option value="Roboto">Roboto</option>
              <option value="Arial">Arial</option>
              <option value="Verdana">Verdana</option>
              <option value="Times New Roman">Times New Roman</option>
              <option value="Courier">Courier</option>
              <option value="Trebuchet MS">Trebuchet MS</option>
              <option value="Georgia">Georgia</option>
            </select>
            <div class="input-group-btn">
              <input
                class="form-control"
                type="color"
                [(ngModel)]="textColor"
                style="width: 58px; padding: 5px; border-left: 0px !important"
              />
            </div>
          </div>
        </div>

        <div style="overflow-y: scroll; overflow-x: hidden; flex-grow: 2; max-height: 361px" *ngIf="!!selectedProfile">
          <div
            *ngFor="let image of selectedProfile.images"
            [ngClass]="image.selected ? 'selectedProfile' : 'transparentBorder'"
            (click)="setSelected(image)"
            class="row application-element clickable"
          >
            <span *ngIf="!image.underEdit">
              <i
                class="fas fa-pencil-alt editTextLogo edit-text-icon"
                *ngIf="!!image.text"
                aria-hidden="true"
                (click)="image.underEdit = true"
              ></i>
              <i class="fas fa-times remove-logo-icon" aria-hidden="true" (click)="removeImage(image)"></i>
              <img [src]="!!image.safeImageUrl ? image.safeImageUrl : image.img.src" class="logo-preview-image" />
            </span>
            <span *ngIf="image.underEdit">
              <div class="input-group" style="margin-top: 36px; margin-bottom: 36px">
                <input
                  [(ngModel)]="image.text"
                  (ngModelChange)="draw()"
                  id="text-input2"
                  class="form-control logo-applicator-text-input"
                  type="text"
                  data-translate
                  data-translate-attr-placeholder="FREE_TEXT"
                />
                <div class="input-group-btn">
                  <button
                    (click)="image.underEdit = false; generateNewPreviewImage(image)"
                    [disabled]="!image.text"
                    class="btn add-text-button"
                    type="button"
                    style="width: 65px; border-radius: 0px"
                  >
                    <i class="fa fa-check" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
            </span>
          </div>
        </div>

        <div>
          <div class="input-group" style="width: 100%">
            <!--<label for="nameField" data-translate="NAME">Name</label>-->
            <input
              id="nameField"
              [(ngModel)]="nameInput"
              class="form-control logo-applicator-text-input"
              type="text"
              placeholder="{{ 'NAME_YOUR_CUSTOMIZATION' | translate }}"
            />
          </div>
          <button
            class="btn btn-primary btn-block"
            [disabled]="!nameInput || !logoIsApplied()"
            (click)="saveCanvas()"
            style="margin-bottom: 0px"
          >
            <span translate>SAVE_APPLICATION</span>
          </button>
          <div class="text-center">
            <div class="spinner-border spinner-border-sm mt-3" role="status" *ngIf="saving">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
          <div *ngIf="isSaved" class="mt-3">
            <i class="fas fa-check"></i>
            <span class="ml-2" translate>LOGO_APPLICATION_SAVED</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Other colors -->
<div class="row mt-5 ml-3" *ngIf="!!colors">
  <div class="col">
    <h5 translate>COLORS</h5>
  </div>
</div>
<div class="d-flex ml-3">
  <div
    (click)="selectColor(color.key)"
    *ngFor="let color of colors | keyvalue"
    class="logo-applicator-color-card clickable mb-3"
    [ngClass]="selectedColor === color.key ? 'selectedColor' : ''"
  >
    <div>
      <img [src]="color.value.profiles[0].background.src" class="img-fluid" alt="" />
    </div>
    <small>{{ color.value.name }} - {{ color.key.slice(0, 3) }}</small>
  </div>
</div>
