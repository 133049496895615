import { Injectable } from '@angular/core';
import {
  OpenOrdersConfigService,
  OrderService,
  SelectedCustomerService,
  MetadataService,
  CustomerService,
  OpenOrderTableComponent,
  Order
} from 'gung-standard';
import { TranslateService } from '@ngx-translate/core';
import { ListLayout, ListLayoutMultipleComponent, SimpleConfigBaseFilter, ConfigBaseFilter } from 'gung-list';
import { switchMap, mergeMap, map } from 'rxjs';
import { forkJoin, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class JeevesOpenOrdersListTableConfigService extends OpenOrdersConfigService {
  constructor(
    protected translationService: TranslateService,
    protected orderService: OrderService,
    protected selectedCustomerService: SelectedCustomerService,
    protected metadataService: MetadataService,
    protected customerService: CustomerService
  ) {
    super(translationService, orderService, selectedCustomerService, metadataService, customerService);
  }

  getLayouts(): ListLayout<Order>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => OpenOrderTableComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'Product grid'
      }
    ];
  }

  getSearchTerms(item: Order): string[] {
    const result = [item.id];

    if (item.extra.customerName) {
      result.push(item.extra.customerName);
    }

    return result;
  }
}
