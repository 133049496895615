<ngb-accordion #acc="ngbAccordion" id="saved-cart-accordion" class="accordion-spacing">
  <ngb-panel [id]="cart.id" *ngFor="let cart of data; let i = index">
    <ng-template ngbPanelHeader>
      <div class="row" style="place-content: center">
        <div class="d-flex flex-direction-row align-items-center col-12">
          <button class="btn btn-link text-dark col-4">
            {{ cart.name }} - {{ dateUtilService.parseDate(cart.creationDate, 'yyyy-MM-dd"T"HH:mm:ss.SSSXXX') | date: dateUtilService.dateFormat }}
          </button>
          <button ngbPanelToggle class="btn btn-link ml-auto col-4" (click)="loadCart(cart)" translate>
            LOAD_CART
          </button>
          <button ngbPanelToggle class="btn btn-link col-3" (click)="fetchProductData(cart)" translate>
            SHOW_DETAILS
          </button>
          <button
            class="btn btn-link col-1"
            (click)="deleteCart(cart); $event.preventDefault(); $event.stopPropagation()"
          >
            <i class="fas fa-trash-alt"></i>
          </button>
        </div>
        <div style="font-size: x-small">
          <span *ngIf="cart.customerId" style="margin-right: 10px"
            ><span style="font-weight: bold" translate>CUSTOMER_ID</span>: {{ cart.customerId }}
          </span>
          <span *ngIf="cart.username"
            ><span style="font-weight: bold" translate>USER_ID</span>: {{ cart.username }}
          </span>
        </div>
      </div>
    </ng-template>

    <ng-template ngbPanelContent>
      <div class="table-responsive overflow-hidden" *ngIf="mapData[cart.id]; else loading">
        <!--<div class="row pl-3 pr-3 pb-3 justify-content-end" *ngIf="saveData[cart.id]">
          <button class="btn btn-primary" (click)="saveCartOrder(i ,$event)" translate>SAVE</button>
        </div>-->
        <table class="table table-sm table-borderless table-striped">
          <thead>
            <tr>
              <th class="td-id"><span translate>PRODUCT_ID</span></th>
              <th class="td-name"><span translate>NAME</span></th>
              <th class="td-qty"><span translate>QTY</span></th>
              <th class="td-price"><span translate>PRICE</span></th>
              <th class="delete-row">Delete</th>
              <th class="td-move"></th>
            </tr>
          </thead>
          <tbody cdkDropList (cdkDropListDropped)="drop(i, $event)" class="drag-list">
            <tr *ngFor="let row of mapData[cart.id]" class="dragable-item" cdkDrag>
              <td class="td-id">
                <a [routerLink]="['/product/', row.productId]">
                  {{ row.productId }}
                </a>
              </td>
              <td class="td-name">
                {{ row?.product?.name }}
              </td>
              <td  class="edit-qty text-right">
                <!-- {{ row.qty }} -->
                <!-- <div class="edit-cart-buttons">
                  <button
                    type="button"
                    class="btn btn-link p-0"
                    (click)="refreshQty(row, cart)"
                  >
                    <i class="fas fa-redo"></i>
                  </button>

                  <button
                    type="button"
                    class="btn btn-link py-0 ml-2"
                    (click)="deleteRow(row, cart)"
                  >
                    <i class="fas fa-trash-alt"></i>
                  </button>
                </div>
                <span>{{ row.qty }}</span> -->
                <div class="d-flex">
                  <div class="input-group-prepend">
                    <button
                      class="btn btn-primary btn-sm d-flex flex-row justify-content-center align-items-center"
                      tabindex="-1"
                      (click)="sub(i, row, cart, inputEle); $event.preventDefault(); $event.stopPropagation()"
                    >
                      <i class="fas fa-minus" style="font-size: 1rem"></i>
                    </button>
                  </div>
                  <input
                    #inputEle
                    type="number"
                    class="form-control form-control-sm text-center"
                    [(ngModel)]="row.qty"
                    [ngModelOptions]="{ updateOn: 'blur' }"
                    (ngModelChange)="updateQuantity($event, row, cart, inputEle)"
                    (click)="$event.preventDefault(); $event.stopPropagation()"
                    [class.font-weight-bold]="checkModulus(row.qty, row.product?.extra.ar?.artfsgforp).multiple"
                    [step]="row.product?.packageSize || 1"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-sm btn-primary d-flex flex-row justify-content-center align-items-center"
                      tabindex="-1"
                      (click)="add(i, row, cart, inputEle); $event.preventDefault(); $event.stopPropagation()"
                    >
                      <i class="fas fa-plus" style="font-size: 1rem"></i>
                    </button>
                  </div>
                </div>
              </td>
              <td class="td-price">
                <lib-price
                  [price]="row?.product?.extra?.price?.value"
                  [currency]="row?.product?.extra?.price?.currencyCode"
                ></lib-price>
              </td>
              <td class="delete-row">
                <button
                  type="button"
                  class="btn btn-link py-0"
                  (click)="deleteRow(i, row, cart)"
                >
                  <i class="fas fa-trash-alt"></i>
                </button>
              </td>
              <td class="td-move flex-row flex-nowrap justify-content-center align-items-center">
                <i class="fas fa-arrows-alt fa-lg drag-handle" cdkDragHandle></i>
              </td>
            </tr>

          </tbody>
          <tfoot>
            <tr>
              <td colspan="4" class="pl-0">

                <button class="btn btn-primary"  (click)="addProductToCart(cart, i)" translate>ADD</button>
              </td>
              <td colspan="4">
                <div class="row pl-3 pr-3 pb-3 justify-content-end" *ngIf="saveData[cart.id]">
                  <button class="btn btn-primary" (click)="saveCartOrder(i ,$event)" translate>SAVE</button>
                </div>
            </td>
            </tr>
            <tr>
              <td translate>SUMMARY</td>
              <td></td>
              <td></td>
              <td>
                <lib-price [price]="totalPriceByCart[cart.id]" [currency]="currencyCode"></lib-price>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </ng-template>
  </ngb-panel>
</ngb-accordion>

<ng-template #loading>
  <div class="row">
    <div class="col-12 m-2 p-2 text-center">
      <i class="fa fa-spinner fa-spin-lg"></i>
    </div>
  </div>
</ng-template>
