<form [formGroup]="form" *ngIf="!loading || !form; else loadingSpinner">
  <fieldset [disabled]="disabled">
    <div class="form-group">
      <label class="control-label" translate>EMAIL_CONFIRMATION</label>
    
      <ng-container *ngFor="let email of controlsEmail?.controls; let i = index; trackBy: trackByFn" [formGroup]="email">
        <div class="input-group email-group mb-2">
          <div class="input-group-prepend">
            <button type="button" class="btn btn-secondary" (click)="removeEmail(i)" [disabled]="disabled">-</button>
          </div>
          <input
            type="email"
            class="form-control"
            [attr.placeholder]="'ENTER_EMAIL' | translate"
            formControlName="email"
          />
        </div>
        <lib-forms-message-error [controller]="email.get('email')" class="mb-2"></lib-forms-message-error>
      </ng-container>
      
      <div class="input-group">
        <button type="button" [disabled]="disabled" class="btn btn-block btn-primary" (click)="addEmail()">+</button>
      </div>
    </div>
  </fieldset>
</form>

<ng-template #loadingSpinner> LOADING <i class="fas fa-spinner fa-spin ml-2"></i> </ng-template>