import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';
import {
  ProductDetailComponent,
  AuthService,
  AvailabilityService,
  PriceService,
  MetadataService,
  Product,
  SelectedCustomerService,
  AssortmentService,
  GungFlowService
} from 'gung-standard';
import { NgbCarousel } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import { map, mergeMap, debounce, first } from 'rxjs';
import { forkJoin, of, timer } from 'rxjs';
import { HlProductService, langExists } from '../../services/hl-product.service';
import { environment } from '../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-hl-display-product-detail',
  templateUrl: './hl-display-product-detail.component.html',
  styleUrls: ['./hl-display-product-detail.component.scss']
})
export class HlDisplayProductDetailComponent extends ProductDetailComponent implements OnInit {
  public isCloettaPortal =
    environment.bolag === '1210' &&
    !environment.sales &&
    environment.managers &&
    environment.mainCountry === 'en' &&
    environment.mainCustomer === 'cloetta';

  public isManagersNisa = environment.managers && environment.mainCountry === 'en';

  public isCurrysPortal =
    environment.bolag === '1710' &&
    !environment.sales &&
    !environment.managers &&
    environment.mainCountry === 'en' &&
    environment.mainCustomer === '';

  public isMousquetairesPortal =
    environment.bolag === '1600' &&
    environment.sales === false &&
    environment.managers === false &&
    environment.mainCountry === 'fr' &&
    environment.mainCustomer === '' &&
    environment.subCustomer === '';

  constructor(
    protected route: ActivatedRoute,
    protected productService: HlProductService,
    protected availabilityService: AvailabilityService,
    protected authService: AuthService,
    protected priceService: PriceService,
    protected metadataService: MetadataService,
    protected customerService: SelectedCustomerService,
    translateService: TranslateService,
    assortmentService: AssortmentService,
    protected gungFlowService: GungFlowService
  ) {
    super(
      route,
      productService,
      availabilityService,
      authService,
      priceService,
      metadataService,
      translateService,
      assortmentService,
      gungFlowService
    );
  }

  @ViewChild(NgbCarousel)
  private myCarousel: NgbCarousel;
  @ViewChild('scrollContainer')
  scrollingImages: ElementRef;

  public imageIdString = 'productImage';
  private customerId: string;
  isPortalNo = environment.mainCountry === 'no';
  isSales = environment.sales;

  currentLang = this.translateService.currentLang;

  ngOnInit(): void {
    this.route.params
      .pipe(
        map(params => {
          return params.productId as string;
        }),
        mergeMap(productId =>
          forkJoin([
            of(productId),
            this.authService.getCurrentUser().pipe(
              debounce(() => timer(100)),
              first()
            )
          ])
        ),
        mergeMap(([productId, currentUser]) =>
          forkJoin([
            this.productService.getProduct(productId),
            this.availabilityService.getAvailability(productId, currentUser.managedMultistockIds[0]),
            this.priceService.getCurrentCustomerPrice(productId).pipe(first())
          ])
        )
      )
      .subscribe(([product, availability, price]) => {
        this.product = product;
        this.availability = availability;
        if (availability.extra._componentsAvailable) {
          const componentIds = Object.keys(availability.extra._componentsAvailable);
          componentIds.forEach(id => {
            this.componentAmounts[id] = availability.extra._componentsRequired[id];
          });

          this.productService
            .getComponentsByIds(componentIds)
            .pipe(first())
            .subscribe(products => (this.components = products));
        } else {
          this.components = [];
        }
        this.price = price;
      });
    this.customerService.getSelectedCustomer().subscribe(cust => (this.customerId = cust.id));
  }

  select(index: number): void {
    this.myCarousel.select(this.imageIdString + index);
  }

  scrollImagesRight(): void {
    const scroller = this.scrollingImages.nativeElement as HTMLElement;
    const scrollWidth = Math.min(
      scroller.scrollLeft + scroller.clientWidth,
      scroller.scrollWidth - scroller.clientWidth
    );
    scroller.scroll({ left: scrollWidth, top: 0, behavior: 'smooth' });
  }

  scrollImagesLeft(): void {
    const scroller = this.scrollingImages.nativeElement as HTMLElement;
    const scrollWidth = Math.max(scroller.scrollLeft - scroller.clientWidth, 0);
    scroller.scroll({ left: scrollWidth, top: 0, behavior: 'smooth' });
  }

  getColorTranslated(name: string) {
    const metadataTableList = this.metadataService
      .getMetadataTableList('ColourName')
      .filter(element => {
        if (Array.isArray(name)) {
          return name.includes(element.Id);
        }
        return element.Id === name;
      });
    if (metadataTableList?.length > 0) {
      return (metadataTableList as any).map(colorTranslated => colorTranslated.Values[this.currentLang]).join(', ');
    }
    return '';
  }

  getName(): string {
    if (!environment.sales) {
      return this.product.name;
    }

    const id = this.product.id;

    // const itemGroup = this.metadataService.getMetadataValue('vg', 'varugruppbeskr', this.product.extra.ar.varugruppkod);
    const itemProductSystem = this.product.extra.hlPimProduct?.ProductSystem;
    let itemDesc = this.product.extra.ar.artbeskr;
    if (langExists(environment.mainCountry)(this.product)) {
      itemDesc = this.product.extra.i18n[environment.mainCountry].artbeskr || itemDesc;
    }
    return `${id}${itemProductSystem ? ' - ' + itemProductSystem : ''} - ${itemDesc}`;
  }

  getKuarDesc(): string {
    if (!this.product.extra.kuar) {
      return '';
    }
    const customerId = this.customerId?.includes('@') ? this.customerId.slice(0, this.customerId.indexOf('@')) : this.customerId;
    const kuar = this.product.extra.kuar.find(row => row.data.ftgnr === customerId);
    if (!kuar) {
      return '';
    }
    return `${kuar.data.artbeskr2 || ''} (${kuar.data.artnrkund})`;
  }

  getDescription(): string {
    if (environment.bolag === '1710') {
      const extraDesc = this.getKuarDesc();
      return this.product.description + extraDesc;
    }
    if (!environment.sales) {
      return this.product.description;
    }

    let desc1 = '';
    if (langExists(environment.mainCountry)(this.product)) {
      desc1 = this.product.extra.i18n[environment.mainCountry].artbeskrspec || desc1;
    }
    const desc2 = this.product.extra.ar.artbeskrspec;
    return `${desc1} (${desc2})`;
  }

  getMinimumOrderQuantity(extra: any): number {
    if (!this.isSales) {
      return extra.stepAmount;
    }

    return extra.minimumOrderQuantity;
  }
}
