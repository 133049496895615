import { Injectable } from '@angular/core';
import { NgbDateStruct, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { format, parse } from 'date-fns';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HlNgbDateParserFormatterService extends NgbDateParserFormatter {
  parse(value: string): NgbDateStruct {
    if (value) {
      const dateParts = value.trim().split('-');
      if (dateParts.length === 1 && this.isNumber(dateParts[0])) {
        return { year: this.toInteger(dateParts[0]), month: null, day: null };
      } else if (dateParts.length === 2 && this.isNumber(dateParts[0]) && this.isNumber(dateParts[1])) {
        return { year: this.toInteger(dateParts[0]), month: this.toInteger(dateParts[1]), day: null };
      } else if (
        dateParts.length === 3 &&
        this.isNumber(dateParts[0]) &&
        this.isNumber(dateParts[1]) &&
        this.isNumber(dateParts[2])
      ) {
        return {
          year: this.toInteger(dateParts[0]),
          month: this.toInteger(dateParts[1]),
          day: this.toInteger(dateParts[2])
        };
      }
    }
    return null;
  }
  /*
    constructor(private isoDateFormatter: NgbDateISOParserFormatter) {
      super();
    }
   */
  format(date: NgbDateStruct): string {
    if (!date) {
      return '';
    }
    const year = date.year;
    const month = date.month;
    const day = date.day;

    if (!year || !month || !day) {
      return 'N/A';
    }

    const parsedDate = parse(`${year}-${month}-${day}`, 'yyyy-MM-dd', new Date());
    if (environment.bolag === '1210') {
      return format(parsedDate, 'yyyy-MM-dd');
    }
    if (environment.bolag === '1710') {
      return format(parsedDate, 'dd-MM-yyyy');
    }
    return format(parsedDate, 'dd-MM-yy');
  }

  toInteger(value: any): number {
    return parseInt(`${value}`, 10);
  }

  isNumber(value: any): value is number {
    return !isNaN(this.toInteger(value));
  }
}
