<footer class="footer gung-footer">
  <div class="row">
    <div class="col-12 col-lg-10 offset-lg-1 footer-content-container">
      <div class="row">
        <div class="col-sm-4 d-none d-sm-block footer-left-column">
          <ng-container
            [ngTemplateOutlet]="iterateColumnItems"
            [ngTemplateOutletContext]="{ content: leftContent }"
          ></ng-container>
        </div>
        <div class="col-sm-4 d-none d-sm-block footer-center-column">
          <ng-container
            [ngTemplateOutlet]="iterateColumnItems"
            [ngTemplateOutletContext]="{ content: centerContent }"
          ></ng-container>
        </div>
        <div class="col-sm-4 d-none d-sm-block footer-right-column">
          <ng-container
            [ngTemplateOutlet]="iterateColumnItems"
            [ngTemplateOutletContext]="{ content: rightContent }"
          ></ng-container>
        </div>
      </div>

      <!--iterates through all items which is sent in-->
      <ng-template #iterateColumnItems let-content="content">
        <div *ngFor="let item of content">
          <div *ngIf="!!item.text">
            <p>{{ item.text }}</p>
          </div>
          <div *ngIf="!!item.label">
            <a href="{{ item.linkUrl }}">{{ item.label }}</a>
          </div>
          <div *ngIf="!!item.imageUrl"><img src="{{ item.imageUrl }}" /></div>
        </div>
      </ng-template>
    </div>
  </div>
</footer>
