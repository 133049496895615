<div *ngIf="!!customer">
  <lib-gung-tabset justify="fill">
    <lib-gung-tab *ngIf="isSalesPortal">
      <ng-template libGungTabTitle><span translate>CONTACT</span></ng-template>
      <ng-template libGungTabContent>
        <div class="mt-4 white-box">
          <h5 translate>DELIVERY_INFORMATION</h5>
          <div class="row mb-2">
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label class="control-label" for="name" translate>CUSTOMER_NAME</label>
                <input class="form-control" name="name" type="text" [(ngModel)]="customer.name" disabled />
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label class="control-label" for="emailtt" translate>TRACKING_EMAIL</label>
                <input
                  class="form-control"
                  name="emailtt"
                  type="text"
                  [ngModel]="customer.extra.kus.q_hl_emailtt"
                  (ngModelChange)="(false)"
                  (blur)="updateTrackingEmail(customer, $event.target.value)"
                />
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label class="control-label" for="address" translate>FR_FTGPOSTADR1</label>
                <input
                  class="form-control"
                  name="address"
                  type="text"
                  [(ngModel)]="customer.extra.fr.ftgpostadr1"
                  disabled
                />
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label class="control-label" for="email" translate>INVOICE_EMAIL</label>
                <input
                  class="form-control"
                  name="email"
                  type="text"
                  [(ngModel)]="customer.extra.kus.q_hl_default_email"
                  disabled
                />
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label class="control-label" for="city" translate>FR_FTGPOSTADR2</label>
                <input
                  class="form-control"
                  name="city"
                  type="text"
                  [(ngModel)]="customer.extra.fr.ftgpostadr2"
                  disabled
                />
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label class="control-label" for="contact" translate>CONTACT_PERSON_NAME</label>
                <input class="form-control" name="contact" type="text" disabled />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6" *ngIf="!isSales">
              <div class="form-group">
                <label class="control-label" for="postalCode" translate>FR_FTGPOSTNR</label>
                <input
                  class="form-control"
                  name="postalCode"
                  type="text"
                  [(ngModel)]="customer.extra.fr.ftgpostnr"
                  disabled
                />
              </div>
            </div>
            <div class="col-12 col-md-6" *ngIf="isSales">
              <div class="row">
                <div class="col-5 col-md-3">
                  <div class="form-group">
                    <label class="control-label" for="postalCode" translate>FR_FTGPOSTNR</label>
                    <input
                      class="form-control"
                      name="postalCode"
                      type="text"
                      [(ngModel)]="customer.extra.fr.ftgpostnr"
                      disabled
                    />
                  </div>
                </div>
                <div class="col-7 col-md-9">
                  <div class="form-group">
                    <label class="control-label" for="city" translate>FR_FTGPOSTADR3</label>
                    <input
                      class="form-control"
                      name="city"
                      type="text"
                      [(ngModel)]="customer.extra.fr.ftgpostadr3"
                      disabled
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label class="control-label" for="phone" translate>PHONE</label>
                <input class="form-control" name="phone" type="text" disabled />
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label class="control-label" for="city" translate>FR_LANDSKOD</label>
                <input class="form-control" name="city" type="text" [(ngModel)]="customer.extra.fr.landskod" disabled />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div
                *ngIf="alertMessageTrackingEmail"
                class="alert mt-2 text-center"
                role="alert"
                [ngClass]="{
                  'alert-success': alertMessageTrackingEmail.type === 'success',
                  'alert-danger': alertMessageTrackingEmail.type === 'danger'
                }"
                (click)="alertMessageTrackingEmail = null"
              >
                <span>{{ alertMessageTrackingEmail.message | translate }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="mt-4 white-box">
          <h5 translate>BILLING_INFORMATION</h5>
          <div class="row mb-2">
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label class="control-label" for="city" translate>NAME</label>
                <input
                  class="form-control"
                  name="city"
                  type="text"
                  [ngModel]="customer.extra._billing?.ftgnamn"
                  disabled
                />
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label class="control-label" for="address" translate>FR_FTGPOSTADR1</label>
                <input
                  class="form-control"
                  name="address"
                  type="text"
                  [ngModel]="customer.extra._billing?.ftgpostadr1"
                  disabled
                />
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label class="control-label" for="city" translate>FR_FTGPOSTADR2</label>
                <input
                  class="form-control"
                  name="city"
                  type="text"
                  [ngModel]="customer.extra._billing?.ftgpostadr2"
                  disabled
                />
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label class="control-label" for="city" translate>FR_FTGPOSTNR</label>
                <input
                  class="form-control"
                  name="city"
                  type="text"
                  [ngModel]="customer.extra._billing?.ftgpostnr"
                  disabled
                />
              </div>
            </div>
            <div class="col-12 col-md-6" *ngIf="isSales">
              <div class="form-group">
                <label class="control-label" for="city" translate>FR_FTGPOSTADR3</label>
                <input
                  class="form-control"
                  name="city"
                  type="text"
                  [ngModel]="customer.extra._billing?.ftgpostadr3"
                  disabled
                />
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label class="control-label" for="city" translate>FR_LANDSKOD</label>
                <input
                  class="form-control"
                  name="city"
                  type="text"
                  [ngModel]="customer.extra._billing?.landskod"
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </lib-gung-tab>

    <lib-gung-tab *ngIf="!isSalesPortal">
      <ng-template libGungTabTitle><span translate>DELIVERY_ADDRESS</span></ng-template>
      <ng-template libGungTabContent>
        <div class="mt-4 white-box">
          <div class="row mb-2">
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label class="control-label" for="name" translate>CUSTOMER_NAME</label>
                <input class="form-control" name="name" type="text" [(ngModel)]="customer.name" disabled />
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label class="control-label" for="email" translate>EMAIL</label>
                <input
                  class="form-control"
                  name="email"
                  type="text"
                  [(ngModel)]="customer.extra.kus.q_hl_default_email"
                  disabled
                />
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label class="control-label" for="address" translate>FR_FTGPOSTADR1</label>
                <input
                  class="form-control"
                  name="address"
                  type="text"
                  [ngModel]="customer.extra.fr?.ftgpostadr1"
                  disabled
                />
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label class="control-label" for="city" translate>FR_FTGPOSTADR2</label>
                <input
                  class="form-control"
                  name="city"
                  type="text"
                  [ngModel]="customer.extra.fr?.ftgpostadr2"
                  disabled
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label class="control-label" for="contact" translate>CONTACT_PERSON_NAME</label>
                <input class="form-control" name="contact" type="text" disabled />
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label class="control-label" for="phone" translate>PHONE</label>
                <input class="form-control" name="phone" type="text" disabled />
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label class="control-label" for="postalCode" translate>FR_FTGPOSTNR</label>
                <input
                  class="form-control"
                  name="postalCode"
                  type="text"
                  [(ngModel)]="customer.extra.fr.ftgpostnr"
                  disabled
                />
              </div>
            </div>
            <div class="col-12 col-md-6" *ngIf="isSales">
              <div class="form-group">
                <label class="control-label" for="city" translate>FR_FTGPOSTADR3</label>
                <input
                  class="form-control"
                  name="city"
                  type="text"
                  [(ngModel)]="customer.extra.fr.ftgpostadr3"
                  disabled
                />
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label class="control-label" for="city" translate>FR_LANDSKOD</label>
                <input class="form-control" name="city" type="text" [(ngModel)]="customer.extra.fr.landskod" disabled />
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </lib-gung-tab>

    <lib-gung-tab *ngIf="!isSalesPortal">
      <ng-template libGungTabTitle><span translate>BILLING_INFORMATION</span></ng-template>
      <ng-template libGungTabContent>
        <div class="mt-4 white-box">
          <div class="row mb-2">
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label class="control-label" for="city" translate>NAME</label>
                <input
                  class="form-control"
                  name="city"
                  type="text"
                  [(ngModel)]="isManagerCh ? customer.extra?._billingCustomer?.extra?.fr?.ftgnamn || customer.extra?.fr?.ftgnamn : customer.extra._billing.ftgnamn"
                  disabled
                />
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label class="control-label" for="address" translate>FR_FTGPOSTADR1</label>
                <input
                  class="form-control"
                  name="address"
                  type="text"
                  [(ngModel)]="isManagerCh ? customer.extra?._billingCustomer?.extra?.fr?.ftgpostadr1 || customer.extra?.fr?.ftgpostadr1 : customer.extra._billing.ftgpostadr1"
                  disabled
                />
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label class="control-label" for="city" translate>FR_FTGPOSTADR2</label>
                <input
                  class="form-control"
                  name="city"
                  type="text"
                  [(ngModel)]="isManagerCh ? customer.extra?._billingCustomer?.extra?.fr?.ftgpostadr2 || customer.extra?.fr?.ftgpostadr2 : customer.extra._billing.ftgpostadr2"
                  disabled
                />
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label class="control-label" for="city" translate>FR_FTGPOSTNR</label>
                <input
                  class="form-control"
                  name="city"
                  type="text"
                  [(ngModel)]= "isManagerCh ? customer.extra?._billingCustomer?.extra?.fr?.ftgpostnr || customer.extra?.fr.ftgpostnr : customer.extra._billing.ftgpostnr"
                  disabled
                />
              </div>
            </div>
            <div class="col-12 col-md-6" *ngIf="isSales">
              <div class="form-group">
                <label class="control-label" for="city" translate>FR_FTGPOSTADR3</label>
                <input
                  class="form-control"
                  name="city"
                  type="text"
                  [(ngModel)]="isManagerCh ? customer.extra?._billingCustomer?.extra?.fr?.ftgpostadr3 || customer.extra?.fr.ftgpostadr3 : customer.extra._billing.ftgpostadr3"
                  disabled
                />
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label class="control-label" for="city" translate>FR_LANDSKOD</label>
                <input
                  class="form-control"
                  name="city"
                  type="text"
                  [(ngModel)]="isManagerCh ? customer.extra?._billingCustomer?.extra?.fr?.landskod || customer.extra?.fr.landskod : customer.extra._billing.landskod"
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </lib-gung-tab>

    <lib-gung-tab *ngIf="isSales">
      <ng-template libGungTabTitle><span translate>ORDERS</span></ng-template>
      <ng-template libGungTabContent>
        <app-open-orders></app-open-orders>
      </ng-template>
    </lib-gung-tab>

    <lib-gung-tab *ngIf="isSales">
      <ng-template libGungTabTitle><span translate>ORDER_FINDER</span></ng-template>
      <ng-template libGungTabContent>
        <app-hl-display-order-finder></app-hl-display-order-finder>
      </ng-template>
    </lib-gung-tab>

    <!--
    <lib-gung-tab *ngIf="isSales">
      <ng-template libGungTabTitle><span translate>INVOICES</span></ng-template>
      <ng-template libGungTabContent>
        <app-invoices></app-invoices>
      </ng-template>
    </lib-gung-tab>
    -->

    <lib-gung-tab *ngIf="isSalesPortal">
      <ng-template libGungTabTitle><span translate>MEETINGS</span></ng-template>
      <ng-template libGungTabContent>
        <app-meetings></app-meetings>
      </ng-template>
    </lib-gung-tab>

    <lib-gung-tab *ngIf="isSalesPortal">
      <ng-template libGungTabTitle><span translate>PRODUCTS</span></ng-template>
      <ng-template libGungTabContent>
        <app-customer-ordered-products-last-x-months></app-customer-ordered-products-last-x-months>
      </ng-template>
    </lib-gung-tab>
  </lib-gung-tabset>
</div>

<!-- <ng-container *ngIf="isSales | async then sales; else user;"></ng-container>

<ng-template #sales>
    <lib-gung-tabset justify="fill">
        <lib-gung-tab>
          <ng-template libGungTabTitle>
            <span translate>ORDERS</span>
          </ng-template>
          <ng-template libGungTabContent>
            <div class="container mt-4">
                <lib-order-list></lib-order-list>
            </div>
          </ng-template>
        </lib-gung-tab>
        <lib-gung-tab>
            <ng-template libGungTabTitle>
              <span translate>INVOICES</span>
            </ng-template>
            <ng-template libGungTabContent>
              <div class=" container mt-4">
                  <lib-invoice-list></lib-invoice-list>
              </div>
            </ng-template>
          </lib-gung-tab>
      </lib-gung-tabset>
</ng-template>


<ng-template #user>
    <h2 class="mt-4 mb-4" translate>MY_PAGES</h2>
    <lib-gung-tabset justify="fill">
        <lib-gung-tab>
          <ng-template libGungTabTitle>
            <span translate>DELIVERY_ADDRESS</span>
          </ng-template>
          <ng-template libGungTabContent>
            <div class="container mt-4">
            </div>
          </ng-template>
        </lib-gung-tab>
        <lib-gung-tab>
            <ng-template libGungTabTitle>
              <span translate>BILLING_INFORMATION</span>
            </ng-template>
            <ng-template libGungTabContent>
              <div class=" container mt-4">

              </div>
            </ng-template>
          </lib-gung-tab>
      </lib-gung-tabset>
</ng-template> -->

<!-- <h2 class="mt-4 mb-4" translate>MY_PAGES</h2>
<lib-gung-tabset justify="fill">
  <lib-gung-tab [title]="'DELIVERY_ADDRESS' | translate">
    <ng-template libGungTabContent>
      <div class="row pt-2">
        <div class="col-12 col-md-6">
          <div class="form-group">
            <label class="control-label" for="name" translate>CUSTOMER_NAME</label>
            <input class="form-control" name="name" type="text" [(ngModel)]="customer.name" disabled />


          <div class="form-group">
            <label class="control-label" for="email" translate>EMAIL</label>
            <input class="form-control" name="email" type="text" [(ngModel)]="customer.extra.kus.q_hl_default_email"
              disabled />
          </div>

          <div class="form-group">
            <label class="control-label" for="address" translate>FR_FTGPOSTADR1</label>
            <input class="form-control" name="address" type="text" [(ngModel)]="customer.extra.fr.ftgpostadr1"
              disabled />
          </div>

          <div class="form-group">
            <label class="control-label" for="city" translate>FR_FTGPOSTADR2</label>
            <input class="form-control" name="city" type="text" [(ngModel)]="customer.extra.fr.ftgpostadr2" disabled />
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-group">
            <label class="control-label" for="contact" translate>CONTACT_PERSON_NAME</label>
            <input class="form-control" name="contact" type="text" disabled />
          </div>

          <div class="form-group">
            <label class="control-label" for="phone" translate>PHONE</label>
            <input class="form-control" name="phone" type="text" disabled />
          </div>

          <div class="form-group">
            <label class="control-label" for="postalCode" translate>FR_FTGPOSTNR</label>
            <input class="form-control" name="postalCode" type="text" [(ngModel)]="customer.extra.fr.ftgpostnr"
              disabled />
          </div>

          <div class="form-group">
            <label class="control-label" for="city" translate>FR_LANDSKOD</label>
            <input class="form-control" name="city" type="text" [(ngModel)]="customer.extra.fr.landskod" disabled />
          </div>
        </div>
      </div>
    </ng-template>
  </lib-gung-tab>
  <lib-gung-tab [title]="'BILLING_INFORMATION' | translate">
    <ng-template libGungTabContent>
      <div class="row pt-2">
        <div class="col">
          <div class="form-group">
            <label class="control-label" for="city" translate>NAME</label>
            <input class="form-control" name="city" type="text" [(ngModel)]="customer.extra._billing.ftgnamn"
              disabled />
          </div>
          <div class="form-group">
            <label class="control-label" for="address" translate>FR_FTGPOSTADR1</label>
            <input class="form-control" name="address" type="text" [(ngModel)]="customer.extra._billing.ftgpostadr1"
              disabled />
          </div>

          <div class="form-group">
            <label class="control-label" for="city" translate>FR_FTGPOSTADR2</label>
            <input class="form-control" name="city" type="text" [(ngModel)]="customer.extra._billing.ftgpostadr2"
              disabled />
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <label class="control-label" for="city" translate>FR_FTGPOSTNR</label>
            <input class="form-control" name="city" type="text" [(ngModel)]="customer.extra._billing.ftgpostnr"
              disabled />
          </div>
          <div class="form-group">
            <label class="control-label" for="city" translate>FR_LANDSKOD</label>
            <input class="form-control" name="city" type="text" [(ngModel)]="customer.extra._billing.landskod"
              disabled />
          </div>
        </div>
      </div>
    </ng-template>
  </lib-gung-tab>
</lib-gung-tabset> -->
<!-- <h5 class="mb-2" translate>INFORMATION</h5> -->
