<div>
    <div class="modal-header">
      <h3 translate>UPDATE_IMAGES</h3>
      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  
    <div class="modal-body">
      <div class="row">
        <div class="col-12">
            <table class="table table-striped">
                <thead class="thead-dark">
                    <tr>
                        <th scope="col" id="idImage" translate>IMAGE</th>
                        <th scope="col" id="idPosition" translate>POSITION_IN_EXPORT</th>
                        <th scope="col" id="idDelete" translate>DELETE</th>
                        <th scope="col" class="text-center" id="idMove" translate>MOVE</th>
                    </tr>
                </thead>
                <tbody cdkDropList (cdkDropListDropped)="drop($event)">
                    <tr *ngFor="let image of images; index as i" cdkDrag>
                        <td colspan="7" class="p-0" *cdkDragPlaceholder>
                            <div class="row-ist-placeholder"></div>
                        </td>
                        <td headers="idImage">
                            <img
                            [src]="image.s3Uri | gungImageUrl
                              :'type:thumbnail'
                              :'etag:'+image.s3ETag
                            "
                            />
                        </td>
                        <td headers="idPosition" scope="row">{{ i + 1 }}</td>
                        <td headers="idDelete">
                            <button type="button" class="btn btn-danger" (click)="delete(i)" translate>DELETE</button>
                        </td>
                        <td
                            headers="idMove"
                            class="text-center cursor-move"
                            width="10%"
                            cdkDragHandle
                            [cdkDragHandleDisabled]="loader"
                            [class.cdk-drag-handle-disabled]="loader"
                        >
                            <i class="fas fa-arrows-alt"></i>
                        </td>
                    </tr>
                </tbody>

            </table>
        </div>
      </div>
    </div>
  
    <div>
      <button type="button" class="btn btn-sm btn-default btn-block btn-primary" (click)="save()">
        {{ 'OK' | translate | uppercase }}
      </button>
    </div>
  </div>
  