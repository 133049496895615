<!-- <ngb-carousel *ngIf="sliderContent?.length > 0" 
  [showNavigationArrows]="false"
	[showNavigationIndicators]="false"
  class="h-100">
  <ng-template ngbSlide *ngFor="let slide of sliderContent">
    <div class="slide-wrapper" [class]="slide.customClass">
      <div class="img-wrapper">
        <img [src]="slide.background" class="absolute" />
      </div>
      <div class="carousel-caption text-left">
        <h3 *ngIf="slide.title" class="header-text" translate>{{ slide.title }}</h3>
        <p *ngIf="slide.description" class="sub-headline-text mt-4" translate>{{ slide.description }}</p>
        <div *ngIf="slide.innerHTML" class="mt-4" [innerHTML]="slide.innerHTML | translate | safeHtml"></div>
        <a [routerLink]="slide.link" [queryParams]="slide.queryParams">
          <button type="button" class="btn btn-outline-light mt-5 p-3 px-4" translate>{{ slide.button }}</button>
        </a>
      </div>
    </div>
  </ng-template>
</ngb-carousel> -->
<owl-carousel-o [options]="customOptions">
  <ng-container *ngFor="let slide of sliderContent; let idx = index">
    <ng-template class="slide" carouselSlide>
      <div class="slide-wrapper" [class]="slide.customClass">
        <div class="img-wrapper d-flex align-items-end" style="background: url('{{slide.background}}') center center / cover no-repeat;">
          <div class="container pb-5">
            <div class="carousel-caption text-left">
              <h3 *ngIf="slide.title" class="header-text" translate>{{ slide.title }}</h3>
              <p *ngIf="slide.description" class="sub-headline-text mt-4" translate>{{ slide.description }}</p>
              <div *ngIf="slide.innerHTML" class="mt-4" [innerHTML]="slide.innerHTML | translate | safeHtml"></div>
              <a *ngIf="slide.link && slide.button" [routerLink]="slide.link" [queryParams]="slide.queryParams">
                <button type="button" class="btn btn-outline-light mt-5 p-3 px-4" translate>{{ slide.button }}</button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </ng-container>
</owl-carousel-o>