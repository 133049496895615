<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title" translate>{{ error.i18nKeyTitle || 'ERROR' }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="delegate.modalRef.get(this.componentId).close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div class="alert alert-danger" role="alert">
    <span translate>{{ error.i18nKeyMessage }}</span>
  </div>
</div>

<div class="modal-footer">
  <div class="btn-group btn-group-justified btn-block">
    <button
      class="btn btn-sm btn-primary btn-default"
      (click)="delegate.modalRef.get(this.componentId).close()"
      translate
    >
      OK
    </button>
  </div>
</div>
