import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, of, Observable, Subject, catchError } from 'rxjs';
import { first, map, switchMap, takeUntil } from 'rxjs';
import { Product } from '../../models';
import { Availability } from '../../models/availability';
import { CustomerProductPrice } from '../../models/price';
import { AssortmentRecursiveExtended, AssortmentService } from '../../services/assortment.service';
import { AuthService } from '../../services/auth/auth.service';
import { AvailabilityService } from '../../services/availability/availability.service';
import { CloudPimPropertiesHelperService } from '../../services/cloud-pim-properties-helper/cloud-pim-properties-helper.service';
import { GungFlowService } from '../../services/gung-flow/gung-flow.service';
import { MetadataService } from '../../services/metadata/metadata.service';
import { PriceService } from '../../services/price/price.service';
import { ProductService } from '../../services/products/product.service';
import { User } from '../../state/auth/types';
import { GungFlow } from '../../state/flow/types';

@Component({
  selector: 'lib-product-detail-v2',
  templateUrl: './product-detail-v2.component.html',
  styleUrls: ['./product-detail-v2.component.scss']
})
export class ProductDetailV2Component implements OnInit, OnDestroy {
  @Input()
  productId?: string;

  currentLang = this.translateService.currentLang;
  activePanels: object = {};
  public noCache = false;
  public isAnonymous = true;
  public isSales = false;
  public product: Product;
  public availability: Availability;
  public price: CustomerProductPrice;
  public user: User;
  public flow: GungFlow;
  public components: Product[];
  public hasPimPropertiesToShow = false;
  selectedImageIndex = 0;
  visibleDocuments = [];

  currentAssortment?: AssortmentRecursiveExtended;

  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected route: ActivatedRoute,
    protected productService: ProductService,
    protected availabilityService: AvailabilityService,
    protected authService: AuthService,
    protected priceService: PriceService,
    protected metadataService: MetadataService,
    protected translateService: TranslateService,
    protected assortmentService: AssortmentService,
    protected cloudPimPropertyHelper: CloudPimPropertiesHelperService,
    protected gungFlowService: GungFlowService
  ) {}

  ngOnInit(): void {
    const baseObservable = this.route.params.pipe(
      map(params => {
        return this.productId || (params.productId as string);
      }),
      switchMap(productId =>
        forkJoin({
          productId: of(productId),
          multistock: this.authService.getCurrentUser().pipe(
            first(),
            map(user => user.managedMultistockIds[0])
          )
        })
      ),
      switchMap(({ productId, multistock }) =>
        forkJoin({
          product: this.productService.getProduct(productId),
          availability: this.availabilityService.getAvailability(productId, multistock, this.noCache).pipe(catchError((err, caught) => {return of(null)})),
          price: this.priceService.getCurrentCustomerPrice(productId).pipe(first()),
          user: this.authService.getCurrentUser().pipe(first()),
          flow: this.gungFlowService.getSelectedFlow().pipe(first())
        })
      )
    );
    this.handleObservable(baseObservable);
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  protected handleObservable(observable: Observable<BaseDetailData>): void {
    observable.pipe(takeUntil(this.unsubscribe)).subscribe(({ product, availability, price, user, flow }) => {
      this.mapData(product, availability, price, user, flow);
      this.filterDocuments(user);
      this.checkForPimProperties(product);
    });
  }

  protected mapData(product, availability, price, user, flow) {
    this.product = product;
    this.availability = availability;
    this.price = price;
    this.isAnonymous = user.roles.filter(role => role.toUpperCase() === 'ANONYMOUS').length > 0;
    this.isSales = user.roles.filter(role => role.toUpperCase() === 'ADMIN' || role.toUpperCase() === 'SALES').length > 0;
    this.flow = flow;
  }

  /**
   * Filter documents to show - no default filter for now. Need to add property to cloud pim to filter on
   */
  protected filterDocuments(user: User): void {
    this.visibleDocuments = this.product.extra.documents;
  }

  handleAccordionToggle(event): void {
    this.activePanels[event.panelId] = event.nextState;
  }

  public checkForPimProperties(product: Product) {
    this.hasPimPropertiesToShow = this.cloudPimPropertyHelper.hasAnyPropertiesToShow(product);
  }
}

interface BaseDetailData {
  product: Product;
  price: CustomerProductPrice;
  availability: Availability;
  user: User;
  flow: GungFlow;
}
