import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageSelectionComponent } from '../language-selection/language-selection.component';
import { GungLanguageConfigService } from '../../services/gung-language-config/gung-language-config.service';

@Component({
  selector: 'lib-language-selection-dropdown',
  templateUrl: './language-selection-dropdown.component.html',
  styleUrls: ['./language-selection-dropdown.component.css']
})
export class LanguageSelectionDropdownComponent extends LanguageSelectionComponent implements OnInit {
  currentLang;

  public forceShowLanguage = this.languageConfigService.getForceShowLanguages();

  @Input()
  showOnlyFlags = false;

  constructor(protected translateService: TranslateService, public languageConfigService: GungLanguageConfigService) {
    super(translateService, languageConfigService);
  }

  ngOnInit() {
    this.currentLang = this.languageConfigService.getLanguages().filter(l => l.short === this.translateService.currentLang).shift();
    this.translateService.onLangChange.subscribe(
      lang => {
        this.currentLang = this.languageConfigService.getLanguages().filter(l => l.short === lang.lang).shift();
      }
    )
  }
}
