
<div class="row justify-content-center">
  <div [ngClass]="!!authenticated && !!customerSalesPerson ? 'col col-md-10' : 'col-6'" *ngIf="!isCoopPortal">
    <div class="row mb-3">
      <div class="col">
        <h4 class="mt-4">
          <span translate>CONTACT_US</span>
        </h4>
        <p *ngIf="currentLang === 'fr'">
          Vous avez des questions sur un produit, sur votre commande, pour une autorisation de retour ou toute autre
          demande relative à votre commande, notre service est à votre écoute
        </p>
        <p *ngIf="currentLang === 'nl' && !isCustNlSuperunie1600">
          Heeft u vragen over een product, uw bestelling, een retourautorisatie of een ander verzoek met betrekking tot uw bestelling, onze service staat tot uw beschikking.
        </p>
      </div>
    </div>

    <div class="row mb-4">
      <div class="col service-client">
        <h3 class="mb-3">
          <span translate>SERVICE_CLIENT</span>
        </h3>
        <div class="row">
          <div class="col" *ngIf="mainCountry === 'fr' || mainCountry === 'be'">
            <div *ngIf="isIntermacheOrCarrefourBE;else displayDefault">
              <div>
                <h4 translate>HL_CONTACT_NAME</h4>
                <p class="mb-4">
                  <span translate>C_PHONE</span>: +32 3 457 88 77

                  <br />
                  Email: <a href="mailto:OrdersBenelux@HL-display.com">OrdersBenelux@HL-display.com</a>
                </p>

                <h4 translate>ADDRESS</h4>
                <p>
                  Emmastraat 2C,
                  <br />
                  4811 AG Breda,
                  <br />
                  <span translate>NETHERLANDS</span>
                </p>
                <h4 translate>POST_ADRESS</h4>
                <p>
                  De Regenboog 11 bus 19
                  <br />
                  2800 Mechelen
                  <br />
                  <span translate>BELGIUM</span>
                </p>
   
              </div>
            </div>
            <ng-template #displayDefault>
              
                <h4>HL Display France S.A.S.</h4>
                <p class="mb-4">
                  <span translate>PHONE</span>: +33 2 47 48 85 00
                  <br />
                  Fax: 02.47.48.11.88
                  <br />
                  E-mail: {{ getFrServiceClientEmail() }}
                </p>
    
                <span *ngIf="!isFRCustomerPortalPharma">
                  <h4 translate>ADDRESS</h4>
                  <p>
                    20, rue de la Milletière
                    <br />
                    37000 Tours
                    <br />
                    France
                  </p>
                </span>
                <span *ngIf="isFRCustomerPortalPharma">
                  <h4 translate>HL DISPLAY</h4>
                  <p>
                    20, rue de la Milletière
                    <br />
                    37100 Tours
                    <br />
                    France
                  </p>
                </span>
              
            </ng-template>            
          </div>

          <div class="col contact-info" *ngIf="isCustomerPortal1210Kesko">
            <h4>HL Display Suomi OY</h4>
            <p class="mb-4">
              <span translate>Puhelinnumero</span>: +358 (0)9 562 9180
              <br />
              Sähköposti: info.fi@hl-display.com
            </p>
            <h4>Käyntiosoite</h4>
            <p class="mb-4">
              Metsänneidonkuja 6
              <br />
              Duo-talo
              <br />
              02130 Espoo
              <br />
              Suomi
            </p>
          </div>

          <div class="col" *ngIf="mainCountry === 'en'">
            <h4>HL Display (UK) Ltd</h4>
            <p class="mb-4">
              <span *ngIf="!isCurrysPortal">Phone: 0845 070 4211</span>
              <span *ngIf="isCurrysPortal">Phone: 01652 682140</span>
              <br />
              E-mail: <a href="maito:{{ getFrServiceClientEmail() }}">{{ getFrServiceClientEmail() }}</a>
            </p>
            <h4 translate>ADDRESS</h4>
            <p>
              1-2 Horsecroft Road
              <br />
              The Pinnacles
              <br />
              Harlow
              <br />
              Essex, CM19 5BH
            </p>
            <h4>Opening hours</h4>
            <p>
              Monday to Friday
              <br />
              8.30am to 5.00pm
            </p>
          </div>

          <div class="col" *ngIf="mainCountry === 'no'">
            <h4>HL Display Norge AS</h4>
            <p class="mb-4">
              Tlf 66983600
              <br />
              E-post: info.no@hl-display.com
            </p>
            <h4 translate>ADDRESS</h4>
            <p>
              Nye Vakåsvei 8c
              <br />
              1395 HVALSTAD
              <br />
              Norge
            </p>
            <h4>Åpningstider</h4>
            <p>
              Mandag til fredag
              <br />
              0800-1600
            </p>
          </div>

          <div class="col" *ngIf="isCustNlSuperunie1600">
              
            <h4 translate>HL Display Nederland</h4>
            <p class="mb-4">
              <span translate>PHONE</span>: 076 2 300 300
              <br />
              E-mail: <a href="maito:{{ getFrServiceClientEmail() }}">{{ getFrServiceClientEmail() }}</a>
            </p>

            <span *ngIf="!isFRCustomerPortalPharma">
              <h4 translate>ADDRESS</h4>
              <p>
                Heerbaan 14
                <br />
                4817 NL Breda
                <br />
                The Netherlands
              </p>
            </span>          
          </div> 

          <div class="col-5">
            <img class="img-fluid" src="assets/Contact.png" alt="" />
          </div>
        </div>
      </div>

      <div class="col vhr" *ngIf="authenticated && customerSalesPerson && !isIntermarchePortal && !isCustomerPortal1210Kesko && !isCarrefourBE">
        <h3 class="mb-3">
          <span translate>ACCOUNT_MANAGER</span>
        </h3>
        <div class="row">
          <div class="col">
            <span *ngIf="customerSalesPerson.name">
              <h4>
                <span translate>CONTACT_NAME</span>
              </h4>
              <p>{{ customerSalesPerson.name }}</p>
            </span>
            <span *ngIf="customerSalesPerson.phone">
              <h4>
                <span translate>PHONE</span>
              </h4>
              <p>{{ customerSalesPerson.phone }}</p>
            </span>
            <span *ngIf="customerSalesPerson.email">
              <h4>
                <span translate>EMAIL</span>
              </h4>
              <p>{{ customerSalesPerson.email }}</p>
            </span>
          </div>
          <div class="col-5">
            <img class="img-fluid" src="assets/Customer.png" alt="" />
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-5" *ngIf="currentLang === 'fr' && isSales">
      <div class="col">
        <p>
          Pour tous remodeling /ouverture/ extension de magasin , votre
          <a [routerLink]="'/customer-service'">interlocuteur régional</a> pourra prendre contact avec vous
        </p>
      </div>
    </div>
  </div>
  <div *ngIf="isCoopPortal">

      <h4 class="mt-4">
        <span translate>CONTACT_US</span>
      </h4>
      <div class="row">
        <div class="col-7">
          <h4 class="pt-5">HL Display Schweiz AG</h4>
            <p class="mb-4">
              <br />
              <br />
              <span translate>PHONE</span>: +41 62 834 50 30
              <br />
              <br />
              <span translate>EMAIL</span>:  
              <a href="mailto:info.ch@hl-display.com">info.ch@hl-display.com</a>
            </p>
          </div>

          <div class="col-5">
            <img class="img-fluid" src="assets/Contact.png" alt="" />
          </div>
      
      </div>
  </div>
</div>
