import { Component, OnInit } from '@angular/core';
import { parse } from 'date-fns';
import { ListItemRendererComponent } from 'gung-list';
import { LocationConfigService } from '../../../services/location-config/location-config.service';
import { OpenPurchaseOrdersActionConfigService } from '../../../services/open-purchase-orders-action-config/open-purchase-orders-action-config.service';
import { OpenPurchaseOrders } from '../../../services/open-purchase-orders/open-purchase-orders.service';
import { DateUtilService } from 'gung-common';

@Component({
  selector: 'lib-open-purchase-orders-card-list',
  templateUrl: './open-purchase-orders-card-list.component.html',
  styleUrls: ['./open-purchase-orders-card-list.component.css']
})
export class OpenPurchaseOrdersCardListComponent
  extends ListItemRendererComponent<OpenPurchaseOrders[]>
  implements OnInit
{
  adjustedScroll = false;

  constructor(
    protected locationConfigService: LocationConfigService,
    public dateUtilService: DateUtilService,
    public configService: OpenPurchaseOrdersActionConfigService
  ) {
    super();
  }

  ngOnInit(): void {

  }

  parseDate(value: any): Date {
    if (!!value) {
      return parse(value, 'yyMMdd', new Date());
    }
    return null;
  }

  getTotalValue(order: OpenPurchaseOrders): number {
    return parseFloat(order.extra.IGA.LVV) + parseFloat(order.extra.IGA.OLV);
  }

  getStatusTranslationTag(key: string): string {
    if (key === '0') {
      return 'NOT_DELIVERED';
    }
    if (key === '4') {
      return 'PART_DELIVERED';
    }
    if (key === '5') {
      return 'DELIVERED';
    }
    return 'UNDEFINED';
  }
}
