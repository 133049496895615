import { Component, OnInit } from '@angular/core';
import { CartSummaryComponent, CartTotalsService, TotalObject } from 'gung-standard';
import { Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-hl-display-lib-cart-summary',
  templateUrl: './hl-display-lib-cart-summary.component.html',
  styleUrls: ['./hl-display-lib-cart-summary.component.css']
})
export class HlDisplayLibCartSummaryComponent extends CartSummaryComponent {
  isPortalNo = environment.mainCountry === 'no';

  public isManagersNisa = environment.managers && environment.mainCountry === 'en';

  public isCurrysPortal =
    environment.bolag === '1710' &&
    !environment.sales &&
    !environment.managers &&
    environment.mainCountry === 'en' &&
    environment.mainCustomer === '';

  constructor(protected cartTotalsService: CartTotalsService) {
    super(cartTotalsService);
  }
}
