import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Customer } from '../../models';
import { SelectedCustomerService } from '../../services/selected-customer/selected-customer.service';
import { FooterService, FooterText, FooterImage, FooterLink } from '../../services/footer/footer.service';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs';
@Component({
  selector: 'lib-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  leftContent: (FooterText | FooterImage | FooterLink)[];
  centerContent: (FooterText | FooterImage | FooterLink)[];
  rightContent: (FooterText | FooterImage | FooterLink)[];

  protected unsubscribe: Subject<void> = new Subject();
  constructor(protected footerService: FooterService) {}

  ngOnInit() {
    this.leftContent = this.footerService.getLeftColumnContent();
    this.centerContent = this.footerService.getCenterColumnContent();
    this.rightContent = this.footerService.getRightColumnContent();
  }
}
