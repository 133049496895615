
<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title" ><span translate>SELECT_CART_FOR</span> {{cell.day}}/{{cell.month}}/{{cell.year}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div class="form-group">
      <label for="cartId" class="control-label" translate>CART</label>
      <select name="cartId" id="cartId" class="form-control" [(ngModel)]="selectedCartId">
          <option *ngFor="let cart of savedCarts" [value]="cart.id">{{cart.name}}</option>
      </select>
  </div>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-sm btn-default btn-border-primary" translate (click)="close()">
      CANCEL
  </button>
  <button type="button" class="btn btn-sm btn-default btn-primary" translate (click)="select()">
  SELECT
  </button>
</div>

