import { Injectable, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

export interface Language {
  language: string;
  short: string;
  image: string;
  enabled: boolean;
  dateFormat?: string;
}

@Injectable({
  providedIn: 'root'
})
export class GungLanguageConfigService {
  protected defaultLanguage = 'en';
  protected defaultCountryPhone = '+46';
  public reloadOnLangChange = false;

  constructor(protected translateService: TranslateService) {
    // Calling this in constructor have a bug, when we extend this the extended service call the super constructor before replacing the variables.
    // this.selectLanguage(); 
  }

  selectLanguage() {
    const langFromOldVisit = localStorage.getItem('selectedLanguage');
    const enabledLangs = this.getLanguages().filter(lang => lang.enabled);
    if (!enabledLangs.some(lang => lang.short === langFromOldVisit) && !!enabledLangs.length) {
      const defaultLanguage = this.getDefaultLanguage();
      if (enabledLangs.some(lang => lang.short === defaultLanguage)) {
        this.translateService.use(defaultLanguage);
        localStorage.setItem('selectedLanguage', defaultLanguage);
      } else {
        this.translateService.use(enabledLangs[0].short);
        localStorage.setItem('selectedLanguage', enabledLangs[0].short);
      }
    } else {
      this.translateService.use(langFromOldVisit);
    }
  }

  public getLanguages(): Language[] {
    return [
      {
        language: 'finnish',
        short: 'fi',
        image: 'finland-flag-round-icon-256.png',
        enabled: false
      },
      {
        language: 'french',
        short: 'fr',
        image: 'france-flag-round-icon-256.png',
        enabled: false
      },
      {
        language: 'german',
        short: 'de',
        image: 'germany-flag-round-icon-256.png',
        enabled: false
      },
      {
        language: 'norwegian',
        short: 'no',
        image: 'norway-flag-round-icon-256.png',
        enabled: false
      },
      {
        language: 'portuguese',
        short: 'pt',
        image: 'portugal-flag-round-icon-256.png',
        enabled: false
      },
      {
        language: 'swedish',
        short: 'se',
        image: 'sweden-flag-round-icon-256.png',
        enabled: true
      },
      {
        language: 'english',
        short: 'en',
        image: 'united-states-of-america-flag-round-icon-256.png',
        enabled: true
      }
    ];
  }

  public getDefaultLanguage() {
    return this.defaultLanguage;
  }

  public setDefaultLanguage(lang) {
    this.defaultLanguage = lang;
  }

  getForceShowLanguages(): boolean {
    return false;
  }

  public getDefaultCountryPhone() {
    return this.defaultCountryPhone;
  }
}
