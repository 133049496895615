import {
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  OnDestroy,
  OnInit,
  Type,
  ViewChild
} from '@angular/core';
import { forkJoin, switchMap } from 'rxjs';
import { first } from 'rxjs';
import { Product, ProductMultiDimension } from '../../models';
import { AuthService } from '../../services/auth/auth.service';
import { BeepService } from '../../services/beep/beep.service';
import { CartService } from '../../services/cart/cart.service';
import { EanNumbers, EanNumbersService } from '../../services/ean-numbers/ean-numbers.service';
import { GungFlowService } from '../../services/gung-flow/gung-flow.service';
import { ProductService } from '../../services/products/product.service';
/* import { Html5Qrcode } from 'html5-qrcode'; */
import { ProductInputQuantityConfigService } from '../../services/product-input-quantity-config/product-input-quantity-config.service';
import { BaseViewConfigService } from '../../services/base-view-config/base-view-config.service';

@Component({
  selector: 'lib-barcode-scanner',
  templateUrl: './barcode-scanner.component.html',
  styleUrls: ['./barcode-scanner.component.css']
})
export class BarcodeScannerComponent implements OnInit {
  scannerComponent = this.baseViewConfigService.getScannerComponent();
  productsInCartComponent = this.baseViewConfigService.getProductsInCartComponent();

  started: boolean | undefined;
  errorMessage: string | undefined;
  lastScannedCode: string | undefined;
  lastScannedCodeDate: number | undefined;
  products: Product[] | ProductMultiDimension[];
  productsMulti: ProductMultiDimension[];
  findResult = false;
  initProductsInCart = false;
  fullscreen = false;
  eanNumbers: EanNumbers[];
  lastTenCodes = [];
  code: string;
  cameras;
  cameraIndexSelected = 0;
  stockIds;
  notFound = false;
  notFoundCode = null;
  isLoading = true;
  gettingEan = false;
  now = new Date();

  constructor(
    protected changeDetectorRef: ChangeDetectorRef,
    protected beepService: BeepService,
    protected productsService: ProductService,
    protected authService: AuthService,
    protected gungFlowService: GungFlowService,
    protected cartService: CartService,
    protected eanNumbersService: EanNumbersService,
    protected componentFactoryResolver: ComponentFactoryResolver,
    protected productInputQuantityConfigService: ProductInputQuantityConfigService,
    protected baseViewConfigService: BaseViewConfigService
  ) {}

  ngOnInit() {
    this.authService.getCurrentUser().pipe(first()).subscribe(user => {
      this.stockIds = user.managedMultistockIds;
      this.gungFlowService.getSelectedFlow().pipe(
        switchMap(flow => this.productsService.getProductsByAssortmentExpandedAndFlow(user.assortment, flow.id))
      ).subscribe(products => {
        this.products = products;
        this.initProductsInCart = true;
      });
    });
  }

  onBarcodeScanned(code) {
    this.lastTenCodes.push(code);
    if (this.lastTenCodes.length < 2) {
      return;
    } else {
      this.lastTenCodes.shift();
    }
    // ignore duplicates for an interval of 1.5 seconds
    const now = new Date().getTime();
    if (!this.allAreEqual(this.lastTenCodes)) {
      return;
    }
    if (
      code === this.lastScannedCode &&
      this.lastScannedCodeDate !== undefined &&
      now < this.lastScannedCodeDate + 1500
    ) {
      return;
    }
    this.findProductByEan(code, now);
    this.changeDetectorRef.detectChanges();
  }

  findProductByEan(code, now) {
    /*  const eanProduct = this.eanNumbers.find(p => p.ean === code); */
    if (!this.gettingEan) {
      this.gettingEan = true;
      this.eanNumbersService.getEanNumber(code).subscribe(eanProductService => {
        const eanProduct = eanProductService && eanProductService[0] ? eanProductService[0] : null;
        if (eanProduct) {
          const productToAdd = this.products.find(
            p =>
              p.id === eanProduct.sku ||
              (p.productType === 'multiDimension' &&
                p.id === eanProduct.sku.substring(0, eanProduct.sku.length - 4) &&
                (p as ProductMultiDimension).secondaryDimension.find(
                  s => s.id === eanProduct.sku.substring(eanProduct.sku.length - 4)
                ))
          );
          if (productToAdd) {
            this.notFound = false;
            this.findResult = true;
            setTimeout(() => {
              this.findResult = false;
            }, 1000);
            this.lastScannedCode = code;
            this.lastScannedCodeDate = now;
            this.beepService.beep();
            // Check if the product is already in cart if not get getMinimumOrderQuantity before getStepAmount
            this.cartService.getProductQty(productToAdd.id).pipe(first()).subscribe({
              next: (v) => {
                let qty;
                if (v && v > 0) {
                  qty = this.productInputQuantityConfigService.getStepAmount(productToAdd.id, productToAdd);
                } else {
                  qty = this.productInputQuantityConfigService.getMinimumOrderQuantity(productToAdd.id, productToAdd);
                }
                this.cartService.addToCart(
                  productToAdd.id, qty
                );
              },
              error: (e) => {
                this.cartService.addToCart(
                  productToAdd.id, this.productInputQuantityConfigService.getMinimumOrderQuantity(productToAdd.id, productToAdd)
                );
              }
          })
            
          }
        } else {
          this.notFound = true;
          this.notFoundCode = code;
        }
        this.gettingEan = false;
      });
    }
  }

  expand() {
    this.fullscreen = !this.fullscreen;
  }

  allAreEqual(array) {
    const result = array.every(element => {
      if (element === array[0]) {
        return true;
      }
    });
    return result;
  }
}
