import { Component, Input, OnInit } from '@angular/core';
import { CartRow, ThankYouComponent, PriceService, ProductService } from 'gung-standard';
import { environment } from './../../../environments/environment';

@Component({
  selector: 'app-hl-display-thank-you',
  templateUrl: './hl-display-thank-you.component.html',
  styleUrls: ['./hl-display-thank-you.component.scss']
})
export class HlDisplayThankYouComponent extends ThankYouComponent implements OnInit {
  replyTo = '';
  isPortalNo = environment.mainCountry === 'no';
  isNOCustomerPortal = !environment.sales && environment.mainCountry === 'no';
  isFRCustomerPortalPharma =
    !environment.sales && environment.mainCountry === 'fr' && environment.mainCustomer === 'Pharmacies';
  public isFRCustomerPortal1600General =
    !environment.sales &&
    environment.mainCountry === 'fr' &&
    environment.bolag === '1600' &&
    environment.mainCustomer === 'General';
  isSECustomerPortal = !environment.sales && environment.mainCountry === 'se';
  isIntermarcheOrCarrefourOrSpar =
    environment.subCustomer === 'intermarche' ||
    environment.subCustomer === 'carrefour' ||
    environment.mainCustomer === 'spar';
  isCustomerPortal1210Kesko = environment.bolag === '1210' && !environment.sales && environment.mainCountry === 'fi';

  public isCurrysPortal =
    environment.bolag === '1710' &&
    !environment.sales &&
    !environment.managers &&
    environment.mainCountry === 'en' &&
    environment.mainCustomer === '';

  constructor(protected priceService: PriceService, protected productService: ProductService) {
    super(priceService, productService);
  }

  ngOnInit() {
    super.initData();
    this.setReplyTo();
  }



  setReplyTo(): void {
    if (
      ((this.isFRCustomerPortalPharma ||
        this.isFRCustomerPortal1600General ||
        this.isNOCustomerPortal ||
        this.isSECustomerPortal) &&
        !this.isIntermarcheOrCarrefourOrSpar) ||
      this.isCustomerPortal1210Kesko
    ) {
      this.replyTo = environment.quoteConfirmationEmailAddress;
    } else if (this.isIntermarcheOrCarrefourOrSpar) {
      this.replyTo = 'OrdersBenelux@hl-display.com';
    } else if (this.isCurrysPortal) {
      this.replyTo = 'DSG@hl-display.com';
    } else {
      this.replyTo = 'mousquetaires.info@hl-display.com';
    }
  }
}
