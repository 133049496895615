<h2 class="mb-2" translate>TERMS</h2>
<div class="row">
  <div class="col">
    <lib-meta-select-input
      table="x2e"
      metaId="levvillkkod"
      metaDisplay="levvillkbeskr"
      [(ngModel)]="checkout.extra.procargs.levvillkkod"
      [disabled]="true"
    ></lib-meta-select-input>
    <lib-meta-select-input
      table="x2f"
      metaId="levsattkod"
      metaDisplay="levsattbeskr"
      [(ngModel)]="checkout.extra.procargs.levsattkod"
      [disabled]="true"
    ></lib-meta-select-input>

    <div class="form-group">
      <label for="kus.kundklass" translate>CARRIER_CODE</label>
      <lib-meta-select-input
        [suppressLabel]="true"
        [optionsFilterFunction]="filterCarrierCodes"
        [(ngModel)]="checkout.extra.oh.transportorskod"
        table="x2t"
        metaId="transportorskod"
        (ngModelChange)="changeCarrierCode($event)"
        metaDisplay="transportorsnamn"
        [disabled]="true"
      ></lib-meta-select-input>
    </div>
  </div>
  <div class="col">
    <lib-meta-select-input 
    [(ngModel)]="checkout.extra.oh.betkod" 
    table="x2" 
    metaId="betkod" 
    metaDisplay="betvillbeskr" 
    [disabled]="true"
    ></lib-meta-select-input>
    <lib-meta-select-input
      [(ngModel)]="checkout.extra.oh.dellevtillaten"
      table="xdl"
      metaId="dellevtillaten"
      metaDisplay="dellevtillatenbeskr"
    ></lib-meta-select-input>
  </div>
</div>
