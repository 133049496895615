<div class="row">
  <div class="col-1 d-flex align-items-center justify-content-center">
    <!-- <button class="btn btn-light delete-button" (click)="removeAll(content);"><i class="fas fa-times"></i></button> -->
  </div>
  <div class="col-1"></div>
  <div class="col-2 d-flex align-items-center">
    <div translate>NAME</div>
  </div>
  <div class="col-2 d-flex align-items-center justify-content-center">
    <div class="text-center" translate>REQUESTED_QUANTITY</div>
  </div>
  <div class="col-1 d-flex align-items-center justify-content-center">
    <div class="text-center" translate>QUANTITY_AVAILABLE</div>
  </div>
  <div *ngIf="!isCurrysPortal" class="col-1 d-flex align-items-center justify-content-center" [ngClass]="{ 'portal-no': isNoPortal }">
    <div class="text-center" translate="">FIRST_AVAILABLE_DATE</div>
  </div>
  <div *ngIf="isCurrysPortal" class="col-1 d-flex align-items-center justify-content-center">
  </div>
  <div class="col-2 d-flex align-items-center justify-content-center">
    <div class="text-center" translate>UNIT_PRICE</div>
  </div>
  <div class="vhr col-2 d-flex align-items-center justify-content-center flex-column">
    <div translate>TOTAL_PRICE</div>
  </div>
</div>
<div class="row hr mt-2"></div>
<div class="row">
  <div class="col-12" *ngFor="let item of items">
    <ng-template libCustomHost></ng-template>
  </div>
</div>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title" translate>CLEAR_CART</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('CANCEL')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p translate>CLEAR_CART_CONFIRM</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn secondary" (click)="modal.dismiss('CANCEL')" translate>CANCEL</button>
    <button type="button" class="btn btn-primary" (click)="modal.close('CLEAR_CART')" translate>OK</button>
  </div>
</ng-template>
