import { Component, OnInit } from '@angular/core';
import { CustomerDetailsComponent, CustomerService } from 'gung-standard';
import { ActivatedRoute } from '@angular/router';
import { JeevesCustomerActionConfigService } from '../../services/jeeves-customer-action-config/jeeves-customer-action-config.service';

@Component({
  selector: 'gung-jeeves-jeeves-customer-details-wrapper',
  templateUrl: './jeeves-customer-details-wrapper.component.html',
  styleUrls: ['./jeeves-customer-details-wrapper.component.css']
})
export class JeevesCustomerDetailsWrapperComponent extends CustomerDetailsComponent implements OnInit {
  constructor(
    route: ActivatedRoute,
    customerService: CustomerService,
    public configService: JeevesCustomerActionConfigService
  ) {
    super(route, customerService);
  }

  ngOnInit() {
    super.ngOnInit();
  }
}
