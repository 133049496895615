import { Component } from '@angular/core';
import { OrderListConfigService } from 'gung-standard';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-hl-display-order-list-wrapper',
  templateUrl: './hl-display-order-list-wrapper.component.html',
  styleUrls: ['./hl-display-order-list-wrapper.component.css']
})
export class HlDisplayOrderListWrapperComponent {
  constructor(public orderListConfigService: OrderListConfigService, route: ActivatedRoute) {
    const customerId = route.snapshot.params.customerId;

    if (customerId != null) {
      orderListConfigService.setCustomerId(customerId);
    } else {
      orderListConfigService.setCustomerId(null);
    }
  }
}
