import { Injectable } from '@angular/core';
import { Product, ProductExtended } from '../../models';
import { MetadataService } from '../metadata/metadata.service';
import { Assortment, AssortmentRecursive, AssortmentRecursiveExtended } from '../assortment.service';
import { PimTemplateProperties } from 'gung-list';

@Injectable({
  providedIn: 'root'
})
export class DynamicColumnsService {

  constructor(
    protected metadataService: MetadataService
  ) { }

  getDynamicColumnsValuesConcept(dynamicColumns: any, items: Product[]): Product[]{
    if (dynamicColumns && dynamicColumns.length > 0) {
        return items.map((item: ProductExtended) => {
          this.mapItem(dynamicColumns, item);
          return item;
        }) as Product[];
    }
    return items;
  }

  getDynamicColumnsValuesAssortment(dynamicColumns: any, items: AssortmentRecursive[]): AssortmentRecursive[]{
    if (dynamicColumns && dynamicColumns.length > 0) {
        return items.map((item: AssortmentRecursiveExtended) => {
          this.mapItem(dynamicColumns, item);
          return item;
        }) as AssortmentRecursive[];
    }
    return items;
  }

  mapItem(dynamicColumns, item){
    const dynamicCollumnsValues: string[] = [];
    for (const column of dynamicColumns) {
      if (column.isDisplay) {
        const paths: string[] = column.path.split('.');
        const value = (item.extra[paths[0]] && item.extra[paths[0]][paths[1]]) || '';
        if (value && value.description) {
          dynamicCollumnsValues.push(value.description);
        } else if (Array.isArray(value)) {
          const valueArray = value.map(v => v.description).join(', ');
          dynamicCollumnsValues.push(valueArray);
        } else if (column.type === 'metadata') {
          if (column.metaReference) {
            dynamicCollumnsValues.push(
              this.metadataService.getMetadataValue(
                column.metaReference.table,
                column.metaReference.field,
                value
              )
            );
          } else if (column.metadata && column.metadata.split('.').length === 2) {
            const splitmeta = column.metadata.split('.');
            const metaTable = splitmeta[0];
            const metaField = splitmeta[1];
            dynamicCollumnsValues.push(this.metadataService.getMetadataValue(metaTable, metaField, value));
          } else {
            dynamicCollumnsValues.push(
              this.metadataService.getMetadataValue(column.metadata, 'description', value)
            );
          }
        } else {
          dynamicCollumnsValues.push(value || '');
        }
      } else {
        dynamicCollumnsValues.push('');
      }
    }
    item.dynamicCollumnsValues = dynamicCollumnsValues;
  }

  getDynamicColumns(assortment: Assortment): PimTemplateProperties[] {
    const headerDynamicTranslationKeys: PimTemplateProperties[] = [];
    // Dynamic column
    const dynamicColumns = assortment?.extra?.skuProperties || assortment?.extra?.itemProperties;
    if (dynamicColumns) {
      for (const skuProperty of dynamicColumns as PimTemplateProperties[]) {
        if (skuProperty.isDisplay || skuProperty.isDisplayGrid || skuProperty.isDisplayList) {
          headerDynamicTranslationKeys.push(skuProperty);
        }
      }
    }
    return headerDynamicTranslationKeys;
  }

}
