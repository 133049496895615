<div class="barcode-wrapper" *ngIf="initProductsInCart">
  <div class="camera-fix-area">
    <div>
      <!-- <lib-scanner (outputCodeScanned)="onBarcodeScanned($event)"></lib-scanner> -->
      <ng-template
        [libCustomHostDynamic]="scannerComponent"
        [inputs]="{}"
        [outputs]="{ outputCodeScanned: onBarcodeScanned.bind(this) }"
      >
      </ng-template>
    </div>
    <div class="-ean text-center" *ngIf="notFound">
      <div class="not-found-close cursor-pointer">
        <i class="fas fa-times click-icon" (click)="notFound = false"></i>
      </div>
      <span translate>Cannot find barcode</span> {{ notFoundCode }}
    </div>
  </div>

  <!-- <div class="select-camera" *ngIf="cameras">
        <select (change)="changeCamera($event.target.value)">
            <option [value]="camera.id" *ngFor="let camera of cameras">
                {{camera.label}}
            </option>
        </select>
    </div> -->
  <!-- <div class="fullscreen-change text-right">
        <button class="btn btn-sm btn-primary" (click)="expand()">
            <i class="fas" [ngClass]="{'fa-expand-alt': !fullscreen, 'fa-compress-alt': fullscreen}"></i>
        </button>
    </div> -->
  <!-- <div class="reader-wrapper mt-1">
        <div class="change-camera" *ngIf="cameras" (click)="changeCamera()"><i class="fas fa-sync-alt"></i></div>
        <div class="code-focus" [class.code-find]="findResult">
        </div>
        <div id="reader" height="200px" width="100%">

        </div>
        <div class="not-found-ean py-2" *ngIf="notFoundCode">
            <span translate>CODE NOT FOUND</span> {{code}}
        </div>
    </div> -->
  <div class="cart-list" [class.cart-full]="fullscreen">
    <!-- <lib-products-in-cart [allProducts]="products" [stockIds]="stockIds" [fullscreen]="fullscreen" *ngIf="initProductsInCart"></lib-products-in-cart> -->
    <ng-template
      [libCustomHostDynamic]="productsInCartComponent"
      [inputs]="{ allProducts: products, stockIds: stockIds, fullscreen: fullscreen }"
      [outputs]="{}"
    >
    </ng-template>
  </div>
</div>

<div *ngIf="!initProductsInCart" class="text-center">
  <i class="fa fa-spinner fa-spin fa-7x"></i>
</div>
