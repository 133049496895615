import { Component, OnInit, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { DateUtilService, JobKey, SchedulersService } from 'gung-common';
import { ListItemRendererComponent } from 'gung-list';
import { first } from 'rxjs';
import { SchedulerV2 } from '../../services/schedulers-list-config/schedulers-list-config.service';

@Component({
  selector: 'lib-schedulers-monitor-list-view',
  templateUrl: './schedulers-monitor-list-view.component.html',
  styleUrls: ['./schedulers-monitor-list-view.component.css']
})
export class SchedulersMonitorListViewComponent extends ListItemRendererComponent<SchedulerV2[]> {
  isLoading: boolean = true;
  panelOpenStates: string[] = [];
  lastException: { jobName: string; lastExceptionDate: Date; lastExceptionTrace: string };
  dateFormat = this.dateUtilService.dateFormat;

  constructor(
    protected schedulersService: SchedulersService,
    protected modalService: NgbModal,
    public dateUtilService: DateUtilService,
    protected translateService: TranslateService
  ) {
    super();
  }

  triggerJob(jobKey: JobKey) {
    const scheduler = this.data.find(s => s.jobKey === jobKey);
    if (scheduler?.jobdatamap) {
      scheduler.jobdatamap.running = true;
    }
    this.schedulersService
      .triggerJob(jobKey)
      .pipe(first())
      .subscribe((jobkey: { jobKey: JobKey }) => {});
  }

  panelOpenState(jobName: string, add?: boolean) {
    if (add === undefined) {
      return this.panelOpenStates.includes(jobName);
    }
    if (add) {
      this.panelOpenStates.push(jobName);
    } else {
      this.panelOpenStates = this.panelOpenStates.filter(p => p !== jobName);
    }
  }

  showLastExceptionTrace(scheduler: SchedulerV2, content) {
    if (!scheduler?.jobdatamap?.lastExceptionTrace) {
      return;
    }
    this.lastException = {
      jobName: scheduler.jobName,
      lastExceptionDate: scheduler.jobdatamap.lastExceptionDate,
      lastExceptionTrace: scheduler.jobdatamap.lastExceptionTrace
    };
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'xl', windowClass: 'modal-xl-custom' }).result.finally(() => {
      this.lastException = undefined;
    });
  }

  parseCronExpression(cronExpression: string): string {
    return this.schedulersService.parseCronExpressionToText(cronExpression);
  }
}
