import { Component } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';

@Component({
  selector: 'lib-mockroles-warning',
  templateUrl: './mockroles-warning.component.html',
  styleUrls: ['./mockroles-warning.component.css']
})
export class MockrolesWarningComponent {
  isMockRolesEnabled = this.authService.mockRolesModeEnabled()

  constructor(protected authService: AuthService) {}
}
