import { Component, OnInit } from '@angular/core';
import { DateUtilService } from 'gung-common';
import { ListItemRendererComponent } from 'gung-list';
import { ReplenishmentProductExpanded } from '../../models/replenishment';
import { ReplenishmentProductService } from '../../services/replenishment-product.service';
import { ReplenishmentProductExcelService } from '../../services/replenishment-product-excel.service';

@Component({
  selector: 'lib-replenishment-product-view',
  templateUrl: './replenishment-product-view.component.html',
  styleUrls: ['./replenishment-product-view.component.css']
})
export class ReplenishmentProductViewComponent extends ListItemRendererComponent<ReplenishmentProductExpanded> {
  constructor(
    public dateUtilService: DateUtilService,
    protected replenishmentProductService: ReplenishmentProductService,
    private replenishmentProductExcelService: ReplenishmentProductExcelService
  ) {
    super();
  }

  saveProduct(input: ReplenishmentProductExpanded) {
    // TODO Add a debounce to stop sending updates for each change.
    this.replenishmentProductService.save(input).subscribe(_ => undefined);
  }

  changeMinimumQuantity(input: ReplenishmentProductExpanded, value: number) {
    input.minimumQuantity = value;
    this.saveProduct(input);
  }

  changeMaximumQuantity(input: ReplenishmentProductExpanded, value: number) {
    input.maximumQuantity = value;
    this.saveProduct(input);
  }

  changeIncludedInReplenishment(input: ReplenishmentProductExpanded, value: boolean) {
    input.active = value;
    this.saveProduct(input);
  }
}
