import { Component, OnInit, OnDestroy, OnChanges } from '@angular/core';
import { Subscription, forkJoin, of, Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs';
import { ListItemRendererComponent } from 'gung-list';
import {
  Product,
  CustomerProductPrice,
  PriceService,
  Availability,
  GungFlowService,
  AuthService,
  GungFlow,
  LocationConfigService
} from 'gung-standard';
import { Router } from '@angular/router';

@Component({
  selector: 'gung-jeeves-jeeves-product-table',
  templateUrl: './jeeves-product-table.component.html',
  styleUrls: ['./jeeves-product-table.component.css']
})
export class JeevesProductTableComponent
  extends ListItemRendererComponent<Product[]>
  implements OnInit, OnDestroy, OnChanges
{
  public mappedData: ProductTableRow[] = [];
  protected mappedDataChanged: Subject<void> = new Subject<void>();
  public includeAvailability = false;

  protected keyedMapData: { [id: string]: ProductTableRow } = {};
  protected subscriptions: Subscription[] = [];
  protected flowSubscriptions: Subscription;

  isSales: boolean;

  protected currentFlow: GungFlow;
  protected oldFlow: GungFlow;

  adjustedScroll = false;
  protected unsubscribe: Subject<void> = new Subject();
  errorMessage = 'ERROR: ';
  findError = false;

  constructor(
    protected priceService: PriceService,
    protected gungFlowService: GungFlowService,
    protected authService: AuthService,
    protected router: Router,
    protected locationConfigService: LocationConfigService
  ) {
    super();
  }
  public ngOnInit() {
    this.flowSubscriptions = this.gungFlowService.getSelectedFlow().subscribe(flow => (this.currentFlow = flow));
    this.subscribeToProducts();

    this.authService
      .getRoles()
      .pipe(first())
      .subscribe(
        roles =>
          (this.isSales =
            roles.filter(role => role.toUpperCase() === 'ADMIN' || role.toUpperCase() === 'SALES').length > 0)
      );

    this.mappedDataChanged
      .asObservable()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {

      });
  }

  ngOnChanges() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.subscribeToProducts();
  }

  public ngOnDestroy() {
    this.flowSubscriptions.unsubscribe();
    this.subscriptions.forEach(sub => sub.unsubscribe());

    this.mappedDataChanged.complete();
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  protected subscribeToProducts() {
    if (!this.oldFlow || !this.currentFlow || this.oldFlow.id !== this.currentFlow.id) {
      // remove cached data;
      this.keyedMapData = {};
      this.oldFlow = this.currentFlow;
    }
    const newIds = this.data.map(p => p.id).filter(id => !Object.keys(this.keyedMapData).includes(id));
    if (newIds.length === 0) {
      this.readMappedDataFromCache();
      return;
    }
    const subscription = forkJoin([
      of(this.data),
      this.priceService.getCurrentCustomerPrices(newIds).pipe(first()),
      this.gungFlowService.getSelectedFlow().pipe(first())
    ])
      .pipe(first())
      .subscribe(data => {
        const productData = data[0];
        const prices = data[1];
        let avs = [];
        this.includeAvailability = data[2].useAvailabilities || data[2].requireAvailability;
        if (this.includeAvailability) {
          avs = newIds.map(id => this.getProductAvailability(this.data.find(product => product.id === id)));
        }

        this.keyedMapData = {
          ...this.keyedMapData,
          ...newIds.reduce((acc, curr) => {
            const product = productData.filter(p => p.id === curr)[0];
            if (!product) {
              this.findError = true;
              this.errorMessage += 'No product found. ';
              throw new Error('No product found');
            }

            const price = prices.filter(p => p.productId === curr)[0];
            if (!price) {
              this.findError = true;
              this.errorMessage += 'No price found.';
              throw new Error('No price found');
            }

            const av = avs.filter(p => p.productId === curr)[0];
            if (!av && this.includeAvailability) {
              this.findError = true;
              this.errorMessage += 'No availability found. ';
              throw new Error('No availability found');
            }

            const item: ProductTableRow = this.mapItem(curr, product, price, av);
            return {
              ...acc,
              [curr]: item
            };
          }, {})
        };
        this.readMappedDataFromCache();
      });
    this.subscriptions.push(subscription);
  }

  protected readMappedDataFromCache() {
    const ids = this.data.map(d => d.id);
    this.mappedData = ids.map(id => this.keyedMapData[id]);

    this.mappedDataChanged.next();
  }

  protected mapItem(
    id: string,
    product: Product,
    price: CustomerProductPrice,
    availability: Availability
  ): ProductTableRow {
    return {
      id,
      name: product.name,
      product,
      price,
      packageSize: product.extra.ar?.artfsgforp,
      availability,
      replacementId: product.extra.ar?.q_gung_filter
    };
  }

  public getProductAvailability(product: Product): Availability {
    if (product.extra.availabilities) {
      const availabilities = Object.keys(product.extra.availabilities).map(key => product.extra.availabilities[key]);
      if (availabilities.length > 0) {
        return availabilities[0];
      }
    }

    return null;
  }

  goToDetail(product: Product): void {
    this.router.navigate(['/product/', product.id]);
  }

  getPreviousLayout(): number {
    const defaultLayout = this.locationConfigService.getLayout('current-layout');
    this.locationConfigService.removeLayout(defaultLayout);
    return Number((defaultLayout && defaultLayout.layoutIndex) || 0);
  }
}

export interface ProductTableRow {
  id: string;
  name: string;
  packageSize: number;
  product?: Product;
  price: CustomerProductPrice;
  availability: Availability;
  replacementId: string;
}
