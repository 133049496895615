import { Component, OnInit, OnDestroy, Optional } from '@angular/core';
import { JeevesDeliveryAddressesService, SummarizeOrderJeevesComponent } from 'gung-standard-jeeves';
import {
  CustomerService,
  AuthService,
  PriceService,
  ProductService,
  Customer,
  Product,
  MetadataService,
  CartRowPrice,
  CheckoutRow,
  CartService,
  GungGoogleTagManagerService,
  BackendFeatureService
} from 'gung-standard';
import { DateUtilService, OptionsList } from 'gung-common';
import { environment } from '../../../../../environments/environment';
import { langExists } from '../../../../services/hl-product.service';
import { first, mergeMap, takeUntil } from 'rxjs';
import { forkJoin, of, Subject } from 'rxjs';
import { HlDisplayDummyArticleService } from '../../../../services/hl-display-dummy-article.service';
import { HlDisplayOrderService } from '../../../../services/hl-display-order.service';

@Component({
  selector: 'app-hl-summarize-order',
  templateUrl: './hl-summarize-order.component.html',
  styleUrls: ['./hl-summarize-order.component.css']
})
export class HlSummarizeOrderComponent extends SummarizeOrderJeevesComponent implements OnInit, OnDestroy {
  private orderLineItemNotInJeevesProductId = ['155035', '900070'];

  customer: Customer;
  billingCustomer: Customer;
  sales: boolean = environment.sales;
  isPortalNo = environment.mainCountry === 'no';
  productsMap?: { [productId: string]: Product };
  pricesMap?: { [productId: string]: CartRowPrice };
  today: Date = new Date();

  isPortalFr = environment.mainCountry === 'fr';
  isPortalDe = environment.mainCountry === 'de';
  isPortalSe = environment.mainCountry === 'se';
  isPortalFi = environment.mainCountry === 'fi';
  isManagerCh = environment.bolag === '2400';
  isCoopPortal = environment.mainCustomer === 'coop';

  public isManagersNisa = environment.managers && environment.mainCountry === 'en';

  public isCurrysPortal =
    environment.bolag === '1710' &&
    !environment.sales &&
    !environment.managers &&
    environment.mainCountry === 'en' &&
    environment.mainCustomer === '';

  showTerms = true;

  public showApprovalQuote = false;

  productsFullMap?: { [productId: string]: Product };
  protected unsubscribe: Subject<void> = new Subject();

  selectInvoiceCustomerEnabled: boolean = false;
  selectInvoiceCustomerOptionsList: OptionsList[] = [];
  selectInvoiceCustomer: Customer[];

  constructor(
    protected customerService: CustomerService,
    protected productService: ProductService,
    protected authService: AuthService,
    priceService: PriceService,
    public dateUtilService: DateUtilService,
    private metadataService: MetadataService,
    private cartService: CartService,
    public dummyArticleService: HlDisplayDummyArticleService,
    protected jeevesDeliveryAddressesService: JeevesDeliveryAddressesService,
    protected orderService: HlDisplayOrderService,
    protected backendFeatureService: BackendFeatureService,
    @Optional()
    protected gungGoogleTagManagerService: GungGoogleTagManagerService
  ) {
    super(customerService, productService, authService, priceService, dateUtilService, jeevesDeliveryAddressesService, gungGoogleTagManagerService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.authService
      .getRoles()
      .pipe(first())
      .subscribe(roles => {
        this.showTerms = !environment.managers && !roles.includes('ADMIN');
      });
    this.showApprovalQuote =
      this.sales && (this.isPortalFr || this.isPortalNo || this.isPortalDe || this.isPortalSe || this.isPortalFi);
    if (this.showApprovalQuote) {
      // Get product images
      this.getProductImages();
    }

    this.backendFeatureService.isActivated('select-invoice-customer').subscribe(isActivated => {
      this.selectInvoiceCustomerEnabled = isActivated;
      if (isActivated) {
        this.orderService.getSelectableInvoiceCustomers().subscribe(data => {
          const currentValue = this.isManagerCh ? this.customer.extra?._billingCustomer?.extra.fr?.ftgnr || this.customer.extra?.fr?.ftgnr : this.customer.extra._billing?.ftgnr || '';
          this.selectInvoiceCustomerOptionsList = data.map(d => ({
            id: d.id,
            name: d.id
          }))
          this.selectInvoiceCustomerOptionsList.unshift({
            id: currentValue,
            name: currentValue
          });
          this.selectInvoiceCustomer = data;
        })
      }
    })
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  getName(product, orderRow): string {
    if (!this.sales) {
      return product.name;
    }

    if (this.orderLineItemNotInJeevesProductId.includes(product.id)) {
      return orderRow.extra.orp.artbeskr;
    }

    const id = product.id;

    const itemGroup = this.metadataService.getMetadataValue('vg', 'varugruppbeskr', product.extra.ar.varugruppkod);
    let itemDesc = product.extra.ar.artbeskr;
    if (langExists(environment.mainCountry)(product)) {
      itemDesc = product.extra.i18n[environment.mainCountry].artbeskr || itemDesc;
    }
    return `${itemGroup} - ${itemDesc}`;
  }

  getProductImages() {
    this.cartService
      .getCurrentCart()
      .pipe(
        takeUntil(this.unsubscribe),
        mergeMap(cartRows => {
          const ids = cartRows.map(row => row.productId);
          return forkJoin([of(cartRows), this.productService.getFullProductsByIds(ids).pipe(first())]);
        })
      )
      .subscribe(([cartRows, products]) => {
        this.productsFullMap = {};
        for (const product of products) {
          this.productsFullMap[product.id] = product;
          product?.extra?.images?.map(i => delete i.isSelected);
          // Get previous selected images
          const row = cartRows.find(r => r.productId === product.id);
          if (!row || !row.extra.orp || !row.extra.orp.edit) {
            continue;
          }
          const checkoutRow = this.checkout.rows.find(r => r.id === product.id);
          if (checkoutRow) {
            checkoutRow.extra.orp.edit = row.extra.orp.edit;
          }
          if (
            row &&
            row.extra.orp.edit &&
            row.extra.orp.edit.trim() !== '' &&
            row.extra.orp.edit.split(';').length > 0
          ) {
            for (const imgS3Uri of row.extra.orp.edit.split(';')) {
              const imgIndex = this.productsFullMap[product.id].extra.images.findIndex(
                i => 'filters:etag(' + i.s3ETag + ')/' + i.s3Uri === imgS3Uri
              );
              if (imgIndex !== -1 && this.productsFullMap[product.id].extra.images[imgIndex]) {
                this.productsFullMap[product.id].extra.images[imgIndex].isSelected = true;
              }
            }
          }
        }
      });
  }

  checkImageSelected(row: CheckoutRow, image): boolean {
    if (row?.extra?.orp?.edit?.trim() !== '') {
      const selected: string[] = row.extra.orp.edit.split(';');
      if (selected.length > 0) {
        return selected.indexOf(image.s3Uri) > -1;
      }
    }
    return false;
  }

  selectImage(row: CheckoutRow, image) {
    image.isSelected = !image.isSelected;
    if (!row.extra.orp.edit) {
      row.extra.orp.edit = '';
    }

    const orpEdit = row.extra.orp.edit.trim() === '' ? [] : row.extra.orp.edit.split(';');
    if (image.isSelected) {
      // Count type of all already selected images
      const countImage = {};
      if (orpEdit.length > 0) {
        for (const imgS3Uri of orpEdit) {
          const img = this.productsFullMap[row.id].extra.images.find(
            i => 'filters:etag(' + i.s3ETag + ')/' + i.s3Uri === imgS3Uri
          );
          if (!img) {
            return;
          }
          const pictureType = img.extra.PictureType; // LineDrawing, ProductPicture
          if (!countImage[pictureType]) {
            countImage[pictureType] = 0;
          }
          countImage[pictureType]++;
        }
      }
      if (!this.maxSelectedImage(countImage, image.extra.PictureType)) {
        orpEdit.push('filters:etag(' + image.s3ETag + ')/' + image.s3Uri);
        row.extra.orp.edit = orpEdit.join(';');
      } else {
        image.isSelected = false;
      }
    } else {
      // Remove deselected image
      row.extra.orp.edit = orpEdit.filter(i => i !== 'filters:etag(' + image.s3ETag + ')/' + image.s3Uri).join(';');
    }

    this.cartService.setExtra(
      row.id,
      {
        orp: {
          // ...row.extra.orp,
          edit: row.extra.orp.edit
        }
      },
      row.targetStockId
    );
  }

  /**
   * Check if selected images reach the max allow selection
   */
  maxSelectedImage(countImage, type): boolean {
    return false; // Remove max selection restriction
    if (!countImage[type]) {
      return false;
    }
    switch (type) {
      case 'ProductPicture':
        return countImage[type] >= 2;
      case 'LineDrawing':
      case 'StorePicture': // CONFIRM THIS
      default:
        return countImage[type] >= 1;
    }
  }

  selectInvoiceCustomerOption(value) {
    if (!this.billingCustomer) {
      this.billingCustomer = this.customer;
    }
    this.customer = this.selectInvoiceCustomer.find(c => c.id === value);
    if (!this.customer) {
      this.customer = this.billingCustomer;
    }
  }
}
