import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import { CustomHostDirective } from 'gung-list';
import { Observable, of, Subject } from 'rxjs';
import { Availability } from '../../models/availability';
import { Product, ProductMultiDimension } from '../../models/product';
import { BaseViewConfigService } from '../../services/base-view-config/base-view-config.service';
import { ProductService } from '../../services/products/product.service';
import { CartRow } from '../../state/cart/types';

@Component({
  selector: 'lib-buy-button-wrapper',
  templateUrl: './buy-button-wrapper.component.html',
  styleUrls: ['./buy-button-wrapper.component.css']
})
export class BuyButtonWrapperComponent implements OnInit, AfterViewInit {
  @ViewChild(CustomHostDirective)
  viewChild: CustomHostDirective;

  @Input()
  id: string;

  @Input()
  product?: Product;

  @Input()
  availability?: Availability;

  @Input()
  productPartialId?: string;

  @Input()
  minimumOrderQuantity?: number;

  @Input()
  stepAmount?: number;

  @Input()
  targetStockId?: string;

  @Input()
  disabled: boolean;
  
  @Input()
  disabledAdd: boolean;

  @Input()
  disabledSub: boolean;

  currentQty: number;
  previousQty: number;

  isOnlyDisplayFlow = false;
  unsubscribe: Subject<void> = new Subject();

  @Input()
  checkoutCart: CartRow | boolean;

  productTypeKey = 'productType';
  isMultiDimension = false;
  constructor(
    protected productService: ProductService,
    protected changeDetectorRef: ChangeDetectorRef,
    protected componentFactoryResolver: ComponentFactoryResolver,
    protected baseViewConfigService: BaseViewConfigService
  ) {}

  ngOnInit(): void {
    let productObservable: Observable<Product>;
    if (!!this.product) {
      productObservable = of(this.product);
    } else {
      productObservable = this.productService.getProduct(this.id);
    }
    productObservable.subscribe(product => {
      this.product = product;
    });
  }

  ngAfterViewInit() {
    if (this.viewChild) {
      // Clear views already rendered from previous navigation
      const containerRef = this.viewChild.viewContainerRef;
      containerRef.clear();
    }
    this.renderLayout(this.baseViewConfigService.getBuyButtonComponent(this.product, this.checkoutCart));
  }

  renderLayout(viewComponent: any) {
    if (!this.viewChild) {
      return;
    }
    this.changeDetectorRef.detectChanges();
    const factory = this.componentFactoryResolver.resolveComponentFactory(viewComponent);
    const containerRef = this.viewChild.viewContainerRef;
    containerRef.clear();
    const componentRef = containerRef.createComponent(factory);
    const typedComponent = componentRef.instance as any;
    typedComponent.id = this.product?.id || this.id;
    if (!!this.productPartialId) {
      typedComponent.productPartialId = this.productPartialId;
    }
    typedComponent.product = this.product;
    typedComponent.disabled = this.disabled;
    if (!!this.availability) {
      typedComponent.availability = this.availability;
    }
    typedComponent.checkoutCart = this.checkoutCart;
    typedComponent.disabledAdd = this.disabledAdd;
    typedComponent.disabledSub = this.disabledSub;
    this.changeDetectorRef.detectChanges();
  }
}
