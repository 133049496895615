import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs';
import { Availability, AvailabilityService, CartRow, CartService } from 'gung-standard';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-hl-display-availability',
  template: `<span (click)="updateAvailability()">
    <span class="">
      <span *ngIf="availabilityClass" class="dot mr-1 {{ availabilityClass }}"></span>
      {{ availabilityString | translate }}
    </span>
    <span *ngIf="!isAvailabilityLoading">
      <i *ngIf="isSales" (click)="updateAvailability()" class="fa fa-sync"></i>
      {{ updatedAvailabilityString | translate }}
    </span>
  </span>`,
  styleUrls: ['./hl-display-availability.component.css']
})
export class HlDisplayAvailabilityComponent implements OnInit, OnDestroy {
  @Input()
  availability: Availability;

  private unsubscribe: Subject<void> = new Subject();

  private onStockString = { text: 'IN_STOCK', class: 'on-stock' };
  private onStockBelowRequestedQtyString = {
    text: 'IN_STOCK_BELOW_REQUESTED_QTY',
    class: 'on-stock-below-requested-qty'
  };
  private availableLaterOne = { text: 'AVAILABLE_2_3_WEEKS', class: 'available-later-one' };
  private availableLaterTwo = { text: 'AVAILABLE_4_5_WEEKS', class: 'available-later-two' };
  private notAvailable = { text: 'NOT_IN_STOCK', class: 'not-available' };

  public isSales: boolean = false;
  public isAvailabilityLoading: boolean = false;
  public availabilityString: string;
  public availabilityClass: string;
  public availabilityRefreshed: boolean;
  public updatedAvailabilityString = '';

  private portalFr = environment.mainCountry === 'fr';

  constructor(private cartService: CartService, private availabilityService: AvailabilityService) {}

  ngOnInit() {
    if (environment.sales) {
      this.isSales = true;
    }
    if (environment.sales) {
      this.availabilityString = this.availability.currentAvailability + '';
      return;
    }

    if (this.availability.currentAvailability * 0.5 >= 1) {
      // IN_STOCK
      // for customer portals (sales portal check above) when there is stock then
      // if request quantity is higher than current availablity then show IN_STOCK_BELOW_REQUESTED_QTY instead of IN_STOCK
      this.cartService
        .getCurrentCart()
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((cart: CartRow[]) => {
          const cartRow = cart.filter(cr => cr.productId === this.availability.productId)[0];
          if (!!cartRow && cartRow.qty > this.availability.currentAvailability) {
            this.availabilityString = this.onStockBelowRequestedQtyString.text;
            this.availabilityClass = this.onStockBelowRequestedQtyString.class;

            if (this.portalFr) {
              // Get new availability lead time
              const keys = Object.keys(this.availability.extra.availabilities);
              const keysWithAvailabilities = keys.filter(key => this.availability.extra.availabilities[key] > 0);
              let availabilityDate;
              for (const av of keysWithAvailabilities) {
                const qty = this.availability.extra.availabilities[av];
                if (cartRow.qty < qty) {
                  availabilityDate = {av, qty};
                  break;
                }
              }

              if (availabilityDate) {
                const now = new Date();
                const availableDateMillis = Date.parse(this.getStandardizedDateString(availabilityDate.av));
                const diffInMillis = availableDateMillis - now.getTime();
    
                const diffInDays = diffInMillis / 1000 / 60 / 60 / 24;
    
                if (diffInDays <= 21) {
                  this.availabilityString = this.availableLaterOne.text;
                  this.availabilityClass = this.availableLaterOne.class;
                } else {
                  this.availabilityString = this.availableLaterTwo.text;
                  this.availabilityClass = this.availableLaterTwo.class;
                }
              }
            }
          } else {
            this.availabilityString = this.onStockString.text;
            this.availabilityClass = this.onStockString.class;
          }
        });
    } else {
      const keys = Object.keys(this.availability.extra.availabilities);

      const keysWithAvailabilities = keys.filter(key => this.availability.extra.availabilities[key] > 0);

      if (!!keysWithAvailabilities && keysWithAvailabilities.length > 0) {
        const now = new Date();
        const availableDateMillis = Date.parse(this.getStandardizedDateString(keysWithAvailabilities[0]));
        const diffInMillis = availableDateMillis - now.getTime();

        const diffInDays = diffInMillis / 1000 / 60 / 60 / 24;

        if (diffInDays <= 21) {
          this.availabilityString = this.availableLaterOne.text;
          this.availabilityClass = this.availableLaterOne.class;
        } else {
          this.availabilityString = this.availableLaterTwo.text;
          this.availabilityClass = this.availableLaterTwo.class;
        }
      } else {
        this.availabilityString = this.notAvailable.text;
        this.availabilityClass = this.notAvailable.class;
      }
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  // Require format yyMMdd.
  public getStandardizedDateString(date: string): string {
    const res = '20' + date.substring(0, 2) + '-' + date.substring(2, 4) + '-' + date.substring(4);
    return res;
  }

  updateAvailability() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.isAvailabilityLoading = true;
    this.availabilityService.getAvailability(this.availability.productId, undefined, true).subscribe(availability => {
      this.availabilityString = 'OLD';
      this.updatedAvailabilityString = ' [' + availability.currentAvailability + ']';
      this.isAvailabilityLoading = false;
      this.ngOnInit();
    });
  }
}
