<div *ngIf="data; else loading" class="table-responsive gung-responsive">
  <table class="table">
    <thead>
      <tr>
        <th translate>STATUS</th>
        <!-- <th translate>ID</th> -->
        <th translate>DATE_CREATED</th>
        <th translate>ACTIVITY_DATE</th>
        <th translate>CUSTOMER</th>
        <th translate>CUSTOMER_RESPONSIBLE</th>
        <!-- <th translate>CUSTOMER_PREVIEW</th> -->
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of data">
        <td [attr.data-label]="'STATUS' | translate">
          <div class="text-nowrap" [ngClass]="{ 'w-150': item._showEdit }">
            <span *ngIf="!item._showEdit" class="badge badge-pill" translate>
              {{
                metadataService.getMetadataValue(
                  'ACTIVITYINTERNALSTATUS',
                  'name',
                  item.status + '@ACTIVITYINTERNALSTATUS'
                ) || item.status
              }}
            </span>
            <!-- <button #editBtn *ngIf="!item._showEdit" type="button" class="btn btn-light btn-sm" (click)="item._showEdit=!item._showEdit">
                <i class="fas fa-edit"></i>
              </button> -->
            <div class="row">
              <div class="col pr-0 pl-1">
                <lib-meta-data-select-input
                  #metaDataInput
                  class="mb-0"
                  *ngIf="item._showEdit"
                  [(ngModel)]="item.status"
                  table="ACTIVITYINTERNALSTATUS"
                  metaId="id"
                  metaDisplay="name"
                  prefix="@ACTIVITYINTERNALSTATUS"
                  suppressLabel="true"
                  hideEmptyOption="true"
                ></lib-meta-data-select-input>
              </div>
              <div class="col-auto pl-0 pr-1">
                <button
                  #saveBtn
                  *ngIf="item._showEdit"
                  type="button"
                  class="btn btn-info"
                  (click)="item._showEdit = !item._showEdit; saveStatus(item)"
                >
                  <i class="far fa-save"></i>
                </button>
              </div>
            </div>
          </div>
        </td>
        <!-- <td [attr.data-label]="'ID' | translate">
            <button type="button" class="btn btn-link" (click)="openViewModal(item)">{{item.id}}</button>
          </td> -->
        <td [attr.data-label]="'DATE_CREATED' | translate">
          {{ item.date | date: dateUtilService.dateFormat }}
        </td>
        <td [attr.data-label]="'ACTIVITY_DATE' | translate">
          <div class="row">
            <input
              class="form-control input-date"
              placeholder="{{ item.activityDate | date: dateUtilService.dateFormat }}"
              name="dp"
              readonly
              ngbDatepicker
              #d="ngbDatepicker"
              [minDate]="item.activityDate | date: dateUtilService.dateFormat"
              container="body"
              (dateSelect)="updateDate($event, item)"
            />
            <div class="input-group-append">
              <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button">
                <i class="fal fa-calendar-alt"></i>
              </button>
            </div>
          </div>
        </td>
        <td [attr.data-label]="'CUSTOMER' | translate">{{ item.customerId }} - {{ item.customerName }}</td>
        <td [attr.data-label]="'CUSTOMER_RESPONSIBLE' | translate">
          <lib-gung-meta-output
            [value]="item.customerResponsible"
            [gungTranslate]="'CUSTOMER_RESPONSIBLE'"
            [suppressLabel]="true"
            [metadataTable]="activityInternalCustomerResponsibleService.metadataTable"
            [metaOptionName]="activityInternalCustomerResponsibleService.metadataName"
            [showIfNotFound]="true"
            [showOnlyText]="true"
          ></lib-gung-meta-output>
        </td>
        <!-- <td [attr.data-label]="'CUSTOMER_PREVIEW' | translate" class="text-truncate">
            {{item.comments[item.comments.length-1].comment}}
          </td> -->
        <td [attr.data-label]="'ACTIONS' | translate">
          <!-- <button *ngIf="isAdmin" type="button" class="btn btn-sm btn-light mr-1" (click)="editActivityInternal(item)"><i class="fas fa-edit"></i></button> -->
          <!-- <button *ngIf="isAdmin" type="button" class="btn btn-sm btn-danger" (click)="deleteActivityInternal(item)"><i class="fas fa-trash"></i></button> -->
          <button type="button" class="btn btn-sm btn-light" (click)="openViewModal(item)">
            <i class="fas fa-comments"></i>
          </button>
          <button type="button" class="btn btn-sm btn-light" (click)="openDeleteModal(item)">
            <i class="fas fa-trash"></i>
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<ng-template #loading>
  <i class="fa fa-spinner fa-spin fa-7x"></i>
  LOADING...
</ng-template>
