import { Injectable } from '@angular/core';
import {
  CustomerListConfigService,
  CustomerService,
  Customer,
  MetadataService,
  UsersService,
  AuthService,
  CustomerSelectionActionConfigService
} from 'gung-standard';
import { ListLayout, ListLayoutSingleComponent, SimpleConfigBaseFilter } from 'gung-list';
import { HlCustomerListCardComponent } from '../components/hl-customer-list-card/hl-customer-list-card.component';
import { map, Observable, tap } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CustomerFinancialGroupFilter extends SimpleConfigBaseFilter<Customer> {
  constructor(protected metadataService: MetadataService) {
    super();
  }

  getName(): string {
    return 'FINANCIAL_GROUP';
  }

  getOptionIds(item: Customer): string[] {
    const metadataValue = this.metadataService.getMetadataValue(
      'q_financialgrp',
      'q_financialgrpdesc',
      item.extra.kus.q_financialgrpcode
    );
    if (metadataValue == null) {
      return [];
    } else {
      return [metadataValue];
    }
  }

  getOptionName(key: string): string {
    return key;
  }
}

@Injectable({
  providedIn: 'root'
})
export class CustomerSuperGroupFilter extends SimpleConfigBaseFilter<Customer> {
  constructor(protected metadataService: MetadataService) {
    super();
  }

  getName(): string {
    return 'SUPER_GROUP';
  }

  getOptionIds(item: Customer): string[] {
    const metadataValue = this.metadataService.getMetadataValue(
      'q_supergrp',
      'q_supergrpdesc',
      item.extra.kus.q_supergrpcode
    );
    if (metadataValue == null) {
      return [];
    } else {
      return [metadataValue];
    }
  }

  getOptionName(key: string): string {
    return key;
  }
}

@Injectable({
  providedIn: 'root'
})
export class HlCustomerListConfigService extends CustomerListConfigService {
  isMangUkNisa = environment.managers && environment.mainCountry === 'en';
  isCoopPortal = environment.mainCustomer === 'coop';
  isChCoopPortal = environment.mainCustomer === 'coop' && environment.bolag === '2400' && environment.mainCountry === 'ch';

  constructor(
    customerService: CustomerService,
    protected usersService: UsersService,
    protected authService: AuthService,
    protected customerSelectionActionConfigService: CustomerSelectionActionConfigService,
    protected translateService: TranslateService,
    protected customerSuperGroupFilter: CustomerSuperGroupFilter,
    protected financialGroupFilter: CustomerFinancialGroupFilter
  ) {
    super(customerService, usersService, authService, customerSelectionActionConfigService, translateService);
  }

  getFilters() {
    return this.isMangUkNisa || this.isCoopPortal ? [] : [this.financialGroupFilter, this.customerSuperGroupFilter];
  }

  getLayouts(): ListLayout<Customer>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => HlCustomerListCardComponent,
        getListLayoutComponent: () => ListLayoutSingleComponent,
        getName: () => 'Customer list'
      }
    ];
  }

  getSearchTerms(item: Customer): string[] {
    const terms = [];
    if (item.id) {
      terms.push(item.id);
    }
    if (item.name) {
      terms.push(item.name);
    }
    if (item.extra.fr.ftgpostadr2) {
      terms.push(item.extra.fr.ftgpostadr2);
    }
    if (item.extra.fr.ftgpostadr3) {
      terms.push(item.extra.fr.ftgpostadr3);
    }
    if (item.extra.fr.ftgpostnr) {
      terms.push(item.extra.fr.ftgpostnr);
    }
    if (this.isMangUkNisa && item.extra?.fr?.q_siren) {
      terms.push(item.extra.fr.q_siren);
    }
    return terms;
  }

  hideZeroOptions() {
    return true;
  }

  getItems(): Observable<Customer[]> {
    return super.getItems().pipe(
      map(customers => {
        if (this.isChCoopPortal) {
          return customers.filter(c => c.id.includes('@'));
        }
        return customers;
      })
    )
  }
}
