import { Component } from '@angular/core';
import { ListItemRendererComponent } from 'gung-list';
import { News } from '../../../models/news';

@Component({
  selector: 'lib-news-page-grid-view',
  templateUrl: './news-page-grid-view.component.html',
  styleUrls: ['./news-page-grid-view.component.css']
})
export class NewsPageGridViewComponent extends ListItemRendererComponent<News[]> {
  constructor() {
    super();
  }
}
