import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  PriceService,
  ProductCardComponent,
  CartService,
  CustomerProductPrice,
  MetadataService,
  Product,
  SelectedCustomerService,
  Availability,
  Customer
} from 'gung-standard';
import { first } from 'rxjs';
import { environment } from '../../../environments/environment';
import { langExists } from '../../services/hl-product.service';
import { portals } from '../../portal-flags';

@Component({
  selector: 'app-hl-display-product-card-list',
  templateUrl: './hl-display-product-card-list.component.html',
  styleUrls: ['./hl-display-product-card-list.component.scss']
})
export class HlDisplayProductCardListComponent extends ProductCardComponent implements OnInit {
  showFavourites = environment.showFavourites;
  price: CustomerProductPrice;
  private customerId: string;
  isUkBolag = environment.bolag === '1710';
  isSalesDePortal = environment.sales && environment.mainCountry === 'de';
  isPortalNo = environment.mainCountry === 'no';

  isSales = environment.sales;
  hasArticlesOutsidePriceLists = environment.getArticlesOutsidePriceLists || false;

  currentLang = this.translateService.currentLang;
  productSize: string;

  availability: Availability;

  isCoopPortal = environment.mainCustomer === 'coop';
  isManagersNisa = environment.managers && environment.mainCountry === 'en';

  isKeskoCustomer =
    !environment.sales &&
    environment.mainCountry === 'fi' &&
    environment.bolag === '1210' &&
    environment.mainCustomer === 'General';
  public isCloettaPortal = portals.isCloettaPortal;
  public isCurrysPortal =
    environment.bolag === '1710' &&
    !environment.sales &&
    !environment.managers &&
    environment.mainCountry === 'en' &&
    environment.mainCustomer === '';

  currentCustomer: Customer;

  constructor(
    private priceService: PriceService,
    cartService: CartService,
    protected metadataService: MetadataService,
    protected customerService: SelectedCustomerService,
    protected translateService: TranslateService
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.priceService
      .getCurrentCustomerPrice(this.data.id)
      .pipe(first())
      .subscribe(price => {
        this.price = price;
      });
    this.customerService
      .getSelectedCustomer()
      .pipe(first())
      .subscribe(cust => {
        this.customerId = cust.id;
        this.currentCustomer = cust;
        if (this.isCurrysPortal) {
          const customerId = this.currentCustomer?.id.includes('@') ? this.currentCustomer.id.slice(0, this.currentCustomer.id.indexOf('@')) : this.currentCustomer?.id;
          this.data.extra._kuar = this.data?.extra.kuar?.find(kuar => kuar.customerId === customerId)?.data?.artnrkund;
        }
        if (this.isCloettaPortal) {
          this.data.extra._kuar = this.currentCustomer?.extra.kuarMapped?.[this.data.id]?.artnrkund;
        }
      });

    this.productSize = this.getProductSize(this.data);
    this.availability = this.getProductAvailability(this.data);
  }

  private getProductAvailability(product: Product): Availability {
    if (product.extra.availabilities) {
      const availabilities = Object.keys(product.extra.availabilities).map(key => product.extra.availabilities[key]);
      if (availabilities.length > 0) {
        return availabilities[0];
      }
    }

    return null;
  }

  getProductSize(product: Product): string {
    let productSize: string;
    if (product.extra.hlPimItem.Size && product.extra.hlPimItem.Size !== '-') {
      productSize = product.extra.hlPimItem.Size;
    } else if (
      product.extra.hlPimProduct.ProductExternalCategory === 'PriceCommunication' &&
      product.extra.hlPimItem.LabelHeightmm
    ) {
      productSize = '';
      if (product.extra.hlPimItem.LabelHeightmm && product.extra.hlPimItem.LabelHeightmm !== '-') {
        productSize += `H:${product.extra.hlPimItem.LabelHeightmm} mm`;
      }
      if (product.extra.hlPimItem.Widthmm && product.extra.hlPimItem.Widthmm !== '-') {
        if (productSize) {
          productSize += ` x `;
        }
        productSize += `W:${product.extra.hlPimItem.Widthmm}mm`;
      }
    } else {
      productSize = '';
      if (product.extra.hlPimItem.Widthmm && product.extra.hlPimItem.Widthmm !== '-') {
        productSize += `W:${product.extra.hlPimItem.Widthmm}mm`;
      }
      if (product.extra.hlPimItem.Depthmm && product.extra.hlPimItem.Depthmm !== '-') {
        if (productSize) {
          productSize += ` x `;
        }
        productSize += `D:${product.extra.hlPimItem.Depthmm}mm`;
      }
      if (product.extra.hlPimItem.Heightmm && product.extra.hlPimItem.Heightmm !== '-') {
        if (productSize) {
          productSize += ` x `;
        }
        productSize += `H:${product.extra.hlPimItem.Heightmm}mm`;
      }
    }

    return productSize;
  }

  getName(): string {
    if (!this.isSales) {
      return this.data.name;
    }

    const id = this.data.id;

    // const itemGroup = this.metadataService.getMetadataValue('vg', 'varugruppbeskr', this.data.extra.ar.varugruppkod);
    const itemProductSystem = this.data.extra.hlPimProduct?.ProductSystem;
    let itemDesc = this.data.extra.ar.artbeskr;
    if (langExists(environment.mainCountry)(this.data)) {
      itemDesc = this.data.extra.i18n[environment.mainCountry].artbeskr || itemDesc;
    }
    return `${id}${itemProductSystem ? ' - ' + itemProductSystem : ''} - ${itemDesc}`;
  }

  getKuarDesc(): string {
    if (!this.data.extra.kuar) {
      return '';
    }
    const kuar = this.data.extra.kuar.find(row => row.data.ftgnr === this.customerId);
    if (!kuar) {
      return '';
    }
    return `${kuar.data.artbeskr2 || ''} (${kuar.data.artnrkund})`;
  }

  getDescription(): string {
    if (environment.bolag === '1710') {
      const extraDesc = this.getKuarDesc();
      return this.data.description + extraDesc;
    }
    if (!this.isSales) {
      return this.data.description;
    }

    let desc1 = '';
    if (langExists(environment.mainCountry)(this.data)) {
      desc1 = this.data.extra.i18n[environment.mainCountry].artbeskrspec || desc1;
    }

    const desc2 = this.data.extra.ar.artbeskrspec;
    return `${desc1} (${desc2})`;
  }

  getSecondaryDescription() {
    const artbeskr2 = this.data.extra.i18n[this.currentLang].artbeskr2;
    const isFound = !!artbeskr2 && !!this.isSalesDePortal;

    return isFound ? artbeskr2 : '';
  }

  getMinimumOrderQuantity(extra: any): number {
    if (!this.isSales) {
      return extra.stepAmount;
    }

    return extra.minimumOrderQuantity;
  }

  getAssortmentType(extra: any): string {
    if (extra.assortmentType === 'C') {
      return '';
    }

    return '(' + extra.assortmentType + ')';
  }
}
