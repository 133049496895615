import { Component } from '@angular/core';
import {
  OrderDetailsComponent,
  AuthService,
  Order,
  ProductService,
  gungAddRemoveSpinner,
  GungModalService,
  CustomerService,
  BaseViewConfigService,
  ProductAssortmentParentService
} from 'gung-standard';
import { CommonModalService, DateUtilService } from 'gung-common';
import { ActivatedRoute } from '@angular/router';
import { HlBackendInterceptorService } from '../../services/hl-backend-interceptor/hl-backend-interceptor.service';
import { OrderRow } from 'gung-standard';
import { first } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../environments/environment';
import { HlDisplayOrderAddRowModalComponent } from '../hl-display-order-add-row-modal/hl-display-order-add-row-modal.component';
import { HlDisplayOrderActionConfigService } from '../../services/hl-display-order-action-config.service';
import { HlDisplayOrderService } from '../../services/hl-display-order.service';
import { TranslateService } from '@ngx-translate/core';

export enum AddressInfoVersion {
  Default = 'DEFAULT',
  PointOfDelivery = 'POD',
  Delivery = 'DELIVERY',
}

@Component({
  selector: 'app-hl-display-order-details',
  templateUrl: './hl-display-order-details.component.html',
  styleUrls: ['./hl-display-order-details.component.scss']
})
export class HlDisplayOrderDetailsComponent extends OrderDetailsComponent {
  isSalesPortal = environment.sales;
  isPortalNo = environment.mainCountry === 'no';
  is1210Bolag = environment.bolag === '1210';
  isSalesDePortal = environment.sales && environment.mainCountry === 'de';
  public isManagersPortal = environment.managers;

  activeIds = ['panel-1', 'panel-3'];

  isCoopPortal = environment.mainCustomer === 'coop';
  isManagersNisa = environment.managers && environment.mainCountry === 'en';
  isChCoopPortal =
    environment.mainCustomer === 'coop' && environment.bolag === '2400' && environment.mainCountry === 'ch'; //use pod (point of delivery)

  public isPortalBenelux =
    environment.mainCountry === 'fr' && environment.quoteConfirmationEmailAddress === 'OrdersBenelux@hl-display.com';

  public isCurrysPortal =
    environment.bolag === '1710' &&
    !environment.sales &&
    !environment.managers &&
    environment.mainCountry === 'en' &&
    environment.mainCustomer === '';

  addressInfoDisplayVersion: AddressInfoVersion = AddressInfoVersion.Default;

  constructor(
    protected route: ActivatedRoute,
    protected orderService: HlDisplayOrderService,
    protected authService: AuthService,
    protected hlBackendInterceptor: HlBackendInterceptorService,
    protected modalService: NgbModal,
    public configService: HlDisplayOrderActionConfigService,
    protected productService: ProductService,
    public dateUtilService: DateUtilService,
    protected baseViewConfigService: BaseViewConfigService,
    protected productAssortmentParentService: ProductAssortmentParentService,
    protected translateService: TranslateService,
    protected gungModalService: GungModalService,
    protected customerService: CustomerService,
    protected commonModalService: CommonModalService
  ) {
    super(
      route,
      orderService,
      authService,
      hlBackendInterceptor,
      productService,
      dateUtilService,
      baseViewConfigService,
      productAssortmentParentService,
      commonModalService
    );
  }

  protected mapOrder(order: Order): Order {
    order.rows = order.rows.filter(row => row.extra.orp.ordradnrstrpos === 0);
    if (this.isSalesDePortal && order.extra.oh.ordlevplats1) {
      this.customerService
        .getCustomer(order.deliveryCustomerId + '@' + order.extra.oh.ordlevplats1)
        .pipe(first())
        .subscribe(customer => {
          order.extra._deliveryCustomerName = customer?.name;
        });
    }
    if (this.isSalesDePortal) {
      order.rows = order.rows.filter(row => row.id !== '900000');
    }
    this.getAddressInfoDisplayVersion(order);
    return order;
  }

  getTotal(rows: OrderRow[]): number {
    const valueArray = rows.map(row => row.extra.orp.vbordradsum as number);
    const sum = valueArray.reduce((acc, next) => acc + next, 0);
    return sum;
  }

  addRow() {
    const modalRef = this.modalService.open(HlDisplayOrderAddRowModalComponent, { size: 'lg', backdrop: 'static' });
    modalRef.componentInstance.order = this.order;
  }

  saveOrderMessage(element) {
    gungAddRemoveSpinner(element.target);
    let oh = { oh: { editext: this.order.extra.oh.editext } };
    if (this.isSalesPortal) {
      oh = { oh: { edit: this.order.extra.oh.edit } } as any;
    }

    this.orderService.updateOrderHeader({ id: this.order.id, extra: oh } as any).subscribe(
      result => {
        this.gungModalService
          .openConfirmYesNoModal(
            undefined,
            this.translateService.instant('CHANGE_SAVED_SUCCESS'),
            { size: 'sm' },
            'OK',
            null
          )
          .then(_ => { });
      },
      error => {
        gungAddRemoveSpinner(element.target);
        this.gungModalService
          .openConfirmYesNoModal(
            undefined,
            this.translateService.instant('AN_ERROR_HAS_OCCURED'),
            { size: 'sm' },
            'OK',
            null
          )
          .then(_ => { });
      },
      () => {
        gungAddRemoveSpinner(element.target);
      }
    );
  }

  protected getAddressInfoDisplayVersion(order: Order): void {
    if (!!order?.extra?.pod && this.isCoopPortal) {
      this.addressInfoDisplayVersion = AddressInfoVersion.PointOfDelivery;
    } else if (this.isManagersNisa || this.isPortalBenelux) {
      this.addressInfoDisplayVersion = AddressInfoVersion.Delivery;
    } else {
      this.addressInfoDisplayVersion = AddressInfoVersion.Default;
    }
  }
}
