import { Component, Optional } from '@angular/core';
import { DateUtilService } from 'gung-common';
import { AbstractSelectionService, ListItemRendererComponent } from 'gung-list';
import { Bankgiro } from '../../models/bankgiro';
import { BankgiroSelectionService } from '../../services/bankgiro-selection.service';

import { GungCurrencyPipe } from '../../../../pipes/gung-currency.pipe';

@Component({
  selector: 'lib-bankgiro-list-view',
  templateUrl: './bankgiro-list-view.component.html',
  styleUrls: ['./bankgiro-list-view.component.css']
})
export class BankgiroListViewComponent extends ListItemRendererComponent<Bankgiro[]> {
  constructor(public dateUtilService: DateUtilService, public gungCurrencyPipe: GungCurrencyPipe) {
    super();
  }
}
