<div class="container">
  <div class="row">
    <div class="col-12">
      <lib-gung-tabset [destroyOnHide]="false" class="user-details-tabs" *ngIf="currentUser">
        <lib-gung-tab [title]="'USER' | translate">
          <ng-template libGungTabContent>
            <lib-user-details-user [currentUser]="currentUser" (validPasswordChanged)="onValidPasswordChanged($event)"></lib-user-details-user>
          </ng-template>
        </lib-gung-tab>

        <lib-gung-tab [title]="'CUSTOMERS' | translate">
          <ng-template libGungTabContent>
            <lib-user-details-customers [currentUser]="currentUser"></lib-user-details-customers>
          </ng-template>
        </lib-gung-tab>

        <lib-gung-tab [title]="'SUBASSORTMENT' | translate">
          <ng-template libGungTabContent>
            <lib-user-details-subassortment [currentUser]="currentUser"></lib-user-details-subassortment>
          </ng-template>
        </lib-gung-tab>

        <!-- CONTENT MOVED TO <lib-user-details-user></lib-user-details-user> -->
        <!--<lib-gung-tab [title]="'MULTI_STOCKS' | translate">
          <ng-template libGungTabContent>
            <lib-user-details-multistocks [currentUser]="currentUser"></lib-user-details-multistocks>
          </ng-template>
        </lib-gung-tab>

        <lib-gung-tab [title]="'ROLES' | translate">
          <ng-template libGungTabContent>
            <lib-user-details-roles [currentUser]="currentUser"></lib-user-details-roles>
          </ng-template>
        </lib-gung-tab>-->

        <lib-gung-tab [title]="'ORDER_PARAMS' | translate">
          <ng-template libGungTabContent>
            <lib-user-details-order-params [currentUser]="currentUser"></lib-user-details-order-params>
          </ng-template>
        </lib-gung-tab>

        <!-- CONTENT MOVED TO <lib-user-details-user></lib-user-details-user> -->
        <!--<lib-gung-tab [title]="'GROUPS' | translate">
          <ng-template libGungTabContent>
            <lib-user-details-groups [currentUser]="currentUser"></lib-user-details-groups>
          </ng-template>
        </lib-gung-tab>-->

        <lib-gung-tab [title]="'SUPPLIERS' | translate">
          <ng-template libGungTabContent>
            <lib-user-details-managed-supplier [currentUser]="currentUser"></lib-user-details-managed-supplier>
          </ng-template>
        </lib-gung-tab>
      </lib-gung-tabset>
    </div>
  </div>
  <div class="text-center my-2" *ngIf="showError">
    {{ errorMessage }}
  </div>
  <div class="row justify-content-end pt-4">
    <div class="col-12">
      <div class="btn-group btn-group-justified btn-block">
        <button type="button" class="btn btn-sm btn-warning" (click)="cancelChanges()">
          {{ 'CANCEL' | translate }}
        </button>
        <button type="button" class="btn btn-sm btn-primary" (click)="saveCurrentUser()">
          {{ 'SAVE_CHANGES' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
