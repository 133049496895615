<div class="row">
    <div class="col-12 col-lg-6">
        <h5 translate>FLOW_MAPPING_PRICE</h5>
        <table class="table table-striped">
            <tr>
                <th translate>FLOW</th>
                <th translate>PRICE_LIST</th>
            </tr>
            <tr *ngFor="let flow of flows">
                <td>{{flow.id}} - {{flow.name}}</td>
                <td>
                    <div class="form-group" >
                        <select class="form-control" [(ngModel)]="mappedFlowPricelist[flow.id]">
                            <option [value]=""></option>
                            <option [value]="pricelist.id" *ngFor="let pricelist of pricelists"><span></span>{{pricelist.id}} - {{pricelist.name}}</option>
                          </select>
                    </div>
                </td>
            </tr>
        </table>

    </div>
    <div class="col-12 col-lg-6 text-right">
        <span *ngIf="saved"><span translate class="pb-2 d-inline-block">CUSTOMER_UPDATED_REFRESHING</span><br></span>
        <button class="btn btn-primary d-inline-block" translate (click)="save($event)">SAVE</button>
    </div>
</div>