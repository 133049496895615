<div class="row">
  <div *ngFor="let digitalAsset of data" class="col-12 col-sm-6 col-md-4 col-lg-3 div-card">
    <div
      class="card border h-100 middle inside-center pointer bg-secondary"
      [routerLink]="[digitalAsset.extra.flow ? 'gungflow-' + digitalAsset.id : digitalAsset.id]"
    >
      <div
        *ngIf="isManagement && digitalAsset.id !== 'product-images' && !digitalAsset.extra?.flow"
        class="position-absolute remove"
      >
        <button
          type="button"
          class="btn btn-success rounded-circle mr-1"
          (click)="$event.preventDefault(); $event.stopPropagation(); open(content, digitalAsset)"
        >
          <i class="fas fa-pen"></i>
        </button>
        <button
          type="button"
          class="btn btn-danger rounded-circle"
          (click)="$event.preventDefault(); $event.stopPropagation(); onDelete(digitalAsset)"
        >
          <i class="fas fa-times"></i>
        </button>
      </div>
      <img
        *ngIf="digitalAsset.images && digitalAsset.images[0] as image; else noimage"
        class="card-img-top img-fluid w-100 inside-center-image rounded-circle p-3 mh-100"
        [src]="image.s3Uri | gungImageUrl
          :'type:list'
          :'etag:'+image.s3ETag
        "
        alt="{{ image.description }}"
        class="img-fluid mx-auto d-block"
      />
      <ng-template #noimage>
        <img
          class="card-img-top img-fluid w-100 inside-center-image rounded-circle p-3 mh-100"
          [src]="'images/no-image.jpg' | gungImageUrl"
          alt="NO IMAGE"
          class="img-fluid mx-auto d-block"
        />
      </ng-template>
      <div class="card-body inside-center-text image-text w-100 text-uppercase">
        <div class="inside-content text-center">
          <i class="far fa-folders" *ngIf="digitalAsset.extra.content === 'folder'"></i>
          <i class="fal fa-images" *ngIf="digitalAsset.extra.content === 'digitalAsset'"></i>
        </div>
        <h5 class="card-title text-center mt-2">{{ digitalAsset.name }}</h5>
        <!-- <p class="card-text">{{digitalAsset.id}}</p> -->
        <!-- <button *ngIf="isManagement" type="button" class="btn btn-primary w-100" [routerLink]="[digitalAsset.id]" translate>DIGITAL_ASSETS_MANAGE</button> -->
      </div>
    </div>
  </div>
  <div *ngIf="isManagement" class="col-12 col-sm-6 col-md-4 col-lg-3 add-new-folder div-card">
    <div class="card border h-100 middle inside-center pointer bg-light" (click)="open(content)">
      <div class="card-body inside-center-text image-text w-100 text-uppercase">
        <h5 class="card-title text-center">
          <button type="button" class="btn btn-link"><i class="fas fa-plus-circle fa-2x"></i></button>
        </h5>
      </div>
    </div>
  </div>
</div>

<ng-template #content let-modal>
  <form [formGroup]="form">
    <div class="modal-header">
      <h4 class="modal-title m-0" id="modal-basic-title" translate>{{ digitalAsset ? 'ADD' : 'EDIT' }}</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-body">
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label class="" for="FOLDER_NAME" translate>FOLDER_NAME</label>
            <input formControlName="name" #id class="form-control" type="text" id="FOLDER_NAME" name="FOLDER_NAME" />
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <label class="w-100" for="file-upload" translate>FOLDER_COVER_IMAGE</label>
            <div class="input-group">
              <div class="flex-fill">
                <input
                  [value]="
                    digitalAsset && form.get('image').value === '' ? ('CHANGE' | translate) : form.get('image').value
                  "
                  class="form-control bg-transparent"
                  type="text"
                  (click)="inputFile.click()"
                  readonly
                />
                <input
                  #inputFile
                  formControlName="image"
                  type="file"
                  class="_custom-file-input form-control d-none"
                  placeholder="File Upload"
                  id="file-upload"
                  aria-label="File Upload"
                  aria-describedby="file-upload"
                  accept=".jpg, .jpeg, .png"
                  [multiple]="false"
                />
              </div>
              <div class="input-group-append" (click)="inputFile.click()">
                <span class="input-group-text" id="file-upload"><i class="fas fa-folder"></i></span>
              </div>
            </div>
          </div>
          <div class="w-100 text-center" *ngIf="digitalAsset && form.get('image').value === ''">
            <img
              *ngIf="digitalAsset.images && digitalAsset.images[0] as image"
              class="card-img-top img-fluid w-100 inside-center-image rounded-circle p-3 mh-100"
              [src]="image.s3Uri | gungImageUrl
                :'type:list'
                :'etag:'+image.s3ETag
              "
              alt="{{ image.description }}"
              class="img-fluid mx-auto d-block"
            />
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <label for="exampleFormControlSelect1" translate>CONTENT_TYPE</label>
            <select formControlName="content" class="form-control" id="exampleFormControlSelect1">
              <option value="folder" translate>FOLDERS</option>
              <option value="digitalAsset" translate>DIGITAL_ASSETS</option>
            </select>
          </div>
        </div>
        <!-- <div class="col-12">
          <div class="form-group">
            <label class="" for="ROLES" translate>ROLES</label>
            <input formControlName="roles" #id class="form-control" type="text" id="ROLES" name="ROLES">
          </div>
        </div> -->
        <div class="col-12">
          <lib-gung-input-multi-select
            *ngIf="userGroups"
            label="GROUPS"
            [optionsList]="userGroups"
            (selectedValues)="setSelectedValues($event)"
          ></lib-gung-input-multi-select>
        </div>
        <div class="col-12">
          <lib-gung-number-input
            [gungTranslate]="'SORTING_PRIORITY'"
            [formControl]="form.get('sequence')"
          ></lib-gung-number-input>
        </div>
      </div>
    </div>

    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-light mr-auto"
        aria-label="Close"
        (click)="modal.close()"
        [disabled]="isSubmitted ? true : null"
        translate
      >
        CANCEL
      </button>
      <button
        *ngIf="!digitalAsset"
        (click)="onSubmit(inputFile, modal, $event)"
        class="btn btn-primary ml-auto"
        [disabled]="isSubmitted"
        translate
      >
        CREATE
      </button>
      <button
        *ngIf="digitalAsset"
        (click)="onSubmit(inputFile, modal, $event)"
        class="btn btn-primary ml-auto"
        [disabled]="isSubmitted"
        translate
      >
        UPDATE
      </button>
    </div>
  </form>
</ng-template>
