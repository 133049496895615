import { Component, OnInit } from '@angular/core';
import { ListItemRendererComponent } from 'gung-list';
import { GungFlow } from '../../../state/flow/types';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';

@Component({
  selector: 'lib-gung-flows-table',
  templateUrl: './gung-flows-table.component.html',
  styleUrls: ['./gung-flows-table.component.css']
})
export class GungFlowsTableComponent extends ListItemRendererComponent<GungFlow[]> implements OnInit {
  constructor(public router: Router, protected modalService: NgbModal) {
    super();
  }

  public ngOnInit() {}

  showDetails(flowId): void {
    this.router.navigate(['/admin/gung-flow-details/' + flowId]);
  }
}
