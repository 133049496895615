<div class="card">
  <div class="card-body">
    {{ data.id }} - {{ data.name }}
    <div class="btn-group float-right">
      <a class="btn btn-secondary" routerLink="/customers/{{ data.id }}" translate>MORE_INFO</a>
      <button class="btn btn-primary" (click)="selectCustomerService.selectCustomer(data.id)" translate>
        SELECT_CUSTOMER
      </button>
    </div>
  </div>
</div>
