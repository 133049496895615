import { Inject, Injectable, Optional, Type } from '@angular/core';
import { Observable, of, forkJoin, throwError, catchError } from 'rxjs';
import { first, map, filter, mergeMap, switchMap } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { SelectedCustomerService } from '../selected-customer/selected-customer.service';
import { Router } from '@angular/router';
import { GungModalService } from '../gung-modal/gung-modal.service';
import { GungFlowService } from '../gung-flow/gung-flow.service';
import { CartService } from '../cart/cart.service';
import { CustomerService } from '../customers/customer.service';
import { Customer, Document } from '../../models';
import { NewsActionConfigService } from '../news/news-action-config/news-action-config.service';
import { BackendInterceptor } from '../backend-interceptor/backend-interceptor.service';
import { DocumentsService } from '../documents/documents.service';
import { PriceConfigService } from '../price-config/price-config.service';
import { Location } from '@angular/common';
import { BaseViewConfigService, ProductViewType } from '../base-view-config/base-view-config.service';
import { GungCompanyService } from '../gung-company/gung-company.service';
import { GungFlow } from '../../state/flow/types';
import { NavigationConfig } from '../../models/navigation-config';
import { AssortmentService } from './../assortment.service';
import { LocationConfigService } from '../location-config/location-config.service';
import { SelectedSupplierService } from '../selected-supplier/selected-supplier.service';
import { MetadataService } from '../metadata/metadata.service';
import { gungComparatorHelper } from '../../utils/gung-utils';
import { BackendFeatureService, GungFeatureMap } from '../backend-feature.service';
import { ConfigurationsService } from '../configurations.service';

interface NavBase {
  heading: string;
  icon?: string;
  class?: string;
}
export interface NavLink extends NavBase {
  link: string;
  queryParams?: {
    [key: string]: string;
  };
  queryParamsHandling?: string;
  /**
   * @deprecated you should use queryParams instead of params
   * @quickfix 'queryParams'
   */
  params?: {
    [key: string]: string;
  };
}

export interface NavAction extends NavBase {
  action(): void;
}

export interface NavGroup extends NavBase {
  link?: string;
  links?: (NavLink | NavAction | NavGroup)[];
  queryParams?: {
    [key: string]: string;
  };
  queryParamsHandling?: string;
  action?(event?): void;
}

export interface UserAndRole {
  name: string;
  role: string;
  link?: string;
  hide?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class NavbarConfigService {
  devMode = localStorage.getItem('dev') || false;
  public sideMenuExpanded = false; // Show side menu open on start

  enableDigitalAssets = this.environment?.enableDigitalAssets || false;
  enableBarcodeScanner = false;
  enableAccountRequestManage = false;
  enablePimMetadata = false;
  enablePimDimensions = false;
  enableBrandsManage = false;
  enableDocumentUserGroups = false;
  enableDocumentsOnLeftMenu = false;
  documentsOnLeftMenuLinkHeading = 'DOCUMENTS';
  enableActivityInternal = false;
  enableDiscountCodesManage = false;
  enableShippingChargesManage = false;
  enableWhiteboardManagement = false;
  enablePurchaseOrders = false;
  enableMyCompany = true;
  enableContacts = false;
  enableExportPdf = this.environment?.enableExportPdf || false;
  enableOffers = this.environment?.enableOffers || false;
  enableWarrantyClaims =
    this.environment?.enableWarrantyClaims !== undefined ? this.environment?.enableWarrantyClaims : false;
  enableCustomerActivities = this.environment?.enableCustomerActivities || false;
  isAssortmentBased = !!this.environment?.isAssortmentBasedNavigation || false;
  enableJeevesClaims = this.environment?.enableJeevesClaims || false;
  enableGlobalSearch = this.environment?.enableGlobalSearch || false; // Garp
  enableStatisticsExcelExport = this.environment?.enableStatisticsExcelExport || false;
  enableCatalogues = this.environment?.enableCatalogues || false;
  enableBulkScheduleOrder = this.environment?.enableBulkScheduleOrders || false;
  enablePimVariants = this.environment?.enablePimVariants || false;
  enableNavbarGlobalSearch = this.environment?.enableNavbarGlobalSearch || false;

  pimExternalUrl: string = this.environment?.pimExternalUrl || undefined;
  public showFlowsAsDropdown = true;
  public hideNavbar = true;

  isEnabledShowUserNewsInPage = false; // set by service newsActionConfigService
  documents: Document[];

  logoUrl = './assets/logo.png' || 'https://demo-api.gung.io/images/logo.png';
  logoLoginUrl = this.logoUrl;
  backgroundLogin: string;
  logoLink = '/';
  currentLang = this.translateService.currentLang || 'se';
  roles: string[];

  constructor(
    protected authService: AuthService,
    protected gungFlowService: GungFlowService,
    protected translateService: TranslateService,
    protected selectedCustomerService: SelectedCustomerService,
    protected router: Router,
    protected gungModalService: GungModalService,
    protected cartService: CartService,
    protected customerService: CustomerService,
    protected newsActionConfigService: NewsActionConfigService,
    protected backendInterceptor: BackendInterceptor,
    protected documentsService: DocumentsService,
    protected priceConfigService: PriceConfigService,
    protected location: Location,
    protected baseViewConfigService: BaseViewConfigService,
    protected gungCompanyService: GungCompanyService,
    protected assortmentService: AssortmentService,
    @Optional()
    @Inject('environment')
    protected environment: NavigationConfig,
    protected locationConfigService: LocationConfigService,
    protected metadataService: MetadataService,
    protected selectedSupplierService: SelectedSupplierService,
    protected backendFeatureService: BackendFeatureService
  ) {}

  protected checkEnabledFeatures(features: GungFeatureMap) {
    this.enableStatisticsExcelExport =
      this.enableStatisticsExcelExport || this.hasFeatureWithVersion(features, 'reportCentral', undefined);
  }

  public getLogoUrl() {
    return this.logoUrl;
  }

  public getLogoUrlObservable(): Observable<string> {
    // return of(this.getLogoUrl());
    return this.gungCompanyService.getLogoUrlObservable().pipe(
      map(loginBackgroundImage => {
        return loginBackgroundImage || this.getLogoUrl();
      })
    );
  }

  public getLogoLoginUrl() {
    return this.logoLoginUrl || this.logoUrl;
  }

  public getLogoLoginUrlObservable(): Observable<string> {
    // return of(this.getLogoLoginUrl());
    return this.gungCompanyService.getLogoLoginUrlObservable().pipe(
      map(loginLogo => {
        return loginLogo || this.getLogoLoginUrl();
      })
    );
  }

  public getBackgroundLoginUrl() {
    return this.backgroundLogin;
  }

  public getBackgroundLoginUrlObservable(): Observable<string> {
    // return of(this.getBackgroundLoginUrl());
    return this.gungCompanyService.getBackgroundLoginUrlObservable().pipe(
      map(loginBackgroundImage => {
        return loginBackgroundImage || this.getBackgroundLoginUrl();
      })
    );
  }

  logout() {
    this.location.go('/');
    this.authService.logout();
  }

  selectFlow(flowId: string): void {
    forkJoin([this.gungFlowService.getSelectedFlow().pipe(first()), this.cartService.getCurrentCart().pipe(first())])
      .pipe(first())
      .subscribe(([flow, cart]) => {
        if (flow.id === flowId) {
          return;
        }
        if (cart.length === 0) {
          this.gungFlowService.selectFlow(flowId);
          return;
        }
        this.gungModalService
          .openConfirmYesNoModal('FLOW_CHANGE', 'FLOW_CHANGE_CLEAR_CART_CONFIRM', {}, 'OK', 'CANCEL')
          .then(
            result => {
              if (result) {
                this.gungFlowService.selectFlow(flowId);
              }
            },
            reason => undefined
          );
      });
  }

  userRoles(): Observable<string[]> {
    return this.authService.getRoles().pipe(filter(roles => !!roles));
  }

  userActiveGroups(): Observable<string[]> {
    return this.authService.getUserActiveGroups().pipe(filter(groups => !!groups));
  }

  protected getUserMenu(
    customer: Customer,
    isSales?: boolean,
    customers?: number,
    isActuator?: boolean,
    features?: GungFeatureMap
  ): (NavLink | NavAction | NavGroup)[] {
    const menuLinks: NavGroup[] = [
      {
        heading: '',
        links: [
          {
            link: `${'/customers/' + customer?.id}`,
            heading: 'MY_PAGES'
            // icon: 'fa-user'
          },
          {
            link: '/delivery-location',
            heading: 'DELIVERY_LOCATION'
            // icon: 'fa-folder-open'
          },
          {
            link: '/orders',
            heading: 'PREVIOUS_ORDERS'
            // icon: 'fa-folder-open'
          },
          {
            link: '/products-export',
            heading: 'PRODUCT_EXPORT_NAV_BAR'
            // icon: 'fa-file-download'
          },
          {
            link: '/carts',
            heading: 'SAVED_CARTS'
          },
          {
            link: '/saved-quotes',
            heading: 'SAVED_QUOTES'
          },
          {
            link: '/favourites',
            heading: 'FAVOURITES'
          }
        ]
      }
    ];

    if (!isSales) {
      // Remove SAVED_QUOTES if not SALES
      menuLinks[0].links.splice(
        (menuLinks[0] as NavGroup).links.findIndex(m => m.heading === 'SAVED_QUOTES'),
        1
      );
    } else {
      menuLinks[0].links.splice(
        (menuLinks[0] as NavGroup).links.findIndex(m => m.heading === 'MY_PAGES'),
        1
      );
      menuLinks[0].links.splice(
        (menuLinks[0] as NavGroup).links.findIndex(m => m.heading === 'DELIVERY_LOCATION'),
        1
      );
    }

    if (customers < 2 && (menuLinks[0] as NavGroup).links.findIndex(m => m.heading === 'DELIVERY_LOCATION') > -1) {
      // Remove DELIVERY_LOCATION if only one delivery location
      menuLinks[0].links.splice(
        (menuLinks[0] as NavGroup).links.findIndex(m => m.heading === 'DELIVERY_LOCATION'),
        1
      );
    }

    let favouritesIndex = (menuLinks[0] as NavGroup).links.findIndex(m => m.heading === 'FAVOURITES');
    if (this.enableDigitalAssets || this.hasFeatureWithVersion(features, 'gung-feature-digitalassets', undefined)) {
      // Add digital assets to the project
      (menuLinks[0] as NavGroup).links.splice(++favouritesIndex, 0, {
        link: '/digital-assets',
        heading: 'DIGITAL_ASSETS'
      });
    }

    if (
      this.enableActivityInternal || // Legacy way to activate the feature
      isSales // We want to add it to menu if we are sales, even though it might not be activated. Activation check
      // done in next step
    ) {
      // Add activity internal to the project
      (menuLinks[0] as NavGroup).links.splice(++favouritesIndex, 0, {
        link: '/activity-internal',
        heading: 'ACTIVITIES'
      });
    }

    if (this.isEnabledShowUserNewsInPage) {
      (menuLinks[0] as NavGroup).links.push({ link: '/news', heading: 'NEWS' });
    }

    if (
      this.enableBarcodeScanner || // legacy
      isSales || // We want to add it to menu if we are sales, even though it might not be activated. Activation check done in next step.
      this.hasFeatureWithVersion(features, 'gung-barcode-scanner', undefined)
    ) {
      (menuLinks[0] as NavGroup).links.push({ link: '/barcode-scanner', heading: 'BARCODE_SCANNER' });
    }

    // LEGACY WHITEBOARD; NOT THE ONE THAT IS USED AMONG OUR CUSTOMERS.
    if (this.enableWhiteboardManagement) {
      (menuLinks[0] as NavGroup).links.push({ link: '/whiteboard-management', heading: 'WHITEBOARD' });
    }

    if (
      this.enableExportPdf || // legacy
      isSales // We want to add it to menu if we are sales, even though it might not be activated. Activation check
      // done in next step
    ) {
      (menuLinks[0] as NavGroup).links.push({
        link: '/export-pdf',
        heading: 'WHITEBOARD',
        action: () => this.router.navigate(['export-pdf'], { queryParams: { filters: 'ARCHIVED__:__NO' } })
      });
    }

    if (this.enableDocumentUserGroups) {
      this.documents.forEach(doc => {
        const url = this.backendInterceptor.getBaseUrl() + '/gung-document-archive/document/' + doc.id;
        menuLinks[0].links.push({
          heading:
            doc.metaData.description && doc.metaData.description !== 'undefined'
              ? doc.metaData.description
              : doc.filename,
          action: () => {
            window.open(url, '_blank');
          }
        });
      });
    }

    if (
      this.hasFeatureWithVersion(features, 'reportCentral', undefined) &&
      this.featureVisibleForRole(features, 'reportCentral', 'USER')
    ) {
      menuLinks[0].links.push({
        link: '/report-central',
        heading: 'REPORT_CENTRAL'
      });
    }

    if (this.enableBulkScheduleOrder) {
      menuLinks[0].links.push({
        link: '/bulk-order',
        heading: 'SCHEDULE_ORDERS'
      });
    }

    return menuLinks;
  }

  protected hasFeatureWithVersion(features: GungFeatureMap, featureId: string, version?: string) {
    // We don't have any features
    if (!features) {
      return false;
    }
    // We don't have the specified feature
    if (!features[featureId]) {
      return false;
    }
    // We don't care about the version if not given
    if (!version) {
      return true;
    }

    // We need to version to match
    return features[featureId].version === version;
  }

  protected featureVisibleForRole(features: GungFeatureMap, featureId: string, role: string) {
    if (!this.hasFeatureWithVersion(features, featureId, undefined)) {
      return false;
    }
    return (features[featureId].visibleForRoles || []).indexOf(role) >= 0;
  }

  protected getSalesMenu(
    isActuator?: boolean,
    roles?: string[],
    salesCodeErp?: string,
    features?: GungFeatureMap
  ): (NavLink | NavAction | NavGroup)[] {
    const menuLinks = [
      {
        heading: '',
        links: [
          {
            link: '/customers',
            heading: 'MY_CUSTOMERS',
            action: salesCodeErp
              ? () => this.router.navigate(['customers'], { queryParams: { filters: 'Sales Rep__:__' + salesCodeErp } })
              : null
            // icon: 'fa-user'
          },
          {
            link: '/open-orders',
            heading: 'OPEN_ORDERS'
            // icon: 'fa-folder-open'
          },
          {
            link: '/invoices',
            heading: 'INVOICES'
            // icon: 'fa-file-invoice-dollar'
          },

          {
            link: '/sales-dashboard',
            heading: 'SALES_DASHBOARD'
            // icon: 'fa-chart-line'
          },
          {
            link: '/admin/import-linesheet',
            heading: 'IMPORT_LINESHEET'
          },
          {
            link: '/admin/upload-items',
            heading: 'UPLOAD_EXCEL'
          },
          {
            link: '/quickorder',
            heading: 'QUICK_ORDER'
          }
        ]
      }
    ];
    if (this.enableOffers) {
      menuLinks[0].links.splice(
        (menuLinks[0] as NavGroup).links.findIndex(m => m.heading === 'OPEN_ORDERS'),
        0,
        { link: '/offers', heading: 'OFFERS' }
      );
    }

    if (this.hasFeatureWithVersion(features, 'gung-replenishment', undefined)) {
      menuLinks[0].links.push({
        link: '/replenishment',
        heading: 'REPLENISHMENT'
      });
    }

    // Add NEW Return/Warranty (OLD order services ticket)
    const returnWarranty = {
      link: '/return-warranty', // '/order-services',
      heading: 'RETURN_WARRANTY' // 'ORDER_SERVICES'
    };
    menuLinks[0].links.splice(
      (menuLinks[0] as NavGroup).links.findIndex(m => m.heading === 'OPEN_ORDERS'),
      0,
      returnWarranty
    );

    if (this.enableJeevesClaims) {
      //
      const returnWarranty = {
        link: '/claims',
        heading: 'CLAIMS'
      };
      menuLinks[0].links.splice(
        (menuLinks[0] as NavGroup).links.findIndex(m => m.heading === 'OPEN_ORDERS'),
        0,
        returnWarranty
      );
    }

    return menuLinks;
  }

  protected getAdminMenu(isActuator?: boolean, features?: GungFeatureMap): (NavLink | NavAction | NavGroup)[] {
    const menuLinks: NavGroup[] = [
      {
        heading: 'DOCUMENTS',
        links: [
          {
            link: '/admin/custom-page-editor',
            heading: 'CUSTOM_PAGES_MENU_TITLE'
            // icon: 'fa-edit'
          },
          {
            link: '/admin/document-templates/template-item-editor',
            heading: 'EXPORT_TEMPLATES'
          },
          {
            link: '/admin/pim-templates/pim-template-item-editor',
            heading: 'PIM_EXPORT_TEMPLATES'
          },
          {
            link: '/admin/documents',
            heading: 'DOCUMENTS_ARCHIVE'
          }
        ]
      },
      {
        heading: 'SYSTEM',
        links: [
          {
            link: '/admin/schedulers-monitor',
            heading: 'SCHEDULERS'
            // icon: 'fa-calendar-alt'
          },
          {
            link: '/admin/manage-users',
            heading: 'USERS'
          },
          {
            link: '/admin/gung-flows',
            heading: 'GUNG_FLOWS'
          },
          {
            link: '/admin/manage-caches',
            heading: 'CACHES'
          },
          {
            link: '/admin/config/export-cover-sheets',
            heading: 'CATEGORY_MAPPING'
          },
          {
            link: '/admin/edi',
            heading: 'EDI'
          },
          {
            link: '/pim',
            heading: 'PIM'
          },
          {
            link: '/admin/availability-conditions',
            heading: 'AVAILABILITY_CONDITIONS'
          },
          {
            link: '/admin/news',
            heading: 'NEWS_EDITOR'
          },
          {
            link: '/admin/translations',
            heading: 'TRANSLATIONS'
          }
        ]
      }
    ];

    // If we have specified a pim external URL use that instead of internal
    if (this.pimExternalUrl) {
      // If we do'
      // Add external pim URL instead
      menuLinks[1].links.splice(
        menuLinks[1].links.findIndex(m => m.heading === 'PIM'),
        1,
        { heading: 'PIM', action: () => window.open(this.pimExternalUrl, '_blank') }
      );
    } else if (!!features && !!features['gung-cloud-pim'] && !!features['gung-cloud-pim'].extra?.url) {
      // If we have the cloud pim feature, we should use the url found there.
      menuLinks[1].links.splice(
        menuLinks[1].links.findIndex(m => m.heading === 'PIM'),
        1,
        {
          heading: 'PIM',
          action: () => {
            window.open(features['gung-cloud-pim'].extra.url, '_blank');
          }
        }
      );
    }

    if (this.enablePimVariants && menuLinks[1].links.findIndex(m => m.heading === 'PIM') > -1) {
      menuLinks[1].links.splice(menuLinks[1].links.findIndex(m => m.heading === 'PIM') + 1, 0, {
        heading: 'PIM Variants',
        link: '/pim-variants'
      });
    }

    // Add digital assets to the project
    menuLinks[0].links.splice(menuLinks[0].links.length, 0, {
      link: '/digital-assets/management',
      heading: 'DIGITAL_ASSETS_MANAGEMENT'
    });

    if (this.enableBrandsManage) {
      // Add related brands to the project
      menuLinks[0].links.splice(menuLinks[0].links.findIndex(m => m.heading === 'DOCUMENTS_ARCHIVE') + 1, 0, {
        link: '/admin/related-brands',
        heading: 'RELATED_BRANDS'
      });
    }
    if (this.enableShippingChargesManage) {
      // Add Shipping charges to the project
      menuLinks[0].links.splice(menuLinks[0].links.findIndex(m => m.heading === 'MANAGE_ASSORTMENT') + 1, 0, {
        link: '/admin/shipping-charges',
        heading: 'SHIPPING_CHARGES'
      });
    }
    if (this.enableAccountRequestManage) {
      // Add account request to the project
      menuLinks[1].links.splice(menuLinks[1].links.findIndex(m => m.heading === 'USERS') + 1, 0, {
        link: '/admin/account-request/manage',
        heading: 'ACCOUNT_REQUEST_MANAGE'
      });
    }
    if (this.enablePimMetadata) {
      // Add PIM metadata to the project
      menuLinks[1].links.splice(menuLinks[1].links.length, 0, {
        link: '/admin/pim-metadata',
        heading: 'PIM_METADATA'
      });
    }
    if (this.enablePimDimensions) {
      // Add PIM dimensions to the project
      menuLinks[1].links.splice(menuLinks[1].links.length, 0, {
        link: '/admin/pim-dimensions',
        heading: 'PIM_DIMENSIONS'
      });
    }
    if (this.enableDiscountCodesManage || this.hasFeatureWithVersion(features, 'gung-discount-codes', undefined)) {
      // Add account request to the project
      menuLinks[0].links.splice(menuLinks[0].links.length, 0, {
        link: '/admin/related-brands',
        heading: 'RELATED_BRANDS'
      });
      menuLinks[1].links.splice(menuLinks[1].links.findIndex(m => m.heading === 'PIM') + 1, 0, {
        link: '/admin/discount-codes',
        heading: 'DISCOUNT_CODES'
      });
    }

    if (this.hasFeatureWithVersion(features, 'orderPortal', undefined)) {
      menuLinks[1].links.push({
        link: '/admin/order-portal',
        heading: 'ORDER_PORTAL'
      });
    }

    if (this.enablePurchaseOrders) {
      // Add Purchase Orders List
      menuLinks.unshift({
        heading: '',
        links: [
          {
            link: '/open-purchase-orders',
            heading: 'OPEN_PURCHASE_ORDER'
            // icon: 'fa-user'
          }
        ]
      });
    }
    if (this.enableMyCompany) {
      // Add My Company
      menuLinks[1].links.unshift({ link: '/admin/my-company', heading: 'THE_COMPANY' });
    }
    if (isActuator) {
      const actuatorMenu = {
        link: '/admin/actuator',
        heading: 'ACTUATOR'
      };
      menuLinks[1].links.push(actuatorMenu);
    }

    if (this.hasFeatureWithVersion(features, 'gung-bankgiro', undefined)) {
      // Add link bankgiro
      const idx = menuLinks.findIndex(m => m.heading === 'DOCUMENTS');
      menuLinks[idx > -1 ? idx : 0].links.push({ link: '/bankgiro', heading: 'BANKGIRO' });
    }

    if (this.enableCatalogues) {
      // Add link Catalogues
      const idx = menuLinks.findIndex(m => m.heading === 'DOCUMENTS');
      menuLinks[idx > -1 ? idx : 0].links.push({ link: '/catalogues', heading: 'CATALOGUES' });
    }

    // Add settings page
    if (isActuator) {
      menuLinks[1].links.unshift({ link: '/admin/settings', heading: 'SETTINGS' });
    }

    return menuLinks;
  }

  public getMainMenu(): Observable<NavGroup> {
    return this.userRoles().pipe(
      mergeMap(roles =>
        forkJoin([
          of(roles),
          this.selectedCustomerService.getSelectedCustomer().pipe(
            filter(customer => (this.devMode ? true : !!customer)),
            first()
          ),
          this.customerService.getCustomerCount().pipe(
            // filter(customer => this.devMode ? true : !!customer), // commented out because is breaking the menu when no customers
            first(),
            catchError(error => of(undefined))
          ),
          this.newsActionConfigService.getIsEnabledShowUserNewsInPage().pipe(first()),
          this.enableDocumentUserGroups ? this.getUserDocuments().pipe(first()) : of([]),
          this.authService.getCurrentUser().pipe(first()),
          this.backendFeatureService.getAvailableFeatures().pipe(
            first(),
            catchError(error => of(undefined))
          )
        ])
      ),
      map(([roles, customer, customers, isEnabledShowUserNewsInPage, documents, user, features]) => {
        this.roles = roles;
        this.checkEnabledFeatures(features);
        this.isEnabledShowUserNewsInPage = isEnabledShowUserNewsInPage;
        this.documents = documents;
        const mainMenu: NavGroup = {
          heading: '',
          links: [
            {
              link: undefined,
              heading: 'LOGOUT',
              // icon: 'fa-file-invoice-dollar',
              action: () => {
                this.logout();
              }
            }
          ]
        };
        if (roles.indexOf('SUPPLIER') > -1 || roles.indexOf('BUYER') > -1) {
          mainMenu.links.unshift(
            ...this.getSupplierMenu(
              roles.indexOf('ADMIN') > -1,
              roles.indexOf('ACTUATOR') > -1,
              roles.indexOf('BUYER') > -1,
              roles.indexOf('SUPPLIER') > -1
            )
          );
          return mainMenu;
        }
        if (roles.indexOf('SALES') > -1 || roles.indexOf('ADMIN') > -1) {
          if (roles.indexOf('ADMIN') > -1) {
            mainMenu.links.unshift(...this.getAdminMenu(roles.indexOf('ACTUATOR') > -1, features));
          }
          mainMenu.links.unshift(
            ...this.getSalesMenu(roles.indexOf('ACTUATOR') > -1, roles, user.extra.salesCodeErp, features)
          );
        }
        mainMenu.links.unshift(
          ...this.getUserMenu(
            customer,
            roles.indexOf('SALES') > -1,
            customers,
            roles.indexOf('ACTUATOR') > -1,
            features
          )
        );

        if (this.priceConfigService.enableHidePrice) {
          const hidePriceSwitch: NavGroup = {
            link: undefined,
            heading: 'HIDE_PRICE',
            action: (inputElement: HTMLInputElement) => {
              this.priceConfigService.setHidePrice(inputElement.checked);
            }
          };
          mainMenu.links.push(hidePriceSwitch);
        }

        if (roles.includes('ACTUATOR')) {
          const roleLinks: NavGroup[] = [];
          user.roles.forEach(role => {
            if (role !== 'ACTUATOR') {
              roleLinks.push({
                heading: role,
                link: 'TOGGLE',
                action: (_inputElement: HTMLInputElement) => this.authService.toggleMockRole(role),
                icon: roles.includes(role) ? 'fa-toggle-on' : 'fa-toggle-off'
              });
            }
          });

          const mockRoleMode = this.authService.mockRolesModeEnabled();

          mainMenu.links.push({
            heading: mockRoleMode ? 'Disable mock roles' : 'Enable mock roles',
            links: mockRoleMode ? roleLinks : [],
            action: () => this.authService.toggleUseMockRoles()
          });
        }

        if (
          this.hasFeatureWithVersion(features, 'gung-production', undefined) &&
          (roles.indexOf('WAREHOUSE') > -1 || roles.indexOf('ACTUATOR') > -1)
        ) {
          (mainMenu.links[0] as NavGroup).links.push({
            link: `warehouse-management`,
            heading: 'WAREHOUSE'
            // icon: 'fa-user'
          });
        }

        return mainMenu;
      })
    );
  }

  protected getSupplierMenu(
    isAdmin?: boolean,
    isActuator?: boolean,
    isBuyer?: boolean,
    isSupplier?: boolean,
    customer?: Customer,
    customers?: Customer[]
  ): (NavLink | NavAction | NavGroup)[] {
    const menuLinks = [];
    if (isBuyer) {
      menuLinks.push({
        heading: '',
        links: [
          {
            link: '/suppliers',
            heading: 'MY_SUPPLIERS'
            // icon: 'fa-user'
          }
        ]
      });
    }
    return menuLinks;
  }

  public getRightMenus(): Observable<NavGroup[]> {
    return this.userRoles().pipe(
      mergeMap(roles =>
        forkJoin([
          of(roles),
          this.selectedCustomerService.getSelectedCustomer().pipe(
            filter(customer => !!customer),
            first()
          )
        ])
      ),
      map(([roles, _customer]) => {
        const baseMenu: NavGroup[] = [];
        if (roles.indexOf('ANONYMOUS') > -1) {
          baseMenu.push({
            heading: this.translateService.instant('LOGIN'),
            link: '/login',
            links: null
          } as NavGroup);
        }
        return baseMenu;
      })
    );
  }

  protected getDynamicLeftTitleMenu(): Observable<string> {
    return this.gungFlowService.getSelectedFlow().pipe(
      map(flow => {
        if (flow) {
          return `${this.translateService.instant('PRODUCTS')}: ${this.translateService.instant(flow.name)}`;
        }
        return this.translateService.instant('PRODUCTS');
      })
    );
  }

  public getLeftMenus(): Observable<NavGroup[]> {
    return this.userRoles().pipe(
      switchMap(userRoles => {
        const isSupplierBuyer = userRoles.indexOf('SUPPLIER') > -1 || userRoles.indexOf('BUYER') > -1;
        if (isSupplierBuyer) {
          return this.getSupplierBasedNavigation();
        } else if (!!this.isAssortmentBased) {
          return this.getAssortmentBasedNavigation();
        } else {
          return this.getFlowBasedNavigation();
        }
      }),
      switchMap(menuLinks => this.getLeftMenuDocuments(menuLinks))
    );
  }

  getSupplierBasedNavigation(): Observable<NavGroup[]> {
    let postatus = this.metadataService.getMetadataTableList('POSTATUS');
    const postatusLinks: NavGroup[] = [];
    if (postatus?.length > 0) {
      postatus = Array(...postatus).sort((a, b) => gungComparatorHelper(a.id, b.id, 1)) as any;
      postatusLinks.push(
        ...postatus.map(p => ({
          heading: p.name,
          link: 'suppliers-purchase-order/' + p.id
        }))
      );
    } else {
      console.error(`NOTE: Supplier portal need metadata POSTATUS to work correctly`);
    }
    return of(postatusLinks);
  }

  public getFlowBasedNavigation(): Observable<NavGroup[]> {
    return this.getDynamicLeftTitleMenu().pipe(
      switchMap(title =>
        forkJoin([
          of(title),
          this.userRoles().pipe(
            first(),
            map(roles => roles.indexOf('ANONYMOUS') > -1)
          )
        ])
      ),
      switchMap(([title, isAnonymous]) => {
        return this.gungFlowService.getGungFlows().pipe(
          map(gungFlow => {
            const baseMenu: NavGroup[] = [];
            if (!gungFlow) {
              return null;
            } else {
              gungFlow = gungFlow.sort((a, b) =>
                a.extra.sortingPriority > b.extra.sortingPriority
                  ? 1
                  : b.extra.sortingPriority > a.extra.sortingPriority
                  ? -1
                  : 0
              );
              if (this.showFlowsAsDropdown) {
                const flowsMenu = [];
                gungFlow.map(flow => {
                  if (!flow.extra.digitalAssets) {
                    flowsMenu.push({
                      heading: flow.name,
                      action: () => {
                        this.selectFlow(flow.id);
                        this.redirectToProducts();
                        this.cleanScroll();
                      }
                    } as NavAction);
                  }
                });
                baseMenu.push({
                  heading: title,
                  action: () => {
                    this.cleanScroll();
                  },
                  // icon: 'fa-bolt',
                  link: this.getProductListBaseRoute(),
                  links: flowsMenu
                });
              } else {
                baseMenu.push(
                  ...gungFlow.map(flow => {
                    if (!flow.extra.digitalAssets) {
                      return {
                        heading: flow.name,
                        action: () => {
                          this.selectFlow(flow.id);
                          this.redirectToProducts();
                          this.cleanScroll();
                        }
                      } as NavAction;
                    }
                  })
                );
              }
              if (isAnonymous) {
                baseMenu.push({
                  heading: this.translateService.instant('PRODUCT_EXPORT_NAV_BAR'),
                  action: () => {
                    this.cleanScroll();
                  },
                  link: '/products-export',
                  links: null
                } as NavGroup);
              }
              return baseMenu;
            }
          })
        );
      })
    );
  }

  protected getAssortmentBasedNavigation(): Observable<NavGroup[]> {
    return this.authService.getCurrentUser().pipe(
      filter(user => !!user),
      first(),
      mergeMap(user => {
        return this.assortmentService.getAssortmentRecursive(user.assortment).pipe(first());
      }),
      map(assortment => {
        return assortment.children.map(childAssortment => {
          const link = {
            heading:
              (this.currentLang && childAssortment.extra?.i18n?.[this.currentLang]?.assortmentName) ||
              childAssortment.name,
            action: () => {
              this.cleanScroll();
            },
            link: this.getProductListBaseRoute() + `/${childAssortment.id}`
          } as NavGroup;
          return link;
        });
      })
    );
  }

  cleanScroll() {
    const locationPosition = this.locationConfigService.get('products');
    if (!!locationPosition) {
      this.locationConfigService.remove(locationPosition);
    }
  }

  public getTopInformation(): Observable<NavLink> {
    return this.userRoles().pipe(
      first(),
      mergeMap(roles =>
        forkJoin([
          this.selectedCustomerService.getSelectedCustomer().pipe(
            filter(customer => !!customer),
            first()
          ),
          of(roles.indexOf('ANONYMOUS') > -1),
          of(roles.indexOf('SUPPLIER') > -1 || roles.indexOf('BUYER') > -1)
        ])
      ),
      switchMap(([customer, isAnonymous, isSupplierBuyer]) => {
        if (isAnonymous) {
          return of(null);
        } else if (isSupplierBuyer) {
          return this.getTopInformationSupplier();
        } else {
          return of({
            heading: customer.name + ' ' + customer.id,
            link: `/customers/${customer.id}`
          } as NavLink);
        }
      })
    );
  }

  public getTopInformationSupplier(): Observable<NavLink> {
    return this.selectedSupplierService.getSelectedSupplier().pipe(
      map(
        supplier =>
          ({
            heading: supplier?.name + ' ' + supplier?.id,
            link: undefined /* `/suppliers/${supplier.id}` */
          } as NavLink)
      )
    );
  }

  redirectToProducts(flow?: GungFlow): void {
    this.router.navigate([this.getProductListBaseRoute()]);
  }

  public showCart(): Observable<boolean> {
    return forkJoin([this.userRoles().pipe(first()), this.gungFlowService.getSelectedFlow().pipe(first())]).pipe(
      map(([roles, flow]) => roles.indexOf('ANONYMOUS') === -1 || flow?.displayOnly)
    );
  }

  protected getUserDocuments(nocache?: boolean): Observable<Document[]> {
    let currentUserKeys: any;
    this.authService
      .getCurrentUser()
      .pipe(first())
      .subscribe(user => {
        const currentUser: any = user;
        currentUserKeys = Object.keys(currentUser.activeGroups).filter(
          userGroup => currentUser.activeGroups[userGroup] === true
        );
      });

    return this.documentsService.getDocuments(nocache).pipe(
      first(),
      map(documents =>
        documents.filter(
          doc =>
            doc.metaData &&
            doc.metaData.inMenu &&
            doc.metaData.inMenu === 'true' &&
            this.showDocument(doc, currentUserKeys)
        )
      )
    );
  }

  showDocument(document, currentUserKeys): boolean {
    let result = false;
    if (document && document.metaData && document.metaData.userGroups) {
      if (document.metaData && document.metaData.userGroups && typeof document.metaData.userGroups === 'string') {
        document.metaData.userGroups = JSON.parse(document.metaData.userGroups);
      }
      const documentKeys = Object.keys(document.metaData.userGroups).find(
        userGroup => document.metaData.userGroups[userGroup] === true
      );
      const groupKey = Object.keys(document.metaData.userGroups).find(
        userGroup => document.metaData.userGroups[userGroup] === true && currentUserKeys.some(key => key === userGroup)
      );
      if (groupKey) {
        result = true;
      } else {
        result = false;
      }
    } else {
      // If no user group on document show for all user groups
      result = true;
    }
    return result;
  }

  getEnableDocumentUserGroups() {
    return this.enableDocumentUserGroups;
  }

  getProductListBaseRoute() {
    if (this.baseViewConfigService.productViewType === ProductViewType.assortmentTreeView) {
      return '/articles';
    }
    return '/products';
  }

  protected getLeftMenuDocuments(menuLinks): Observable<NavGroup[]> {
    return this.authService.getCurrentUser().pipe(
      first(),
      switchMap(user =>
        forkJoin({
          menuLinks: of(menuLinks),
          userGroups: of(Object.keys(user.activeGroups).filter(key => user.activeGroups[key] === true)),
          documents: this.documentsService.getDocuments().pipe(first())
        })
      ),
      switchMap(({ menuLinks, userGroups, documents }) => {
        if (!this.enableDocumentsOnLeftMenu) {
          return of(menuLinks);
        }

        const newLink = {
          heading: this.documentsOnLeftMenuLinkHeading,
          links: []
        };

        for (const document of documents) {
          if (
            !(document?.metaData?.inMenu === true || document?.metaData?.inMenu === 'true') ||
            !document?.metaData?.userGroups
          ) {
            continue;
          }

          const metadataUserGroupsArray = Object.keys(document.metaData.userGroups).filter(
            key => document.metaData.userGroups[key] === true
          );
          if (metadataUserGroupsArray.some(group => userGroups.includes(group))) {
            const url = this.backendInterceptor.getBaseUrl() + '/gung-document-archive/document/' + document.id;
            newLink.links.push({
              heading:
                document.metaData.description && document.metaData.description !== 'undefined'
                  ? document.metaData.description
                  : document.filename,
              action: () => {
                window.open(url, '_blank');
              }
            });
          }
        }

        if (newLink.links.length > 0) {
          menuLinks.push(newLink);
        }

        return of(menuLinks);
      })
    );
  }

  getUserAndRole(): Observable<UserAndRole> {
    return this.authService.getCurrentUser().pipe(
      first(),
      switchMap(user =>
        forkJoin({
          user: of(user),
          roles: this.authService.getRoles().pipe(first())
        })
      ),
      switchMap(({ user, roles }) => {
        if (!user || !roles) {
          return of({
            name: '',
            role: '',
            link: undefined,
            hide: true
          });
        }

        return of({
          name: user.username,
          role: this.getUserRole(roles),
          link: roles.findIndex(r => r === 'ADMIN') > -1 ? `/admin/users-details/${user.username}` : undefined,
          hide: false
        });
      })
    );
  }

  getUserRole(roles: string[]): string {
    if (roles.includes('ADMIN')) {
      return 'ADMIN';
    } else if (roles.includes('SALES')) {
      return 'SALES';
    } else if (roles.includes('USER') && roles.length > 1) {
      return roles.filter(role => role !== 'USER')[0];
    } else if (roles.includes('USER') && roles.length === 1) {
      return 'USER';
    } else {
      return '';
    }
  }
}
