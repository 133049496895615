<div class="col">
  <div class="row justify-content-center">
    <div class="d-flex flex-column justify-content-center align-items-center">
      <h1><span translate>UNDER_MAINTENANCE</span> <i class="ml-3 fas fa-cogs"></i></h1>

      <span translate>UNDER_MAINTENANCE_LONG</span>

      <br />
      <span class="mt-4"
        ><span translate>PROLONGED_DOWNTIME_INSTRUCTION</span>
        <a href="mailto: support@gung.se">support@gung.se</a></span
      >
    </div>
  </div>
</div>
