import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { forkJoin, of, Observable } from 'rxjs';
import { first, map, switchMap, tap, mergeMap } from 'rxjs';
import { ListLayout, ListLayoutSingleComponent, ListLayoutMultipleComponent, SimpleConfigBaseFilter } from 'gung-list';
import {
  Product,
  ProductListConfigService,
  ProductService,
  AuthService,
  SelectedCustomerService,
  ProductSortService,
  PriceService,
  MetadataService,
  AssortmentService,
  GungFlowService,
  Customer,
  CloudPimFiltersService,
  CurrentAssortmentService
} from 'gung-standard';
import { ProductItemGroupFilterService } from 'gung-standard-jeeves';
import { ProductCategoryFilterService } from './filters/product-category-filter.service';
import { ProductMerchandiseTypeFilterService } from './filters/product-merchandise-type-filter.service';
import { ProductDepthFilterService } from './filters/product-depth-filter.service';
import { ProductEtiquetteFilterService } from './filters/product-etiquette-filter.service';
import { ProductGondolaBrandFilterService } from './filters/product-gondola-brand-filter.service';
import { ProductLengthFilterService } from './filters/product-length-filter.service';
import { ProductCapacityFilterService } from './filters/product-capacity-filter.service';
import { ProductColorFilterService } from './filters/product-color-filter.service';
import { ProductShelfManufacturerFilterService } from './filters/product-shelf-manufacturer-filter.service';
import { HlDisplayProductCardListComponent } from '../components/hl-display-product-card-list/hl-display-product-card-list.component';

import { environment } from '../../environments/environment';
import { ProductMousquetairesFilterService } from './filters/product-mousquetaires-filter.service';
import { HlDisplayProductCardListGridViewComponent } from '../components/hl-display-product-card-list-grid-view/hl-display-product-card-list-grid-view.component';
import { ProductAssortmentTypeFilterService } from './filters/product-assortment-type-filter.service';
import { ProductPimFilterService } from './filters/product-pim-filter.service';
import { HlDisplayHelperService } from './hl-display-helper.service';
import { ProductExternalCategoryFilterService } from './filters/product-external-category-filter.service';
import { ProductExternalSubCategoryFilterService } from './filters/product-external-sub-category-filter.service';
import { ProductMainFunctionFilterService } from './filters/product-main-function-filter.service';
import { ProductStoreCategoryFilterService } from './filters/product-store-category-filter.service';
import { HlProductService } from './hl-product.service';
import { HlDisplayOrderService } from './hl-display-order.service';
import { ProductHeightFilterService } from './filters/product-height-filter.service';
import { ProductProductShelfTypeFilterService } from './filters/product-product-shelf-type-filter.service';
import { ProductWidthFilterService } from './filters/product-width-filter.service';

export class ProductMetadataPimFilterService extends SimpleConfigBaseFilter<Product> {
  isCurrysPortal = environment.bolag === '1710' && !environment.sales && !environment.managers && environment.mainCountry === 'en' && environment.mainCustomer === '';

  constructor(
    protected metadataService: MetadataService,
    protected metadataTable: string,
    protected language: string,
    protected filterLabel: string,
    protected segment: string,
    protected field: string,
    protected isList = false,
  ) {
    super();
  }

  getName(): string {
    return this.filterLabel;
  }

  getOptionIds(item: Product): string[] {
    let options = [];
    if (this.isList) {
      options = item.extra[this.segment][this.field] || [];
    } else {
      options = [item.extra[this.segment][this.field] || ''];
    }
    return options;
  }

  getOptionName(key: string): string {
    if (this.isCurrysPortal) {
      const i18nValues = this.metadataService.getMetadataValue(this.metadataTable, 'Values', key);
      if (i18nValues?.[this.language === 'en' ? 'en-GB' : this.language]) {
        return i18nValues?.[this.language === 'en' ? 'en-GB' : this.language];
      }
    }

    return key;
  }
}

@Injectable({
  providedIn: 'root'
})
export class HlDisplayProductListConfigService extends ProductListConfigService {
  private warehouseProductsCache: { [productId: string]: Product };

  isFRCustomerPortalPharma =
    !environment.sales && environment.mainCountry === 'fr' && environment.mainCustomer === 'Pharmacies';
  public isFRCustomerPortal1600General =
    !environment.sales &&
    environment.mainCountry === 'fr' &&
    environment.bolag === '1600' &&
    environment.mainCustomer === 'General';
  isNOCustomerPortal = !environment.sales && environment.mainCountry === 'no';
  isSECustomerPortal = !environment.sales && environment.mainCountry === 'se';
  isFICustomerPortal = !environment.sales && environment.mainCountry === 'fi';
  isCustomerPortal = !environment.sales;
  currentLang = this.translateService.currentLang;
  private isManagersNisa = environment.managers && environment.mainCountry === 'en';
  private isManagersCoop = environment.managers && environment.mainCountry === 'ch' && environment.mainCustomer === 'coop';
  private isManagers = environment.managers;
  private isCustomerPortal1710En = !environment.sales && environment.bolag === '1710' && environment.mainCountry === 'en';
  private isCustomerPortal1210Kesko = environment.bolag === '1210' && !environment.sales && environment.mainCountry === 'fi';
  private isCustomerPortalsFr = environment.mainCountry === 'fr' && !environment.sales && !environment.subCustomer && environment.bolag === '1600';
  private isCustNlSuperunie1600 = !environment.sales && environment.bolag === '1600' && environment.mainCountry === 'nl' && environment.mainCustomer === 'spar';
  private isCloettaPortal = environment.bolag === '1210' && !environment.sales && environment.managers && environment.mainCountry === 'en' && environment.mainCustomer === 'cloetta';
  private isCurrysPortal = environment.bolag === '1710' && !environment.sales && !environment.managers && environment.mainCountry === 'en' && environment.mainCustomer === '';
  isMousquetairesPortal = environment.bolag === '1600' && environment.sales === false && environment.managers === false && environment.mainCountry === 'fr' && environment.mainCustomer === '' && environment.subCustomer === '';

  constructor(
    public productService: HlProductService,
    protected productCategoryFilter: ProductCategoryFilterService,
    protected productMerchandiseTypeFilter: ProductMerchandiseTypeFilterService,
    protected productLengthFilter: ProductLengthFilterService,
    protected productDepthFilter: ProductDepthFilterService,
    protected productGondolaBrandFilter: ProductGondolaBrandFilterService,
    protected productEtiquetteFilter: ProductEtiquetteFilterService,
    protected productCapacityFilter: ProductCapacityFilterService,
    protected productColorFilter: ProductColorFilterService,
    protected productShelfManufacturerFilter: ProductShelfManufacturerFilterService,
    protected productItemGroupFilter: ProductItemGroupFilterService,
    protected productMousquetairesFilter: ProductMousquetairesFilterService,
    protected productAssortmentTypeFilter: ProductAssortmentTypeFilterService,
    protected metaDataService: MetadataService,
    protected authService: AuthService,
    protected selectedCustomerService: SelectedCustomerService,
    protected translateService: TranslateService,
    productSortService: ProductSortService,
    protected priceService: PriceService,
    protected assortmentService: AssortmentService,
    protected gungFlowService: GungFlowService,
    private helperService: HlDisplayHelperService,
    protected productCategoryFilterService: ProductCategoryFilterService,
    protected productExternalCategoryFilterService: ProductExternalCategoryFilterService,
    protected productExternalSubCategoryFilterService: ProductExternalSubCategoryFilterService,
    protected productMainFunctionFilterService: ProductMainFunctionFilterService,
    protected productStoreCategoryFilterService: ProductStoreCategoryFilterService,
    protected hlDisplayOrderService: HlDisplayOrderService,
    protected productProductShelfTypeFilterService: ProductProductShelfTypeFilterService,
    protected productWidthFilterService: ProductWidthFilterService,
    protected cloudPimFiltersService: CloudPimFiltersService,
    protected currentAssortmentService: CurrentAssortmentService
  ) {
    super(productService, authService, productSortService, gungFlowService, cloudPimFiltersService, currentAssortmentService);
  }

  getFilters() {
    let toReturn = [
      this.productMerchandiseTypeFilter,
      this.productLengthFilter,
      this.productDepthFilter,
      this.productEtiquetteFilter,
      this.productProductShelfTypeFilterService,
      this.productColorFilter,
      this.productShelfManufacturerFilter
    ];
    if (!environment.sales) {
      toReturn.unshift(this.productCategoryFilter);
    }

    if (this.isCustomerPortalsFr) {
      toReturn.push(this.productWidthFilterService);
    }

    if (environment.sales) {
      // toReturn.push(this.productItemGroupFilter);
      toReturn.push(new ProductPimFilterService('PRODUCT_SYSTEM', 'hlPimProduct', 'ProductSystem'));
      toReturn.splice(toReturn.indexOf(this.productMerchandiseTypeFilter), 1);
      toReturn.splice(toReturn.indexOf(this.productDepthFilter), 1);
      toReturn.splice(toReturn.indexOf(this.productLengthFilter), 1);
      toReturn.splice(toReturn.indexOf(this.productColorFilter), 1);
      // if (environment.getArticlesOutsidePriceLists) {
      toReturn.push(this.productAssortmentTypeFilter);
      // }
    } else if (environment.bolag === '1710') {
      toReturn = [this.productCapacityFilter, ...toReturn];
      toReturn.splice(toReturn.indexOf(this.productMerchandiseTypeFilter), 1);
      toReturn.splice(toReturn.indexOf(this.productEtiquetteFilter), 1);
      toReturn.splice(toReturn.indexOf(this.productShelfManufacturerFilter), 1);
    } else {
      // for customer FR portal (Pharmacies) and no customer portals and se customer portal
      // not show MOUSQUETAIRES_SELECTION filter
      if (
        this.isFRCustomerPortalPharma ||
        this.isFRCustomerPortal1600General ||
        this.isNOCustomerPortal ||
        this.isSECustomerPortal ||
        this.isFICustomerPortal ||
        this.isManagers ||
        this.isCustNlSuperunie1600
      ) {
        toReturn = [this.productCapacityFilter, new CustomerPurchasedProductFilter(), ...toReturn];
      } else if (environment.showPreviousOrderFilter) {
        toReturn = [this.productMousquetairesFilter, new CustomerPurchasedProductFilter(), this.productCapacityFilter, ...toReturn];
      } else {
        toReturn = [this.productMousquetairesFilter, this.productCapacityFilter, ...toReturn];
      }
    }

    if (this.isMousquetairesPortal && toReturn.indexOf(this.productMousquetairesFilter) > -1) {
      toReturn.splice(toReturn.indexOf(this.productMousquetairesFilter) + 1, 0, new ProductDiscountedItemsFilter());
    }

    // for sales CustomerPortalPharma
    if (!this.isFRCustomerPortalPharma) {
      toReturn.splice(toReturn.indexOf(this.productProductShelfTypeFilterService), 1);
    }

    // for Kesko
    if (this.isCustomerPortal1210Kesko) {
      toReturn.splice(toReturn.indexOf(this.productLengthFilter), 1);
      toReturn.push(new ProductPimFilterService('PIMITEM_WIDTH_MM', 'hlPimItem', 'Widthmm', ' mm'));
    }

    // for sales DE
    if (environment.sales && environment.mainCountry === 'de') {
      toReturn = [];
      toReturn.push(this.productAssortmentTypeFilter);

      toReturn.push(this.productExternalCategoryFilterService);
      toReturn.push(this.productExternalSubCategoryFilterService);

      toReturn.push(this.productItemGroupFilter);

      toReturn.push(this.productMainFunctionFilterService);
      toReturn.push(this.productStoreCategoryFilterService);

      // toReturn.push(new ProductPimFilterService('PRODUCT_SYSTEM', 'hlPimProduct', 'ProductSystem'));
      toReturn.push(new ProductPimFilterService('MERCHANDISE_TYPE', 'hlPimItem', 'ItemMerchandiseType', '', true));

      toReturn.push(new ProductPimFilterService('MERCHANDISE_BRAND', 'hlPimItem', 'ItemMerchandiseBrand'));

      toReturn.push(new ProductPimFilterService('PIMITEM_DEPTH_MM', 'hlPimItem', 'Depthmm', ' mm'));
      toReturn.push(new ProductPimFilterService('PIMITEM_WIDTH_MM', 'hlPimItem', 'Widthmm', ' mm'));

      toReturn.push(this.productColorFilter);

      toReturn.push(new ProductPimFilterService('FRONT', 'hlPimItem', 'Front'));
      toReturn.push(this.productEtiquetteFilter);
      toReturn.push(new ProductPimFilterService('LABEL_TYPE', 'hlPimItem', 'LabelType'));
      toReturn.push(new ProductPimFilterService('SPRING', 'hlPimItem', 'Spring'));

      // New filters 20220311

      /*
protected productExternalCategoryFilterService: ProductExternalCategoryFilterService,
protected productExternalSubCategoryFilterService: ProductExternalSubCategoryFilterService,
protected productMainFunctionFilterService: ProductMainFunctionFilterService,
protected productStoreCategoryFilterService: ProductStoreCategoryFilterService*/
      //   toReturn.push(this.productShelfManufacturerFilter);

      /*
      Wait with these
      toReturn.push(new ProductPimFilterService('SHEFT_TYPE', 'ProductShelfType'));
      toReturn.push(new ProductPimFilterService('SHELF_MANUFACTURER', 'ProductShelfManufacturer'));
      toReturn.push(new ProductPimFilterService('SHELF_LENGHT', 'ItemShelfLength'));
      toReturn.push(new ProductPimFilterService('SHELF_DEPTH', 'ItemShelfDepth')); */
    }

    if (this.isManagersNisa || this.isCustomerPortal1710En) {
      toReturn = [];
      toReturn.push(this.productCategoryFilterService);
      // toReturn.push(this.productExternalCategoryFilterService);
      toReturn.push(this.productExternalSubCategoryFilterService);

      if (this.isCurrysPortal) {
        const indexSubCategory = toReturn.indexOf(this.productExternalSubCategoryFilterService);
        if (indexSubCategory > -1) {
          toReturn.splice(indexSubCategory, 1);
        }

        toReturn.unshift(new ProductMetadataPimFilterService(this.metaDataService, 'StoreCategoryNonFood', this.translateService.currentLang, 'STORE_AREA', 'hlPimItem', 'ItemStoreCategoryNonFood', true));
        toReturn.splice(1, 0, this.productExternalSubCategoryFilterService);
        toReturn.splice(2, 0, new RollOutProductFilter());
      }

      // Previously bought (Make it for the selected POD) - Talk to Daniel about this one.
      toReturn.push(new ProductPimFilterService('PIMITEM_WIDTH_MM', 'hlPimItem', 'Widthmm', ' mm'));
      toReturn.push(new ProductPimFilterService('PIMITEM_DEPTH_MM', 'hlPimItem', 'Depthmm', ' mm'));
      toReturn.push(new ProductHeightFilterService());
      toReturn.push(new ProductPimFilterService('SIZE', 'hlPimItem', 'Size'));
      toReturn.push(new ProductPimFilterService('PIMITEM_DIAMETER_MM', 'hlPimItem', 'Diametermm', ' mm'));
      toReturn.push(new ProductPimFilterService('SPRING', 'hlPimItem', 'Spring'));
      toReturn.push(new ProductMetadataPimFilterService(this.metaDataService, 'ColourName', this.translateService.currentLang, 'COLOUR', 'hlPimItem', 'ItemColourName', true));
      toReturn.push(new ProductMetadataPimFilterService(this.metaDataService, 'Material', this.translateService.currentLang, 'SHELF_MANUFACTURER', 'hlPimProduct', 'ShelfManufacturer', true));
      toReturn.push(new ProductMetadataPimFilterService(this.metaDataService, 'ShelfManufacturer', this.translateService.currentLang, 'MATERIAL', 'hlPimItem', 'Material', true));

      if ((this.isManagersNisa || this.isCustomerPortal1710En) && !this.isCurrysPortal) {
        toReturn.push(new ProductPimFilterService('STORE_DEPARTMENT', 'hlPimProduct', 'ProductStoreCategory', '', true));
        toReturn.push(new ProductPimFilterService('TRADEMARK', 'hlPimProduct', 'ProductRange', '', true));
      }

      toReturn = [new CustomerPurchasedProductFilter(), ...toReturn];
    }

    // for customer portals sort the filter by name
    if (!environment.sales && !environment.managers && !this.isCurrysPortal) {
      toReturn.sort((a, b) => {
        const aName = this.translateService.instant(a.getName()) as string;
        const bName = this.translateService.instant(b.getName()) as string;
        if (aName > bName) {
          return 1;
        }
        if (aName < bName) {
          return -1;
        }
        return 0;
      });
    }

    console.log(toReturn);
    return toReturn;
  }

  getWarehouseProducts(): Observable<Product[]> {
    return this.productService.getProductsByAssortment('warehouse');
  }

  getItems() {
    if (environment.sales) {
      return this.selectedCustomerService.getSelectedCustomer().pipe(
        switchMap(customer =>
          forkJoin({
            selectedCustomer: of(customer),
            products: this.productService
              .getProductsByAssortment(
                this.helperService.checkRequestPricelistAssortmentWithWebCondition(customer.extra.kus.prislista)
              )
              .pipe(first()),
            priceListAssortment: this.assortmentService.getAssortment(
              this.helperService.checkRequestPricelistAssortmentWithWebCondition(customer.extra.kus.prislista)
            ),
            warehouseProducts:
              !!environment.getArticlesOutsidePriceLists && !this.warehouseProductsCache
                ? this.getWarehouseProducts().pipe(first())
                : of([])
          })
        ),
        map(({ selectedCustomer, products, priceListAssortment, warehouseProducts }) => {
          const customerProducts = priceListAssortment.children
            .filter(child => child.extra.pricelist === '' + selectedCustomer.extra.kus.prislista)
            .map(child => child.id);
          const standardProducts = priceListAssortment.children
            .filter(child => child.extra.pricelist !== '' + selectedCustomer.extra.kus.prislista)
            .map(child => child.id);

          const mergedProducts = [...products];

          const mergedProductsIndex = {};
          mergedProducts.forEach(p => {
            mergedProductsIndex[p.id] = true;
          });

          const standardProductsIndex = {};
          standardProducts.forEach(p => {
            standardProductsIndex[p] = true;
          });

          const customerProductsIndex = {};
          customerProducts.forEach(p => {
            customerProductsIndex[p] = true;
          });

          warehouseProducts.forEach(p => {
            if (!mergedProductsIndex[p.id]) {
              mergedProducts.push(p);
              mergedProductsIndex[p.id] = true;
            }
          });

          mergedProducts.forEach(product => {
            if (customerProductsIndex[product.id]) {
              product.extra.assortmentType = 'C';
            } else if (standardProductsIndex[product.id]) {
              product.extra.assortmentType = 'S';
            } else {
              product.extra.assortmentType = 'W';
            }
          });

          const sorted = mergedProducts.sort((p1, p2) => {
            const artbeskr1 = p1.extra.ar.artbeskr || 'ZZZZZZZZZZZZZZZZZ';
            const artbeskr2 = p2.extra.ar.artbeskr || 'ZZZZZZZZZZZZZZZZZ';
            return artbeskr1.localeCompare(artbeskr2);
          });
          console.log('leaving getItems', sorted.length);
          return sorted;
        })
      );
    } else if ((environment.mainCountry && environment.mainCustomer) || !!environment.showPreviousOrderFilter) {
      let selectedCustomer: Customer;
      return this.selectedCustomerService.getSelectedCustomer().pipe(
        tap(customer => (selectedCustomer = customer)),
        switchMap(customer =>
          this.productService.getProductsByAssortment(
            this.helperService.checkRequestPricelistAssortmentWithWebCondition(customer.extra.kus.prislista)
          )
        ),
        mergeMap(products =>
          forkJoin({
            products: of(products),
            // customerPurchasedProducts: this.productService.getCustomerPurchasedProducts().pipe(first())
            orderRows: selectedCustomer
              ? this.hlDisplayOrderService.getOrderRows(selectedCustomer.id).pipe(first())
              : of([])
          })
        ),
        map(({ products /* , customerPurchasedProducts */, orderRows }) => {
          // const purchasedIds = customerPurchasedProducts.map(p => p.artnr);
          const purchasedIds = orderRows.map(p => p.productId);
          return products.map(product => {
            if (purchasedIds.includes(product.id)) {
              product.extra._customerPurchasedProduct = true;
            }
            return product;
          });
        }),
        map(products => {
          const blockedItems = this.getBlockedItems();
          const filteredProducts = products.filter(item => blockedItems.indexOf(item.id) === -1);

          if (this.isManagersCoop || this.isManagersNisa) {
            products.map(p => {
              p.extra._idToCustomer = p.extra?.kuar?.find(kuar => kuar.customerId === selectedCustomer.id)?.data?.artnrkund;
            })
          }

          if (this.isCloettaPortal && selectedCustomer?.extra.kuarMapped) {
            const kuarMapped = selectedCustomer?.extra.kuarMapped;
            filteredProducts.forEach(p => {
              if (kuarMapped[p.id]) {
                p.extra._artnrkund = kuarMapped[p.id].artnrkund;
              }
            })
          }

          return filteredProducts.sort((a, b) => {
            if (a.name > b.name) {
              return 1;
            }
            if (a.name < b.name) {
              return -1;
            }
            return 0;
          });
        })
      );
    } else {
      return this.authService.getCurrentUser().pipe(
        switchMap(user =>
          forkJoin({
            products: this.productService.getProductsByAssortment(user.assortment).pipe(first()),
            selectedCustomer: this.selectedCustomerService.getSelectedCustomer().pipe(first())
          })
        ),
        switchMap(({ products, selectedCustomer }) => {
          if (this.isCurrysPortal) {
            const customerId = selectedCustomer?.id.includes('@') ? selectedCustomer.id.slice(0, selectedCustomer.id.indexOf('@')) : selectedCustomer?.id;
            products.forEach(p => {
              p.extra._kuar = p?.extra.kuar?.find(kuar => kuar.customerId === customerId)?.data?.artnrkund;
            })
          }
          return forkJoin({
            products: of(products),
            // customerPurchasedProducts: this.productService.getCustomerPurchasedProducts().pipe(first())
            orderRows: selectedCustomer
              ? this.hlDisplayOrderService.getOrderRows(selectedCustomer.id).pipe(first())
              : of([])
          })
        }),
        map(({ products /* , customerPurchasedProducts */, orderRows }) => {
          // const purchasedIds = customerPurchasedProducts.map(p => p.artnr);
          const purchasedIds = orderRows.map(p => p.productId);
          return products.map(product => {
            if (purchasedIds.includes(product.id)) {
              product.extra._customerPurchasedProduct = true;
            }
            return product;
          });
        }),
        map(products => {
          const blockedItems = this.getBlockedItems();
          const filteredProducts = products.filter(item => blockedItems.indexOf(item.id) === -1);

          return filteredProducts.sort((a, b) => {
            if (a.name > b.name) {
              return 1;
            }
            if (a.name < b.name) {
              return -1;
            }
            return 0;
          });
        })
      );
    }
  }

  getBatchSizes(): number[] {
    return [20, 80, 120, 160];
  }

  getLayouts(): ListLayout<Product>[] {
    if (this.isCustomerPortal && !this.isNOCustomerPortal) {
      return [
        {
          getIconClass: () => 'fas fa-th',
          getListItemComponent: () => HlDisplayProductCardListGridViewComponent,
          getListLayoutComponent: () => ListLayoutMultipleComponent,
          getName: () => 'Grid View'
        },
        {
          getIconClass: () => 'fa fa-list',
          getListItemComponent: () => HlDisplayProductCardListComponent,
          getListLayoutComponent: () => ListLayoutSingleComponent,
          getName: () => 'Product grid'
        }
      ];
    }
    return [
      {
        getIconClass: () => 'fa fa-list',
        getListItemComponent: () => HlDisplayProductCardListComponent,
        getListLayoutComponent: () => ListLayoutSingleComponent,
        getName: () => 'Product grid'
      },
      {
        getIconClass: () => 'fas fa-th',
        getListItemComponent: () => HlDisplayProductCardListGridViewComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'Grid View'
      }
    ];
  }

  getSearchTerms(item: Product): string[] {
    let result = [];
    if (item.id) {
      result.push(item.id);
    }
    if (item.name) {
      result.push(item.name);
    }

    if (item.description) {
      result.push(item.description);
    }

    if (item?.extra?.i18n?.[this.currentLang]?.artbeskr2) {
      result.push(item.extra.i18n[this.currentLang].artbeskr2);
    }

    result = [...result, ...this.getTranslatedTerms(item)];

    const name = this.getName(item);
    if (name) {
      result.push(name);
    }

    if (item.extra.ar.varugruppkod) {
      const value = this.metaDataService.getMetadataValue('vg', 'varugruppbeskr', item.extra.ar.varugruppkod);
      if (value) {
        result.push(value);
      }
    }

    if (item.extra.hlPimItem.ItemMerchandiseType && item.extra.hlPimItem.ItemMerchandiseType.length > 0) {
      result = [...result, ...item.extra.hlPimItem.ItemMerchandiseType.filter(type => !!type)];
    }

    if (item.extra._artnrkund) {
      result.push(item.extra._artnrkund);
    }

    if (item.extra._kuar) {
      result.push(item.extra._kuar);
    }

    let itemDesc = item.extra.ar.artbeskr;
    if (item.extra?.i18n?.[environment.mainCountry]) {
      itemDesc = item.extra.i18n[environment.mainCountry].artbeskr || itemDesc;
    }

    result.push(itemDesc);

    return result;
  }

  private getTranslatedTerms(item: Product): string[] {
    if (!item.extra.i18n) {
      return [];
    }

    const mainCountryCode = environment.mainCountry;
    const selectedLanguageCode = this.translateService.currentLang;
    const result = [];

    const i18n = item.extra.i18n;

    [mainCountryCode, selectedLanguageCode].forEach(languageCode => {
      const languageProperties = i18n[languageCode];
      if (!languageProperties) {
        return;
      }

      if (languageProperties.artbeskrspec) {
        result.push(languageProperties.artbeskrspec);
      }
      if (languageProperties.itemspecweb) {
        result.push(languageProperties.itemspecweb);
      }
      if (languageProperties.artbeskr) {
        result.push(languageProperties.artbeskr);
      }
    });

    return result;
  }

  protected getBlockedItems(): string[] {
    // Customer portal
    if (!environment.sales) {
      if (environment.mainCountry === 'no') {
        return ['527859', '991100', '785710', '534222', '798076', '900000'];
      }
      if (
        environment.mainCountry === 'fr' &&
        (environment.mainCustomer === 'Pharmacies' || environment.mainCustomer === 'General')
      ) {
        return ['527859', '900000'];
      }
      return ['900000'];
    }
  }

  getSearchGroupCss(): string {
    return '';
  }

  getFilterGroupCss(): string {
    return '';
  }

  getName(product: Product): string {
    if (!environment.sales) {
      return product.name;
    }

    const id = product.id;

    // const itemGroup = this.metaDataService.getMetadataValue('vg', 'varugruppbeskr', product.extra.ar.varugruppkod);
    const itemProductSystem = product.extra.hlPimProduct?.ProductSystem;
    const itemDesc =
      this.i18nSeExists(product) && product.extra.i18n.se.artbeskr
        ? product.extra.i18n.se.artbeskr
        : product.extra.ar.artbeskr;
    return `${id}${itemProductSystem ? ' - ' + itemProductSystem : ''} - ${itemDesc}`;
  }

  i18nSeExists(data: Product): boolean {
    return data.extra.i18n && data.extra.i18n.se;
  }

  hideZeroOptions() {
    return true;
  }
}
function peek(arg0: (products: any) => void): import('rxjs').OperatorFunction<Product[], unknown> {
  throw new Error('Function not implemented.');
}

export class CustomerPurchasedProductFilter extends SimpleConfigBaseFilter<Product> {
  getName(): string {
    return 'CUSTOMER_PURCHASED_PRODUCT';
  }
  getOptionIds(item: Product): string[] {
    if (!!item.extra?._customerPurchasedProduct) {
      return ['CUSTOMER_PURCHASED_PRODUCT_FILTER_SELECTED'];
    }
    return [];
  }
  getOptionName(key: string): string {
    return `${key}`;
  }
}

export class RollOutProductFilter extends SimpleConfigBaseFilter<Product> {
  getName(): string {
    return 'ROLL_OUT';
  }
  getOptionIds(item: Product): string[] {
    if (!!item.extra?.hlPimItem?.ItemCSRollOut) {
      return ['ROLL_OUT'];
    }
    return [];
  }
  getOptionName(key: string): string {
    return `${key}`;
  }
}

export class ProductDiscountedItemsFilter extends SimpleConfigBaseFilter<Product> {
  getName(): string {
    return 'DISCOUNTED_ITEMS';
  }
  getOptionIds(item: Product): string[] {
    if (item?.extra?.price?.backendPrice?.levels?.[0]?.extra?.prl?.proc1 > 0) { // This conditions is not correct
      return ['DISCOUNTED_FILTER_SELECTED'];
    }
    return [];
  }
  getOptionName(key: string): string {
    return `${key}`;
  }
}

