<div class="product-detail">
  <div class="product-id">{{ product.id }}</div>
  <div class="product-name text-center">
    {{
      product.extra.i18n && product.extra.i18n[currentLang] && product.extra.i18n[currentLang].name
        ? product.extra.i18n[currentLang].name
        : product.name
    }}
  </div>
  <div class="product-images clearfix">
    <div class="left-image text-right" *ngIf="product.extra.images.length > 1">
      <i class="fas fa-chevron-left" [hidden]="!(indexImage > 0)" (click)="changeImage('-')"></i>
    </div>
    <div class="image">
      <img
        src="product.extra.images[indexImage].s3Uri
          | gungImageUrl
            :'type:thumbnail'
            :'etag:'+product.extra.images[indexImage].s3ETag
            :'background_color:f8f8f8'
        "
        alt=""
      />
    </div>
    <div class="right-image" *ngIf="product.extra.images.length > 1">
      <i
        class="fas fa-chevron-right"
        [hidden]="!(indexImage < product.extra.images.length - 1)"
        (click)="changeImage('+')"
      ></i>
    </div>
  </div>
  <div class="dots-image-select text-center mb-2" *ngIf="product.extra.images.length > 1">
    <div
      class="dot d-inline-block"
      (click)="indexImage = i"
      [class.active]="i === indexImage"
      *ngFor="let iNumber of product.extra.images; let i = index"
    ></div>
  </div>
  <ngb-accordion
    #acc="ngbAccordion"
    class="details-tables"
    (panelChange)="handleAccordionToggle($event)"
    activeIds="ngb-panel-0, stock-delivery"
  >
    <ngb-panel id="stock-delivery">
      <ng-template ngbPanelTitle>
        <div class="d-flex justify-content-between align-items-center w-100">
          <span translate>STOCK_DELIVERY_DATE</span>
          <i
            class="fas"
            [ngClass]="{
              'fa-angle-down': !activePanels['stock-delivery'],
              'fa-angle-up': !!activePanels['stock-delivery']
            }"
          ></i>
        </div>
      </ng-template>
      <ng-template ngbPanelContent>
        <div class="row">
          <div class="col-12">
            <div *ngIf="availability.currentAvailability > 0" class="green">
              {{ availability.currentAvailability }} St <span translate>IN_STOCK</span>
            </div>
            <div *ngIf="availability.currentAvailability === 0" class="orange">
              <span *ngFor="let av of availability.extra._av"
                >{{ av.stock }} St, {{ av.date | date: 'yyyy-MM-dd' }}<br
              /></span>
            </div>
          </div>
        </div>
      </ng-template>
    </ngb-panel>
    <ngb-panel id="ngb-panel-0">
      <ng-template ngbPanelTitle>
        <div class="d-flex justify-content-between align-items-center w-100">
          <span translate>INFORMATION</span>
          <i
            class="fas"
            [ngClass]="{ 'fa-angle-down': !activePanels['ngb-panel-0'], 'fa-angle-up': !!activePanels['ngb-panel-0'] }"
          ></i>
        </div>
      </ng-template>
      <ng-template ngbPanelContent>
        <div class="row">
          <div class="col-12">
            <div class="table-responsive">
              <table class="table attribute-table">
                <tbody>
                  <tr *ngIf="product.extra.pim?.series">
                    <th><span translate class="text-uppercase">SERIE</span></th>
                    <td>{{ product.extra.pim?.series }}</td>
                  </tr>
                  <tr
                    *ngIf="
                      product.extra.i18n &&
                      product.extra.i18n[currentLang] &&
                      product.extra.i18n &&
                      product.extra.i18n[currentLang].color
                    "
                  >
                    <th><span translate class="text-uppercase">COLOR</span></th>
                    <td>{{ product.extra.i18n[currentLang].color }}</td>
                  </tr>
                  <tr
                    *ngIf="
                      product.extra.i18n &&
                      product.extra.i18n[currentLang] &&
                      product.extra.i18n &&
                      product.extra.i18n[currentLang].material
                    "
                  >
                    <th translate class="text-uppercase">MATERIAL</th>
                    <td>{{ product.extra.i18n[currentLang].material }}</td>
                  </tr>
                  <tr *ngIf="product.extra.pim?.dimension">
                    <th translate class="text-uppercase">SIZE</th>
                    <td>{{ product.extra.pim?.dimension }}</td>
                  </tr>
                  <tr *ngIf="product.extra.pim?.cataloguePages">
                    <th translate class="text-uppercase">CATALOUGE_PAGE</th>
                    <td>{{ product.extra.pim.cataloguePages }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </ng-template>
    </ngb-panel>
    <!-- <ngb-panel>
            <ng-template ngbPanelTitle>
              <div class="d-flex justify-content-between align-items-center w-100">
                <strong><span translate>OTHER_INFORMATION</span></strong>
                <i class="fas"
                  [ngClass]="{'fa-angle-down': !activePanels['ngb-panel-1'], 'fa-angle-up': !!activePanels['ngb-panel-1']}"></i>
              </div>
            </ng-template>
            <ng-template ngbPanelContent>
              <div class="row">

              </div>
            </ng-template>
          </ngb-panel> -->
  </ngb-accordion>
</div>
