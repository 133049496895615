import { Component, ElementRef, OnInit } from '@angular/core';
import {
  NavbarComponent,
  AuthService,
  CartService,
  NavbarConfigService,
  GungFlowService,
  FavouriteProductsService,
  GlobalSearchConfigService
} from 'gung-standard';
import { SelectedCustomerService } from 'gung-standard';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { first } from 'rxjs';

@Component({
  selector: 'app-hl-display-navbar',
  templateUrl: './hl-display-navbar.component.html',
  styleUrls: ['./hl-display-navbar.component.scss']
})
export class HlDisplayNavbarComponent extends NavbarComponent implements OnInit {
  favouritesCount = 0;
  showFavourites = environment.showFavourites;
  isManagerCh = environment.bolag === '2400';

  private onCategoriesPage = false;
  public displayShadow = false;
  public isCollapsed = false;
  public isSales = environment.sales;
  public isCustomerPortal = !environment.sales;
  public isManagersPortal = environment.managers;
  private isCustNlSuperunie1600 = !environment.sales && environment.bolag === '1600' && environment.mainCountry === 'nl' && environment.mainCustomer === 'spar';
  public baseCollapseClasses: { [className: string]: boolean } = {
    show: false
  };

  private extraMenuLinks: {
    routerLink: string;
    translateTag: string;
  }[] = [];

  formLink =
    'https://forms.office.com/Pages/ResponsePage.aspx?Host=Teams&lang=%7Blocale%7D&groupId=%7BgroupId%7D&tid=%7Btid%7D&teamsTheme=%7Btheme%7D&upn=%7Bupn%7D&id=9szF6altbkOtvoEZML0OyTSiYr_W1-VOvNHJUznEFClUOUU1SklVNkFSWjlEMTdGMklPUzMxOEEyTyQlQCN0PWcu';
  constructor(
    selectedCustomerService: SelectedCustomerService,
    authService: AuthService,
    router: Router,
    cartService: CartService,
    elementRef: ElementRef,
    navbarConfigService: NavbarConfigService,
    gungFlowService: GungFlowService,
    protected favouriteProductsService: FavouriteProductsService,
    protected globalSearchConfig: GlobalSearchConfigService
  ) {
    super(selectedCustomerService, authService, cartService, elementRef, navbarConfigService, gungFlowService, router, globalSearchConfig);
    authService.getIsAuthenticated().subscribe(authed => {
      this.signedIn = authed;

      if (authed) {
        authService
          .getRoles()
          .pipe(first())
          .subscribe(roles => {
            if (roles.indexOf('SALES') < 0 && roles.indexOf('ADMIN') < 0) {
              this.extraMenuLinks = [
                {
                  routerLink: '/categories',
                  translateTag: environment.sales ? 'SHOP_BY_CATEGORY' : 'HOME'
                },
                {
                  routerLink: '/products',
                  translateTag: 'SHOP_BY_PRODUCT_LIST'
                },
                {
                  routerLink: '/orders',
                  translateTag: 'PREVIOUS_ORDERS'
                },
                {
                  routerLink: '/most-purchased',
                  translateTag: 'MOST_PURCHASED'
                }
              ];
            } else {
              this.extraMenuLinks = [
                {
                  routerLink: '/customers',
                  translateTag: 'MY_CUSTOMERS'
                },
                {
                  routerLink: '/open-orders',
                  translateTag: 'ACTIVITIES'
                },
                {
                  routerLink: '/products',
                  translateTag: 'SHOP_BY_PRODUCT_LIST'
                }
              ];
            }
            if(this.isManagerCh && this.isManagersPortal)
            this.extraMenuLinks.push(
              {
                routerLink: '/orders',
                translateTag: 'PREVIOUS_ORDERS'
              }
            )
            const idx = this.extraMenuLinks.findIndex(m => m.translateTag === 'MOST_PURCHASED');
            if (idx > -1) {
              this.extraMenuLinks.splice(idx, 1);
            }
          });
      }
    });

    this.router.events.subscribe(event => {
      if (this.router.url === '/categories') {
        this.onCategoriesPage = true;
      } else {
        this.onCategoriesPage = false;
      }
    });

    window.addEventListener('scroll', this.scrollListener.bind(this));
  }

  ngOnInit() {
    super.ngOnInit();
    if (this.signedIn) {
      this.favouriteProductsService.getFavouritesByFlow().subscribe((products: any[]) => {
        this.favouritesCount = products.length;
      });
    }
  }

  public isSignedIn(): boolean {
    return this.signedIn;
  }

  public isOnCategoriesPage(): boolean {
    return this.onCategoriesPage;
  }

  public scrollListener(event): void {
    this.displayShadow = this.onCategoriesPage ? window.scrollY >= 240 : window.scrollY >= 80;
  }

  public toggleMobileMenu() {
    this.baseCollapseClasses.show = !this.baseCollapseClasses.show;
    this.isCollapsed = this.baseCollapseClasses.show;
  }

  public getExtraMenuLinks(): { routerLink: string; translateTag: string }[] {
    return this.extraMenuLinks;
  }
}
