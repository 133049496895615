<div *ngIf="initStatus === checkoutInitStates.Failed" class="row mt-5">
  <div class="col-6 offset-3">
    <div class="checkout-btn-container previous p-4 d-flex flex-column justify-content-center mb-3">
      <h4 class="highlight mb-4" translate>CART_IS_EMPTY</h4>
      <a class="btn-block btn btn-primary" routerLink="/products" translate>CONTINUE_SHOPPING</a>
    </div>
  </div>
</div>

<lib-custom-stepper *ngIf="initStatus === checkoutInitStates.Completed">
  <!-- CART -->
  <cdk-step [label]="'CART'">
    <div class="border-gray">
      <lib-cart></lib-cart>
      <div class="row expedition-row">
        <div
          class="col-10 ml-2"
          style="font-size: 10px"
          *ngIf="!isSales && anyCartItemRequestQtyAboveAvailable"
          [innerHTML]="'CART_ITEM_QTY_ABOVE_AVAILABLE_QTY' | translate"
        ></div>
        <div class="col-3 align-self-end">
          <div class="checkout-btn-container previous p-4 d-flex flex-column justify-content-center mb-3">
            <a class="btn-block btn btn-primary" routerLink="/products" translate>CONTINUE_SHOPPING</a>
          </div>
        </div>
        <div class="col-3 offset-1 align-self-end">
          <lib-discount-input
            class="p-4 mb-3 d-block"
            *ngIf="!isUkBolag"
            [(ngModel)]="campaignCode"
            (discountDescription)="setDiscountDescription($event)"
            [readonly]="readOnlyPrice"
          ></lib-discount-input>
          <div
            class="alert alert-success position-absolute mt-n4"
            *ngIf="discountDescription.length > 0"
            style="z-index: 1"
          >
            {{ discountDescription | translate }}
          </div>
          <!-- <div class="form-group">
              <label for="campaignCode" class="control-label" translate>CAMPAIGN_CODE</label>
              <input type="text" class="form-control" name="campaignCode" id="campaignCode">
            </div> -->
        </div>
        <div class="col-3 d-flex align-self-end">
          <app-hl-delivery-date-picker
            *ngIf="!isCurrysPortal"
            class="p-4"
            [deliveryMethod]="checkoutObject.extra.procargs.levsattkod"
            (selectedDate)="setSelectedDeliveryDate($event)"
          ></app-hl-delivery-date-picker>
        </div>
        <div class="col-2 vhr checkout-btn-container align-self-end">
          <div class="checkout-btn-container next d-flex flex-column py-4 px-2 justify-content-center mb-3">
            <app-hl-display-lib-cart-summary></app-hl-display-lib-cart-summary>
            <div [ngSwitch]="total && total.totalValue > getMinValueToOrder() && !isSales">
              <div *ngSwitchCase="true">
                <button type="button" class="btn btn-primary btn-block" cdkStepperNext translate>
                  CONTINUE_TO_SHIPPING
                </button>
              </div>
              <div *ngSwitchCase="false">
                <button type="button" class="btn btn-secondary btn-block" disabled cdkStepperNext translate>
                  LOW_VALUE
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </cdk-step>
  <cdk-step
    *ngFor="let formItem of checkoutForms | keyvalue"
    [stepControl]="formItem.value"
    [label]="'SHIPPING_INFORMATION'"
  >
    <div class="border-gray">
      <div class="row">
        <div class="col-10 offset-1">
          <h2 class="mb-4" translate>SHIPPING_INFORMATION</h2>
        </div>
      </div>
      <div class="form row" [formGroup]="formItem.value">
        <div [formGroupName]="'procargs'" class="col-5 offset-1">
          <div *ngIf="isDeliveryInformationFromDeliveryAddresses">
            <div class="form-group">
              <label for="procargs.ordlevadr1" class="control-label" translate>CUSTOMER_PORTAL_OH_ORDLEVADR1</label>
              <select
                name="procargs.ordlevadr1"
                id="procargs.ordlevadr1"
                class="form-control"
                [value]="deliveryAddressesSelectedIndex"
                (change)="onDeliveryAddressSelected($event.target.value)"
              >
                <option [value]="i" *ngFor="let option of deliveryAddresses; let i = index">
                  {{ option.addressValues.ordlevadr1 }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <label for="procargs.ordlevadr3" class="control-label" translate>CUSTOMER_PORTAL_OH_ORDLEVADR3</label>
              <select
                name="procargs.ordlevadr3"
                id="procargs.ordlevadr3"
                class="form-control"
                [value]="deliveryAddressesSelectedIndex"
                (change)="onDeliveryAddressSelected($event.target.value)"
              >
                <option [value]="i" *ngFor="let option of deliveryAddresses; let i = index">
                  {{ option.addressValues.ordlevadr3 }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <label for="procargs.ordlevadr2" class="control-label" translate>CUSTOMER_PORTAL_OH_ORDLEVADR2</label>
              <input
                class="form-control"
                type="text"
                name="procargs.ordlevadr2"
                id="procargs.ordlevadr2"
                [formControlName]="'ordlevadr2'"
              />
            </div>
            <div class="form-group">
              <label for="procargs.ordlevadr4" class="control-label" translate>CUSTOMER_PORTAL_OH_ORDLEVADR4</label>
              <input
                class="form-control"
                type="text"
                name="procargs.ordlevadr4"
                id="procargs.ordlevadr4"
                [formControlName]="'ordlevadr4'"
              />
            </div>
            <div class="form-group">
              <label for="procargs.ftgpostnr" class="control-label" translate>ZIP</label>
              <input
                class="form-control"
                type="text"
                name="procargs.ftgpostnr"
                id="procargs.ftgpostnr"
                [formControlName]="'ftgpostnr'"
              />
            </div>
            <lib-meta-select-input formControlName="ordlevadrlandskod" table="xr" metaId="landskod" metaDisplay="land">
            </lib-meta-select-input>
          </div>
          <div *ngIf="!isDeliveryInformationFromDeliveryAddresses">
            <div class="form-group">
              <label for="procargs.ordlevadr2" class="control-label" translate>ADDRESS1</label>
              <input
                class="form-control"
                type="text"
                name="procargs.ordlevadr2"
                id="procargs.ordlevadr2"
                [formControlName]="'ordlevadr2'"
              />
            </div>
            <div class="form-group">
              <label for="procargs.ordlevadr3" class="control-label" translate>ADDRESS2</label>
              <input
                class="form-control"
                type="text"
                name="procargs.ordlevadr3"
                id="procargs.ordlevadr3"
                [formControlName]="'ordlevadr3'"
              />
            </div>
            <div class="form-group">
              <label for="procargs.ordlevadr4" class="control-label" translate>CITY</label>
              <input
                class="form-control"
                type="text"
                name="procargs.ordlevadr4"
                id="procargs.ordlevadr4"
                [formControlName]="'ordlevadr4'"
              />
            </div>
            <div class="form-group">
              <label for="procargs.ftgpostnr" class="control-label" translate>ZIP</label>
              <input
                class="form-control"
                type="text"
                name="procargs.ftgpostnr"
                id="procargs.ftgpostnr"
                [formControlName]="'ftgpostnr'"
              />
            </div>
            <lib-meta-select-input formControlName="ordlevadrlandskod" table="xr" metaId="landskod" metaDisplay="land">
            </lib-meta-select-input>
          </div>
        </div>
        <div class="col-5">
          <div class="form-group" *ngIf="!getDisableEmail()">
            <label for="confirmationEmail" class="control-label" translate>EMAIL</label>
            <input
              type="text"
              class="form-control"
              name="confirmationEmail"
              id="confirmationEmail"
              formControlName="confirmationEmail"
            />
          </div>
          <span [formGroupName]="'oh'">
            <div class="form-group" *ngIf="isNOCustomerPortal">
              <label for="oh.kundref2" class="control-label" translate>OH_KUNDREF2</label>
              <input
                class="form-control"
                type="text"
                name="oh.kundref2"
                id="oh.kundref2"
                [formControlName]="'kundref2'"
              />
              <div *ngIf="showKundref2Error" class="input-required small my-1">*<span translate>REQUIRED</span></div>
            </div>
            <div class="form-group" *ngIf="!isNOCustomerPortal">
              <label for="oh.kundbestnr" class="control-label" translate>OH_KUNDBESTNR</label>
              <span *ngIf="showKundbestnr2Error && isCurrysPortal" class="input-required small my-1 float-right">*<span translate>REQUIRED</span></span>
              <input
                class="form-control"
                type="text"
                name="oh.kundbestnr"
                id="oh.kundbestnr"
                [formControlName]="'kundbestnr'"
              />
            </div>
            <div class="form-group">
              <label for="oh.editext" class="control-label" translate>MESSAGE</label>
              <textarea
                class="form-control"
                type="text"
                name="oh.editext"
                id="oh.editext"
                [formControlName]="'editext'"
              ></textarea>
            </div>
          </span>
        </div>
      </div>

      <div class="row">
        <div class="col-10 offset-1">
          <div class="row expedition-row justify-content-between">
            <div class="col-3 align-self-end">
              <div class="checkout-btn-container previous p-4 d-flex flex-column justify-content-center">
                <button type="button" class="btn btn-primary" cdkStepperPrevious translate>CART</button>
              </div>
            </div>

            <div class="col-3 align-self-end">
              <div class="checkout-btn-container next p-4 d-flex flex-column justify-content-center">
                <app-hl-display-lib-cart-summary></app-hl-display-lib-cart-summary>
                <button type="button" class="btn btn-primary" cdkStepperNext [disabled]="!getIsShippinInfoStepValid() || !getIsKundbestnrValid()">
                  <span *ngIf="!isUkBolag" translate>CONFIRM</span>
                  <span *ngIf="isUkBolag" translate>CONTINUE_TO_BILLING</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </cdk-step>

  <!-- SHIPPING END -->

  <!-- BILLING -->
  <cdk-step [label]="'BILLING_INFORMATION'">
    <div class="border-gray">
      <div class="row">
        <div class="col-10 offset-1">
          <h2 class="mb-4" translate>BILLING_INFORMATION</h2>
        </div>
      </div>
      <div class="row" *ngIf="billingCustomer">
        <div class="col-5 offset-1">
          <div class="form-group">
            <label class="control-label" translate>NAME</label>
            <input disabled="disabled" class="form-control" type="text" [ngModel]="billingCustomer['ftgnamn']" />
          </div>
          <div class="form-group">
            <label class="control-label">
              <span *ngIf="!isSales" translate> CUSTOMER_PORTAL_ADDRESS2 </span>
              <span *ngIf="isSales" translate> ADDRESS2 </span>
            </label>
            <input disabled="disabled" class="form-control" type="text" [ngModel]="billingCustomer['ftgpostadr2']" />
          </div>
          <div class="form-group">
            <label class="control-label">
              <span *ngIf="!isSales" translate> CUSTOMER_PORTAL_ADDRESS1 </span>
              <span *ngIf="isSales" translate> ADDRESS1 </span>
            </label>
            <input disabled="disabled" class="form-control" type="text" [ngModel]="billingCustomer['ftgpostadr1']" />
          </div>
        </div>
        <div class="col-5">
          <div class="form-group">
            <label class="control-label">
              <span *ngIf="!isSales" translate> CUSTOMER_PORTAL_CITY </span>
              <span *ngIf="isSales" translate> CITY </span>
            </label>
            <input disabled="disabled" class="form-control" type="text" [ngModel]="billingCustomer['ftgpostadr3']" />
          </div>
          <div class="form-group">
            <label class="control-label" translate>ZIP</label>
            <input disabled="disabled" class="form-control" type="text" [ngModel]="billingCustomer['ftgpostnr']" />
          </div>
          <lib-meta-select-input
            disabled="disabled"
            [(ngModel)]="billingCustomer['landskod']"
            table="xr"
            metaId="landskod"
            metaDisplay="land"
          >
          </lib-meta-select-input>
        </div>
      </div>
      <div class="row">
        <div class="col-10 offset-1">
          <div class="row expedition-row justify-content-between">
            <div class="col-3 align-self-end">
              <div class="checkout-btn-container previous p-4 d-flex flex-column justify-content-center">
                <button type="button" class="btn btn-primary" cdkStepperPrevious translate>BACK</button>
              </div>
            </div>
            <div class="col-3 align-self-end">
              <div class="checkout-btn-container next p-4 d-flex flex-column justify-content-center">
                <app-hl-display-lib-cart-summary></app-hl-display-lib-cart-summary>
                <button type="button" class="btn btn-primary" (click)="submit()" cdkStepperNext>
                  {{ getSubmitButtonText() }}
                </button>
                <div translate *ngIf="isCoopPortal || isManagersNisa" class="pt-1">NO_FREIGHT_NO_VAT</div>
              </div>
            </div>
          </div>
          <div class="row justify-content-end mt-2">
            <div class="col-3">
              <div class="alert alert-success" *ngIf="discountDescription.length > 0">
                {{ discountDescription | translate }}
              </div>
              <span translate>CHECKOUT_CONDITIONS</span>&nbsp;<a
                href=""
                [routerLink]="'/terms-and-conditions'"
                translate
                >CHECKOUT_CONDITIONS_LINK</a
              >
              <br />
              <span
                *ngIf="!(isFRCustomerPortalPharma || isFRCustomerPortal1600General) && !isNOCustomerPortal"
                style="font-size: 10px; font-style: italic"
                translate
                >FINAL_FREIGHT_CONDITIONS</span
              >
              <span *ngIf="(isFRCustomerPortalPharma || isFRCustomerPortal1600General) && isCardPaymentCustomer">
                <img class="img-fluid" src="./assets/card-payment-logos-checkout_v1.png" alt="" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </cdk-step>
  <!-- BILLING END -->
  <!-- SUBMIT ORDER -->
  <cdk-step [label]="''">
    <div class="border-gray">
      <div *ngIf="!submitting">
        <app-hl-display-thank-you [receipt]="submittedCart"></app-hl-display-thank-you>
      </div>
      <div *ngIf="submitting">
        <div *ngIf="isCreditHardBlocked; else isLoading">
          <div class="row expedition-row justify-content-between">
            <div class="col-3 align-self-end">
              <div class="checkout-btn-container previous p-4 d-flex flex-column justify-content-center">
                <button type="button" class="btn btn-primary" cdkStepperPrevious translate>BACK</button>
              </div>
            </div>
          </div>
        </div>
        <ng-template #isLoading>
          <div
            class="progress progress-bar progress-bar-striped progress-bar-animated bg-primary"
            role="progressbar"
            aria-valuenow="75"
            aria-valuemin="0"
            aria-valuemax="100"
            style="width: 75%"
          ></div>
        </ng-template>
      </div>
    </div>
  </cdk-step>
  <!-- SUBMIT ORDER END -->
</lib-custom-stepper>

<div class="row mt-n4" *ngIf="initStatus !== checkoutInitStates.Failed">
  <div class="col">
    <div
      class="border-gray mx-15"
      *ngIf="
        !(isFRCustomerPortalPharma || isFRCustomerPortal1600General) &&
        !isNOCustomerPortal &&
        !isCoopPortal &&
        !isManagersNisa &&
        !isCustNlSuperunie1600 &&
        !isCurrysPortal
      "
    >
      <p class="mx-15" [innerHTML]="'ADVANCE_DELIVERY_INFORMATION' | translate"></p>
      <p class="mx-15" [innerHTML]="'FREIGHT_INFORMATION' | translate">FREIGHT_INFORMATION</p>
      <p class="mx-15" [innerHTML]="'MINIMUM_ORDER_INFORMATION_PHARMA_PORTAL_VALUE' | translate : { value: minOrderQty }"></p>
    </div>
    <div
      class="border-gray mx-15"
      *ngIf="isCurrysPortal"
    >
      <p class="mx-15" [innerHTML]="'ADVANCE_DELIVERY_INFORMATION' | translate"></p>
      <p class="mx-15" [innerHTML]="'MINIMUM_ORDER_INFORMATION_CURRYS_PORTAL_VALUE' | translate : { value: minOrderQty }"></p>
    </div>
    <div
      class="border-gray mx-15"
      *ngIf="isCustNlSuperunie1600"
    >
      <p class="mx-15" [innerHTML]="'FREIGHT_INFORMATION' | translate">FREIGHT_INFORMATION</p>
    </div>
    <div class="border-gray mx-15" *ngIf="isFRCustomerPortal1600General || isFRCustomerPortalPharma">
      <p class="mx-15" [innerHTML]="'VAT_INFORMATION_PHARMA_PORTAL' | translate" *ngIf="isCardPaymentCustomer"></p>
      <p class="mx-15" [innerHTML]="'ADVANCE_DELIVERY_INFORMATION' | translate"></p>
      <p
        class="mx-15"
        [innerHTML]="'FREIGHT_INFORMATION_PHARMA_PORTAL_VALUE' | translate : { value: freight, min: freightMin }"
      ></p>
      <p
        class="mx-15"
        [innerHTML]="
          'ADMIN_FEE_INFORMATION_PHARMA_PORTAL_VALUE' | translate : { value: adminCharge, min: adminChargeMin }
        "
      ></p>
      <p
        class="mx-15"
        [innerHTML]="'MINIMUM_ORDER_INFORMATION_PHARMA_PORTAL_VALUE' | translate : { value: minOrderQty }"
      ></p>
    </div>
    <div class="border-gray mx-15" *ngIf="isNOCustomerPortal">
      <p class="mx-15" [innerHTML]="'ADVANCE_DELIVERY_INFORMATION' | translate"></p>
      <p
        *ngIf="freight > 0"
        class="mx-15"
        [innerHTML]="'FREIGHT_INFORMATION_NO_CUSTOMER' | translate : { value: freight, min: freightMin }"
      ></p>
      <p
        *ngIf="adminCharge > 0"
        class="mx-15"
        [innerHTML]="'ADMIN_FEE_INFORMATION_NO_CUSTOMER' | translate : { value: adminCharge, min: adminChargeMin }"
      ></p>
    </div>
  </div>
</div>
<!--
<div class="row mt-n4">
  <div class="col">
    <div class="border-gray mx-15"
      *ngIf="!(isFRCustomerPortalPharma || isFRCustomerPortal1600General) && !isNOCustomerPortalCoop && !isNOCustomerPortalNorgesGroupAndSpar && !isNOCustomerPortalReitanGroup">
      <p class="mx-15" [innerHTML]="'ADVANCE_DELIVERY_INFORMATION' | translate"></p>
      <p class="mx-15" [innerHTML]="'FREIGHT_INFORMATION' | translate">FREIGHT_INFORMATION</p>
      <p class="mx-15" [innerHTML]="'MINIMUM_ORDER_INFORMATION' | translate"></p>
    </div>
    <div class="border-gray mx-15" *ngIf="(isFRCustomerPortalPharma || isFRCustomerPortal1600General)">
      <p class="mx-15" [innerHTML]="'VAT_INFORMATION_PHARMA_PORTAL' | translate" *ngIf="isCardPaymentCustomer"></p>
      <p class="mx-15" [innerHTML]="'ADVANCE_DELIVERY_INFORMATION' | translate"></p>
      <p class="mx-15" [innerHTML]="'FREIGHT_INFORMATION_PHARMA_PORTAL_VALUE' | translate:{value: freight}"></p>
      <p class="mx-15" [innerHTML]="'ADMIN_FEE_INFORMATION_PHARMA_PORTAL_VALUE' | translate:{value: adminCharge}"></p>
      <p class="mx-15" [innerHTML]="'MINIMUM_ORDER_INFORMATION_PHARMA_PORTAL_VALUE' | translate:{value: minOrderQty}"></p>
    </div>
    <div class="border-gray mx-15" *ngIf="isNOCustomerPortalCoop">
      <p class="mx-15" [innerHTML]="'ADVANCE_DELIVERY_INFORMATION' | translate"></p>
      <p class="mx-15" [innerHTML]="'FREIGHT_INFORMATION_COOP_CUSTOMER' | translate"></p>
    </div>
    <div class="border-gray mx-15" *ngIf="isNOCustomerPortalNorgesGroupAndSpar">
      <p class="mx-15" [innerHTML]="'ADVANCE_DELIVERY_INFORMATION' | translate"></p>
      <p class="mx-15" [innerHTML]="'ADMIN_FEE_INFORMATION_NORGE_GROUP_SPAR_PORTAL' | translate"></p>
    </div>
    <div class="border-gray mx-15" *ngIf="isNOCustomerPortalReitanGroup">
      <p class="mx-15" [innerHTML]="'ADVANCE_DELIVERY_INFORMATION' | translate"></p>
      <p class="mx-15" [innerHTML]="'ADMIN_FEE_INFORMATION_REITA_GROUP_PORTAL' | translate"></p>
    </div>
  </div>
</div>
-->
