import { Component } from '@angular/core';
import { ListItemRendererComponent } from 'gung-list';
import { Product } from '../../../../models';
import { PlanogramAddItemsService } from '../../services/planogram-add-items.service';

@Component({
  selector: 'lib-planogram-product-grid',
  templateUrl: './planogram-product-grid.component.html',
  styleUrls: ['./planogram-product-grid.component.css']
})
export class PlanogramProductGridComponent extends ListItemRendererComponent<Product[]>{

  constructor(protected planogramAddItemsService: PlanogramAddItemsService){
    super();
  }

  onDragStart(event, product) {
    this.planogramAddItemsService.draggedItem = product;
  }
}
