import { Component, OnInit } from '@angular/core';
import { ListItemRendererComponent } from 'gung-list';
import { Product } from '../../models/product';

@Component({
  selector: 'lib-product-export-card',
  templateUrl: './product-export-card.component.html',
  styleUrls: ['./product-export-card.component.scss']
})
export class ProductExportCardComponent extends ListItemRendererComponent<Product> implements OnInit {
  constructor() {
    super();
  }

  ngOnInit() {}
}
