import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  AuthService,
  CartTotalsService,
  CheckoutObject,
  CheckoutStepComponent,
  SelectedCustomerService,
  TotalObject
} from 'gung-standard';
import { Subject, first, takeUntil } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { ActionType, HlDisplayMsflowArgumentService } from '../../../../services/hl-display-msflow-argument.service';
import { JeevesCheckCreditService } from 'gung-standard-jeeves';

@Component({
  selector: 'app-hl-summarize-order-step',
  templateUrl: './hl-summarize-order-step.component.html',
  styleUrls: ['./hl-summarize-order-step.component.scss']
})
export class HlSummarizeOrderStepComponent extends CheckoutStepComponent implements OnInit, OnDestroy {
  public showQuote = false;
  private isQuote = false;
  private internalOnNextBtnClicked = new Subject<void>();

  public composedOnNextBtnClicked = this.internalOnNextBtnClicked.asObservable();

  sales: boolean = environment.sales;
  isPortalFr = environment.mainCountry === 'fr';
  isPortalDe = environment.mainCountry === 'de';
  isPortalNo = environment.mainCountry === 'no';
  isPortalSe = environment.mainCountry === 'se';
  isPortalFi = environment.mainCountry === 'fi';
  isPortalBenelux =
    environment.mainCountry === 'fr' && environment.quoteConfirmationEmailAddress === 'OrdersBenelux@hl-display.com';
  
  isCoopPortal = environment.mainCustomer === 'coop';
  isManagersNisa = environment.managers && environment.mainCountry === 'en';

  public showApprovalQuote = false;
  private isApprovalQuote = false;
  public showCreateOrder = false;

  envShowApprovalQuote = environment.showApprovalQuote;

  private isSampleOrder = false;
  public showCreateSampleOrder = false;
  envShowCreateSampleOrder = environment.showCreateSampleOrder;

  isCreditHardBlock: boolean = false;
  isCreditSoftBlock: boolean = true;
  creditLeft: number = 0;
  totals: TotalObject = { totalQuantity: 0, totalValue: 0, totalRows: 0 };
  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected authService: AuthService,
    protected hlDisplayMsflowArgumentService: HlDisplayMsflowArgumentService,
    protected selectCustomerService: SelectedCustomerService,
    protected jeevesCheckCreditService: JeevesCheckCreditService,
    protected cartTotalsService: CartTotalsService
  ) {
    super();

    authService.getRoles().subscribe(roles => {
      this.showQuote = roles.includes('SALES');
    });
  }

  ngOnInit() {
    super.ngOnInit();

    this.showApprovalQuote = this.envShowApprovalQuote && this.sales;
    this.showCreateOrder =
      !this.sales || this.isPortalNo || this.isPortalSe || this.isPortalFi || this.isPortalDe || this.isPortalBenelux;
    console.log('showCreateOrder', this.showCreateOrder, this.sales, this.isPortalNo, this.isPortalSe, this.isPortalFi);
    this.showCreateSampleOrder = this.envShowCreateSampleOrder && this.sales;

    this.cartTotalsService
      .getTotals()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(totalsObject => {
        this.totals = totalsObject;
      });

    this.selectCustomerService
      .getSelectedCustomer()
      .pipe(first())
      .subscribe(customer => {
        if (customer.extra?.kus?.kreditsparr == '1') {
          this.isCreditHardBlock = true;
          this.isCreditSoftBlock = false;
        } else if (customer.extra?.kus?.kreditsparrtyp == 1) {
          this.isCreditHardBlock = false;
          this.isCreditSoftBlock = true;
        } else {
          this.isCreditSoftBlock = false;
        }

        const billingCustomerId: string = customer?.extra?._billingCustomer?.id;
        if (billingCustomerId && this.isCreditSoftBlock) {
          this.jeevesCheckCreditService
            .getAmountOfCredit(billingCustomerId)
            .pipe(first())
            .subscribe(credit => {
              this.creditLeft = Number(credit?.creditLeft) || 0;
            });
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  sendApprovalQuote() {
    this.isApprovalQuote = true;
    this.createOrder();
  }

  createQuote() {
    this.isQuote = true;
    this.internalOnNextBtnClicked.next();
  }

  createOrder() {
    this.internalOnNextBtnClicked.next();
  }

  createSampleOrder() {
    this.isSampleOrder = true;
    this.internalOnNextBtnClicked.next();
  }

  getLabel() {
    return 'CONFIRM';
  }

  handleStepDone(checkout: CheckoutObject) {
    let sendCheckout = checkout;
    if (this.isQuote || this.isApprovalQuote) {
      sendCheckout = {
        ...sendCheckout,
        extra: {
          ...sendCheckout.extra,
          _approval_quote: this.isApprovalQuote ? this.isApprovalQuote : undefined,
          oh: {
            ...checkout.extra.oh,
            ordstat: 0
          }
        }
      };

      sendCheckout.extra[this.hlDisplayMsflowArgumentService.getArgumentKey()] =
        this.hlDisplayMsflowArgumentService.createMsFlowArguments(ActionType.Offer);
    }
    if (this.isSampleOrder) {
      sendCheckout = {
        ...sendCheckout,
        extra: {
          ...sendCheckout.extra,
          oh: {
            ...checkout.extra.oh,
            kundfraktdeb: 0, // Charge freight box
            q_hl_chargepalletcost: '0' // Box Charge Pallet Cost
          },
          opr: {
            ...checkout.extra.opr,
            vb_pris: 0 // PrcCurr
          },
          procargs: {
            ...checkout.extra.procargs,
            ordtyp: 203 // Order type
          }
        }
      };
      sendCheckout.extra[this.hlDisplayMsflowArgumentService.getArgumentKey()] =
        this.hlDisplayMsflowArgumentService.createMsFlowArguments(ActionType.Sample);
    }

    // If we don't have set the msflow arguments before this step (i.e it is not a sample or an offer) we can assume
    // that it is an order.
    if (!sendCheckout.extra[this.hlDisplayMsflowArgumentService.getArgumentKey()]) {
      sendCheckout.extra[this.hlDisplayMsflowArgumentService.getArgumentKey()] =
        this.hlDisplayMsflowArgumentService.createMsFlowArguments(ActionType.Order);
    }

    this.stepDone.emit(sendCheckout);
  }
}
