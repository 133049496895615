import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HlDisplayHelperService {
  constructor() {}

  public checkRequestPricelistAssortmentWithWebCondition(prislista: string): string {
    let prislistaWithCondition = prislista;
    if (environment.requestPricelistAssortmentWithWebCondition) {
      prislistaWithCondition += '_WEB';
    } else if (!environment.sales && !environment.managers) {
      prislistaWithCondition += '_CUSTOMER';
    }
    return prislistaWithCondition;
  }
}
