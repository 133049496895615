import { Component, Input, OnChanges, OnInit, OnDestroy } from '@angular/core';
import { RenderFilter } from 'gung-list';
import { fromEventPattern, Subject } from 'rxjs';
import { takeUntil } from 'rxjs';
import { environment } from './../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { portals } from '../../portal-flags';

const imageDataMappings = {
  default: {
    image: 'DEFAULT',
    imagePharma: 'DEFAULT_PHARMA',
    title: ''
  },
  'Concept Vrac': {
    image: 'CONCEPT_VRAC',
    title: 'Concept Vrac'
  },
  'Facing automatique': {
    image: 'FACING_AUTOMATIQUE',
    title: 'Facing Automatique'
  },
  'Porte-étiquettes': {
    image: 'PORTES_ETIQUETTES',
    title: 'Porte-étiquettes'
  },
  'Présentoirs & Paniers': {
    image: 'FRUIT',
    title: 'Présentoirs & Paniers'
  },
  'Séparateurs & Accessoires': {
    image: 'SEPARATORS',
    title: 'Séparateurs & Accessoires'
  },
  'Protection et sécurité': {
    image: 'PROTECTION',
    title: 'Protection et sécurité'
  },
  GravityScoopBins: {
    image: 'CONCEPT_VRAC',
    title: null // when title is null then the title is obtained from the filter (which contains the i18n value name of the category filter)
  },
  ShelfManagementAutomatic: {
    image: 'FACING_AUTOMATIQUE',
    title: null
  },
  PriceCommunication: {
    image: 'PORTES_ETIQUETTES',
    title: null
  },
  FreshProduceSolutions: {
    image: 'FRUIT',
    title: null
  },
  ShelfManagementManual: {
    image: 'SEPARATORS',
    imagePharma: 'SEPARATORS_PHARMA',
    title: null
  },
  ProtectionSafetyEquipment: {
    image: 'PROTECTION',
    title: null
  },
  LightingPowerInfrastructure: {
    image: 'DIGITAL_MISE_EN_LUMIERE',
    imagePharma: 'DIGITAL_MISE_EN_LUMIERE_PHARMA',
    title: null
  },
  StoreCommunicationBranding: {
    image: 'COMMUNICATION_SIGNALETIQUE',
    title: null
  },
  'LightingPowerInfrastructure;DigitalScreensAccessories': {
    // equals to LightingPowerInfrastructure
    image: 'DIGITAL_MISE_EN_LUMIERE',
    title: null
  },
  'DigitalScreensAccessories;LightingPowerInfrastructure': {
    // equals to LightingPowerInfrastructure
    image: 'DIGITAL_MISE_EN_LUMIERE',
    title: null
  },
  'FreshProduceSolutions;DisplayAccessories': {
    // equals to FreshProduceSolutions
    image: 'FRUIT',
    title: null
  },
  'DisplayAccessories;FreshProduceSolutions': {
    // equals to FreshProduceSolutions
    image: 'FRUIT',
    title: null
  },
  ShelfManagementManual_NL: {
    image: 'Header_1_(small_long)',
    title: null
  },
  ShopFittingEquipment_NL: {
    image: 'Header_7_(Small_long).png',
    title: null
  },
  PriceCommunication_NL: {
    image: 'Header_3_(small_long).png',
    title: null
  },
  FreshProduceSolutions_NL: {
    image: 'Header_4_(small_long).png',
    title: null
  }
};

const getMappingFromRenderFilters = (filters: RenderFilter[]): { image: string; title: string } | undefined => {
  const selectedCategory = filters
    .filter(filter => filter.name === 'CATEGORY')
    .map(filter => filter.valueList)
    .reduce((acc, curr) => [...curr, ...acc], [])
    .filter(value => value.selected)[0];

  // reset title of default to empty
  imageDataMappings.default.title = '';

  if (!selectedCategory) {
    return imageDataMappings.default;
    // const specialSelection = filters
    //   .filter(filter => filter.name === 'MOUSQUETAIRES_SELECTION')
    //   .some(filter => filter.valueList.some(v => v.selected));
    // if (!specialSelection) {
    //   return undefined;
    // }
    // return imageDataMappings.selection;
  }

  let imageDataMapping = imageDataMappings[selectedCategory.valueId] || imageDataMappings.default;

  const isFRCustomerPortalPharma =
    !environment.sales && environment.mainCountry === 'fr' && environment.mainCustomer === 'Pharmacies';
  const isFRCustomerPortal1600General =
    !environment.sales &&
    environment.mainCountry === 'fr' &&
    environment.bolag === '1600' &&
    environment.mainCustomer === 'General';
  if (isFRCustomerPortalPharma || isFRCustomerPortal1600General) {
    if ((selectedCategory.valueId || '').indexOf('FreshProduceSolutions') > -1) {
      imageDataMapping.image = 'PRESENTOIRS_PANIERS';
    }
  }
  if (isFRCustomerPortal1600General) {
    if ((selectedCategory.valueId || '').indexOf('ShelfManagementManual') > -1) {
      imageDataMapping.image = 'SEPARATORS_PORTAIL';
      imageDataMapping.imagePharma = 'SEPARATORS_PORTAIL';
    }
  }
  const isCustNlSuperunie1600 = !environment.sales && environment.bolag === '1600' && environment.mainCountry === 'nl' && environment.mainCustomer === 'spar';
  if (isCustNlSuperunie1600 && imageDataMappings[selectedCategory.valueId + '_NL']) {
    imageDataMapping = imageDataMappings[selectedCategory.valueId + '_NL'];
  }

  // in case title is not set then get its value from the filter
  if (!imageDataMapping.title) {
    imageDataMapping.title = selectedCategory.valueName || '';
  }
  return imageDataMapping;
};

@Component({
  selector: 'app-hl-product-list-header',
  templateUrl: './hl-product-list-header.component.html',
  styleUrls: ['./hl-product-list-header.component.scss']
})
export class HlProductListHeaderComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  renderFilters: RenderFilter[];

  private unsubscribe: Subject<boolean> = new Subject<boolean>();

  showImage = true;
  imageData: { image: string; title: string } | undefined;
  isSales = environment.sales;
  public isCustNlSuperunie1600 = !environment.sales && environment.bolag === '1600' && environment.mainCountry === 'nl' && environment.mainCustomer === 'spar';

  constructor(public translateService: TranslateService) {}

  ngOnInit(): void {
    if (this.isSales) {
      const mediaQuery = matchMedia('screen and (max-width: 576px), screen and (max-height: 576px)');
      this.showImage = !mediaQuery.matches;

      const mediaObserver = fromEventPattern<MediaQueryListEvent>(
        mediaQuery.addListener.bind(mediaQuery),
        mediaQuery.removeListener.bind(mediaQuery)
      );

      mediaObserver
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(mediaQueryResult => (this.showImage = !mediaQueryResult.matches));
    }
  }

  ngOnChanges() {
    const mapping = getMappingFromRenderFilters(this.renderFilters);
    if (portals.isCurrysPortal) {
      const image = 'DEFAULT_CURRYS.png';
      this.imageData = { title: mapping?.title || '', image: `assets/product-list-headers/${image}` };
      return;
    }

    if (!!mapping) {
      const title = mapping.title;
      let image = mapping.image;

      if (portals.isFRCustomerPortalPharma || portals.isFRCustomerPortal1600General) {
        const imagePharmaKey = 'imagePharma';

        if (!!mapping[imagePharmaKey]) {
          image = mapping[imagePharmaKey];
        }
      }

      if (image.includes('.')) {
        this.imageData = { title, image: `assets/product-list-headers/${image}` };
      } else {
        this.imageData = { title, image: `assets/product-list-headers/${image}.jpg` };
      }
    } else {
      this.imageData = undefined;
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }
}
