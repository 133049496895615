<div class="card">
  <div class="card-body order-card-list">
    <div class="row border-top">
      <div class="col">
        <a routerLink="/orders/{{ data.id }}/products">
          {{ data.id }}
        </a>
      </div>
      <div class="col text-center">
        {{ data.extra.oh.vbordsumexklmoms | number: '1.2-2' }} {{ data.extra.oh.valkod }}
      </div>
      <div class="col text-center">
        {{ data.extra.oh.orddatum | date: (is1210Bolag ? 'yyyy-MM-dd' : 'dd-MM-yy') }}
      </div>
      <div class="col-2 text-center">
        <span translate>CLICK</span>&nbsp;<a
          routerLink="/orders/{{ data.id }}/products"
          (click)="$event.stopPropagation()"
          translate
          >HERE</a
        >
      </div>
      <div class="col-2 text-center">
        <span *ngIf="isUkBolag" translate> OH_ORDSTAT_{{ data.extra.oh.ordstat }} </span>
        <span *ngIf="!isUkBolag">
          <div *ngFor="let delivery of data.extra.deliveries">
            <a
              *ngIf="delivery.q_hl_tracktrace_url_fra"
              href="{{ delivery.q_hl_tracktrace_url_fra }}"
              target="_BLANK"
              (click)="$event.stopPropagation()"
              translate
              >DELIVERY_TRACKING_LINK</a
            >
            <a
              *ngIf="delivery.q_hl_tracktrace_url"
              href="{{ delivery.q_hl_tracktrace_url }}"
              target="_BLANK"
              (click)="$event.stopPropagation()"
              translate
              >DELIVERY_TRACKING_LINK</a
            >
          </div>
        </span>
      </div>
    </div>
  </div>
</div>
