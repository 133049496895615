import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { SimpleConfigBaseFilter } from 'gung-list';
import { MetadataService, Product } from 'gung-standard';
@Injectable({
  providedIn: 'root'
})
export class ProductColorFilterService extends SimpleConfigBaseFilter<Product> {
  currentLang = this.translate.currentLang;

  constructor(protected translate: TranslateService, protected metadataService: MetadataService) {
    super();
  }

  getName(): string {
    return 'COLOR';
  }
  getOptionIds(item: Product): string[] {
    if (item.extra.hlPimItem.ItemColourName?.length > 0) {
      const colourName = this.metadataService.getMetadataTableList('ColourName');
      const itemColourNameList: string[] = item.extra.hlPimItem.ItemColourName.map(
        itemColourName => colourName.find(c => c.Id === itemColourName)?.Values[this.currentLang] || itemColourName
      );
      if (itemColourNameList?.length > 0) {
        return itemColourNameList;
      }
    }
    return [item.extra.hlPimItem.ItemColourName];
  }
  getOptionName(key: string): string {
    return key;
  }
}
