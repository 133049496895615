import { Inject, Injectable, Optional } from '@angular/core';
import { AppState } from '../../state/state.module';
import { User } from '../../state/auth/types';
import { first, Observable, of, switchMap } from 'rxjs';
import { Store } from '@ngrx/store';
import { Login, LoginFromAnonymous, Logout } from '../../state/auth/actions';
import { filter, map } from 'rxjs';
import { BackendInterceptor } from '../backend-interceptor/backend-interceptor.service';
import { intersection } from 'lodash-es';
import { OAuthService } from 'angular-oauth2-oidc';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  loginLowerCaseSensitive = false;

  constructor(
    protected store: Store<AppState>,
    protected backendInterceptor: BackendInterceptor,
    @Optional()
    protected oauthService: OAuthService,
    @Optional()
    @Inject('environment')
    protected environment: { [s: string]: any }
  ) {
    this.loginLowerCaseSensitive = this.backendInterceptor.getEnvironmentProperty('loginLowerCaseSensitive') || false;
  }

  loginUser(userName: string, password: string, fromAnonymous?: boolean): void {
    if (this.loginLowerCaseSensitive) {
      userName = userName.toLowerCase();
    }
    if (fromAnonymous) {
      this.store.dispatch(
        new LoginFromAnonymous({
          username: userName,
          password
        })
      );
    } else {
      this.store.dispatch(
        new Login({
          username: userName,
          password
        })
      );
    }
  }
  getCurrentUser(): Observable<User> {
    return this.store.select(state => state.auth.user);
  }

  getIsAuthenticated(blockAnonymous: boolean = false): Observable<boolean> {
    return this.store.select(state => state.auth.authenticated).pipe(
      switchMap(authenticated => {
        if (blockAnonymous) {
          return this.getRoles().pipe(map(roles => !roles.includes('ANONYMOUS')))
        }
        return of(authenticated);
      })
    );
  }

  getRoles(): Observable<string[]> {
    if (this.mockRolesModeEnabled()) {
      return this.getMockRoles();
    }
    return this.store
      .select(state => state.auth.user)
      .pipe(
        filter(user => !!user),
        map(user => user.roles)
      );
  }

  getUserActiveGroups(): Observable<any> {
    return this.store
      .select(state => state.auth.user)
      .pipe(
        filter(user => !!user),
        map(user => user.activeGroups)
      );
  }

  getHasRoles(role: string): Observable<boolean> {
    return this.store.select(
      state => !!state?.auth?.user?.roles && state.auth.user.roles.filter(r => r === role).length !== 0
    );
  }

  getIsBadAuth(): Observable<boolean> {
    return this.store.select(state => state.auth.badLogin);
  }

  getInternalServerError(): Observable<boolean> {
    return this.store.select(state => state.auth.internalServerError);
  }

  logout() {
    if (this.environment?.oauth2Enabled) {
      this.oauthService.logOut();
    }

    this.store.dispatch(new Logout());
  }

  mockRolesModeEnabled() {
    return !!localStorage.getItem('mockRoles');
  }

  getMockRoles(): Observable<string[]> {
    return this.getCurrentUser().pipe(
      first(),
      map(user => {
        if (user.roles.includes('ANONYMOUS')) {
          localStorage.removeItem('mockRoles');
          return user.roles;
        }
        const mockRoles = JSON.parse(localStorage.getItem('mockRoles'));
        return intersection(user.roles || [], mockRoles);
      })
    );
  }

  toggleUseMockRoles() {
    if (this.mockRolesModeEnabled()) {
      localStorage.removeItem('mockRoles');
      location.reload();
    } else {
      this.getRoles()
        .pipe(first())
        .subscribe(roles => {
          localStorage.setItem('mockRoles', JSON.stringify(roles));
          location.reload();
        });
    }
  }

  toggleMockRole(role: string) {
    this.getMockRoles().subscribe(currentMockRoles => {
      if (currentMockRoles.includes(role)) {
        currentMockRoles.splice(currentMockRoles.indexOf(role), 1);
        localStorage.setItem('mockRoles', JSON.stringify(currentMockRoles));
        location.reload();
      } else {
        this.getCurrentUser()
          .pipe(first())
          .subscribe(user => {
            if (user.roles.includes(role)) {
              currentMockRoles.push(role);
              localStorage.setItem('mockRoles', JSON.stringify(currentMockRoles));
            } else {
              console.error('CANNOT ENABLE ROLE NOT ON USER');
            }
            location.reload();
          });
      }
    });
  }
}
