import { environment as defaultEnv } from './default-environment';

export const environment = {
  ...defaultEnv,
  production: false,
  backendUri: 'https://1600-test-api.hl-display.com/',
  // backendUri: 'https://sales-fr-api.hl-display.com/',
  //backendUri: 'https://sales-se-api.hl-display.com/',
  allowedPriceLists: [],
  bolag: '1600',
  hideMobile: false,
  discountCustomerFinancialGroup: {},
  sales: true,
  sentryDsn: '',
  analyticsId: '',
  quoteConfirmationEmailAddress: '',
  quoteConfirmationEmailAddressOld: 'support@gung.se',
  orderOurReference: '',
  externalOrderType: 8,
  mainCountry: 'fr',
  mainCustomer: '',
  stripeApiKey: undefined,
  showFavourites: true,
  showApprovalQuote: true,
  showCreateSampleOrder: true
};
