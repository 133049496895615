<div class="row">
  <!--  mr-md-3 ml-md-5 -->
  <div *ngIf="!isCurrysPortal" class="col-10 offset-2 col-md-2 offset-md-9 text-center mb-2">
    <b translate>CHANGE_DATE_ON_ALL_ROWS</b>
  </div>
  <div *ngIf="!isCurrysPortal" class="col-10 offset-2 col-md-3 offset-md-9">
    <div class="row mr-3">
      <div class="col-12 col-md-10 pl-2 pr-1">
        <app-hlsales-cart-delivery-date-picker
          [deliveryMethod]="checkout.extra.procargs.levsattkod"
          [checkout]="checkout"
        >
        </app-hlsales-cart-delivery-date-picker>
      </div>
    </div>
  </div>
</div>

<lib-filter-list #cartList [configService]="salesCartConfigService"></lib-filter-list>
