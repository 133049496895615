<ngb-accordion #acc="ngbAccordion" id="saved-cart-accordion" class="accordion-spacing">
  <ngb-panel [id]="cart.id" *ngFor="let cart of data">
    <ng-template ngbPanelHeader>
      <div class="row">
        <div class="d-flex flex-direction-row align-items-center col-12">
          <button class="btn btn-link text-dark col-4">
            {{ cart.name }} - {{ dateUtilService.parseDate(cart.creationDate) | date: dateUtilService.dateFormat }}
          </button>

          <button
            ngbPanelToggle
            class="btn btn-link ml-auto col-2"
            (click)="donwloadQuotePDF(cart); $event.preventDefault(); $event.stopPropagation()"
            translate
          >
            DOWNLOAD_QUOTE_PDF
          </button>

          <button
            ngbPanelToggle
            class="btn btn-link col-2"
            (click)="loadCart(cart); $event.preventDefault(); $event.stopPropagation()"
            translate
          >
            LOAD_QUOTE
          </button>

          <button ngbPanelToggle class="btn btn-link col-2" (click)="fetchProductData(cart)" translate>
            SHOW_DETAILS
          </button>

          <button
            ngbPanelToggle
            class="btn btn-link col-2"
            (click)="deleteCart(cart); $event.preventDefault(); $event.stopPropagation()"
          >
            <i class="fas fa-trash-alt"></i>
          </button>
        </div>
      </div>
    </ng-template>

    <ng-template ngbPanelContent>
      <div class="row pb-3 pl-1 pr-1" *ngIf="cart.extra.description">
        <div class="col-12">
          <span class="font-weight-bold" translate>DESCRIPTION</span>
        </div>
        <div class="col-12">
          {{ cart.extra.description }}
        </div>
      </div>

      <div class="table-responsive" *ngIf="mapData[cart.id]; else loading">
        <table class="table table-sm table-borderless table-striped">
          <thead>
            <tr>
              <th><span translate>ARTICLE_NUMBER</span></th>
              <th><span translate>NAME</span></th>
              <th><span translate>QTY</span></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let row of mapData[cart.id]">
              <td>
                <a [routerLink]="['/product/', row.product.id]">
                  {{ row.product.id }}
                </a>
              </td>
              <td>
                {{ row.product.name }}
              </td>
              <td>
                {{ row.qty }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </ng-template>
  </ngb-panel>
</ngb-accordion>

<ng-template #loading>
  <div class="row">
    <div class="col-12 m-2 p-2 text-center">
      <i class="fa fa-spinner fa-spin-lg"></i>
    </div>
  </div>
</ng-template>
