import { environment } from '../environments/environment';

export const portals = {
  isCurrysPortal:
    environment.bolag === '1710' &&
    !environment.sales &&
    !environment.managers &&
    environment.mainCountry === 'en' &&
    environment.mainCustomer === '',
  isFRCustomerPortalPharma: !environment.sales && environment.mainCountry === 'fr' && environment.mainCustomer === 'Pharmacies',
  isFRCustomerPortal1600General: !environment.sales &&
    environment.mainCountry === 'fr' &&
    environment.bolag === '1600' &&
    environment.mainCustomer === 'General',
  isCustomerPortal1210Kesko: environment.bolag === '1210' && !environment.sales && environment.mainCountry === 'fi',
  isIntermacheOrCarrefourBE: environment.subCustomer === 'carrefour' || environment.subCustomer === 'intermarche',
  isIntermarchePortal: environment.bolag === '1600' && environment.subCustomer === 'intermarche',
  isCarrefourBE: environment.subCustomer === 'carrefour',
  isCustNlSuperunie1600: !environment.sales && environment.bolag === '1600' && environment.mainCountry === 'nl' && environment.mainCustomer === 'spar',
  isCloettaPortal: environment.bolag === '1210' &&
    !environment.sales &&
    environment.managers &&
    environment.mainCountry === 'en' &&
    environment.mainCustomer === 'cloetta',
  isCoopPortal: environment.mainCustomer === 'coop'
};
