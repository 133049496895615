import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export interface OrderDeliveryRequest {
  orderId: string;
  rows: OrderDeliveryRequestRow[];
}

export interface OrderDeliveryRequestRow {
  rowId: string;
  deliveryQuantity: number;
}

@Injectable({
  providedIn: 'root'
})
export class ProductionManagementShipOrdersService {
  // Define the API endpoint
  private apiUrl = 'json/production/deliveries';

  constructor(private http: HttpClient) {}

  // Create a new item
  deliverOrder(item: OrderDeliveryRequest): Observable<any> {
    return this.http.post<OrderDeliveryRequest>(`${this.apiUrl}/deliver-order`, item);
  }

  deliverOrderAndMarkAsFinished(item: OrderDeliveryRequest): Observable<any> {
    return this.http.post<OrderDeliveryRequest>(`${this.apiUrl}/deliver-order-and-finish`, item);
  }
}
