import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { map, mergeMap, tap, filter, first, catchError, switchMap, retry } from 'rxjs';
import { Action } from '@ngrx/store';
import {
  Init,
  ActionTypes,
  UserGet,
  UserReceived,
  UserNotReceived,
  Login,
  BadLogin,
  Logout,
  BackendDown,
  LoginFromAnonymous,
  InternalServerError
} from './actions';
import { AuthBackendService } from '../../services/auth-backend/auth-backend.service';
import { forkJoin, merge, of } from 'rxjs';
import { Router } from '@angular/router';

@Injectable()
export class AuthEffects implements OnInitEffects {
  userGet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ActionTypes.UserGet),
      mergeMap(_ =>
        this.authBackendService.getUser().pipe(
          map(user => {
            return user ? new UserReceived(user.details) : new UserNotReceived();
          }),
          retry({ count: 5, delay: 1000 }),
          catchError(error => {
            console.error(error);
            this.router.navigate(['maintenance']);
            return of();
          })
        )
      )
    )
  );

  login$ = createEffect(() =>
    this.actions$.pipe(
      filter(action => action.type === ActionTypes.Login),
      map(action => action as Login),
      map(action => action.payload),
      mergeMap(({ username, password }) => this.authBackendService.loginUser(username, password)),
      map(resp => {
        if (resp.valid) {
          return new UserGet();
        } else if (resp.status === 403) {
          return new BadLogin();
        } else if (resp.status === 500) {
          return new InternalServerError();
        }
        return new BackendDown();
      })
    )
  );

  loginFromAnonymous$ = createEffect(() =>
    this.actions$.pipe(
      filter(action => action.type === ActionTypes.LoginFromAnonymous),
      map(action => action as LoginFromAnonymous),
      map(action => action.payload),
      mergeMap(action => forkJoin([of(action), this.authBackendService.logout().pipe(first(), catchError(error => { console.error(error); return of(undefined)}))])),
      mergeMap(([{ username, password }, _]) => {
        return this.authBackendService.loginUser(username, password);
      }),
      map(resp => {
        if (resp.valid) {
          return new UserGet();
        } else if (resp.status === 403) {
          return new BadLogin();
        } else if (resp.status === 500) {
          return new InternalServerError();
        }
        return new BackendDown();
      }),
      tap((data) => {
        if (data.type !== ActionTypes.BadLogin
          && data.type !== ActionTypes.InternalServerError
          && data.type !== ActionTypes.BackendDown
        ) { // Correct login
          window.location.reload();
        }
      })
    )
  );

  logout$ = createEffect(
    () =>
      this.actions$.pipe(
        filter(action => action.type === ActionTypes.Logout),
        map(action => action as Logout),
        mergeMap(() => this.authBackendService.logout().pipe(first())),
        tap(() => {
          window.location.reload();
        })
      ),
    { dispatch: false }
  );

  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ActionTypes.Init),
      map(_ => new UserGet())
    )
  );

  userReceivedOauth2LoginWithAnonymousEnabled$ = createEffect(
    () =>
      this.actions$.pipe(
        filter(action => action.type === ActionTypes.UserReceived),
        map(action => action as UserReceived),
        tap(action => {
          // We have a user that is logged in with authentication method OAUTH2, and we are at the oauth-login page.
          // This means that we most likely have just been redirected back to the application from the oauth2 provider.
          // We should now reload the page to get the user logged in instead of the anonymous user.
          // I attempted to do this in a number of different ways, none of which worked. One example was to try this
          // logic on the actual oauth-login page component, but the problem then became that the user might not yet
          // have been received from the backend, and we instead get the anonymous user. Making the check here solved
          // most of those similar problems.
          //
          // If you want to refactor, please make sure that the following passes:
          // 1. Start a customer that has Oauth login + anonymous login enabled.
          // 2. Log in with Oauth.
          // 3. Ensure that you are redirected to the home page after login. If you are stuck on the oauth redirect page,
          //    then your refactor is not working. We must ensure that we are properly redirected to the home page after
          //    Oauth login.
          setTimeout(() => {
            // @ts-ignore
            if (action?.payload?.authenticationMethod === 'OAUTH2' && this.router.url.startsWith('/oauth-login')) {
              this.router.navigate(['']).then(() => {
                window.location.reload();
              });
            }
          }, 100);
        })
      ),
    { dispatch: false }
  );

  ngrxOnInitEffects(): Action {
    return new Init();
  }
  constructor(
    protected actions$: Actions,
    protected authBackendService: AuthBackendService,
    protected router: Router
  ) {}
}
