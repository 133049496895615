import { Injectable } from '@angular/core';
import { Product, ProductInputQuantityConfigService } from 'gung-standard';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HlProductInputQuantityConfigService extends ProductInputQuantityConfigService {
  isSales = environment.sales;
  isCoopPortal = environment.mainCustomer === 'coop';

  constructor() {
    super();
  }

  getMinimumOrderQuantity(productId: string, product?: Product): number {
    if (!product) return 1;

    if(this.isCoopPortal){
      return 1
    }

    if (!this.isSales) {
      return product.extra.stepAmount;
    }

    return product.extra.minimumOrderQuantity || 1;
  }

  getStepAmount(productId: string, product?: Product): number {
    if(this.isCoopPortal){
      return 1
    }
    if (!!product?.extra?.stepAmount) {
      return Number(product.extra.stepAmount.trim());
    }
    return 1;
  }
}
