import { Component, OnInit, OnDestroy, OnChanges } from '@angular/core';
import { Subscription, forkJoin, of } from 'rxjs';
import { first, switchMap, tap } from 'rxjs';
import { ListItemRendererComponent } from 'gung-list';
import { Product } from '../../models';
import { AvailabilityService } from '../../services/availability/availability.service';
import { PriceService } from '../../services/price/price.service';
import { AuthService } from '../../services/auth/auth.service';

@Component({
  selector: 'lib-product-export-table',
  templateUrl: './product-export-table.component.html',
  styleUrls: ['./product-export-table.component.scss']
})
export class ProductExportTableComponent
  extends ListItemRendererComponent<Product[]>
  implements OnInit, OnDestroy, OnChanges
{
  public mappedData: ProductTableRow[] = [];

  public keyedMapData: { [id: string]: ProductTableRow } = {};

  public subscriptions: Subscription[] = [];

  constructor(
    protected priceService: PriceService,
    protected availabilityService: AvailabilityService,
    protected authService: AuthService
  ) {
    super();
  }
  public ngOnInit() {
    this.subscribeToProducts();
  }

  public ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  protected subscribeToProducts() {
    const newIds = this.data.map(p => p.id).filter(id => !Object.keys(this.keyedMapData).includes(id));
    if (newIds.length === 0) {
      this.readMappedDataFromCache();
      return;
    }
    const subscription = this.authService
      .getCurrentUser()
      .pipe(
        first(),
        switchMap(user => forkJoin([of(this.data)]))
      )
      .subscribe(data => {
        const productData = data[0];

        this.keyedMapData = {
          ...this.keyedMapData,
          ...newIds.reduce((acc, curr) => {
            const product = productData.filter(p => p.id === curr)[0];
            if (!product) {
              throw new Error('No product found');
            }

            const item: ProductTableRow = this.mapItem(curr, product);
            return {
              ...acc,
              [curr]: item
            };
          }, {})
        };
        this.readMappedDataFromCache();
      });
    this.subscriptions.push(subscription);
  }

  protected readMappedDataFromCache() {
    const ids = this.data.map(d => d.id);
    this.mappedData = ids.map(id => this.keyedMapData[id]);
  }

  ngOnChanges() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.subscribeToProducts();
  }

  private mapItem(id: string, product: Product): ProductTableRow {
    return {
      id,
      name: product.name,
      product,
      packageSize: product?.extra?.ar?.artfsgforp,
      replacementId: product?.extra?.ar?.q_gung_filter
    };
  }
}
interface ProductTableRow {
  id: string;
  name: string;
  packageSize: number;
  product?: Product;
  replacementId: string;
}
