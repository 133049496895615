<div class="row">
  <div class="col-12 col-md-6">
    <h4 translate>DELIVERY_ADDRESS</h4>
    <div class="form-group">
      <label for="ordlevadr1" class="control-label" translate>OH_ORDLEVADR1</label>
      <input
        type="text"
        class="form-control form-control-sm"
        id="ordlevadr1"
        [value]="checkout.extra.procargs.ordlevadr1 || ''"
        readonly
      />
    </div>
    <div class="form-group">
      <label for="ordlevadr2" class="control-label" translate>OH_ORDLEVADR2</label>
      <input
        type="text"
        class="form-control form-control-sm"
        id="ordlevadr2"
        [value]="checkout.extra.procargs.ordlevadr2 || ''"
        readonly
      />
    </div>
    <div class="form-group">
      <label for="ordlevadr3" class="control-label" translate>OH_ORDLEVADR3</label>
      <input
        type="text"
        class="form-control form-control-sm"
        id="ordlevadr3"
        [value]="checkout.extra.procargs.ordlevadr3 || ''"
        readonly
      />
    </div>
    <div class="form-group">
      <label for="ordlevadr4" class="control-label" translate>OH_ORDLEVADR4</label>
      <input
        type="text"
        class="form-control form-control-sm"
        id="ordlevadr4"
        [value]="checkout.extra.procargs.ordlevadr4 || ''"
        readonly
      />
    </div>
    <lib-meta-select-input
      [(ngModel)]="checkout.extra.procargs.ordlevadrlandskod"
      table="xr"
      metaId="landskod"
      metaDisplay="land"
      [disabled]="true"
    >
    </lib-meta-select-input>
  </div>
  <div class="col-12 col-md-6" *ngIf="customer">
    <h4 translate>INVOICE_ADDRESS</h4>
    <div class="form-group">
      <label for="billingFtgnr" class="control-label" translate>CUSTOMER_ID</label>
      <input *ngIf="!selectInvoiceCustomerEnabled"
        type="text"
        class="form-control form-control-sm"
        id="billingFtgnr"
        [value]="isManagerCh ? customer.extra?._billingCustomer?.extra.fr?.ftgnr || customer.extra?.fr?.ftgnr : customer.extra._billing?.ftgnr || ''"
        readonly
      />
      <lib-gung-select-option-input *ngIf="selectInvoiceCustomerEnabled"
          suppressLabel="true"
          [optionsList]="selectInvoiceCustomerOptionsList"
          [(ngModel)]="checkout.billingCustomerId"
          extraInputClass="form-control-sm"
          #inputSelectInvoiceCustomer
          (change)="selectInvoiceCustomerOption(inputSelectInvoiceCustomer.value)"
      ></lib-gung-select-option-input>
    </div>
    <div class="form-group">
      <label for="billingFtgnamn" class="control-label" translate>FTGNAMN</label>
      <input
        type="text"
        class="form-control form-control-sm"
        id="billingFtgnamn"
        [value]="isManagerCh ?customer.extra?._billingCustomer?.extra?.fr?.ftgnamn || customer.extra?.fr?.ftgnamn : customer.extra._billing?.ftgnamn || ''"
        readonly
      />
    </div>
    <div class="form-group">
      <label for="billingFtgpostadr2" class="control-label" translate>FTGPOSTADR2</label>
      <input
        type="text"
        class="form-control form-control-sm"
        id="billingFtgpostadr2"
        [value]="isManagerCh ? customer.extra?._billingCustomer?.extra?.fr?.ftgpostadr2 || customer.extra?.fr?.ftgpostadr2 : customer.extra._billing?.ftgpostadr2 || ''"
        readonly
      />
    </div>
    <div class="form-group">
      <label for="billingFtgpostnr" class="control-label" translate>FTGPOSTNR</label>
      <input
        type="text"
        class="form-control form-control-sm"
        id="billingFtgpostnr"
        [value]="isManagerCh ? customer.extra?._billingCustomer?.extra?.fr?.ftgpostnr || customer.extra?.fr.ftgpostnr : customer.extra._billing?.ftgpostnr || ''"
        readonly
      />
    </div>
    <div class="form-group">
      <label for="billingFtgpostadr3" class="control-label" translate>FTGPOSTADR3</label>
      <input
        type="text"
        class="form-control form-control-sm"
        id="billingFtgpostadr3"
        [value]="isManagerCh ? customer.extra?._billingCustomer?.extra?.fr?.ftgpostadr3 || customer.extra?.fr.ftgpostadr3 : customer.extra._billing?.ftgpostadr3 || ''"
        readonly
      />
    </div>
    <lib-meta-select-input
      [ngModel]="isManagerCh ? customer.extra?._billingCustomer?.extra?.fr?.landskod || customer.extra?.fr.landskod : customer.extra._billing?.landskod"
      table="xr"
      metaId="landskod"
      metaDisplay="land"
      [disabled]="true"
    >
    </lib-meta-select-input>
  </div>
</div>
<div *ngIf="showTerms;else showTextareas">
  <h4 translate>CONDITIONS</h4>
  <div class="row">
    <div class="col-12 col-md-6">
      <lib-meta-select-input
        [(ngModel)]="checkout.extra.procargs.levsattkod"
        table="x2f"
        metaId="levsattkod"
        metaDisplay="levsattbeskr"
        [disabled]="true"
      >
      </lib-meta-select-input>
      <lib-meta-select-input
        [(ngModel)]="checkout.extra.procargs.levvillkkod"
        table="x2e"
        metaId="levvillkkod"
        metaDisplay="levvillkbeskr"
        [disabled]="true"
      >
      </lib-meta-select-input>
    </div>
    <div class="col-12 col-md-6">
      <lib-meta-select-input
        [(ngModel)]="checkout.extra.oh.betkod"
        table="x2"
        metaId="betkod"
        metaDisplay="betvillbeskr"
        [disabled]="true"
      >
      </lib-meta-select-input>
      <lib-meta-select-input
        [(ngModel)]="checkout.extra.procargs.saljare"
        table="salj"
        metaId="saljare"
        metaDisplay="saljarenamn"
        [disabled]="true"
      >
      </lib-meta-select-input>
    </div>
  </div>
</div>
<ng-template #showTextareas>
  <div class="row">
    <div *ngIf="!isCoopPortal" class="col-6">
      <div class="form-group">
        <label translate>INTERNAL_TEXT</label>
        <textarea disabled class="form-control">{{checkout.extra.oh.edit}}</textarea>
      </div>
    </div>
    <div class="col-6">
      <div class="form-group">
        <label translate>EXTERNAL_TEXT</label>
        <textarea disabled class="form-control">{{checkout.extra.oh.editext}}</textarea>
      </div>
    </div>
  </div>
</ng-template>

<div *ngIf="productsMap && pricesMap">
  <h4 translate>ROWS</h4>
  <div class="table-responsive">
    <table class="table table-striped">
      <thead>
        <tr>
          <th translate *ngIf="!sales">PRODUCT_ID</th>
          <th translate>NAME</th>
          <th *ngIf="showApprovalQuote" class="d-none d-sm-table-cell"><!-- iamges --></th>
          <th translate *ngIf="!sales">ESTIMATED_DELIVERY_DATE</th>
          <th translate>QUANTITY</th>
          <th translate>UNIT_PRICE</th>
          <th translate>TOTAL</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let row of checkout.rows">
          <td *ngIf="!sales">{{ row.id }}</td>
          <!-- <td>{{productsMap[row.id].name}}</td> -->
          <td>{{ getName(productsMap[row.id], row) }}</td>
          <td *ngIf="showApprovalQuote" class="d-none d-sm-table-cell">
            <div class="row">
              <div
                class="col-auto"
                *ngFor="let image of productsFullMap[row.id]?.extra?.images"
                (click)="selectImage(row, image)"
              >
                <img
                  src="https://cdn1.gung.io/fit-in/250x250/filters:etag({{ image.s3ETag }})/{{ image.s3Uri }}"
                  [alt]="image.extra.PictureType"
                  [class.border-selected]="!!image.isSelected"
                />
              </div>
            </div>
          </td>
          <td *ngIf="!sales">
            {{ row.extra.estDeliveryDate }}
          </td>
          <td>{{ row.quantity }}</td>
          <td>
            <div *ngIf="pricesMap">
              <div *ngIf="!dummyArticleService.isDummyRow(row); else dummyPrice">
                {{
                  pricesMap[row.id].cartRowUnitPrice.value
                    | gungCurrency
                      : pricesMap[row.id].cartRowUnitPrice.currencyCode
                      : (isPortalNo ? 'code' : 'symbol-narrow')
                      : '1.2-2':(isCurrysPortal || isManagersNisa ? 'en-US' : 'sv_SE')
                }}
              </div>
              <ng-template #dummyPrice>
                {{
                  row.extra.orp.vb_pris || 0
                    | gungCurrency
                      : pricesMap[row.id].cartRowUnitPrice.currencyCode
                      : (isPortalNo ? 'code' : 'symbol-narrow')
                      : '1.2-2':(isCurrysPortal || isManagersNisa ? 'en-US' : 'sv_SE')
                }}
              </ng-template>
            </div>
          </td>
          <td>
            <div *ngIf="pricesMap">
              <div *ngIf="!dummyArticleService.isDummyRow(row); else dummyPrice">
                {{
                  pricesMap[row.id].cartRowTotalPriceInclRowDiscount.value
                    | gungCurrency
                      : pricesMap[row.id].cartRowTotalPriceInclRowDiscount.currencyCode
                      : (isPortalNo ? 'code' : 'symbol-narrow')
                      : '1.2-2':(isCurrysPortal || isManagersNisa ? 'en-US' : 'sv_SE')
                }}
              </div>
              <ng-template #dummyPrice>
                {{
                  (row.extra.orp.vb_pris || 0) * (row.extra.orp.ordantal || 0)
                    | gungCurrency
                      : pricesMap[row.id].cartRowTotalPriceInclRowDiscount.currencyCode
                      : (isPortalNo ? 'code' : 'symbol-narrow')
                      : '1.2-2':(isCurrysPortal || isManagersNisa ? 'en-US' : 'sv_SE')
                }}
              </ng-template>
            </div>
          </td>
          <!-- <td *ngIf="pricesMap">{{pricesMap[row.id].customerNetPrice.value}} {{pricesMap[row.id].customerNetPrice.currencyCode}}</td> -->
        </tr>
      </tbody>
    </table>
  </div>
</div>
