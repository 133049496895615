<h2 class="mb-4" translate>SHIPPING_INFORMATION</h2>
<div class="row">
  <div class="col">
    <lib-gung-input-multi-select
      *ngIf="deliveryLocations && (
        isPortalBenelux || 
        isCloettaPortal
      )"
      label="DELIVERY_LOCATION"
      [optionsList]="deliveryLocations"
      (selectedValues)="deliveryLocationId = $event?.length > 0 ? $event[0] : undefined"
      [showId]="false"
      [previewField]="['name', ' - ', 'extra.fr.ftgpostadr1', ', ', 'extra.fr.ftgpostnr', ' ', 'extra.fr.ftgpostadr3' ]"
      singleSelection="true"
    ></lib-gung-input-multi-select>
    <div class="form-group">
      <label for="ordlevadr2" class="control-label" translate>ADDRESS1</label>
      <input
        type="text"
        class="form-control form-control-sm"
        id="ordlevadr2"
        [value]="checkout.extra.procargs.ordlevadr2 || ''"
        readonly
      />
    </div>
    <div class="form-group">
      <label for="ordlevadr3" class="control-label" translate>ADDRESS2</label>
      <input
        type="text"
        class="form-control form-control-sm"
        id="ordlevadr3"
        [value]="checkout.extra.procargs.ordlevadr3 || ''"
        readonly
      />
    </div>
    <div class="form-group">
      <label for="city" class="control-label" translate>CITY</label>
      <input
        type="text"
        class="form-control form-control-sm"
        id="city"
        [value]="checkout.extra.procargs.ordlevadr4 || ''"
        readonly
      />
    </div>
    <div class="form-group">
      <label for="zip" class="control-label" translate>ZIP</label>
      <input
        type="text"
        class="form-control form-control-sm"
        id="zip"
        [value]="checkout.extra.procargs.ftgpostnr || ''"
        readonly
      />
    </div>
    <lib-meta-select-input
      table="xr"
      metaId="landskod"
      metaDisplay="land"
      [disabled]="true"
      [(ngModel)]="checkout.extra.procargs.ordlevadrlandskod"
    ></lib-meta-select-input>
  </div>
  <div class="col">
    <!-- <lib-gung-text-input gungTranslate="EMAIL" [(ngModel)]="checkout.extra.confirmationEmails[0]" *ngIf="!getDisableEmail()"></lib-gung-text-input> -->
    <lib-gung-text-input
      gungTranslate="TRACKING_EMAIL"
      [(ngModel)]="checkout.extra.kus.q_hl_emailtt"
      (change)="emailttChange('kus', $event.target.value)"
    ></lib-gung-text-input>
    <div class="form-group m-0">
      <label for="q_hl_emailtt" class="control-label" translate>TRACKING_EMAIL_TEMP</label>&nbsp;
      <small>(<span translate>TRACKING_LINK_TEMP_EMAIL</span>)</small>
      <input
        type="text"
        class="form-control"
        name="q_hl_emailtt"
        id="q_hl_emailtt"
        [(ngModel)]="checkout.extra.oh.q_hl_emailtt"
        (change)="emailttChange('oh', $event.target.value)"
      />
    </div>
    <div *ngIf="isCoopPortal" class="form-group mt-2">
      <label for="EMAIL_ORDER_CONFIRMATION_TEMPORARY" class="control-label" translate>EMAIL_ORDER_CONFIRMATION_TEMPORARY</label>
      <!-- Cannot be bound to oh, since that will trigger a update -->
      <input
        type="text"
        class="form-control form-control-sm"
        id="EMAIL_ORDER_CONFIRMATION_TEMPORARY"
        [(ngModel)]="checkout.extra._emailQuoteTemporary"
      />
      <p *ngIf="emailTemporaryInvalidError" class="small text-danger mt-0 mb-0" translate>EMAIL_QUOTE_TEMPORARY_INVALID</p>
      <span class="small mt-3">* <span class="ml-3" translate>MESSAGE_EMAIL_ORDER_CONFIRMATION_TEMP</span></span>
    </div>
    <lib-gung-text-input gungTranslate="CUSTOMER_REFERENCE" [(ngModel)]="checkout.extra.oh.kundref2" [required]="isManagersNisa">
    </lib-gung-text-input>
    <span *ngIf="fieldError.kundref2" class="alert alert-danger d-block">
      {{'FORM_ERROR_REQUIRED' | translate}}
    </span>

    <!-- nisa, coop, and cloetta -->   
    <div *ngIf="isCoopPortal || isManagersNisa || isCloettaPortal">
      <div class="form-group">
        <lib-gung-text-input  gungTranslate="OH_KUNDBESTNR" [(ngModel)]="checkout.extra.oh.kundbestnr" [required]="isManagersNisa">
        </lib-gung-text-input>
        <span *ngIf="fieldError.kundbestnr" class="alert alert-danger d-block">
          {{'FORM_ERROR_REQUIRED' | translate}}
        </span>
      </div>

      <div *ngIf="!isCoopPortal && !isCloettaPortal" class="form-group">
        <label for="oh.editext" class="control-label" translate>INTERNAL_MESSAGE</label>
        <textarea
          class="form-control"
          type="text"
          name="oh.edit"
          id="oh.edit"
          [(ngModel)]="checkout.extra.oh.edit"
        ></textarea>
      </div>
    </div>

    <div class="form-group" *ngIf="!isCoopPortal && !isManagersNisa">
      <label for="oh.editext" class="control-label" translate>MESSAGE</label>
      <textarea
        class="form-control"
        type="text"
        name="oh.editext"
        id="oh.editext"
        *ngIf="!getIsSalesPortal()"
        [(ngModel)]="checkout.extra.oh.editext"
      ></textarea>
      <textarea
        class="form-control"
        type="text"
        name="oh.edit"
        id="oh.edit"
        *ngIf="getIsSalesPortal()"
        [(ngModel)]="checkout.extra.oh.edit"
      ></textarea>
    </div>

    <lib-gung-text-input
      gungTranslate="GOODS_LABEL"
      [(ngModel)]="checkout.extra.oh.godsmarke2"
      *ngIf="getIsSalesPortal()"
    >
    </lib-gung-text-input>
  </div>
</div>
