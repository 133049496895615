import { Component, OnInit } from '@angular/core';
import { AuthService } from 'gung-standard';
import { CustomerSalesPersonService, CustomerSalesPerson } from '../../services/customer-sales-person.service';
import { filter, switchMap, tap } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';
import { of } from 'rxjs';

@Component({
  selector: 'app-contact-us-page',
  templateUrl: './contact-us-page.component.html',
  styleUrls: ['./contact-us-page.component.scss']
})
export class ContactUsPageComponent implements OnInit {
  public authenticated = false;
  public currentLang: string;
  public customerSalesPerson: CustomerSalesPerson | undefined;
  public isSales = environment.sales;
  public isFRCustomerPortalPharma =
    !environment.sales && environment.mainCountry === 'fr' && environment.mainCustomer === 'Pharmacies';
  public isFRCustomerPortal1600General =
    !environment.sales &&
    environment.mainCountry === 'fr' &&
    environment.bolag === '1600' &&
    environment.mainCustomer === 'General';
  public mainCountry = environment.mainCountry;
  public isCustomerPortal1210Kesko = environment.bolag === '1210' && !environment.sales && environment.mainCountry === 'fi';
  public isIntermacheOrCarrefourBE = environment.subCustomer === 'carrefour' || environment.subCustomer === 'intermarche';
  public isIntermarchePortal = environment.bolag === '1600' && environment.subCustomer === 'intermarche';
  public isCarrefourBE = environment.subCustomer === 'carrefour';
  public isCustNlSuperunie1600 = !environment.sales && environment.bolag === '1600' && environment.mainCountry === 'nl' && environment.mainCustomer === 'spar';
  public isCloettaPortal =
    environment.bolag === '1210' &&
    !environment.sales &&
    environment.managers &&
    environment.mainCountry === 'en' &&
    environment.mainCustomer === 'cloetta';

  isCoopPortal = environment.mainCustomer === 'coop';

  public isCurrysPortal =
    environment.bolag === '1710' &&
    !environment.sales &&
    !environment.managers &&
    environment.mainCountry === 'en' &&
    environment.mainCustomer === '';

  constructor(
    protected authService: AuthService,
    protected customerSalesPersonService: CustomerSalesPersonService,
    protected translate: TranslateService
  ) { }

  ngOnInit() {
    this.currentLang = this.translate.currentLang || '';

    this.authService
      .getIsAuthenticated()
      .pipe(
        filter(authenticated => authenticated),
        tap(() => (this.authenticated = true)),
        switchMap(() => {
          if (this.isFRCustomerPortalPharma) {
            return of({
              name: 'Vanessa De Oliveira',
              phone: '',
              email: 'vanessa.deoliveira@hl-display.com'
            });
          } else {
            return this.customerSalesPersonService.getCurrentCustomerSalesPerson();
          }
        })
      )
      .subscribe(csp => {
        this.customerSalesPerson = csp;
      });
  }

  getFrServiceClientEmail(): string {
    if (this.isFRCustomerPortal1600General) {
      return 'portail.info@hl-display.com';
    }
    if (this.isFRCustomerPortalPharma) {
      return 'pharmacie.info@hl-display.com';
    }
    if (this.isCustNlSuperunie1600) {
      return 'Ordersbenelux@hl-display.com';
    }
    // if (this.isCloettaPortal) {
    //   return 'juliette.bonda@hl-display.com';
    // }
    if (this.isCurrysPortal) {
      return 'DSG@hl-display.com';
    }
    if (this.mainCountry === 'en') {
      return 'ales@poscentre.co.uk';
    }
    return 'mousquetaires.info@hl-display.com';
  }
}
