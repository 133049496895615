import { Component, OnInit } from '@angular/core';
import { JeevesInvoiceActionConfigService, JeevesInvoiceCardListComponent } from 'gung-standard-jeeves';
import { CustomerService } from 'gung-standard';
import { first } from 'rxjs';

@Component({
  selector: 'app-hl-display-invoice-card-list',
  templateUrl: './hl-display-invoice-card-list.component.html',
  styleUrls: ['./hl-display-invoice-card-list.component.css']
})
export class HlDisplayInvoiceCardListComponent extends JeevesInvoiceCardListComponent implements OnInit {
  deliveryClient = '';
  payingClient = '';
  constructor(public configService: JeevesInvoiceActionConfigService, protected costumerService: CustomerService) {
    super(configService);
  }

  ngOnInit(): void {
    this.costumerService
      .getCustomer(this.data.extra.fh.ftgnr)
      .pipe(first())
      .subscribe(data => (this.deliveryClient = data.name));
    this.costumerService
      .getCustomer(this.data.extra.fh.kundbetalarenr)
      .pipe(first())
      .subscribe(data => (this.payingClient = data.name));
  }
}
