import { Component, OnInit } from '@angular/core';
import { ListItemRendererComponent } from 'gung-list';
import { News } from '../../../models/news';

@Component({
  selector: 'lib-news-page-table',
  templateUrl: './news-page-table.component.html',
  styleUrls: ['./news-page-table.component.css']
})
export class NewsPageTableComponent extends ListItemRendererComponent<News[]> implements OnInit {
  constructor() {
    super();
  }

  ngOnInit(): void {}
}
