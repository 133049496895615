import { Component, EventEmitter, Output, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { distinctUntilChanged, debounceTime, map, first, switchMap } from 'rxjs';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { ProductService, AuthService, MetadataService, Product, Order, CustomerService } from 'gung-standard';
import { HlDisplayProductListConfigService } from '../../services/hl-display-product-list-config.service';
import { HlDisplayHelperService } from '../../services/hl-display-helper.service';

@Component({
  selector: 'app-hl-display-product-select-dropdown',
  templateUrl: './hl-display-product-select-dropdown.component.html',
  styleUrls: ['./hl-display-product-select-dropdown.component.css']
})
export class HLDisplayProductSelectDropdownComponent implements OnInit {
  model: any;
  searching = false;
  searchFailed = false;

  public products: Product[];

  @Input()
  protected order: Order;

  @Output()
  protected productSelected = new EventEmitter<Product>();

  formatter = (result: Product) => this.getName(result);

  constructor(
    protected productService: ProductService,
    protected authService: AuthService,
    private productList: HlDisplayProductListConfigService,
    private customerService: CustomerService,
    private metadataService: MetadataService,
    private helperService: HlDisplayHelperService
  ) {}

  ngOnInit(): void {
    this.customerService
      .getCustomer(this.order.deliveryCustomerId)
      .pipe(
        switchMap(customer =>
          this.productService.getProductsByPriceList(
            this.helperService.checkRequestPricelistAssortmentWithWebCondition(customer.extra.kus.prislista)
          )
        )
      )
      .subscribe(products => (this.products = products));
  }

  search = (searchTerm$: Observable<string>) => {
    const debouncedText$ = searchTerm$.pipe(debounceTime(200), distinctUntilChanged());
    return debouncedText$.pipe(
      map(term =>
        this.products
          .filter(product => {
            let hasHitAllTerms = true;
            const queryTerms = term.split(' ');
            const terms = this.productList.getSearchTerms(product);
            queryTerms.forEach(queryTerm => {
              const locatedTerm = terms.find(searchTerm => {
                return searchTerm.toUpperCase().indexOf(queryTerm.toUpperCase()) >= 0;
              });

              hasHitAllTerms = hasHitAllTerms && !!locatedTerm;
            });

            return hasHitAllTerms;
          })
          .slice(0, 10)
      )
    );
  };

  onItemSelect(event: NgbTypeaheadSelectItemEvent) {
    const product = event.item as Product;
    this.model = this.formatter(event.item);
    this.productSelected.emit(product);
  }

  getName(item: Product): string {
    const id = item.name;

    const itemGroup = this.metadataService.getMetadataValue('vg', 'varugruppbeskr', item.extra.ar.varugruppkod);
    const itemDesc = this.i18nSeExists(item) ? item.extra.i18n.se.artbeskr : '';
    return `${id} - ${itemGroup} - ${itemDesc}`;
  }
  i18nSeExists(data: Product): boolean {
    return data.extra.i18n && data.extra.i18n.se;
  }
}
