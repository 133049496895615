import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { first } from 'rxjs';
import { formatISO } from 'date-fns';
import { News } from '../../../models/news';
import { NewsService } from '../../../services/news/news.service';
import { forkJoin } from 'rxjs';
import { GungFlowService } from '../../../services/gung-flow/gung-flow.service';
import { TranslateService } from '@ngx-translate/core';
import { CustomPageConfigService } from '../../../services/custom-page/custom-page-config.service';
import { GungImageUrlService, OptionsListMulti, gungAddRemoveSpinner } from 'gung-common';
import { Product } from '../../../models';

@Component({
  selector: 'lib-news-editor-modal',
  templateUrl: './news-editor-modal.component.html',
  styleUrls: ['./news-editor-modal.component.css']
})
export class NewsEditorModalComponent implements OnInit {
  productsSelected: Product[] = [];
  isCreate = false;
  news: News;
  newsForm: FormGroup;
  roles: { name: string; value: boolean; translationKey: string }[];
  alertMessage: { type: string; message: string };
  imageUpload: File;
  isLoading: boolean;

  allRoles: boolean;
  allGroups: boolean;
  noRoles: boolean;
  noGroups: boolean;

  public userGroups: OptionsListMulti[];

  constructor(
    protected activeModal: NgbActiveModal,
    protected formBuilder: FormBuilder,
    protected newsService: NewsService,
    protected gungFlowService: GungFlowService,
    protected customPagesService: CustomPageConfigService,
    protected gungImageUrlService: GungImageUrlService
  ) {}

  ngOnInit(): void {
    if (!this.news) {
      this.isCreate = true;

      this.news = {
        id: '',
        name: '',
        extra: {
          activeRoles: {},
          cover: null,
          category: null
        },
        activeGroups: {},
        html: '',
        active: false
      };
    }

    forkJoin([
      this.newsService.getActiveRoles().pipe(first()),
      this.gungFlowService.getSystemGung().pipe(first())
    ]).subscribe(([roles, systemSettings]) => {
      this.roles = roles;

      for (const role of roles) {
        if (this.news.extra.activeRoles[role.name] === undefined) {
          this.news.extra.activeRoles[role.name] = role.value;
        }
      }

      const settingsGroups = systemSettings.extra.userGroups;
      this.userGroups = settingsGroups?.map(g => ({ id: g, name: undefined, selected: false }));
      if (this.news?.activeGroups && this.userGroups) {
        const groups = Object.keys(this.news.activeGroups);
        for (const group of groups) {
          if (this.userGroups.find(g => g.id === group)?.selected) {
            this.userGroups.find(g => g.id === group).selected = this.news.activeGroups[group];
          }
        }
      }

      if (!this.news?.id) {
        this.selectAllGroups(true);
        this.selectAllRoles(true);
      }

      this.checkAllSelected();
      this.initForm();
    });

    if(this.news?.extra?.productList) {
      this.productsSelected = this.news.extra.productList
    }
  }

  initForm(): void {
    this.newsForm = this.formBuilder.group({
      id: this.formBuilder.control(this.news.id || '', [Validators.required, Validators.pattern('^[0-9]*$')]),
      name: this.formBuilder.control(this.news.name || '', [Validators.required]),
      extra: this.formBuilder.group({
        cover: this.formBuilder.control(this.news.extra?.cover || '', []),
        category: this.formBuilder.control(this.news.extra?.category || '', [])
      })
    });
  }

  getFormControl(value): AbstractControl {
    return this.newsForm.get(value);
  }

  close() {
    this.activeModal.close();
  }

  uploadCoverImage() {
    const imageObject = {
      index: 0,
      description: 'custompage image - ' + new Date().getTime(),
      category: 'custompage image',
      s3Uri: 'images/no-image.jpg'
    };
    const formData = new FormData();
    formData.append('file', this.imageUpload);
    formData.append('productImage', JSON.stringify(imageObject));
    this.customPagesService.uploadImageS3(formData).subscribe(image => {
      this.newsForm
        .get('extra')
        .get('cover')
        .setValue(this.gungImageUrlService.getUrl(image.s3Uri));
      this.imageUpload = null;
      this.save();
    });
  }

  save(event?) {
    gungAddRemoveSpinner(event?.target);
    if (this.newsForm.invalid) {
      this.newsForm.markAllAsTouched();
      return;
    }

    this.isLoading = true;
    // Upload cover before create news
    if (this.imageUpload) {
      this.uploadCoverImage();
      return;
    }

    const newsForm = this.newsForm.getRawValue();

    this.news = {
      ...this.news,
      ...newsForm,
      extra: {
        ...this.news.extra,
        ...newsForm.extra,
        productList: this.productsSelected
      }
    };

    if (this.isCreate) {
      this.newsService
        .getNewsById(this.news.id)
        .pipe(first())
        .subscribe(news => {
          if (!!news) {
            this.alertMessage = { type: 'danger', message: 'NEWS_ID_ALREADY_EXISTS' };
            return;
          }

          this.news.extra.timestamp = formatISO(new Date());
          this.postNewsData(event);
        });
    } else {
      this.postNewsData(event);
    }
  }

  postNewsData(event?): void {
    this.newsService.postNews(this.news).subscribe(
      () => {
        this.alertMessage = {
          type: 'success',
          message: this.isCreate ? 'NEWS_SUCCESSFULLY_CREATED' : 'NEWS_SUCCESSFULLY_UPDATED'
        };
        this.isCreate = false;
        gungAddRemoveSpinner(event?.target);
      },
      () => {
        this.alertMessage = { type: 'danger', message: 'AN_ERROR_HAS_OCCURED' };
        gungAddRemoveSpinner(event?.target);
      }
    );
  }

  setSelectedValues(event: string[]): void {
    this.news.activeGroups = event.reduce((obj, item) => {
      obj[item] = true;
      return obj;
    }, {});
  }

  selectAllGroups(checked: boolean) {
    this.news.activeGroups = this.userGroups.reduce((obj, item) => {
      obj[item.id] = checked;
      return obj;
    }, {});
  }

  selectAllRoles(checked: boolean) {
    this.news.extra.activeRoles = this.roles.reduce((obj, item) => {
      obj[item.name] = checked;
      return obj;
    }, {});
  }

  checkAllSelected() {
    this.allRoles = true;
    this.noRoles = true;
    for (const role of this.roles) {
      if (this.allRoles && !this.news.extra.activeRoles?.[role.name]) {
        this.allRoles = false;
      }
      if (this.noRoles && this.news.extra.activeRoles?.[role.name]) {
        this.noRoles = false;
      }
    }
    this.allGroups = true;
    this.noGroups = true;
    for (const role of this.userGroups) {
      if (this.allGroups && !this.news.activeGroups?.[role.id]) {
        this.allGroups = false;
      }
      if (this.noGroups && this.news.activeGroups?.[role.id]) {
        this.noGroups = false;
      }
    }
  }

  onFileChange(event) {
    this.imageUpload = event.target.files && event.target.files[0];
    if (this.imageUpload) {
      this.newsForm
        .get('extra')
        .get('cover')
        .setValue(this.imageUpload && this.imageUpload.name);
    }
  }

  setProductSelected(product) {
    if(this.productsSelected.find(item => item.id === product.id)) {
      return;
    }
    this.productsSelected.push(product);
  }

  removeProductSelected(product) {
    this.productsSelected = this.productsSelected.filter((item) => {
      return item.id !== product;
    });
  }
}
