import { Component, OnInit } from '@angular/core';
import { ListItemRendererComponent } from 'gung-list';
import { EdiRespondeOrderResponseActionConfigService } from '../../../services/edi-order-response-action-config/edi-order-response-action-config.service';

@Component({
  selector: 'lib-edi-order-response-card-list',
  templateUrl: './edi-order-response-card-list.component.html',
  styleUrls: ['./edi-order-response-card-list.component.css']
})
export class EdiOrderResponseCardListComponent extends ListItemRendererComponent<any[]> implements OnInit {
  constructor(public actionConfigService: EdiRespondeOrderResponseActionConfigService) {
    super();
  }

  ngOnInit(): void {}

  trackByFn(index: any, item: any) {
    return item.id;
  }
}
