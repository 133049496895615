import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Router, RouterModule } from '@angular/router';
import { GungDynamicPdfComponent } from './gung-dynamic-pdf.component';
import { PdfExportGridViewComponent } from './components/pdf-export-grid-view/pdf-export-grid-view.component';
import { PdfExportEditModalComponent } from './components/pdf-export-edit-modal/pdf-export-edit-modal.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { GungListModule } from 'gung-list';
import { DynamicProductExportListComponent } from './components/dynamic-product-export-list/dynamic-product-export-list.component';
import { ProductExportDragInComponent } from './components/product-export-drag-in/product-export-drag-in.component';
import { ExportDigitalAssetsDragInComponent } from './components/export-digital-assets-drag-in/export-digital-assets-drag-in.component';
import { ProductExportDragDropComponent } from './components/product-export-drag-drop/product-export-drag-drop.component';
import { ExportProductTemplateModalComponent } from './components/export-product-template-modal/export-product-template-modal.component';
import { PdfExportDefaultTemplateModalComponent } from './components/pdf-export-default-template-modal/pdf-export-default-template-modal.component';
import { ExportDigitalAssetsGridViewComponent } from './components/export-digital-assets-grid-view/export-digital-assets-grid-view.component';
import { ExportDigitalAssetsFolderGridViewComponent } from './components/export-digital-assets-folder-grid-view/export-digital-assets-folder-grid-view.component';
import { ProductDragGridComponent } from './components/product-drag-grid/product-drag-grid.component';
import { GungCommonModule } from 'gung-common';
import { GridsterModule } from 'angular-gridster2';
import { ExportPdfProductListComponent } from './components/export-pdf-product-list/export-pdf-product-list.component';
import { PdfDigitalAssetsFolderGridViewComponent } from './components/pdf-digital-assets-folder-grid-view/pdf-digital-assets-folder-grid-view.component';
import { PdfProductGridViewComponent } from './components/pdf-product-grid-view/pdf-product-grid-view.component';
import { AddStandaloneNoteComponent } from './components/add-standalone-note/add-standalone-note.component';
import { PdfExportEditNoteModalComponent } from './components/pdf-export-edit-note-modal/pdf-export-edit-note-modal.component';

@NgModule({
  declarations: [
    GungDynamicPdfComponent,
    PdfExportGridViewComponent,
    PdfExportEditModalComponent,
    DynamicProductExportListComponent,
    ProductExportDragInComponent,
    ExportDigitalAssetsDragInComponent,
    ProductExportDragDropComponent,
    ExportProductTemplateModalComponent,
    PdfExportDefaultTemplateModalComponent,
    ExportDigitalAssetsGridViewComponent,
    ExportDigitalAssetsFolderGridViewComponent,
    ProductDragGridComponent,
    ExportPdfProductListComponent,
    PdfDigitalAssetsFolderGridViewComponent,
    PdfProductGridViewComponent,
    AddStandaloneNoteComponent,
    PdfExportEditNoteModalComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    NgbModule,
    GungCommonModule,
    GungListModule,
    TranslateModule,
    GridsterModule
  ],
  exports: [GungDynamicPdfComponent]
})
export class GungDynamicPdfModule {
  constructor(router: Router) {
    const config = router.config;

    router.resetConfig([
      ...config,
      {
        path: 'export-pdf',
        component: GungDynamicPdfComponent
      },
      {
        path: 'export-pdf/new',
        component: DynamicProductExportListComponent
      },
      {
        path: 'export-pdf/:id',
        component: DynamicProductExportListComponent
      },
      {
        path: 'products-export-pdf/:id',
        component: ExportPdfProductListComponent
      }
    ]);
  }
}
