import { Injectable } from '@angular/core';
import {
  PriceService,
  BackendPrice,
  Customer,
  CustomerProductPrice,
  SelectedCustomerService,
  ProductService,
  BackendPriceLevel,
  GungFlowService,
  GungStringConverterService
} from 'gung-standard';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin, of } from 'rxjs';
import { mergeMap, first, map, switchMap } from 'rxjs';
import { JeevesPriceService } from 'gung-standard-jeeves';
import { environment } from '../../environments/environment';
import { portals } from '../portal-flags';

@Injectable({
  providedIn: 'root'
})
export class HlPriceService extends JeevesPriceService {
  isSalesFrPortal = environment.sales && environment.mainCountry === 'fr' && environment.mainCustomer === '' && environment.bolag === '1600';

  constructor(
    http: HttpClient,
    customerService: SelectedCustomerService,
    productService: ProductService,
    gungFlowService: GungFlowService
  ) {
    super(http, customerService, productService, gungFlowService);
  }

  protected mapCustomerProductPrice(
    backendPrice: BackendPrice,
    customer: Customer,
    qty?: number
  ): CustomerProductPrice {
    let price;
    if (!this.isSalesFrPortal) {
      price = super.mapCustomerProductPrice(backendPrice, customer, qty);
    } else {
      price = this.superMapCustomerProductPrice(backendPrice, customer, qty);
    }
    price = {
      ...price,
      priceList: this.getPriceLevel(backendPrice, qty).extra.PriceList
    } as HlDisplayCustomerProductPrice;
    if (environment.sales) {
      price.backendPriceLevel.extra.isComplementaryPriceList = price.priceList !== customer.extra.kus.prislista;
    }

    return price;
  }

  public getPriceLevel(backendPrice: BackendPrice, qty?: number): BackendPriceLevel {
    let level = super.getPriceLevel(backendPrice, qty);
    let i = 1;
    while (level.price === 0 && i < backendPrice.levels.length) {
      level = backendPrice.levels[i];
      i++;
    }
    return level;
  }

  getCustomerPrice(productId: string, customerId: string): Observable<CustomerProductPrice> {
    return this.internalHttpGetCustomerPrice(productId, customerId).pipe(
      mergeMap(x =>
        forkJoin([
          of(x),
          this.customerService.getSelectedCustomer().pipe(first()),
          this.productService.getProduct(productId).pipe(first())
        ])
      ),
      map(([backendPrice, customer, product]) => {
        return this.mapCustomerProductPrice(backendPrice, customer, product.extra.stepAmount);
      })
    );
  }

  getCustomerPrices(productIds: string[], customerId: string): Observable<CustomerProductPrice[]> {
    return this.internalHttpGetCustomerPrices(productIds, customerId).pipe(
      switchMap(x =>
        forkJoin([
          of(x),
          this.customerService.getSelectedCustomer().pipe(first()),
          this.productService.getProductsByIds(productIds).pipe(first())
        ])
      ),
      map(([backendPrices, customer, products]) => {
        return Object.values(backendPrices).map(backendPrice => {
          const product = products.find(prod => prod.id === backendPrice.productId);
          let stepAmount = 0;
          if (!!product) {
            stepAmount = product.extra.stepAmount;
          }
          return this.mapCustomerProductPrice(backendPrice, customer, stepAmount);
        });
      })
    );
  }

  getCurrentCustomerPrice(productId: string): Observable<CustomerProductPrice> {
    return super.getCurrentCustomerPrice(productId)
      .pipe(map(price => {
        if (portals.isCloettaPortal) {
          price.backendPrice.levels = price.backendPrice.levels.filter(l => l.price > 0);
        }
        return price;
      }));
  }

  getPriceByPricelist(productId: string, pricelistId: string): Observable<CustomerProductPrice> {
    return this.internalHttpGetPriceByPricelist(productId, pricelistId).pipe(
      mergeMap(x => forkJoin([of(x), this.customerService.getSelectedCustomer().pipe(first())])),
      map(([backendPrice, customer]) => {
        return this.mapCustomerProductPrice(backendPrice, customer);
      })
    );
  }

  protected internalHttpGetPriceByPricelist(productId: string, pricelistId: string): Observable<BackendPrice> {
    const convertedId = GungStringConverterService.toGungString(productId);

    return this.gungFlowService.getSelectedFlow().pipe(
      first(),
      switchMap(selectedFlow =>
        this.http.get<BackendPrice>(`json/product-price-pricelist/${pricelistId}/${convertedId}`, {
          headers: { maxAge: '86400' }
        })
      )
    );
  }

  getCustomerDiscountPercent(priceLevel: BackendPriceLevel, backendPrice: BackendPrice) {
    if (priceLevel.discount === 0 && portals.isCloettaPortal) {
      // HL sometimes do not include discounts on their level but rather use "flat" staffed prices in Jeeves
      const basePrice = this.getPriceLevel(backendPrice);
      const testDiscount = (basePrice.price - priceLevel.price) / basePrice.price;
      return testDiscount * 100;
    }
    return priceLevel.discount;
  }

  protected superMapCustomerProductPrice(backendPrice: BackendPrice, customer: Customer, qty?: number) {
    const priceLevel = this.getPriceLevel(backendPrice, qty);

    const customerDiscountPercent = priceLevel.discount;

    // priceLevel.price is with discounts for Jeeves, which is why we calculate gross price by dividing with the
    // discount percent.
    let customerGrossPrice;
    if (!priceLevel?.extra?.prl?.proc1) {
      customerGrossPrice = !!priceLevel.price ? priceLevel.price / ((100 - customerDiscountPercent) / 100) : 0; 
    } else {
      customerGrossPrice = priceLevel.price || 0;
    }
    const customerNetPrice = priceLevel.price;
    const recommendedRetailPrice = backendPrice.rrp || priceLevel.extra.ApproximatePrice || 0;

    const customerProductPrice: CustomerProductPrice = {
      productId: backendPrice.productId,
      customerId: customer.id,
      customerDiscountPercent,
      customerGrossPrice: {
        value: customerGrossPrice,
        currencyCode: customer.extra.kus.valkod
      },
      customerNetPrice: {
        value: customerNetPrice,
        currencyCode: customer.extra.kus.valkod
      },
      customerDiscountAmount: {
        value: customerGrossPrice - customerNetPrice,
        currencyCode: customer.extra.kus.valkod
      },
      backendPriceLevel: priceLevel,
      priceFactor: backendPrice.priceFactor,
      backendPrice: backendPrice
    };

    customerProductPrice.recommendedRetailPrice = {
      value: recommendedRetailPrice,
      currencyCode: customer.extra.kus.valkod
    };

    return customerProductPrice;
  }
}

export interface HlDisplayCustomerProductPrice extends CustomerProductPrice {
  priceList: string | undefined;
  complementaryPricelist?: boolean;
}
