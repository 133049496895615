import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Customer, SelectedCustomerService } from 'gung-standard';
import { CreditCheckAmout, JeevesCheckCreditService } from 'gung-standard-jeeves';
import { Observable, first, forkJoin, of, switchMap } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HlDisplayCreditBlockedService {
  // Portals
  isCustomer1210Se: boolean = !environment.sales && !environment.managers && environment.bolag === '1210' && environment.mainCountry === 'se';
  isCustomer1210No: boolean = !environment.sales && !environment.managers && environment.bolag === '1210' && environment.mainCountry === 'no';
  isCustomer1210Fi: boolean = !environment.sales && !environment.managers && environment.bolag === '1210' && environment.mainCountry === 'fi';
  isCustomer1600FrOrNl: boolean = !environment.sales && !environment.managers && environment.bolag === '1600' && environment.mainCountry === 'fr';
  isCustomer1600Be: boolean = !environment.sales && !environment.managers && environment.bolag === '1600' && environment.mainCountry === 'be';
  isSales1810De: boolean = environment.sales && environment.bolag === '1810' && environment.mainCountry === 'de';
  isManager2400Ch: boolean = environment.managers && environment.bolag === '2400' && environment.mainCountry === 'ch';

  constructor(
    protected selectCustomerService: SelectedCustomerService,
    protected jeevesCheckCreditService: JeevesCheckCreditService,
    protected translateService: TranslateService
  ) { }

  checkCreditBlockedSelectedCustomer(): Observable<{status: string, customer: Customer, credit: CreditCheckAmout}> {
    return this.selectCustomerService.getSelectedCustomer().pipe(
      first(),
      switchMap(customer =>  {
        const result: {status: string, customer: Customer, credit: CreditCheckAmout} = {
          status: '',
          customer: customer,
          credit: undefined
        }

        if (customer.extra?.kus?.kreditsparr == '1') {
          result.status = 'CREDIT_HARD_BLOCK';
        }

        const billingCustomerId: string = customer?.extra?._billingCustomer?.id;
        if (billingCustomerId && customer.extra?.kus?.kreditsparrtyp == 1) {
          result.status = 'CREDIT_SOFT_BLOCK';
        }
        
        return forkJoin({
          status: of(result.status),
          customer: of(result.customer),
          credit: result.status === 'CREDIT_SOFT_BLOCK' ? this.jeevesCheckCreditService.getAmountOfCredit(billingCustomerId).pipe(first()) : of(result.credit)
        })
      })
    );
  }

  checkCreditBlocked(customer: Customer): Observable<{status: string, customer: Customer, credit: CreditCheckAmout}> {
    const result: {status: string, customer: Customer, credit: CreditCheckAmout} = {
      status: '',
      customer: customer,
      credit: undefined
    }

    if (customer.extra?.kus?.kreditsparr == '1') {
      result.status = 'CREDIT_HARD_BLOCK';
    }

    const billingCustomerId: string = customer?.extra?._billingCustomer?.id;
    if (billingCustomerId && customer.extra?.kus?.kreditsparrtyp == 1) {
      result.status = 'CREDIT_SOFT_BLOCK';
    }
    
    return forkJoin({
      status: of(result.status),
      customer: of(result.customer),
      credit: result.status === 'CREDIT_SOFT_BLOCK' ? this.jeevesCheckCreditService.getAmountOfCredit(billingCustomerId).pipe(first()) : of(result.credit)
    });
  }

  getPortalTranslationCart(customer: Customer, creditLeft: number, totalValue: number, displayDefault: boolean = false): {title: string, body: string} {
    const translatedTitle = this.translateService.instant('CREDIT_BLOCKED');
    let translatedBody = 'NO_PORTAL_SPECIFIC_TRANSLATION_FOUND';

    translatedBody = this.getPortalSpecificTranslation();
    const translationList: string[] = this.getPortalSpecificTranslationList();

    // If there is no specific translation available in current language for specific portal, then use default translation
    if (translationList.includes(translatedBody) || displayDefault) {
      translatedBody = this.translateService.instant('CREDIT_SOFT_BLOCKED_MODAL_HTML', {
        customerName: customer.name,
        credit: Number(creditLeft).toFixed(2),
        total: Number(totalValue).toFixed(2),
        currency: customer?.extra?.kus?.valkod
      });
    }

    return {title: translatedTitle, body: translatedBody};
  }

  getPortalTranslationCustomer(customer: Customer): {title: string, body: string} {
    const translatedTitle = this.translateService.instant('CREDIT_BLOCKED');
    let translatedBody = 'NO_PORTAL_SPECIFIC_TRANSLATION_FOUND';

    translatedBody = this.getPortalSpecificTranslation();
    const translationList: string[] = this.getPortalSpecificTranslationList();

    // If there is no specific translation available in current language for specific portal, then use default translation
    if (translationList.includes(translatedBody)) {
      translatedBody = this.translateService.instant('CREDIT_BLOCKED_MODAL_HTML', {
        customerName: customer.name
      });
    }

    return {title: translatedTitle, body: translatedBody};
  }

  private getPortalSpecificTranslation(): string {
    // Portal specific translations
    if (this.isCustomer1210Se) {
      return this.translateService.instant('CREDIT_BLOCKED_MODAL_HTML_1210_SE');
    } else if (this.isCustomer1210No) {
      return this.translateService.instant('CREDIT_BLOCKED_MODAL_HTML_1210_NO');
    } else if (this.isCustomer1210Fi) {
      return this.translateService.instant('CREDIT_BLOCKED_MODAL_HTML_1210_FI');
    } else if (this.isCustomer1600FrOrNl) {
      return this.translateService.instant('CREDIT_BLOCKED_MODAL_HTML_1600_FR_OR_NL');
    } else if (this.isCustomer1600Be) {
      return this.translateService.instant('CREDIT_BLOCKED_MODAL_HTML_1600_BE');
    } else if (this.isSales1810De) {
      return this.translateService.instant('CREDIT_BLOCKED_MODAL_HTML_1810_DE');
    } else if (this.isManager2400Ch) {
      return this.translateService.instant('CREDIT_BLOCKED_MODAL_HTML_2400_CH');
    } else {
      return this.translateService.instant('INTERNAL_CREDIT_BLOCKED_MODAL_HTML')
    }
  }

  // translation tag list, add new translation tags so we can apply default translation when no translations found
  private getPortalSpecificTranslationList(): string[] {
    return [
      'NO_PORTAL_SPECIFIC_TRANSLATION_FOUND',
      'CREDIT_BLOCKED_MODAL_HTML_1210_SE',
      'CREDIT_BLOCKED_MODAL_HTML_1210_NO',
      'CREDIT_BLOCKED_MODAL_HTML_1210_FI',
      'CREDIT_BLOCKED_MODAL_HTML_1600_FR_OR_NL',
      'CREDIT_BLOCKED_MODAL_HTML_1600_BE',
      'CREDIT_BLOCKED_MODAL_HTML_1810_DE',
      'CREDIT_BLOCKED_MODAL_HTML_2400_CH',
      'INTERNAL_CREDIT_BLOCKED_MODAL_HTML'
    ];
  }
}
