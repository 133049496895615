import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ScannedActionsSubject } from '@ngrx/store';
import { AuthService, LoginCheckService, LoginComponent } from 'gung-standard';
import { environment } from '../../../environments/environment';
import { OAuthService } from 'angular-oauth2-oidc';

@Component({
  selector: 'app-hl-display-login',
  templateUrl: './hl-display-login.component.html',
  styleUrls: ['./hl-display-login.component.css']
})
export class HlDisplayLoginComponent extends LoginComponent {
  isCustomerPortal = !environment.sales;
  isOauth2 = environment.oauth2Enabled;

  constructor(
    protected authService: AuthService,
    protected formBuilder: FormBuilder,
    protected route: ActivatedRoute,
    protected router: Router,
    protected actions$: ScannedActionsSubject,
    loginCheckService: LoginCheckService,
    protected oauthService: OAuthService
  ) {
    super(authService, formBuilder, route, router, actions$, loginCheckService, oauthService);
  }

  loginOauth() {
    // Initializes the login towards the oauth2 authentication provider.
    this.oauthService.initCodeFlow();
  }
}
