<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title" translate>UNABLE_TO_DELIVER_ROWS</h4>
</div>

<div class="modal-body">
  <p translate>HL_VALIDATION_MODAL_TEXT</p>
  <div class="table-responsive">
    <table class="table table-striped">
      <thead>
        <tr>
          <th translate="PRODUCT"></th>
          <th translate="REQUESTED_QUANTITY"></th>
          <th translate="AVAILABLE_QUANTITY"></th>
          <th class="text-right" translate="ACTIONS"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let rowData of rowsToHandle; let index = index">
          <td>
            {{ rowData.cartRow.productId }}
            <div *ngIf="!!rowData.product.extra.ar.ersattsavartnr" class="position-absolute">
              <span translate> PRODUCT_REPLACED_BY </span>
              <a href="/product/{{ rowData.product.extra.ar.ersattsavartnr }}">
                {{ rowData.product.extra.ar.ersattsavartnr }}
              </a>
            </div>
            <div class="text-danger position-absolute" *ngIf="!rowData.product.extra.ar.ersattsavartnr" translate>
              PRODUCT_NO_REPLACEMENT
            </div>
          </td>
          <td>
            {{ rowData.cartRow.qty }}
          </td>
          <td>
            {{ rowData.validationData.availabilityData.maxFutureStock }}
          </td>
          <td class="text-right">
            <div class="btn-group">
              <button
                class="btn btn-primary"
                (click)="lowerQuantityOnRow(rowData.cartRow, rowData.validationData, index)"
                translate
              >
                REDUCE_QUANTITY
              </button>
              <button class="btn btn-secondary" (click)="removeRow(rowData.cartRow, index)" translate>
                REMOVE_ROW
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-light mr-auto" aria-label="Close" (click)="activeModal.dismiss()" translate>
    CANCEL
  </button>
</div>
