<div class="checkout-error" *ngIf="error; else steps">
  {{ error | json }}
</div>

<ng-template #steps>
  <ng-template #indicator></ng-template>
  <div class="border-gray" [hidden]="showLoading">
    <span *ngIf="isCreditHardBlock" class="credit-blocked-warning bg-danger" translate>CREDIT_BLOCKED</span>
    <span *ngIf="isCreditSoftBlock" class="credit-blocked-warning bg-warning" translate>CREDIT_SOFT_BLOCKED</span>
    <ng-template #step libCustomHost></ng-template>
    <ng-template #btns></ng-template>
  </div>
  <div>
    <div *ngIf="showLoading" class="progress mt-10">
      <div
        class="progress-bar progress-bar-striped progress-bar-animated bg-primary"
        role="progressbar"
        aria-valuenow="75"
        aria-valuemin="0"
        aria-valuemax="100"
        style="width: 75%"
      ></div>
    </div>
    <div *ngIf="showLoading && isSubmitting">
      <h2 translate>SUBMITTING_ORDER</h2>
    </div>
  </div>
</ng-template>
