import { GungBase } from './base';

export interface Customer extends GungBase {
  customerType?: CustomerType;
  flowToPricelistMapping?: { [s: string]: string}
}

export enum CustomerType {
  CUSTOMER = 'CUSTOMER',
  PROSPECT = 'PROSPECT'
}
