import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ListItemRendererComponent } from 'gung-list';
import { PimDimension } from '../../../models/pim';
import { PimDimensionsService } from '../../../services/pim-dimensions/pim-dimensions.service';
import { PimMetadataService } from '../../../services/pim-metadata/pim-metadata.service';
import { PimDimensionsModalComponent } from '../pim-dimensions-modal/pim-dimensions-modal.component';

@Component({
  selector: 'lib-pim-dimensions-card-list',
  templateUrl: './pim-dimensions-card-list.component.html',
  styleUrls: ['./pim-dimensions-card-list.component.css']
})
export class PimDimensionsCardListComponent extends ListItemRendererComponent<PimDimension[]> implements OnInit {
  public modalRef: Map<string, NgbModalRef> = new Map<string, NgbModalRef>();

  showLanguages: string[];

  constructor(
    protected pimDimensionsService: PimDimensionsService,
    protected ngbModal: NgbModal,
    protected pimMetadataService: PimMetadataService
  ) {
    super();
  }

  ngOnInit(): void {
    this.showLanguages = this.pimMetadataService.translateLanguages;
  }

  onEdit(pimDimension: PimDimension) {
    const id = PimDimensionsModalComponent.name;

    const ref = this.ngbModal.open(PimDimensionsModalComponent, {});
    ref.componentInstance.pimDimension = pimDimension;
    ref.result.then(
      result => {},
      reason => {}
    );

    this.modalRef.set(id, ref);
  }
}
