<div class="row align-items-end" *ngIf="!loading" style="vertical-align: bottom">
  <div class="col-auto">
    <div
      class="checkout-btn-container previous p-4 d-flex flex-column justify-content-center"
      [ngClass]="isLastStepHL ? 'mt-4' : ''"
    >
      <a
        class="btn-block btn btn-primary"
        routerLink="/products"
        *ngIf="isFirstStep"
        style="min-width: 96px"
        >{{ translationService.instant(prevBtnText) }}</a
      >
      <button
        type="button"
        class="btn btn-primary btn-block"
        (click)="prevClicked.emit()"
        *ngIf="!isFirstStep"
        style="min-width: 96px"
      >
        {{ translationService.instant(prevBtnText) }}
      </button>
    </div>
  </div>
  <div class="col-auto ml-auto order-1 order-md-0" *ngIf="isFirstStep">
    <div class="d-flex flex-column p-4 justify-content-center mt-3">
      <app-hl-display-delivery-days *ngIf="getShowTransportTime()"></app-hl-display-delivery-days>
    </div>
  </div>

  <div class="col-auto ml-auto" [ngClass]="{ 'ml-md-0': !!isFirstStep }">
    <div class="checkout-btn-container next d-flex flex-column p-4 justify-content-center mt-3" *ngIf="!isLastStepHL">
      <app-hl-display-lib-cart-summary></app-hl-display-lib-cart-summary>
      <div [ngSwitch]="tooLowValue">
        <div *ngSwitchCase="false">
          <button
            type="button"
            class="btn btn-primary btn-block"
            style="min-width: 96px"
            (click)="nextClicked.emit()"
          >
            {{ translationService.instant(nextBtnText) }}
          </button>
        </div>
        <div *ngSwitchCase="true">
          <button type="button" class="btn btn-secondary btn-block" style="min-width: 96px" disabled translate>
            LOW_VALUE
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
