import { Component, OnInit, Injectable, Optional } from '@angular/core';
import {
  CartSalesStepComponent,
  SalesCartConfigService,
  PipeService,
  BackendPrice,
  Customer,
  CustomerProductPrice,
  PriceService,
  Price,
  CartService,
  CartRow,
  AppState,
  AuthService,
  SelectedCustomerService,
  GungGoogleTagManagerService,
  BaseViewConfigService,
  ProductAssortmentParentService
} from 'gung-standard';
import { HlPipeService } from '../../../../services/hl-pipe.service';
import { HlPriceService, HlDisplayCustomerProductPrice } from '../../../../services/hl-price.service';
import { environment } from '../../../../../environments/environment';
import { Observable, forkJoin, of } from 'rxjs';
import { map, switchMap, first, repeatWhen, tap } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { JeevesSalesCartConfigService } from 'gung-standard-jeeves';
import { HlDisplaySalesCartConfigService } from '../../../../services/hl-display-sales-cart-config.service';

@Injectable()
export class SalesCartPipeService extends HlPipeService {
  preprocessCurrency(value: any): number | string {
    return value as number;
  }
}

@Injectable()
export class SalesCartPriceService extends HlPriceService {
  protected mapCustomerProductPrice(backendPrice: BackendPrice, customer: Customer, qty?: number) {
    const superVal = super.mapCustomerProductPrice(backendPrice, customer, qty);
    const customerFinancialGroup = customer.extra.kus.q_financialgrpcode;
    if (!environment.discountCustomerFinancialGroup[customerFinancialGroup]) {
      return superVal;
    }

    const priceFn = (origPrice: Price): Price => ({
      ...origPrice,
      value: origPrice.value * (1 + environment.discountCustomerFinancialGroup[customerFinancialGroup])
    });

    return {
      ...superVal,
      customerGrossPrice: priceFn(superVal.customerGrossPrice),
      customerNetPrice: priceFn(superVal.customerNetPrice)
    };
  }
}

@Injectable()
export class SalesCartCartService extends CartService {
  constructor(
    store: Store<AppState>,
    authService: AuthService,
    protected customerService: SelectedCustomerService,
    @Optional() protected gungGoogleTagManagerService: GungGoogleTagManagerService
  ) {
    super(store, authService, gungGoogleTagManagerService);
  }
  setExtra(productId: string, extra: { [s: string]: any }, targetStockId?: string, productPartialId?: string) {
    if (extra.orp && extra.orp.vb_pris) {
      // handle extra price for ica group
      this.customerService
        .getSelectedCustomer()
        .pipe(
          first(),
          map(cust => cust.extra.kus.q_financialgrpcode),
          map(financialGrpCode =>
            environment.discountCustomerFinancialGroup[financialGrpCode]
              ? environment.discountCustomerFinancialGroup[financialGrpCode]
              : 0
          ),
          map(factor => ({
            ...extra,
            orp: {
              ...extra.orp,
              vb_pris: extra.orp.vb_pris * (1 / (1 + factor))
            },
            _discountCustomerFinancialGroupInfo: {
              appliedFactor: factor,
              appliedTo: extra.orp.vb_pris,
              appliedFrom: 'SalesCartCartService'
            }
          }))
        )
        .subscribe(newExtra => super.setExtra(productId, newExtra, targetStockId, productPartialId));
      return;
    }
    super.setExtra(productId, extra, targetStockId, productPartialId);
  }
}

@Injectable()
export class SalesCartSalesCartConfigService extends HlDisplaySalesCartConfigService {
  constructor(
    cartService: CartService, 
    protected customerService: SelectedCustomerService,
    protected baseViewConfigService: BaseViewConfigService,
    protected productAssortmentParentService: ProductAssortmentParentService
    ) {
    super(cartService, baseViewConfigService, productAssortmentParentService);
  }

  getItems(): Observable<CartRow[]> {
    return this.customerService.getSelectedCustomer().pipe(
      first(),
      map(cust => cust.extra.kus.q_financialgrpcode),
      map(financialGrpCode =>
        environment.discountCustomerFinancialGroup[financialGrpCode]
          ? environment.discountCustomerFinancialGroup[financialGrpCode]
          : 0
      ),
      switchMap(factor =>
        this.cartService.getCurrentCart().pipe(
          map(rows =>
            rows.map(row => {
              if (!row.extra || !row.extra.orp || !row.extra.orp.vb_pris) {
                return row;
              }
              return {
                ...row,
                extra: {
                  ...row.extra,
                  orp: {
                    ...row.extra.orp,
                    vb_pris: row.extra.orp.vb_pris * (1 + factor)
                  }
                }
              };
            })
          )
        )
      )
    );
  }
}

@Component({
  selector: 'app-hl-sales-cart-step',
  templateUrl: './hl-sales-cart-step.component.html',
  viewProviders: [
    {
      // reset the pipe service
      provide: PipeService,
      useClass: SalesCartPipeService
    },
    {
      // add the customer addon price when you get the prices
      provide: PriceService,
      useClass: SalesCartPriceService
    },
    {
      // add the customer addon price to the row extra price
      provide: SalesCartConfigService,
      useClass: SalesCartSalesCartConfigService
    },
    {
      // remove the customer addon price to the override price when setting row extra
      provide: CartService,
      useClass: SalesCartCartService
    }
  ]
})
export class HlSalesCartStepComponent extends CartSalesStepComponent {
  public isCurrysPortal =
    environment.bolag === '1710' &&
    !environment.sales &&
    !environment.managers &&
    environment.mainCountry === 'en' &&
    environment.mainCustomer === '';

  constructor(public salesCartConfigService: SalesCartConfigService) {
    super(salesCartConfigService);
  }
}
