import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { CartRow } from '../../state/cart/types';
import { ProductService } from '../../services/products/product.service';
import { Product } from '../../models';
import { ListItemRendererComponent } from 'gung-list';
import { CartService } from '../../services/cart/cart.service';
import { PriceService } from '../../services/price/price.service';
import { CustomerProductPrice, CartRowPrice } from '../../models/price';
import { Subscription } from 'rxjs';

@Component({
  selector: 'lib-cart-item',
  templateUrl: './cart-item.component.html',
  styleUrls: ['./cart-item.component.css']
})
export class CartItemComponent extends ListItemRendererComponent<CartRow> implements OnInit, OnDestroy {
  product: Product;
  price: CartRowPrice;
  subscriptions: Subscription[] = [];

  constructor(
    public productService: ProductService,
    protected cartService: CartService,
    protected priceService: PriceService
  ) {
    super();
  }

  ngOnInit() {
    this.subscriptions.push(
      this.productService.getProduct(this.data.productId).subscribe(product => (this.product = product))
    );
    this.subscriptions.push(
      this.priceService.getCartRowPriceObservable(this.data).subscribe(price => (this.price = price))
    );
  }

  removeRow() {
    this.cartService.removeRow(this.data);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(element => {
      element.unsubscribe();
    });
  }
}
