import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ListItemRendererComponent } from 'gung-list';
import { AccountRequest, AccountRequestService } from '../../../services/account-request/account-request.service';
import { GungModalService } from '../../../services/gung-modal/gung-modal.service';
import { User } from '../../../state/auth/types';

@Component({
  selector: 'lib-account-requests-card-list',
  templateUrl: './account-requests-card-list.component.html',
  styleUrls: ['./account-requests-card-list.component.css']
})
export class AccountRequestsCardListComponent extends ListItemRendererComponent<AccountRequest[]> implements OnInit {
  showProcessed = false;

  constructor(
    protected accountRequestService: AccountRequestService,
    protected gungModalService: GungModalService,
    protected translationService: TranslateService,
    protected modalService: NgbModal,
    protected router: Router
  ) {
    super();
  }

  ngOnInit(): void {}

  public createUser(account: AccountRequest) {
    const createUserFrom = {
      username: account.companyId ? `${account.companyContactName}@${account.companyId}` : (account.companyContactEmail || account.companyContactName),
      name: `${account.companyContactName} [${account.companyName}]`,
      managedCompanyIds: account.companyId,
      email: account.companyContactEmail || ''
    };
    this.gungModalService.createUser(createUserFrom, false).then(
      (user: User) => {
        if (!!user) {
          this.router.navigate(['/admin/users-details/' + user.username]);
        }
      },
      reason => {}
    );
  }

  public processed(account: AccountRequest) {
    this.gungModalService
      .openConfirmYesNoModal(
        this.translationService.instant('MARK_PROCESSED'),
        this.translationService.instant('ACCOUNT_PROCESS', { name: account.companyContactName }) + '?',
        { size: 'sm' }
      )
      .then(result => {
        if (result) {
          this.accountRequestService.markAsProcessed(account.id).subscribe(_ => {});
        }
      });
  }

  public rejectUser(account: AccountRequest) {
    this.gungModalService
      .openConfirmYesNoModal(
        this.translationService.instant('REJECT'),
        this.translationService.instant('ACCOUNT_REQUEST_REJECT', { name: account.companyContactName }) + '?',
        { size: 'sm' }
      )
      .then(result => {
        if (result) {
          this.accountRequestService.markAsRejected(account.id).subscribe(_ => {});
        }
      });
  }
}
