import { Component, OnInit, ComponentFactoryResolver, ChangeDetectorRef } from '@angular/core';
import { ListLayoutComponent, ListLayoutSingleComponent } from 'gung-list';
import { CartService } from 'gung-standard';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-hl-display-cart-list-layout',
  templateUrl: './hl-display-cart-list-layout.component.html',
  styleUrls: ['./hl-display-cart-list-layout.component.css']
})
export class HlDisplayCartListLayoutComponent<T> extends ListLayoutSingleComponent<T> {
  isNoPortal = environment.mainCountry === 'no';
  public isCurrysPortal =
    environment.bolag === '1710' &&
    !environment.sales &&
    !environment.managers &&
    environment.mainCountry === 'en' &&
    environment.mainCustomer === '';

  constructor(
    componentFactoryResolver: ComponentFactoryResolver,
    changeDetectorRef: ChangeDetectorRef,
    private cartService: CartService,
    private modalService: NgbModal
  ) {
    super(componentFactoryResolver, changeDetectorRef);
  }

  public removeAll(content): void {
    this.modalService.open(content).result.then(
      closeResult => {
        if (closeResult === 'CLEAR_CART') {
          this.cartService.clearCart();
        }
      },
      dissmissReason => {
        console.log(dissmissReason);
      }
    );
  }
}
