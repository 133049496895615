import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ListItemRendererComponent } from 'gung-list';
import { OrderStatistic, TopProductsStatistics } from '../../../models/order-statistics';
import { ProductService } from '../../../services/products/product.service';
import { TopProductsActionButtonConfigService } from '../../../services/top-products-action-button-config/top-products-action-button-config.service';

@Component({
  selector: 'lib-top-products-summary',
  templateUrl: './top-products-summary.component.html',
  styleUrls: ['./top-products-summary.component.css']
})
export class TopProductsSummaryComponent
  extends ListItemRendererComponent<OrderStatistic[]>
  implements OnInit, OnChanges
{
  @Input() items: undefined; // temporary

  public changes = 0;
  public expandedNumber = -1;

  public productsInfo = {};

  public _items: OrderStatistic[] = [];
  public _productsSorted: TopProductsStatistics[] = [];

  constructor(
    protected productService: ProductService,
    public buttonConfigService: TopProductsActionButtonConfigService
  ) {
    super();
  }

  // @Input() set items(value: OrderStatistic[]) {
  //    this._items = value;
  //    this.updateSummary(this._items);

  // }

  ngOnInit() {
    this.mapProductsBySeason();
  }

  ngOnChanges() {
    this.mapProductsBySeason();
  }

  updateSummary(_items: OrderStatistic[]) {
    this.changes++;
    delete this._items;
    this._items = [];
    this._items = _items;
    console.log('updateSummary', this.changes, this._items.length);

    this.mapProductsBySeason();
  }

  expand(numberToExpand: number) {
    this.expandedNumber = numberToExpand;
    console.log('expand', this.expandedNumber);
  }

  public getGroupId(orderStatistic: OrderStatistic) {
    return orderStatistic.productModelId;
  }

  mapProductsBySeason() {
    const products = {};
    for (const orderStatistic of this.data) {
      if (!!orderStatistic.productModelId && orderStatistic.productModelId !== '-') {
        const groupId = this.getGroupId(orderStatistic);
        /*
          orderStatistic.productModelId +
          orderStatistic.productPrimaryDimensionId;
*/
        // Make sure element exist
        products[groupId] = products[groupId] || {
          qty: 0,
          orderCount: 0,
          productId: groupId,
          productName: orderStatistic.productName,
          subItems: []
        };

        // Increment
        products[groupId].orderCount += 1;
        products[groupId].qty += orderStatistic.orderRowTotalQuantity;
        products[groupId].subItems.push(orderStatistic);
      }
    }

    const displayProducts: TopProductsStatistics[] = [];
    const keys = Object.keys(products);
    keys.forEach(key => {
      displayProducts.push(products[key]);
    });

    const pIds = [];
    if (displayProducts.length > 0) {
      for (const p of displayProducts) {
        if (!this.productsInfo[p.productId]) {
          pIds.push(p.productId);
        }
      }
      if (pIds.length > 0) {
        this.productService.getFullProductsByIds(pIds).subscribe(fullProducts => {
          for (const product of fullProducts) {
            this.productsInfo[product.id] = product;
          }
        });
      }
    }
    displayProducts.sort((a, b) => {
      if (a.qty < b.qty) {
        return 1;
      }
      if (a.qty > b.qty) {
        return -1;
      }
      return 0;
    });
    let i = 1;
    displayProducts.forEach(p => (p.number = i++));
    this._productsSorted = displayProducts;
  }

  formatDate(date) {
    const d = new Date(date);
    let month = (d.getMonth() + 1).toString();
    let day = d.getDate().toString();
    const year = d.getFullYear();
    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }
    return [year, month, day].join('-');
  }
}
