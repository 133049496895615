import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OrderService } from '../../services/orders/order.service';
import { Order } from '../../models/order';
import { catchError, throwError, first, forkJoin, mergeMap, Observable, of, switchMap } from 'rxjs';
import { AuthService } from '../../services/auth/auth.service';
import { BackendInterceptor } from '../../services/backend-interceptor/backend-interceptor.service';
import { ProductService } from '../../services/products/product.service';
import { Product } from '../../models';
import { CanDeactivateComponent } from '../can-deactivate.component';
import { CommonModalService, DateUtilService } from 'gung-common';
import { BaseViewConfigService, ProductViewType } from '../../services/base-view-config/base-view-config.service';
import { ProductAssortmentParentPathTreeMap, ProductAssortmentParentService } from '../../services/product-assortment-parent/product-assortment-parent.service';
import { HttpErrorResponse } from '@angular/common/http';
import { GungError } from '../../models/gung-error';

@Component({
  selector: 'lib-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.css']
})
export class OrderDetailsComponent extends CanDeactivateComponent implements OnInit {
  order: Order;
  isSales: boolean;
  products: { [productId: string]: Product };

  constructor(
    protected route: ActivatedRoute,
    protected orderService: OrderService,
    protected authService: AuthService,
    protected backendInterceptor: BackendInterceptor,
    protected productService: ProductService,
    public dateUtilService: DateUtilService,
    protected baseViewConfigService: BaseViewConfigService,
    protected productAssortmentParentService: ProductAssortmentParentService,
    protected commonModalService: CommonModalService
  ) {
    super();
  }

  ngOnInit() {
    const routeParams = this.route.snapshot.params;
    if (routeParams.orderId != null) {
      this.orderService
        .getOrder(routeParams.orderId, true)
        .pipe(
          first(),
          catchError((err: HttpErrorResponse) => {
            const message = err.error?.gungMessage || err.error?.message || 'ERROR';
            const gungError: GungError = {
              ...new Error(message),
              i18nKeyMessage: message
            };
            
            this.commonModalService.openGungErrorHandler(gungError);

            return throwError(() => new Error(message));
          }),
          mergeMap(order => {
            let request: Observable<any> = of(null);
            if (this.baseViewConfigService.productViewType === ProductViewType.assortmentTreeView) {
              request = this.productAssortmentParentService.postProductAssortmentParentByProductIds(order.rows.map(r => r.productId)).pipe(catchError(error => of(null)))
            }
            return forkJoin([
              of(order),
              request
            ])
          }),
          switchMap(([order, parentMap]: [Order, ProductAssortmentParentPathTreeMap]) => {
            if (parentMap) {
              for (const row of order.rows) {
                if (parentMap?.[row.productId]) {
                  row.extra._path = parentMap[row.productId].map(p => p.id);
                  row.extra._path.shift();
                  row.extra._path.unshift('/articles');
                  row.extra._path.push(row.productId);
                }
              }
            }
            return of(order);
          })
        )
        .subscribe((order) => {
          const mappedOrder = this.mapOrder(order);
          this.order = mappedOrder;
          const productIds = mappedOrder.rows.map(row => row.productId);
          this.productService.getProductsByIds(productIds).subscribe(products => {
            this.products = {};
            products.forEach(product => {
              this.products[product.id] = product;
            });
          });
        });
    }

    this.authService.getRoles().subscribe(roles => {
      const filteredRoles = roles.filter(role => role.toUpperCase() === 'SALES');

      if (filteredRoles.length === 0) {
        return;
      }
      this.isSales = true;
    });
  }

  canDeactivate(): boolean {
    return true;
  }

  orderUpdated(order: Order) {
    this.order = order;
  }

  protected mapOrder(order: Order): Order {
    return order;
  }

  reroute(url: string) {
    window.location.href = this.backendInterceptor.getBaseUrl() + url;
  }
}
