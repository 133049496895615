<h2 class="mb-4" translate>SHIPPING_INFORMATION</h2>
<div class="row">
  <div class="col">
    <lib-gung-input-multi-select
      *ngIf="deliveryLocations && (
        isPortalBenelux || 
        isCloettaPortal
      )"
      label="DELIVERY_LOCATION"
      [optionsList]="deliveryLocations"
      (selectedValues)="deliveryLocationId = $event?.length > 0 ? $event[0] : undefined"
      [showId]="false"
      [previewField]="['name', ' - ', 'extra.fr.ftgpostadr1', ', ', 'extra.fr.ftgpostnr', ' ', 'extra.fr.ftgpostadr3' ]"
      singleSelection="true"
    ></lib-gung-input-multi-select>
    <div class="form-group">
      <label for="name" class="control-label" translate>NAME</label>
      <input
        type="text"
        class="form-control form-control-sm"
        id="name"
        [value]="checkout.extra.procargs.name || ''"
        readonly
      />
    </div>
    <div class="form-group">
      <label for="ordlevadr2" class="control-label" translate>ADDRESS1</label>
      <input
        type="text"
        class="form-control form-control-sm"
        id="ordlevadr2"
        [value]="checkout.extra.procargs.ordlevadr2 || ''"
        readonly
      />
    </div>
    <div class="form-group">
      <label for="ordlevadr3" class="control-label" translate>ADDRESS2</label>
      <input
        type="text"
        class="form-control form-control-sm"
        id="ordlevadr3"
        [value]="checkout.extra.procargs.ordlevadr3 || ''"
        readonly
      />
    </div>
    <div class="row">
      <div class="col-12 col-md-3">
        <div class="form-group">
          <label for="zip" class="control-label" translate>ZIP</label>
          <input
            type="text"
            class="form-control form-control-sm"
            id="zip"
            [value]="checkout.extra.procargs.ftgpostnr || ''"
            readonly
          />
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="form-group">
          <label for="city" class="control-label" translate>CITY</label>
          <input
            type="text"
            class="form-control form-control-sm"
            id="city"
            [value]="checkout.extra.procargs.ordlevadr4 || ''"
            readonly
          />
        </div>
      </div>
      <div class="col-12 col-md-3">
        <lib-meta-select-input
          table="xr"
          metaId="landskod"
          metaDisplay="land"
          [disabled]="true"
          [(ngModel)]="checkout.extra.procargs.ordlevadrlandskod"
        ></lib-meta-select-input>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6">
        <div class="form-group">
          <label for="oh.editext" class="control-label" translate>MESSAGE</label>
          <textarea
            class="form-control"
            type="text"
            name="oh.editext"
            id="oh.editext"
            *ngIf="!getIsSalesPortal()"
            [(ngModel)]="checkout.extra.oh.editext"
            rows="4"
          ></textarea>
          <textarea
            class="form-control"
            type="text"
            name="oh.editext"
            id="oh.editext"
            *ngIf="getIsSalesPortal()"
            [(ngModel)]="checkout.extra.oh.edit"
            rows="4"
          ></textarea>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <lib-gung-text-input gungTranslate="CUSTOMER_REFERENCE" [(ngModel)]="checkout.extra.oh.kundref2">
        </lib-gung-text-input>
        <lib-gung-text-input
          gungTranslate="GOODS_LABEL"
          [(ngModel)]="checkout.extra.oh.godsmarke2"
          *ngIf="getIsSalesPortal()"
        >
        </lib-gung-text-input>
      </div>
    </div>
  </div>
  <div class="col">
    <!-- <lib-gung-text-input gungTranslate="EMAIL" [(ngModel)]="checkout.extra.confirmationEmails[0]" *ngIf="!getDisableEmail()"></lib-gung-text-input> -->
    <div class="form-group">
      <label for="TRACKING_EMAIL" class="control-label" translate>TRACKING_EMAIL</label>
      <input
        type="text"
        class="form-control form-control-sm"
        id="TRACKING_EMAIL"
        [(ngModel)]="checkout.extra.kus.q_hl_emailtt"
        readonly
      />
    </div>
    <div class="form-group">
      <label for="q_hl_emailtt" class="control-label" translate>TRACKING_EMAIL_TEMP</label>&nbsp;
      <small>(<span translate>TRACKING_LINK_TEMP_EMAIL</span>)</small>
      <input
        type="text"
        class="form-control"
        name="q_hl_emailtt"
        id="q_hl_emailtt"
        [(ngModel)]="checkout.extra.oh.q_hl_emailtt"
        (change)="emailttChange('oh', $event.target.value)"
      />
    </div>
    <div class="form-group">
      <label for="EMAIL_INVOICE" class="control-label" translate>EMAIL_INVOICE</label>
      <input
        type="text"
        class="form-control form-control-sm"
        id="EMAIL_INVOICE"
        [(ngModel)]="checkout.extra.kus.q_hl_mail_invoicecc"
        readonly
      />
    </div>
    <div class="form-group">
      <label for="EMAIL_QUOTE" class="control-label" translate>EMAIL_QUOTE_ORDER_CONFIRMATION</label>
      <input
        type="text"
        class="form-control form-control-sm"
        id="EMAIL_QUOTE"
        [(ngModel)]="checkout.extra.kus.q_hl_default_email"
        readonly
      />
    </div>
    <div class="form-group">
      <label for="EMAIL_QUOTE_TEMPORARY" class="control-label" translate>EMAIL_QUOTE_ORDER_CONFIRMATION_TEMPORARY</label>
      <!-- Cannot be bound to oh, since that will trigger a update -->
      <input
        type="text"
        class="form-control form-control-sm"
        id="EMAIL_QUOTE_TEMPORARY"
        [(ngModel)]="checkout.extra._emailQuoteTemporary"
      />
      <p *ngIf="emailQuoteTemporaryInvalidError" class="small text-danger mt-0 mb-0" translate>EMAIL_QUOTE_TEMPORARY_INVALID</p>
      <p *ngIf="emailQuoteTemporaryRequiredError && !emailQuoteTemporaryRequiredError" class="small text-danger mt-0 mb-0" translate>EMAIL_QUOTE_TEMPORARY_REQUIRED</p>
      <span class="small mt-3">* <span class="ml-3" translate>MESSAGE_EMAIL_QUOTE_TEMP</span></span>
    </div>
  </div>
</div>
