import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DateUtilService } from 'gung-common';
import { ListItemRendererComponent } from 'gung-list';
import { LocationConfigService } from '../../../../services/location-config/location-config.service';
import { Subject } from 'rxjs';
import { Order } from '../../../../models/order';
import { GungCurrencyPipe } from '../../../../pipes/gung-currency.pipe';
import { startOfDay, differenceInDays } from 'date-fns';

@Component({
  selector: 'lib-production-management-orders-to-pick-table',
  templateUrl: './production-management-orders-to-pick-table.component.html',
  styleUrls: ['./production-management-orders-to-pick-table.component.css']
})
export class ProductionManagementOrdersToPickTableComponent extends ListItemRendererComponent<Order[]> implements OnInit {
  adjustedScroll = false;
  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected locationConfigService: LocationConfigService,
    protected router: Router,
    public dateUtilService: DateUtilService,
    public gungCurrencyPipe: GungCurrencyPipe
  ) {
    super();
  }

  ngOnInit() {

  }

  classSelector(key: number) {
    if (key === 13) {
      return {
        registered: true
      };
    }
    if (key === 11) {
      return {
        quote: true
      };
    }
    if (key === 10) {
      return {
        registrationInProgress: true
      };
    }
    if (key === 12) {
      return {
        blocked: true
      };
    }
    if (key === 5) {
      return {
        webRegistrationInProgress: true
      };
    }

    return {};
  }
  
  getCssClass(order) {
    if (order.extra.Order.InvoiceReference !== '0') {
      // already invoiced
      return '';
    }

    const date = order.extra.Order.OrderDate;
    const now = startOfDay(new Date());
    const oldDate = startOfDay(new Date(date));
    const days = differenceInDays(now, oldDate);

    if (days > 30) {
      return 'badge badge-pill badge-danger';
    } else if (days > 15) {
      return 'badge badge-pill badge-warning';
    } else {
      return '';
    }
  }
}
