import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-hl-display-open-orders-quotes',
  template: `
    <lib-gung-tabset justify="fill">
      <lib-gung-tab [title]="'ORDERS' | translate">
        <ng-template libGungTabContent>
          <div class="pb-4"></div>
          <app-hl-display-open-orders></app-hl-display-open-orders>
        </ng-template>
      </lib-gung-tab>
      <lib-gung-tab [title]="'INVOICES' | translate" *ngIf="isSales">
        <ng-template libGungTabContent>
          <div class="pb-4"></div>
          <app-hl-display-invoices></app-hl-display-invoices>
        </ng-template>
      </lib-gung-tab>
      <lib-gung-tab [title]="'OFFERS' | translate" *ngIf="!isManagersPortal">
        <ng-template libGungTabContent>
          <div class="pb-4"></div>
          <app-hl-display-quotes></app-hl-display-quotes>
        </ng-template>
      </lib-gung-tab>
      <lib-gung-tab [title]="'MEETINGS' | translate" *ngIf="!isManagersPortal">
        <ng-template libGungTabContent>
          <div class="pb-4"></div>
          <app-hl-display-meetings></app-hl-display-meetings>
        </ng-template>
      </lib-gung-tab>
    </lib-gung-tabset>
  `,
  styleUrls: ['./hl-display-open-orders-quotes.component.css']
})
export class HlDisplayOpenOrdersQuotesComponent implements OnInit {
  public isManagersPortal = environment.managers;
  isSales = environment.sales;
  constructor() {}

  ngOnInit() {}
}
