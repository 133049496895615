<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title" translate>DELETE_USER</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <p>{{ 'CONFIRM_USER_DELETION' | translate: { username: username } }}</p>
</div>

<div class="modal-footer">
  <div class="btn-group btn-group-justified btn-block">
    <button class="btn btn-sm btn-danger btn-default" (click)="activeModal.close('NO')" translate>CANCEL</button>
    <button class="btn btn-sm btn-success btn-default" (click)="activeModal.close('YES')" translate>YES</button>
  </div>
</div>
