<div *ngIf="readOnly; else editable">
  <lib-product-matrix-summary-element [simplifiedAvailability]="availability" [value]="0">
  </lib-product-matrix-summary-element>
</div>

<ng-template #editable>
  <lib-product-matrix-input-element
    [isAvailability]="availability"
    [id]="id"
    (quantityUpdated)="quantityUpdated.emit($event)"
    [productPartialId]="productPartialId"
    [product]="product"
    (selectedId)="selectedId.emit($event)"
    [availability]="availability"
    [showButtons]="showButtons"
  >
  </lib-product-matrix-input-element>

  <lib-product-matrix-element-simplified-availability
    [productId]="id"
    [availability]="availability"
    [product]="product"
  >
  </lib-product-matrix-element-simplified-availability>
</ng-template>
