import { Injectable } from '@angular/core';
import { OrderListConfigService, OrderService, SelectedCustomerService } from 'gung-standard';
import { Order } from 'gung-standard';
import { ConfigBaseFilter, ListLayout, ListLayoutComponent } from 'gung-list';
import { HlDisplayOrderCardListComponent } from '../components/hl-display-order-card-list/hl-display-order-card-list.component';
import { HlDisplayOrderListLayoutComponent } from '../components/hl-display-order-list-layout/hl-display-order-list-layout.component';
import { environment } from '../../environments/environment';
import { Observable, map, of, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HlDisplayOrderListConfigService extends OrderListConfigService {

  isCoopPortal = environment.mainCustomer === 'coop';
  isManagersNisa = environment.managers && environment.mainCountry === 'en';
  private isCustNlSuperunie1600 = !environment.sales && environment.bolag === '1600' && environment.mainCountry === 'nl' && environment.mainCustomer === 'spar';

  constructor(orderService: OrderService, selectedCustomerService: SelectedCustomerService) {
    super(orderService, selectedCustomerService);
  }

  isUkBolag = environment.bolag === '1710';

  getFilters(): ConfigBaseFilter<Order>[] {
    return [];
  }

  getLayouts(): ListLayout<Order>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => HlDisplayOrderCardListComponent,
        getListLayoutComponent: () => HlDisplayOrderListLayoutComponent,
        getName: () => 'Product grid'
      }
    ];
  }

  getLoadMoreCss(): string {
    return 'mt-1 btn-primary col-10';
  }

  getSearchGroupCss(): string {
    return 'col-10 offset-1';
  }

  getSearchTerms(item: Order): string[] {
    if (!this.isUkBolag) {
      return super.getSearchTerms(item);
    }

    const res = [...super.getSearchTerms(item)];

    if (item.extra.oh.ordlevplats1 && item.extra.deliveryCustomer.extra.lp) {
      const lp = item.extra.deliveryCustomer.extra.lp.find(l => l.ordlevplats1 === item.extra.oh.ordlevplats1);
      if (lp && lp._fr.ftgnamn) {
        res.push(lp._fr.ftgnamn);
      }
    }

    return res;
  }

  getSearchPlaceholderTranslate(): string {
    if (this.isUkBolag) {
      return 'SEARCH_STORE_NAME_NUMBER';
    }
    return 'SEARCH';
  }

  getItems(): Observable<Order[]>{
    return super.getItems().pipe(switchMap(orders =>{
      if(this.isCoopPortal || this.isManagersNisa || this.isCustNlSuperunie1600){
        orders = orders.filter(o => !(o.extra?.oh?.ordstat === 90 || o.extra?.oh?.ordtyp === 101 || o.extra?.oh?.ordtyp === 201 || o.extra?.oh?.q_hl_edi_cancel === true));
      }
      return of(orders);
    }));
  }
}
