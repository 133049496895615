<div class="row">
  <div class="col text-center">
    <h3 class="page-title text-center" translate>DOCUMENTS</h3>
  </div>
</div>
<div class="row">
  <div class="col text-right mb-2">
    <button (click)="openUploadModal()" type="button" class="btn btn-primary" translate>UPLOAD</button>
  </div>
</div>
<div class="row">
  <div class="col">
    <div class="table-responsive gung-responsive">
      <table class="table table-striped table-hover">
        <thead class="thead-light">
          <tr>
            <th scope="col" translate>NAME</th>
            <th scope="col" translate>SIZE</th>
            <th scope="col" translate>UPLOADED_BY</th>
            <th scope="col" translate>DATE</th>
            <th scope="col" translate>VISIBLE_IN_MENU</th>
            <th scope="col" translate>ACTION</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let document of documentsList">
            <th scope="row" [attr.data-label]="'NAME' | translate">
              <a class="text-primary" [href]="getDocumentUrl(document.id)" target="_blank">{{ document.filename }}</a>
              <span class="ml-3" *ngIf="duplicate[document.id]">
                <i class="fas fa-exclamation-circle" ngbTooltip="{{ 'ALREADY_EXISTING' | translate }}"></i>
              </span>
            </th>
            <td [attr.data-label]="'SIZE' | translate">{{ formatBytes(document.length, 0) }}</td>
            <td [attr.data-label]="'UPLOADED_BY' | translate">{{ document?.metaData?.user }}</td>
            <td [attr.data-label]="'DATE' | translate">
              {{ dateUtilService.parseDate(document.uploadDate) | date: dateUtilService.dateFormat + ' H:mm:ss' }}
            </td>
            <td [attr.data-label]="'VISIBLE_IN_MENU' | translate">
              <i
                class="fas"
                [class.fa-check]="document?.metaData?.inMenu && document?.metaData?.inMenu !== 'false'"
                [class.fa-times]="!document?.metaData?.inMenu || document?.metaData?.inMenu === 'false'"
              ></i>
            </td>
            <td [attr.data-label]="'ACTION' | translate">
              <button type="button" class="btn btn-primary btn-sm mr-1" (click)="editDocument(document)">
                <i class="fas fa-edit"></i>
              </button>

              <button type="button" class="btn btn-danger btn-sm" (click)="removeDocument(document)">
                <i class="fas fa-trash-alt"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
