import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HlDisplayMsflowArgumentService {
  constructor() {}

  getArgumentKey(): string {
    return '_msFlowArguments';
  }

  createMsFlowArguments(actionType: ActionType): MsFlowArguments {
    return {
      Portal_type: this.#getPortalType(),
      Portal_name: this.#getPortalName(),
      Action_type: actionType.toString()
    };
  }

  #getPortalType(): string {
    if (environment.sales) {
      return 'Sales Portal';
    } else {
      return 'Customer Portal';
    }
  }

  #getPortalName(): string {
    // Hostname gives us for example: sales-fr.hl-display.com
    // We want to return the first part i.e. sales-fr
    const name = window.location.hostname;
    return name.split('.')[0];
  }
}

export interface MsFlowArguments {
  Portal_type: string;
  Portal_name: string;
  Action_type: string;
}

export enum ActionType {
  Order = 'Order',
  Sample = 'Sample',
  Offer = 'Offer',
  OfferToOrder = 'OfferToOrder'
}
