import { Injectable } from '@angular/core';
import { DateUtilService } from 'gung-common';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HlDisplayDateUtilService extends DateUtilService {
  public isManagersNisa = environment.managers && environment.mainCountry === 'en';
  public isCurrysPortal =
    environment.bolag === '1710' &&
    !environment.sales &&
    !environment.managers &&
    environment.mainCountry === 'en' &&
    environment.mainCustomer === '';

  public dateFormat: string = this.isManagersNisa || this.isCurrysPortal ? 'dd-MM-yyyy' : (this.languageConfigService.getLanguages().find(lang => lang.short === this.currentLang)?.dateFormat || 'yyyy-MM-dd');
}
