<div class="modal-header">
  <div *ngIf="news">
    <h5 *ngIf="!news.id" class="modal-title" id="modal-basic-title" translate>NEW</h5>
    <h5 *ngIf="news.id" class="modal-title" id="modal-basic-title">{{ 'EDIT' | translate }} - {{ news.id }}</h5>
  </div>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div *ngIf="newsForm">
    <form [formGroup]="newsForm">
      <div class="form-group">
        <label for="ID" translate>ID</label>
        <input
          id="ID"
          name="ID"
          type="text"
          [placeholder]="'ID' | translate"
          class="form-control"
          formControlName="id"
        />
        <div
          *ngIf="
            getFormControl('id').invalid &&
            (getFormControl('id').dirty || getFormControl('id').touched) &&
            getFormControl('id').errors.required
          "
          class="alert alert-danger"
        >
          <span translate>REQUIRED</span>
        </div>
        <div
          *ngIf="
            getFormControl('id').invalid &&
            (getFormControl('id').dirty || getFormControl('id').touched) &&
            getFormControl('id').errors.pattern
          "
          class="alert alert-danger"
        >
          <span translate>ONLY_NUMBERS_ACCEPTED</span>
        </div>
      </div>

      <div class="form-group">
        <label for="NAME" translate>NAME</label>
        <input
          id="NAME"
          name="NAME"
          type="text"
          [placeholder]="'NAME' | translate"
          class="form-control"
          formControlName="name"
        />
        <div
          *ngIf="
            getFormControl('name').invalid &&
            (getFormControl('name').dirty || getFormControl('name').touched) &&
            getFormControl('name').errors.required
          "
          class="alert alert-danger"
        >
          <span translate>REQUIRED</span>
        </div>
      </div>

      <div class="form-group" formGroupName="extra">
        <label class="w-100" for="COVER" translate>COVER</label>
        <div class="input-group">
          <div class="flex-fill">
            <input
              id="COVER"
              class="form-control bg-transparent"
              type="text"
              [placeholder]="'IMAGE_URL' | translate"
              formControlName="cover"
            />
            <input
              #inputFile
              type="file"
              class="_custom-file-input form-control d-none"
              placeholder="File Upload"
              id="file-upload"
              aria-label="File Upload"
              aria-describedby="file-upload"
              accept=".jpg, .jpeg, .png"
              [multiple]="false"
              (change)="onFileChange($event)"
            />
          </div>
          <div class="input-group-append" (click)="inputFile.click()">
            <span class="input-group-text" id="file-upload"><i class="fas fa-folder mr-2"></i>UPLOAD</span>
          </div>
        </div>
      </div>

      <div class="form-group" formGroupName="extra">
        <label for="CATEGORY" translate>CATEGORY</label>
        <input
          id="CATEGORY"
          name="CATEGORY"
          type="text"
          [placeholder]="'CATEGORY' | translate"
          class="form-control"
          formControlName="category"
        />
      </div>

      <div class="form-group">
        <label for="PRODUCTLIST" translate>PRODUCTS</label>
        <lib-product-select-dropdown
          id="PRODUCTLIST"
          name="PRODUCTLIST"
          (productSelected)="setProductSelected($event)"
        ></lib-product-select-dropdown>
        <div class="products-selected my-3 flex-wrap d-flex" *ngIf="productsSelected.length > 0">
          <div class="product-box" *ngFor="let item of productsSelected">
            {{item.name}} <i class="delete-icon fa-thin fa-circle-xmark" (click)="removeProductSelected(item.id)"></i>
          </div>
        </div>
      </div>
      <hr *ngIf="productsSelected.length > 0">
    </form>

    <div class="d-flex flex-wrap">
      <div class="form-group mr-4">
        <div class="form-check">
          <input class="form-check-input scale12" type="checkbox" id="active" name="active" [(ngModel)]="news.active" />
          <label class="form-check-label" for="active" translate>ACTIVE</label>
        </div>
      </div>
      <div class="form-group mr-4">
        <div class="form-check">
          <input
            class="form-check-input scale12"
            type="checkbox"
            id="showInPopup"
            name="showInPopup"
            [(ngModel)]="news.extra.showInPopup"
          />
          <label class="form-check-label" for="showInPopup" translate>SHOW_IN_POP_UP</label>
        </div>
      </div>
      <div class="form-group mr-4">
        <div class="form-check">
          <input
            class="form-check-input scale12"
            type="checkbox"
            id="showInPage"
            name="showInPage"
            [(ngModel)]="news.extra.showInPage"
          />
          <label class="form-check-label" for="showInPage" translate>SHOW_IN_NEWS_PAGE</label>
        </div>
      </div>
    </div>

    <h6 translate>VISIBLE_FOR_ROLES</h6>
    <div *ngIf="noRoles" class="alert alert-warning" role="alert">
      <i class="fa-solid fa-triangle-exclamation mr-1"></i><span translate>WILL_NOT_SHOW_TO_ANY_ROLE</span>
    </div>
    <div class="d-flex flex-wrap">
      <div class="form-group mr-4">
        <div class="form-check">
          <input
            class="form-check-input scale12"
            type="checkbox"
            id="allRoles"
            name="allRoles"
            [(ngModel)]="allRoles"
            (click)="selectAllRoles($event.target.checked)"
          /> 
          <label class="form-check-label" for="allRoles" translate>ALL</label>
        </div>
      </div>
      <div class="form-group mr-4" *ngFor="let role of roles">
        <div class="form-check">
          <input
            class="form-check-input scale12"
            type="checkbox"
            [id]="role.name"
            [name]="role.name"
            [(ngModel)]="news.extra.activeRoles[role.name]"
            (ngModelChange)="checkAllSelected()"
          />
          <label class="form-check-label" [for]="role.name" translate>{{ role.translationKey }}</label>
        </div>
      </div>
    </div>

    <h6 translate>VISIBLE_FOR_GROUPS</h6>
    <div *ngIf="noGroups" class="alert alert-warning" role="alert">
      <i class="fa-solid fa-triangle-exclamation mr-1"></i><span translate>WILL_NOT_SHOW_TO_ANY_GROUP</span>
    </div>
    <div class="d-flex flex-wrap">
      <div class="form-group mr-4">
        <div class="form-check">
          <input
            class="form-check-input scale12"
            type="checkbox"
            id="all"
            name="all"
            [(ngModel)]="allGroups"
            (click)="selectAllGroups($event.target.checked)"
          /> 
          <label class="form-check-label" for="all" translate>ALL</label>
        </div>
      </div>
      
      <div class="form-group mr-4" *ngFor="let groups of userGroups">
        <div class="form-check">
          <input
            class="form-check-input scale12"
            type="checkbox"
            [id]="groups.id"
            [name]="groups.id"
            [(ngModel)]="news.activeGroups[groups.id]"
            (ngModelChange)="checkAllSelected()"
          /> 
          <label class="form-check-label" [for]="groups.id" translate>{{ groups.id }}</label>
        </div>
      </div>
    </div>
  </div>

  <lib-gung-html-editor
    *ngIf="news"
    suppressLabel="true"
    placeholder="Type the content here!"
    [(ngModel)]="news.html"
  ></lib-gung-html-editor>
  <div class="row">
    <div class="col-12">
      <div
        *ngIf="alertMessage"
        class="alert mt-2 text-center"
        role="alert"
        [ngClass]="{ 'alert-success': alertMessage.type === 'success', 'alert-danger': alertMessage.type === 'danger' }"
        (click)="alertMessage = null"
      >
        <span>{{ alertMessage.message | translate }}</span>
      </div>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-sm btn-default btn-outline-secondary text-uppercase" (click)="close()" translate>
    CLOSE
  </button>
  <button type="button" class="btn btn-sm btn-default btn-primary text-uppercase" (click)="save($event)" translate>
    SAVE
  </button>
</div>
