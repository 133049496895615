import { Action } from '@ngrx/store';
import { User } from './types';

export enum ActionTypes {
  Login = '[Auth] Login',
  BadLogin = '[Auth] Bad login',
  BackendDown = '[Auth] Backend Down',
  Logout = '[Auth] Logout',
  UserReceived = '[Auth] User logged in',
  UserNotReceived = '[Auth] User not received',
  UserGet = '[Auth] User Get',
  Init = '[Auth] Init',
  LoginFromAnonymous = '[Auth] Login from anonymous',
  InternalServerError = '[Auth] Internal Server Error'
}

export interface LoginPayload {
  username: string;
  password: string;
}

export class BadLogin implements Action {
  type: string = ActionTypes.BadLogin;
}

export class InternalServerError implements Action {
  type: string = ActionTypes.InternalServerError;
}

export class BackendDown implements Action {
  type: string = ActionTypes.BackendDown;
}

export class UserGet implements Action {
  type: string = ActionTypes.UserGet;
}

export class Init implements Action {
  type: string = ActionTypes.Init;
}
export class Login implements Action {
  type: string = ActionTypes.Login;
  constructor(public payload: LoginPayload) {}
}

export class LoginFromAnonymous implements Action {
  type: string = ActionTypes.LoginFromAnonymous;
  constructor(public payload: LoginPayload) {}
}

export class UserReceived implements Action {
  type: string = ActionTypes.UserReceived;
  constructor(public payload: User) {}
}

export class UserNotReceived implements Action {
  type: string = ActionTypes.UserNotReceived;
}

export class Logout implements Action {
  type: string = ActionTypes.Logout;
}
