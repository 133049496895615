import { Component } from '@angular/core';
import { ListItemRendererComponent } from 'gung-list';
import { HubspotContacts } from '../../models/hubspot-contacts';

@Component({
  selector: 'lib-hubspot-contact-card-list',
  templateUrl: './hubspot-contact-card-list.component.html',
  styleUrls: ['./hubspot-contact-card-list.component.css']
})
export class HubspotContactCardListComponent extends ListItemRendererComponent<HubspotContacts> {

}
