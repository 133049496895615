<div class="modal-header">
  <h5 class="modal-title" id="modal-basic-title" translate>NEWS</h5>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div *ngIf="news">
    <div class="row" *ngFor="let item of news; last as isLast; trackBy: trackByFn">
      <div class="col-12 d-flex justify-content-center">
        <div #newcontent class="ql-editor ck-content mw-100" [innerHTML]="item.html | safeHtml"></div>
        <hr class="separator" *ngIf="!isLast" />
      </div>
    </div>
  </div>
  <div *ngIf="productListIds && productListIds.length > 0" class="row mt-4">
    <div class="products col-12">
        <lib-simple-product-list-by-ids [productIds]="productListIds"></lib-simple-product-list-by-ids>
    </div>  
  </div>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-sm btn-default btn-primary text-uppercase" (click)="close()" translate>
    CLOSE
  </button>
</div>
