import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Product } from '../../models';
import { Assortment } from '../../services/assortment.service';
import { CloudPimPropertiesHelperService } from '../../services/cloud-pim-properties-helper/cloud-pim-properties-helper.service';
import { MetadataService } from '../../services/metadata/metadata.service';

@Component({
  selector: 'lib-cloud-pim-product-fields',
  templateUrl: './cloud-pim-product-fields.component.html',
  styleUrls: ['./cloud-pim-product-fields.component.scss']
})
export class CloudPimProductFieldsComponent implements OnInit, OnChanges {
  @Input()
  product: Product;

  @Input()
  assortment?: Assortment;

  currentLang = this.translateService.currentLang;

  public productDetailsTable: { title: string; value: string }[] = [];

  @Input()
  public collapse: number;
  public isCollapsed = true;

  @Input()
  propertiesToShow: ('productTemplateProperties' | 'itemProperties' | 'skuProperties')[];

  @Input()
  tableClass: string;

  @Input()
  divClass: string = 'table-responsive';

  @Input()
  removeAttributeTable: boolean = false;

  @Input()
  showEmpty: boolean = false;

  constructor(
    protected metadataService: MetadataService,
    protected translateService: TranslateService,
    protected cloudPimPropertyService: CloudPimPropertiesHelperService
  ) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.product && !changes.product.firstChange && changes.product.currentValue?.id !== changes.product.previousValue?.id) {
      this.initTemplate();
    }
  }

  ngOnInit(): void {
    this.initTemplate();
  }

  initTemplate() {
    this.productDetailsTable = this.cloudPimPropertyService.getPropertiesTable(this.product, this.assortment, this.propertiesToShow, this.showEmpty);
  }
}
