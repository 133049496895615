import { Component, OnInit } from '@angular/core';
import { CheckoutStepComponent, SelectedCustomerService, AuthService, gungValidateEmail } from 'gung-standard';
import { first, mergeMap } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { DeliveryLocationsJeevesComponent, DeliveryLocationsService } from 'gung-standard-jeeves';
import { FormBuilder } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { ShippingStepComponent } from '../shipping-step/shipping-step.component';

@Component({
  selector: 'app-shipping-step-quotes',
  templateUrl: './shipping-step-quotes.component.html',
  styleUrls: ['./shipping-step-quotes.component.css']
})
export class ShippingStepQuotesComponent extends ShippingStepComponent implements OnInit {
  public loading = true;

  sales: boolean = environment.sales;
  isPortalFr = environment.mainCountry === 'fr';
  public showApprovalQuote = false;

  emailQuoteTemporaryRequiredError: boolean = false;
  emailQuoteTemporaryInvalidError: boolean = false;

  public isPortalBenelux =
    environment.mainCountry === 'fr' && environment.quoteConfirmationEmailAddress === 'OrdersBenelux@hl-display.com';
  public isCloettaPortal =
    environment.bolag === '1210' &&
    !environment.sales &&
    environment.managers &&
    environment.mainCountry === 'en' &&
    environment.mainCustomer === 'cloetta';

  constructor(
    protected selectedCustomerService: SelectedCustomerService,
    authService: AuthService,
    deliveryLocationsService: DeliveryLocationsService,
    formBuilder: FormBuilder
  ) {
    super(selectedCustomerService, authService, deliveryLocationsService, formBuilder);
  }

  ngOnInit() {
    super.ngOnInit();
    this.showApprovalQuote = this.isPortalFr && this.sales;
    this.authService
      .getCurrentUser()
      .pipe(first())
      .subscribe(user => {
        this.checkout.extra.oh.vref = user.name;
      });
    this.deliveryLocationsService.getDeliveryLocationsForCustomer(this.selectedCustomer.id).pipe(first()).subscribe(deliveryLocations => {
      // console.log('deliveryLocations', deliveryLocations)
      // this.deliveryLocations = [...deliveryLocations];
    });
  }

  protected handleNextButtonClicked(): void {
    // if they don't have a default and have not written temporary
    if (!this.checkout.extra.kus.q_hl_default_email && !this.checkout.extra._emailQuoteTemporary) {
      this.emailQuoteTemporaryRequiredError = true;
      return;
    } else {
      this.emailQuoteTemporaryRequiredError = false;
    }
    // they either have a default or have typed in a temporary. If it is temporary, validate. If it is default, wish them good luck.
    // https://support.gung.se/a/tickets/211115

    if (
      !!this.checkout.extra._emailQuoteTemporary &&
      this.checkout.extra._emailQuoteTemporary.includes(';')
    ) {
      if (this.checkout.extra._emailQuoteTemporary.split(';').findIndex(m => !gungValidateEmail(m.trim())) > -1) {
        this.emailQuoteTemporaryInvalidError = true;
        return;
      } else {
        this.emailQuoteTemporaryInvalidError = false;
      }
    } else if (
      !!this.checkout.extra._emailQuoteTemporary &&
      !gungValidateEmail(this.checkout.extra._emailQuoteTemporary.trim())
    ) {
      this.emailQuoteTemporaryInvalidError = true;
      return;
    } else {
      this.emailQuoteTemporaryInvalidError = false;
    }

    this.stepDone.emit(this.checkout);
  }
}
