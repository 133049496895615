import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ReplenishmentProduct } from '../models/replenishment';

@Injectable({
  providedIn: 'root'
})
export class ReplenishmentProductService {
  private baseUrl = 'json/replenishments/products';

  constructor(protected http: HttpClient) {}

  public getAllForCustomer(customerId: string): Observable<ReplenishmentProduct[]> {
    const url = `${this.baseUrl}/customer/${customerId}`;
    return this.http.get<ReplenishmentProduct[]>(url);
  }

  public getOne(id: string): Observable<ReplenishmentProduct> {
    const url = `${this.baseUrl}/${id}`;
    return this.http.get<ReplenishmentProduct>(url);
  }

  public delete(id: string): Observable<null> {
    const url = `${this.baseUrl}/${id}`;
    return this.http.delete<null>(url);
  }

  public save(replenishmentProduct: ReplenishmentProduct): Observable<ReplenishmentProduct> {
    const url = `${this.baseUrl}`;
    return this.http.post<ReplenishmentProduct>(url, replenishmentProduct);
  }
}
