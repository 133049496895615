import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ListItemRendererComponent } from 'gung-list';
import {
  ActivityInternal,
  ActivityInternalService
} from '../../../services/activity-internal/activity-internal.service';
import { AuthService } from '../../../services/auth/auth.service';
import { GungModalService } from '../../../services/gung-modal/gung-modal.service';
import { MetadataService } from '../../../services/metadata/metadata.service';
import { DateUtilService } from 'gung-common';
import { ActivityInternalCreateModalComponent } from '../activity-internal-create-modal/activity-internal-create-modal.component';
import { ActivityInternalViewModalComponent } from '../activity-internal-view-modal/activity-internal-view-modal.component';
import { first } from 'rxjs/operators';
import { ActivityInternalCustomerResponsibleService } from '../../../services/activity-internal-customer-responsible.service';

@Component({
  selector: 'lib-activity-internal-table',
  templateUrl: './activity-internal-table.component.html',
  styleUrls: ['./activity-internal-table.component.css']
})
export class ActivityInternalTableComponent extends ListItemRendererComponent<ActivityInternal[]> {
  isAdmin = false;

  constructor(
    protected modalService: NgbModal,
    protected gungModalService: GungModalService,
    protected activityInternalService: ActivityInternalService,
    protected authService: AuthService,
    protected translationService: TranslateService,
    public dateUtilService: DateUtilService,
    public metadataService: MetadataService,
    public activityInternalCustomerResponsibleService: ActivityInternalCustomerResponsibleService
  ) {
    super();
    this.authService.getCurrentUser().subscribe(user => (this.isAdmin = user.roles.indexOf('ADMIN') > -1));
  }

  openViewModal(item: ActivityInternal) {
    this.gungModalService.openViewActivityModal(item).then(
      result => {},
      reason => {
        console.log(`Dismissed ${reason}`);
      }
    );
  }

  openDeleteModal(item: ActivityInternal) {
    const modalRef = this.gungModalService
      .openConfirmYesNoModal(undefined, this.translationService.instant('DELETE_ACTIVITY_CONFIRM'), { size: 'sm' })
      .then(
        result => {
          this.activityInternalService
            .deleteActivityInternal(item.id)
            .pipe(first())
            .subscribe(_ => {});
        },
        reason => {}
      );
  }

  deleteActivityInternal(item: ActivityInternal) {
    this.gungModalService
      .openConfirmYesNoModal(undefined, this.translationService.instant('REMOVE') + ` ${item.id}?`, { size: 'sm' })
      .then(result => {
        if (result) {
          this.activityInternalService.deleteActivityInternal(item.id).subscribe();
        }
      });
  }

  editActivityInternal(item: ActivityInternal) {
    this.gungModalService
      .openCreateActivityModal(item, { ariaLabelledBy: 'modal-basic-title', size: 'lg', backdrop: 'static' })
      .then(
        result => {},
        reason => {
          console.log(`Dismissed ${reason}`);
        }
      );
  }

  saveStatus(item: ActivityInternal) {
    item = {
      ...item,
      _showEdit: undefined
    } as any;
    this.activityInternalService.postActivityInternal(item).subscribe(() => {});
  }
}
