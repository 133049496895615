<div class="modal-header" *ngIf="modalTitle">
  <h4 class="modal-title" id="modal-basic-title" translate>{{ modalTitle }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
    <div class="row">
        <div class="col-12">
            <div class="form-group">
                <div class="row">
                    <div class="col-12">
                        <label *ngIf="inputLabel" for="value" class="control-label" translate>{{ inputLabel }}</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <input
                            class="form-control"
                            type="text"
                            name="value"
                            id="value"
                            [(ngModel)]="value"
                            (keyup.enter)="addBtn.click()"
                            ngbAutofocus
                        />
                    </div>
                    <div class="col-auto">
                        <button #addBtn type="button" class="btn btn-outline-primary rounded-pill" (click)="add(value)"><i class="fa-solid fa-plus mr-1"></i><span translate>ADD</span></button>
                    </div>
                </div>
              
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <ul>
                <li *ngFor="let ele of list; let i = index">{{ele}} <button type="button" class="btn btn-sm btn-outline-danger rounded-circle" (click)="remove(i)"><i class="fa-solid fa-minus"></i></button></li>
            </ul>
        </div>
    </div>
</div>

<div class="modal-footer">
  <div class="row w-100">
    <div class="col-12">
      <div class="btn-group btn-group-justified btn-block">
        <button class="btn btn-sm btn-outline-secondary btn-default" (click)="activeModal.dismiss()" translate>CANCEL</button>
        <button class="btn btn-sm btn-primary btn-default" (click)="activeModal.close(list)" translate>SAVE</button>
      </div>
    </div>
  </div>
</div>
