import { HlFilterServiceService } from './services/hl-filter-service.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { AppComponent } from './app.component';
import {
  GungStandardModule,
  PriceService,
  RequestCacheService,
  CacheInterceptorService,
  ProductListConfigService,
  OrderListConfigService,
  CartConfigService,
  DeliveryDateService,
  createTranslateLocalLoader,
  TranslationLocalService,
  CustomerListConfigService,
  OrderToBasketService,
  BackendInterceptor,
  GOOGLE_ANALYTICS_ID,
  MetadataService,
  ProductInputQuantityConfigService,
  ActivityService,
  GOOGLE_TAG_MANAGER_ID,
  EdiOrderListConfigService,
  AuthService,
  LanguageConfigService,
  PriceConfigService
} from 'gung-standard';
import { IndexPageComponent } from './components/index-page/index-page.component';
import { RouterModule, Routes } from '@angular/router';
import { FooterComponent } from './components/footer/footer.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HlDisplayProductListConfigService } from './services/hl-display-product-list-config.service';
import { HlDisplayProductCardListComponent } from './components/hl-display-product-card-list/hl-display-product-card-list.component';
import { NgbModule, NgbDateParserFormatter, NgbDatepickerI18n, NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';
import { HlBackendInterceptorService } from './services/hl-backend-interceptor/hl-backend-interceptor.service';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClient } from '@angular/common/http';
import { HlDisplayNavbarComponent } from './components/hl-display-navbar/hl-display-navbar.component';
import { HlDisplayCustomerDetailsComponent } from './components/hl-display-customer-details/hl-display-customer-details.component';
import { HlDisplayOrderListConfigService } from './services/hl-display-order-list-config.service';
import { ProductListByOrderComponent } from './components/product-list-by-order/product-list-by-order.component';
import { FilterListService, GungListModule } from 'gung-list';
import { HlDisplayOrderCardListComponent } from './components/hl-display-order-card-list/hl-display-order-card-list.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HlDisplayOrderListLayoutComponent } from './components/hl-display-order-list-layout/hl-display-order-list-layout.component';
import { HlDisplayProductDetailComponent } from './components/hl-display-product-detail/hl-display-product-detail.component';
import { ProductListByCategoryComponent } from './components/product-list-by-category/product-list-by-category.component';
import { CategoryListingComponent } from './components/category-listing/category-listing.component';
import { ResponsibilityPageComponent } from './components/responsibility-page/responsibility-page.component';
import { FaqPageComponent } from './components/faq-page/faq-page.component';
import { HistoryPageComponent } from './components/history-page/history-page.component';
import { HlDisplayNavbarCartComponent } from './components/hl-display-navbar-cart/hl-display-navbar-cart.component';
import { ProductService } from 'gung-standard';
import { HlProductService } from './services/hl-product.service';
import { HlDisplayAvailabilityComponent } from './components/hl-display-availability/hl-display-availability.component';
import { ContactUsPageComponent } from './components/contact-us-page/contact-us-page.component';
import { ProductListByTopProductsComponent } from './components/product-list-by-top-products/product-list-by-top-products.component';
import { HlAppWrapperComponent } from './components/hl-app-wrapper/hl-app-wrapper.component';
import { HlDisplayCartConfigService } from './services/hl-display-cart-config.service';
import { HlDisplayCartItemComponent } from './components/hl-display-cart-item/hl-display-cart-item.component';
import { HlDisplayCartListLayoutComponent } from './components/hl-display-cart-list-layout/hl-display-cart-list-layout.component';
import { HlDeliveryDateService } from './services/hl-delivery-date.service';
import { HlNgbDateParserFormatterService } from './services/hl-ngb-date-parser-formatter.service';
import { PipeService } from 'gung-standard';
import { HlPipeService } from './services/hl-pipe.service';

import { HlPriceService } from './services/hl-price.service';
import { TermsAndConditionsComponent } from './components/terms-and-conditions/terms-and-conditions.component';
import { HlComponentListComponent } from './components/hl-component-list/hl-component-list.component';
import { TopProductListLayoutComponent } from './components/top-product-list-layout/top-product-list-layout.component';
import { HlCheckoutComponent } from './components/hl-checkout/hl-checkout.component';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { HlDeliveryDatePickerComponent } from './components/hl-delivery-date-picker/hl-delivery-date-picker.component';
import { HomeComponent } from './components/home/home.component';

import { CustomersComponent } from './components/hl-dipslay-sales-representative/customers/customers.component';
import { OpenOrdersComponent } from './components/hl-dipslay-sales-representative/open-orders/open-orders.component';
import { HlDipslaySalesRepresentativeComponent } from './components/hl-dipslay-sales-representative/hl-dipslay-sales-representative.component';
import { GeneralComponent } from './components/hl-dipslay-sales-representative/general/general.component';
import {
  GungStandardJeevesModule,
  JeevesSalesCartConfigService,
  JeevesOrderListConfigService,
  JeevesDeliveryAddressesService,
  JeevesPriceService
} from 'gung-standard-jeeves';
import { InvoicesComponent } from './components/hl-dipslay-sales-representative/invoices/invoices.component';
import { SavedCartsComponent } from './components/hl-dipslay-sales-representative/saved-carts/saved-carts.component';

import { HlStepIndicatorComponent } from './components/checkout/hl-step-indicator/hl-step-indicator.component';
import { HlCheckoutConfigService } from './services/hl-checkout-config.service';
import { CheckoutConfigService } from 'gung-standard';
import { HlCheckoutButtonsComponent } from './components/checkout/hl-checkout-buttons/hl-checkout-buttons.component';
import { ShippingStepComponent } from './components/checkout/steps/shipping-step/shipping-step.component';
import { SalesTermsStepComponent } from './components/checkout/steps/sales-terms-step/sales-terms-step.component';
import { HlDisplayOrderDetailsComponent } from './components/hl-display-order-details/hl-display-order-details.component';
import { HlSummarizeOrderStepComponent } from './components/checkout/steps/hl-summarize-order-step/hl-summarize-order-step.component';
import { HlTranslationLocalService } from './services/hl-translation-local.service';
import { HlCustomerListConfigService } from './services/hl-customer-list-config.service';
import { HlCustomerListCardComponent } from './components/hl-customer-list-card/hl-customer-list-card.component';
import { HlDisplayCustomerServiceComponent } from './components/hl-display-customer-service/hl-display-customer-service.component';
import { HlSalesCartStepComponent } from './components/checkout/steps/hl-sales-cart-step/hl-sales-cart-step.component';
import { HlMultilangDatepickerService } from './services/hl-multilang-datepicker.service';
import { HlOrderToBasketService } from './services/hl-order-to-basket.service';
import { HlDisplaySalesCartRowComponent } from './components/hl-display-sales-cart-row/hl-display-sales-cart-row.component';
import { HlDisplaySalesCartListComponent } from './components/hl-display-sales-cart-list/hl-display-sales-cart-list.component';
import { HlDisplaySalesCartConfigService } from './services/hl-display-sales-cart-config.service';
import { HlDisplayOpenOrdersQuotesComponent } from './components/hl-display-open-orders-quotes/hl-display-open-orders-quotes.component';
import { environment } from '../environments/environment';
// eslint-disable-next-line max-len
import { HlDisplayOpenOrdersListConfigService } from './services/hl-display-open-orders-list-config.service';
import { HlDisplayQuotesListConfigService } from './services/hl-display-quotes-list-config.service';
import { HlDisplayOpenOrdersComponent } from './components/hl-display-open-orders-quotes/hl-display-open-orders/hl-display-open-orders.component';
import { HlDisplayQuotesComponent } from './components/hl-display-open-orders-quotes/hl-display-quotes/hl-display-quotes.component';

import { HlDisplayOrderActionConfigService } from './services/hl-display-order-action-config.service';
import { HlDisplayOrderModalComponent } from './components/hl-display-order-modal/hl-display-order-modal.component';
import { HlDisplayOrderAddRowModalComponent } from './components/hl-display-order-add-row-modal/hl-display-order-add-row-modal.component';
import { HlDisplayOrderAddRowComponent } from './components/hl-display-order-add-row/hl-display-order-add-row.component';
import { HLDisplayProductSelectDropdownComponent } from './components/hl-display-product-select-dropdown/hl-display-product-select-dropdown.component';

import * as Sentry from '@sentry/browser';
import { HlDisplayDeliveryDateService } from './services/hl-display-delivery-date.service';
import { JeevesOrderActionConfigService } from 'gung-standard-jeeves';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { HlSummarizeOrderComponent } from './components/checkout/steps/hl-summarize-order/hl-summarize-order.component';
import { HLSalesCartDeliveryDatePickerComponent } from './hlsales-cart-delivery-date-picker/hlsales-cart-delivery-date-picker.component';
import { HlValidationActionModalComponent } from './components/hl-validation-action-modal/hl-validation-action-modal.component';
import { HlCartPrevalidationStepComponent } from './components/checkout/steps/hl-cart-prevalidation-step/hl-cart-prevalidation-step.component';
import { HlDisplayOrderListWrapperComponent } from './components/hl-display-order-list-wrapper/hl-display-order-list-wrapper.component';
import { HlDisplayDeliveryDaysComponent } from './components/hl-display-delivery-days/hl-display-delivery-days.component';
import { HlLangSelectComponent } from './components/hl-lang-select/hl-lang-select.component';
import { HlAssignDefaultsComponent } from './components/checkout/hl-assign-defaults/hl-assign-defaults.component';
import { EmailStepComponent } from './components/checkout/steps/email-step/email-step.component';
import { HlProductListComponent } from './components/hl-product-list/hl-product-list.component';
import { HlProductListWrapperComponent } from './components/hl-product-list-wrapper/hl-product-list-wrapper.component';
import { HlProductListFiltersComponent } from './components/hl-product-list-filters/hl-product-list-filters.component';
import { HlProductListHeaderComponent } from './components/hl-product-list-header/hl-product-list-header.component';
import { HlDisplayCustomerOrderedProductsTableComponent } from './components/hl-display-customer-ordered-products-table/hl-display-customer-ordered-products-table.component';
import { CustomerOrderedProductsLastXMonthsComponent } from './components/hl-dipslay-sales-representative/customer-ordered-products-last-x-months/customer-ordered-products-last-x-months.component';
import { MeetingsComponent } from './components/hl-dipslay-sales-representative/meetings/meetings.component';
import { HlDisplayMeetingTableComponent } from './components/hl-display-meeting-table/hl-display-meeting-table.component';
import { HlDisplayCustomerMeetingModalComponent } from './components/hl-display-customer-meeting-modal/hl-display-customer-meeting-modal.component';
import { MatSelectModule } from '@angular/material/select';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HlCheckoutWrapperComponent } from './components/hl-checkout-wrapper/hl-checkout-wrapper.component';
import { HlDisplayJeevesOrderListConfigService } from './services/hl-display-jeeves-order-list-config.service';
import { HlDisplayJeevesOrderCardListComponent } from './components/hl-display-open-orders-quotes/hl-display-jeeves-order-card-list/hl-display-jeeves-order-card-list.component';
import { HlDisplayProductCardListGridViewComponent } from './components/hl-display-product-card-list-grid-view/hl-display-product-card-list-grid-view.component';
import { HlDisplayCheckoutAddRowModalComponent } from './components/hl-display-checkout-add-row-modal/hl-display-checkout-add-row-modal.component';
import { HlDisplayLibCartSummaryComponent } from './components/checkout/hl-display-lib-cart-summary/hl-display-lib-cart-summary.component';
import { HlDisplayThankYouComponent } from './components/hl-display-thank-you/hl-display-thank-you.component';
import { HlDisplayJeevesDeliveryAddressesService } from './services/hl-display-jeeves-delivery-addresses.service';
import { HlDisplayBuyBtnComponent } from './components/hl-display-buy-btn/hl-display-buy-btn.component';
import { HlDisplayMetadataService } from './services/hl-display-metadata.service';
import { HlDisplayLoginComponent } from './components/hl-display-login/hl-display-login.component';
import { HlDisplayRemoveBlockedProductsStepComponent } from './components/checkout/steps/hl-display-remove-blocked-products-step/hl-display-remove-blocked-products-step.component';
import { ShippingStepQuotesComponent } from './components/checkout/steps/shipping-step-quotes/shipping-step-quotes.component';
import { HlDisplayMeetingsComponent } from './components/hl-display-meetings/hl-display-meetings/hl-display-meetings.component';
import { HlDisplayMeetingCardListComponent } from './components/hl-display-meetings/hl-display-meeting-card-list/hl-display-meeting-card-list.component';
import { HlDisplayInvoicesComponent } from './components/hl-display-invoices/hl-display-invoices.component';
import { HlDisplayInvoiceCardListComponent } from './components/hl-display-invoice-card-list/hl-display-invoice-card-list.component';
import { HlProductInputQuantityConfigService } from './services/hl-product-input-quantity-config.service';
import { HlDisplaySliderPromotionComponent } from './components/hl-display-slider-promotion/hl-display-slider-promotion.component';
import { HlDisplayActivityService } from './services/hl-display-activity.service';
import { DateUtilService, GungCommonModule, GungLanguageConfigService } from 'gung-common';
import { HlDisplayEdiOrderListConfigService } from './services/hl-display-edi-order-list-config.service';
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { HlDisplayAuthService } from './services/hl-display-auth.service';
import { HlDisplayUserDetailsUserComponent } from './components/hl-display-user-details-user/hl-display-user-details-user.component';
import { HlDisplayUserDetailsComponent } from './components/hl-display-user-details/hl-display-user-details.component';
import { HlDisplayOrderFinderComponent } from './components/hl-display-order-finder/hl-display-order-finder.component';
import { HlDisplayOrderLinesTableComponent } from './components/hl-display-order-lines-table/hl-display-order-lines-table.component';
import { HlDisplayCustomerSelectDropdownComponent } from './components/hl-display-customer-select-dropdown/hl-display-customer-select-dropdown.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { HlDisplayGungLanguageConfigService } from './services/hl-display-gung-language-config.service';
import { HlDisplayPriceComponent } from './components/hl-display-price/hl-display-price.component';
import { HlDisplayPriceConfigService } from './services/hl-display-price-config.service';
import { HlDisplayPriceLevelsComponent } from './components/hl-display-price-levels/hl-display-price-levels.component';
import { HlDisplayAdditionalCostsStepComponent } from './components/checkout/steps/hl-display-additional-costs-step/hl-display-additional-costs-step.component';
import { HlDisplayDateUtilService } from './services/hl-display-date-util.service';
import { HlDisplayEditOrderrowModalComponent } from './components/hl-display-edit-orderrow-modal/hl-display-edit-orderrow-modal.component';

if (environment.sentryDsn && environment.sentryDsn !== '') {
  Sentry.init({
    dsn: environment.sentryDsn
  });
}

@NgModule({
  declarations: [
    AppComponent,
    IndexPageComponent,
    FooterComponent,
    HlDisplayProductCardListComponent,
    HlDisplayNavbarComponent,
    HlDisplayCustomerDetailsComponent,
    ProductListByOrderComponent,
    HlDisplayOrderCardListComponent,
    HlDisplayOrderListLayoutComponent,
    HlDisplayProductDetailComponent,
    ProductListByCategoryComponent,
    CategoryListingComponent,
    HlDisplayNavbarCartComponent,
    ResponsibilityPageComponent,
    FaqPageComponent,
    HistoryPageComponent,
    HlDisplayAvailabilityComponent,
    ContactUsPageComponent,
    ProductListByTopProductsComponent,
    HlAppWrapperComponent,
    HlDisplayCartItemComponent,
    HlDisplayCartListLayoutComponent,
    TermsAndConditionsComponent,
    HlComponentListComponent,
    TopProductListLayoutComponent,
    HlCheckoutComponent,
    HlDeliveryDatePickerComponent,
    HomeComponent,
    HlDipslaySalesRepresentativeComponent,
    CustomersComponent,
    OpenOrdersComponent,
    GeneralComponent,
    InvoicesComponent,
    SavedCartsComponent,
    HlStepIndicatorComponent,
    HlCheckoutButtonsComponent,
    ShippingStepComponent,
    SalesTermsStepComponent,
    HlDisplayOrderDetailsComponent,
    HlSummarizeOrderStepComponent,
    HlCustomerListCardComponent,
    HlDisplayCustomerServiceComponent,
    HlSalesCartStepComponent,
    HlDisplaySalesCartRowComponent,
    HlDisplaySalesCartListComponent,
    HlDisplayOpenOrdersQuotesComponent,
    HlDisplayOpenOrdersComponent,
    HlDisplayQuotesComponent,
    HlDisplayOrderModalComponent,
    HlDisplayOrderAddRowModalComponent,
    HlDisplayOrderAddRowComponent,
    HLDisplayProductSelectDropdownComponent,
    DatePickerComponent,
    HlSummarizeOrderComponent,
    HLSalesCartDeliveryDatePickerComponent,
    HlValidationActionModalComponent,
    HlCartPrevalidationStepComponent,
    HlDisplayOrderListWrapperComponent,
    HlDisplayDeliveryDaysComponent,
    HlLangSelectComponent,
    HlAssignDefaultsComponent,
    EmailStepComponent,
    HlProductListComponent,
    HlProductListWrapperComponent,
    HlProductListFiltersComponent,
    HlProductListHeaderComponent,
    MeetingsComponent,
    HlDisplayMeetingTableComponent,
    HlDisplayCustomerMeetingModalComponent,
    HlDisplayCustomerOrderedProductsTableComponent,
    CustomerOrderedProductsLastXMonthsComponent,
    HlCheckoutWrapperComponent,
    HlDisplayJeevesOrderCardListComponent,
    HlDisplayProductCardListGridViewComponent,
    HlDisplayCheckoutAddRowModalComponent,
    HlDisplayLibCartSummaryComponent,
    HlDisplayThankYouComponent,
    HlDisplayBuyBtnComponent,
    HlDisplayLoginComponent,
    HlDisplayRemoveBlockedProductsStepComponent,
    ShippingStepQuotesComponent,
    HlDisplayMeetingsComponent,
    HlDisplayMeetingCardListComponent,
    HlDisplayInvoicesComponent,
    HlDisplayInvoiceCardListComponent,
    HlDisplaySliderPromotionComponent,
    HlDisplayUserDetailsUserComponent,
    HlDisplayUserDetailsComponent,
    HlDisplayOrderFinderComponent,
    HlDisplayOrderLinesTableComponent,
    HlDisplayCustomerSelectDropdownComponent,
    HlDisplayPriceComponent,
    HlDisplayPriceLevelsComponent,
    HlDisplayAdditionalCostsStepComponent,
    HlDisplayEditOrderrowModalComponent
  ],
  imports: [
    BrowserModule,
    GungCommonModule,
    GungStandardModule,
    GungStandardJeevesModule,
    GungListModule,
    GungStandardJeevesModule,
    FormsModule,
    NgbModule,
    MatSelectModule,
    BrowserAnimationsModule,
    RouterModule,
    HttpClientModule,
    CdkStepperModule,
    ReactiveFormsModule,
    NgbCarouselModule,
    CarouselModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLocalLoader,
        deps: [TranslationLocalService]
      }
    }),
    OAuthModule.forRoot()
  ],
  providers: [
    { provide: 'environment', useValue: environment },
    {
      provide: LOCALE_ID,
      useValue: 'fr'
    },
    {
      provide: GOOGLE_ANALYTICS_ID,
      useValue: environment.analyticsId
    },
    RequestCacheService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HlBackendInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CacheInterceptorService,
      multi: true
    },
    {
      provide: BackendInterceptor,
      useClass: HlBackendInterceptorService
    },
    { provide: PriceService, useClass: HlPriceService },
    { provide: JeevesPriceService, useClass: HlPriceService },
    { provide: ProductListConfigService, useClass: HlDisplayProductListConfigService },
    { provide: OrderListConfigService, useClass: HlDisplayOrderListConfigService },
    { provide: ProductService, useClass: HlProductService },
    { provide: CartConfigService, useClass: HlDisplayCartConfigService },
    { provide: DeliveryDateService, useClass: HlDisplayDeliveryDateService },
    { provide: PipeService, useClass: HlPipeService },
    { provide: NgbDateParserFormatter, useClass: HlNgbDateParserFormatterService },
    { provide: CheckoutConfigService, useClass: HlCheckoutConfigService },
    { provide: TranslationLocalService, useClass: HlTranslationLocalService },
    { provide: CustomerListConfigService, useClass: HlCustomerListConfigService },
    { provide: NgbDatepickerI18n, useClass: HlMultilangDatepickerService },
    { provide: OrderToBasketService, useClass: HlOrderToBasketService },
    { provide: JeevesSalesCartConfigService, useClass: HlDisplaySalesCartConfigService },
    { provide: JeevesOrderActionConfigService, useClass: HlDisplayOrderActionConfigService },
    { provide: JeevesOrderListConfigService, useClass: HlDisplayJeevesOrderListConfigService },
    { provide: FilterListService, useClass: HlFilterServiceService },
    { provide: JeevesDeliveryAddressesService, useClass: HlDisplayJeevesDeliveryAddressesService },
    { provide: EdiOrderListConfigService, useClass: HlDisplayEdiOrderListConfigService },
    { provide: OAuthStorage, useValue: localStorage },
    {
      provide: MetadataService,
      useClass: HlDisplayMetadataService
    },
    { provide: ProductInputQuantityConfigService, useClass: HlProductInputQuantityConfigService },
    { provide: ActivityService, useClass: HlDisplayActivityService },
    { provide: GungLanguageConfigService, useClass: HlDisplayGungLanguageConfigService },
    { provide: LanguageConfigService, useClass: HlDisplayGungLanguageConfigService },
    { provide: PriceConfigService, useClass: HlDisplayPriceConfigService },
    {
      provide: GOOGLE_TAG_MANAGER_ID,
      useValue: environment.tagManagerId
    },
    {
      provide: AuthService,
      useClass: HlDisplayAuthService
    },
    HlDisplayOpenOrdersListConfigService,
    HlDisplayQuotesListConfigService,
    { provide: DateUtilService, useClass: HlDisplayDateUtilService }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
