<app-hl-summarize-order
  (stepError)="stepError.emit($event)"
  (stepDone)="handleStepDone($event)"
  [checkout]="checkout"
  [onNextBtnClicked]="composedOnNextBtnClicked"
></app-hl-summarize-order>
<div class="row justify-content-end">
  <div class="col-12 col-sm flex-grow-0">
    <div class="btn-container p-4" style="min-width: 250px;">
      <app-hl-display-lib-cart-summary></app-hl-display-lib-cart-summary>
      <div class="row">
        <!--
        <div class="col" *ngIf="showQuote && !showApprovalQuote">
          <button type="button" class="btn btn-secondary btn-block" translate (click)="createQuote()">
            CREATE_QUOTE
          </button>
        </div>
         -->
        <div class="col-12 mb-2" *ngIf="showCreateOrder">
          <button type="button" class="btn btn-primary btn-block text-nowrap" [disabled]="isCreditHardBlock || (isCreditSoftBlock && creditLeft < totals.totalValue)" translate (click)="createOrder()">
            ORDER
          </button>
          <div translate *ngIf="isCoopPortal || isManagersNisa" class="pt-1">NO_FREIGHT_NO_VAT</div>
        </div>
        <div class="col-12 mb-2" *ngIf="showApprovalQuote">
          <button type="button" class="btn btn-light btn-block" [disabled]="isCreditHardBlock" translate (click)="sendApprovalQuote()">
            OFFER
          </button>
        </div>
        <div class="col-12 mb-2" *ngIf="showCreateSampleOrder">
          <button type="button" class="btn btn-secondary btn-block text-nowrap" translate (click)="createSampleOrder()">
            SAMPLE
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
