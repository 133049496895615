<div class="form-group">
  <select
    class="form-control"
    *ngIf="batchSizes"
    name="batchSize"
    [(ngModel)]="currentBatchSize"
    (change)="updateBatchSize('' + currentBatchSize)"
  >
    <option *ngFor="let option of batchSizes" value="{{ option }}">
      <span translate>DISPLAY_BATCH_PREFIX </span> {{ option }}
    </option>
  </select>
</div>
