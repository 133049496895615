import { Directive, Input, OnInit } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';

export const generateId = (): string =>
  'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    // eslint-disable-next-line no-bitwise
    const r = (Math.random() * 16) | 0;
    // eslint-disable-next-line no-bitwise
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });

@Directive({
  selector: '[libBaseInput]'
})
export abstract class BaseInputDirective<T> implements ControlValueAccessor, OnInit {
  @Input()
  public renderStatic = false;

  @Input()
  public suppressLabel = false;

  @Input()
  public required = false;

  @Input()
  public placeholder = '';

  @Input()
  public gungTranslate: string;

  @Input()
  public tooltip?: string;

  @Input()
  translateParams: any;

  @Input()
  public disabled = false;

  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
  protected _value: T;

  public inputId: string;

  public translateTag: string;

  public onChange: (val: T) => void = _ => {};

  get value() {
    // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
    return this._value;
  }

  set value(value: T) {
    // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
    this._value = value;
    this.onChange(value);
  }

  writeValue(obj: T): void {
    if (obj !== undefined && obj !== null) {
      this.value = obj;
    }
  }

  registerOnChange(fn: (val: T) => void): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {}
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnInit(): void {
    this.inputId = generateId();
    if (!this.gungTranslate && !this.suppressLabel) {
      throw new Error('You need to supply a translate tag to this component');
    }
    this.translateTag = this.gungTranslate;
  }
}
