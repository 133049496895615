<lib-gung-tabset [destroyOnHide]="false" justify="fill">
  <lib-gung-tab [title]="'PDF_TO_CART' | translate" id="lib-gung-tab-0">
    <ng-template libGungTabContent>
      <div class="row">
        <div class="col-12 col-lg-4">
          <div class="row">
            <div class="col-12">
              <div class="file-drop" libFileDrop (fileDropped)="selectFile($event)">
                <input type="file" id="filedrop" [multiple]="false" (change)="selectFile($event.target.files)" />
                <i class="fal fa-5x fa-upload"></i>
                <h3>
                  <span translate> DROP_FILE_TO_UPLOAD </span>
                  <br />
                  <small translate> OR </small>
                </h3>
                <label class="btn btn-primary btn-block" for="filedrop" translate>BROWSE_FILE_SYSTEM</label>
                <p *ngIf="fileToSubmit">
                  <span>{{ fileToSubmit.name }}</span>
                </p>
              </div>
              <div class="btn-group submit-buttons">
                <button
                  [disabled]="!(fileToSubmit && drawnRectangle && s3Uri)"
                  class="btn btn-primary"
                  type="button"
                  (click)="submitFile()"
                >
                  <span *ngIf="!importing" translate>SUBMIT</span>
                  <span *ngIf="importing">
                    <span class="fa fa-spin fa-spinner"></span>
                  </span>
                </button>
              </div>
              <div></div>
            </div>
            <div class="col-12">
              <span translate>INSTRUCTIONS</span>:
              <ol style="padding-top: 0">
                <li translate>PDF_STEP_UPLOAD_PDF</li>
                <li translate>PDF_STEP_MARK_ORDERROWS</li>
                <li translate>PDF_STEP_SEND_FOR_PROCESSING</li>
                <li translate>PDF_STEP_ADJUST_QUANTITIES</li>
                <li translate>PDF_STEP_ADD_TO_CART</li>
              </ol>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-8">
          <div *ngIf="pdf">
            <button class="btn" [disabled]="pageNumber === 1" translate (click)="onPrevPage()">PREVIOUS</button>
            <button class="btn" [disabled]="pageNumber === pageCount" translate (click)="onNextPage()">NEXT</button>
            &nbsp; &nbsp;
            <span
              ><span translate>PAGE</span>: <span>{{ pageNumber }}</span> / <span>{{ pageCount }}</span></span
            >
          </div>
          <canvas
            #pdfCanvas
            id="pdf-canvas"
            (mousedown)="mouseDown($event)"
            (mouseup)="mouseUp($event)"
            (mousemove)="mouseMove($event)"
            (mouseout)="mouseOut()"
          ></canvas>
        </div>
      </div>
      <div *ngIf="pdfMatchingResponse?.lineMatchings && pdfMatchingResponse?.lineMatchings.length > 0" class="col-12">
        <lib-gung-tabset [destroyOnHide]="false">
          <lib-gung-tab [title]="'MATCHING_RESULTS' | translate">
            <ng-template libGungTabContent>
              <div class="row">
                <div class="col-12">
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th>
                          <span translate>PDF_ID</span>
                        </th>
                        <th colspan="2">
                          <span translate>PRODUCT_MATCHING</span>
                        </th>
                        <th>
                          <span class="text-right" translate>QUANTITY</span>
                        </th>
                        <th>
                          <span translate>MATCHER_USED</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <ng-container *ngFor="let line of pdfMatchingResponse.lineMatchings">
                        <tr *ngIf="line.reason !== 'HEADER_LINE'">
                          <td>
                            {{ line.pdfId }}
                          </td>
                          <td>
                            <span [style.color]="line.matchFound ? 'green' : 'red'">{{ line.productId }}</span>
                            <lib-product-select-dropdown
                              (productSelected)="selectProduct($event, line)"
                              [formatterFunction]="productSearchFormatter"
                            >
                            </lib-product-select-dropdown>
                          </td>
                          <td>
                            {{ line.productName }}
                          </td>
                          <td class="text-right">
                            <input type="number" [(ngModel)]="line.qty" min="0" class="form-control" />
                          </td>
                          <td>
                            {{ line.matcherUsed }}
                          </td>
                        </tr>
                      </ng-container>
                    </tbody>
                  </table>
                </div>
              </div>
            </ng-template>
          </lib-gung-tab>
          <lib-gung-tab
            [title]="'MANUALLY_SELECT_COLUMNS' | translate"
            *ngIf="pdfMatchingResponse?.tableData && pdfMatchingResponse?.tableData.length > 0"
          >
            <ng-template libGungTabContent>
              <div class="row">
                <div class="col-12">
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th *ngFor="let _ of pdfMatchingResponse?.tableData[0]; index as colIndex">
                          <div class="btn-group btn-group-justified btn-block">
                            <button
                              class="btn btn-primary btn-sm"
                              (click)="setAsProductIdColumn(colIndex)"
                              [disabled]="colIndex === pdfMatchingResponse?.indices?.idIndex"
                              translate
                            >
                              SET_AS_PRODUCT_ID_COLUMN
                            </button>
                            <button
                              class="btn btn-secondary btn-sm"
                              (click)="setAsQuantityColumn(colIndex)"
                              [disabled]="colIndex === pdfMatchingResponse?.indices?.qtyIndex"
                              translate
                            >
                              SET_AS_QUANTITY_COLUMN
                            </button>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let line of pdfMatchingResponse?.tableData; index as rowIndex">
                        <td *ngFor="let col of line; index as colIndex">
                          <span
                            *ngIf="
                              colIndex === pdfMatchingResponse?.indices?.idIndex &&
                              rowIndex <= pdfMatchingResponse?.indices?.lastHeaderIndex
                            "
                            translate
                            class="current-column"
                            >CURRENT_PRODUCT_ID_COLUMN<br
                          /></span>
                          <span
                            *ngIf="
                              colIndex === pdfMatchingResponse?.indices?.qtyIndex &&
                              rowIndex <= pdfMatchingResponse?.indices?.lastHeaderIndex
                            "
                            translate
                            class="current-column"
                            >CURRENT_QUANTITY_COLUMN<br
                          /></span>
                          {{ col }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="btn-group btn-group-justified btn-block">
                <button
                  class="btn btn-primary"
                  (click)="runMatchersWithManuallySetColumns()"
                  [disabled]="!manuallySetColumns || runningManualMatching"
                  translate
                >
                  RUN_MATCHERS_WITH_MANUAL_COLUMNS
                  <span *ngIf="runningManualMatching">
                    <span class="fa fa-spin fa-spinner"></span>
                  </span>
                </button>
              </div>
            </ng-template>
          </lib-gung-tab>
        </lib-gung-tabset>
        <div class="btn-group submit-buttons">
          <button
            [disabled]="!pdfMatchingResponse?.lineMatchings || pdfMatchingResponse?.lineMatchings.length < 0"
            class="btn btn-primary"
            type="button"
            (click)="addToCart()"
          >
            <span translate> ADD_ROWS_TO_CART </span>
          </button>
        </div>
      </div>
    </ng-template>
  </lib-gung-tab>

  <lib-gung-tab [title]="'PDF_SAVED_MAPPINGS' | translate" id="lib-gung-tab-1">
    <ng-template libGungTabContent>
      <lib-pdf-to-cart-saved-mappings></lib-pdf-to-cart-saved-mappings>
    </ng-template>
  </lib-gung-tab>
</lib-gung-tabset>
