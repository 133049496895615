<div *ngIf="loading || (loading && (!filteredItems || filteredItems.length <= 0))" class="progress mt-10">
  <div
    class="progress-bar progress-bar-striped progress-bar-animated bg-primary"
    role="progressbar"
    aria-valuenow="75"
    aria-valuemin="0"
    aria-valuemax="100"
    style="width: 75%"
  ></div>
</div>

<div class="filter-list">
  <div class="filter-list-wrapper">
    <div class="row">
      <div class="col-12">
        <div class="fixed-search" [ngClass]="getSearchGroupCss()" [ngStyle]="{ 'top.px': navbarHeight }">
          <div class="row search-container">
            <div class="col-12 col-md" *ngIf="!searchDisabled">
              <div class="row">
                <ng-template name="searchFields" libCustomHost></ng-template>
              </div>
            </div>
            <div class="col col-md-2" *ngIf="batchSizes && batchSizes.length > 1">
              <lib-filter-list-batch (batchSizeUpdated)="setBatchSize($event)" [batchSizes]="batchSizes">
              </lib-filter-list-batch>
            </div>
            <div [ngClass]="{'layout-buttons': searchDisabled}" class="col-auto" *ngIf="layouts && layouts.length > 1">
              <button
                class="btn btn-light"
                [ngClass]="{ active: layoutIndex === currentLayoutIdx }"
                *ngFor="let layout of layouts; let layoutIndex = index"
                (click)="changeLayout(layoutIndex)"
              >
                <i [ngClass]="layout.getIconClass()"></i>
              </button>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <ng-template libCustomHost></ng-template>
            <p *ngIf="filteredItems && filteredItems.length === 0" class="nothing-found">
              <span [innerHTML]="getNothingFoundTranslateTag() | translate"></span>
            </p>
          </div>
        </div>

        <div class="d-flex justify-content-center align-items-center btn-load-more">
          <button
            [ngClass]="getLoadMoreCss()"
            *ngIf="limit > 0 && !!filteredItems && filteredItems.length > limit"
            class="btn"
            (click)="loadMore()"
            style="overflow-anchor: none"
          >
            <span translate [translateParams]="{ limit: limit, total: filteredItems.length, batchSize: batchSize }">GUNG_ITEM_LIST_LOAD_MORE</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>