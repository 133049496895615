import { Component, OnChanges, OnInit } from '@angular/core';
import { ListItemRendererComponent } from 'gung-list';
import { ReportCentralReport, ReportCentralReportStatus } from '../../models/report-central';
import { first, interval, Subscription } from 'rxjs';
import { ReportCentralReportService } from '../../services/report-central-report.service';

@Component({
  selector: 'lib-report-central-report-list-table',
  templateUrl: './report-central-report-list-table.component.html',
  styleUrls: ['./report-central-report-list-table.component.scss']
})
export class ReportCentralReportListTableComponent
  extends ListItemRendererComponent<ReportCentralReport[]>
  implements OnInit, OnChanges
{
  subscription: Subscription;
  loaded = false;
  updatePeriodSeconds = 5;
  hasInProgressReports = true;

  keyedReports: { [id: string]: ReportCentralReport };
  noLongerProcessingReports: { [id: string]: ReportCentralReport } = {};

  constructor(protected reportCentralReportService: ReportCentralReportService) {
    super();
  }

  ngOnInit(): void {
    this.loaded = true;
    const source = interval(this.updatePeriodSeconds * 1000);
    this.subscription = source.subscribe(val => {
      if (this.hasInProgressReports) {
        this.getInProgressReports();
      }
    });
  }

  ngOnChanges() {
    if (!this.data) {
      return;
    }

    // This is used in order to be able to use the new data fetched in this component (data from list config will include
    // the old statuses).
    this.keyedReports = this.data.reduce((acc, curr) => {
      if (this.noLongerProcessingReports[curr.id]) {
        // If we have fetched a report that was previously PROCESSING, we want to take it from that map (with the updated values).
        // If we don't do this, it will reuse the old fetched value containing the incorrect status.
        acc[curr.id] = this.noLongerProcessingReports[curr.id];
      } else {
        acc[curr.id] = curr;
      }
      return acc;
    }, {});
  }

  private getInProgressReports(): void {
    const inProgressReportIds = this.data
      .filter(o => o.status === ReportCentralReportStatus.PROCESSING)
      .map(o => o.id)
      // if we have the item in this collection, it is no longer in status processing and can be disregarded.
      .filter(id => !this.noLongerProcessingReports[id]);

    if (inProgressReportIds.length == 0) {
      this.hasInProgressReports = false;
      return;
    }

    this.reportCentralReportService
      .getByIds(inProgressReportIds)
      .pipe(first())
      .subscribe(reports => {
        for (const report of reports) {
          if (report.status !== ReportCentralReportStatus.PROCESSING) {
            this.noLongerProcessingReports[report.id] = report;
            this.keyedReports[report.id] = report;
          }
        }
        this.hasInProgressReports = !!reports.find(o => o.status === ReportCentralReportStatus.PROCESSING);
      });
  }

  protected readonly ReportCentralReportStatus = ReportCentralReportStatus;
}
