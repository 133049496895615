<div class="row">
  <div class="col-12 text-center">
    <h3>{{ 'UPLOAD_EXCEL_ORDERSHEET' | translate | uppercase }}</h3>
  </div>
</div>

<ng-container *ngIf="!result && !multiOrderResult">
  <div class="row my-4">
    <div class="col-md-4 col-sm-6">
      <div class="card"
           [ngClass]="{'selected-format': uploadFormat === MATRIX_FORMAT,
           'not-selected-format': !!uploadFormat && uploadFormat !== MATRIX_FORMAT}"
      >
        <div class="card-body p-5">

          <div class="d-flex mb-3">
            <div>
              <i class="card-icon fa-regular fa-table-cells"></i>
            </div>
            <div class="pl-3">
              <h5 class="card-title" translate>MATRIX_FORMAT</h5>
              <p class="card-text" translate>UPLOAD_ORDER_EXCEL_MATRIX_INFO</p>
            </div>
          </div>

          <div>
            <button
              [disabled]="true"
              ngbTooltip="{{ 'EXAMPLE_FILE_CANNOT_BE_PROVIDED_DOWNLOAD_LINESHEET_FROM_PRODUCT_EXPORTS' | translate }}"
              type="button" class="btn btn-outline-primary mr-2">
              <i class="fa-regular fa-file-arrow-down"></i>
            </button>
            <button
              (click)="selectFormat(MATRIX_FORMAT)"
              type="button" class="btn btn-primary">
              <span class="mx-2" translate>SELECT</span>
            </button>
          </div>
        </div>
      </div>
    </div>


    <div class="col-md-4 col-sm-6">
      <div class="card"
           [ngClass]="{'selected-format': uploadFormat === COLUMN_FORMAT,
           'not-selected-format': !!uploadFormat && uploadFormat !== COLUMN_FORMAT}"
      >
        <div class="card-body p-5">
          <div class="d-flex mb-3">
            <div>
              <i class="card-icon fa-regular fa-columns-3"></i>
            </div>
            <div class="pl-3">
              <h5 class="card-title" translate>COLUMN_FORMAT</h5>
              <p class="card-text" translate>UPLOAD_ORDER_EXCEL_COLUMN_INFO</p>
            </div>
          </div>

          <div>
            <button
              onclick="location.href='https://cdn2.gung.io/_gung_templates/OrderImport-ColumnFormat.xlsx';"
              ngbTooltip="{{ 'DOWNLOAD_EXAMPLE_TEMPLATE' | translate }}"
              type="button" class="btn btn-outline-primary mr-2">
              <i class="fa-regular fa-file-arrow-down"></i>
            </button>
            <button
              (click)="selectFormat(COLUMN_FORMAT)"
              type="button" class="btn btn-primary">
              <span class="mx-2" translate>SELECT</span>
            </button>
          </div>

        </div>
      </div>
    </div>

    <div class="col-md-4 col-sm-6" *ngIf="isSalesOrAdmin && this.featureActivatedGuardWrapper.isActivated('gung', '3.0.20', true) | async">
      <div class="card"
           [ngClass]="{'selected-format': uploadFormat === MULTI_ORDER_COLUMN_FORMAT,
           'not-selected-format': !!uploadFormat && uploadFormat !== MULTI_ORDER_COLUMN_FORMAT}"
      >
        <div class="card-body p-5">

          <div class="d-flex mb-3">
            <div>
              <i class="card-icon fa-regular fa-boxes-stacked"></i>
            </div>
            <div class="pl-3">
              <h5 class="card-title" translate>MULTI_ORDER_COLUMN_FORMAT</h5>
              <p class="card-text" translate>UPLOAD_ORDER_EXCEL_MULTI_ORDER_COLUMN_FORMAT_INFO</p>
            </div>
          </div>

          <div>
            <button
              onclick="location.href='https://cdn2.gung.io/_gung_templates/OrderImport-MultiOrderColumnFormat.xlsx';"
              ngbTooltip="{{ 'DOWNLOAD_EXAMPLE_TEMPLATE' | translate }}"
              type="button" class="btn btn-outline-primary mr-2">
              <i class="fa-regular fa-file-arrow-down"></i>
            </button>
            <button
              (click)="selectFormat(MULTI_ORDER_COLUMN_FORMAT)"
              type="button" class="btn btn-primary">
              <span class="mx-2" translate>SELECT</span>
            </button>
          </div>
        </div>
      </div>
    </div>

  </div>

</ng-container>



<!-- IMPORT FILE -->
<ng-container *ngIf="!result && !multiOrderResult && uploadFormat">

  <div *ngIf="uploadFormat === MATRIX_FORMAT" class="row">
    <div class="col-md-6 text-md-right">
      <label translate>MODE</label>
    </div>
    <div class="col-md-6">
      <div style="max-width: 300px;">
        <lib-gung-select-option-input
          [suppressLabel]="true"
          [optionsList]="replaceModeOptions"
          [(ngModel)]="replaceMode"
          [disabled]="loading ? true : null"
        ></lib-gung-select-option-input>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-6 text-md-right">
      <label for="file-upload" translate>SELECT_FILES</label>
    </div>
    <div class="col-md-6">
      <input
        #inputFile
        type="file"
        class="_custom-file-input"
        id="file-upload"
        aria-describedby="file-upload"
        [multiple]="false"
        [disabled]="loading ? true : null"
      />
    </div>
  </div>
  <div class="row">
    <div class="col-12 text-right">
      <button
        type="button"
        class="btn btn-primary px-5"
        (click)="uploadFile(inputFile)"
        [disabled]="loading ? true : null"
        translate
      >
        UPLOAD <i *ngIf="loading" class="fa fa-spinner fa-spin fa-lg"></i>
      </button>
    </div>
  </div>
</ng-container>

<!-- IMPORT PRODUCT FROM FILE -->
<ng-container *ngIf="result && (uploadFormat === MATRIX_FORMAT || uploadFormat === COLUMN_FORMAT)">
  <div *ngIf="result.errors && result.errors.length > 0" class="row mt-4">
    <div class="col-12">
      <table class="table">
        <thead>
          <tr>
            <th scope="col" translate>ERRORS</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let error of result.errors">
            <td>{{ error }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <table class="table table-striped">
        <thead class="thead-dark">
          <tr>
            <th scope="col">#</th>
            <th scope="col" translate>ITEM_NO</th>
            <th scope="col" translate>EAN</th>
            <th scope="col" translate>QUANTITY</th>
            <th scope="col" translate>PRODUCT</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of result.rows">
            <th scope="row">{{ row.rownr }}</th>
            <td>{{ row.product.id.includes('_GUNGDOT_') ? replaceGungDot(row.product.id) : row.product.id }}</td>
            <td>{{ row.eanCode }}</td>
            <td>{{ row.quantity }}</td>
            <td>
              <a [routerLink]="['/product/', row.productId]" target="_blank">{{
                row.productId.includes('_GUNGDOT_') ? replaceGungDot(row.productId) : row.productId
              }}</a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div *ngIf="quantityChanged" class="alert alert-warning" role="alert" translate>
    QUANTITY_CHANGED_IMPORT_WARNING
  </div>
  <div class="row" *ngIf="!loading">
    <div class="col">
      <button type="button" class="btn btn-danger" translate (click)="uploadFormat = undefined; result = undefined">
        CANCEL
      </button>
      <button type="button" class="btn btn-secondary ml-2" translate (click)="loadProductsToCart()">
        {{
          uploadFormat === 'COLUMN_FORMAT' ||
          (uploadFormat === 'MATRIX_FORMAT' && replaceMode === 'REPLACE_CURRENT_ORDER')
            ? 'REPLACE_CART'
            : 'ADD_TO_ORDER'
        }}
      </button>
      <span
        *ngIf="
          uploadFormat === 'COLUMN_FORMAT' ||
          (uploadFormat === 'MATRIX_FORMAT' && replaceMode === 'REPLACE_CURRENT_ORDER')
        "
        class="ml-2"
        translate
        >REPLACE_CART_INFO</span
      >
    </div>
  </div>
</ng-container>


<ng-container *ngIf="multiOrderResult && (uploadFormat === MULTI_ORDER_COLUMN_FORMAT)">
  <lib-multi-order-excel-import-result [result]="multiOrderResult"></lib-multi-order-excel-import-result>
</ng-container>

