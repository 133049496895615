import { Component, OnInit } from '@angular/core';
import { UserDetailsUserComponent } from 'gung-standard';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-hl-display-user-details-user',
  templateUrl: './hl-display-user-details-user.component.html',
  styleUrls: ['./hl-display-user-details-user.component.css']
})
export class HlDisplayUserDetailsUserComponent extends UserDetailsUserComponent {
  isManagersPortal = environment.managers;
  public isCurrysPortal =
    environment.bolag === '1710' &&
    !environment.sales &&
    !environment.managers &&
    environment.mainCountry === 'en' &&
    environment.mainCustomer === '';
}
