import { EmailStepComponent } from './../components/checkout/steps/email-step/email-step.component';
import { Injectable, Type } from '@angular/core';
import {
  JeevesCheckoutConfigService,
  DeliveryLocationsJeevesComponent,
  OrderTermsJeevesComponent,
  SummarizeOrderJeevesComponent,
  AssignJeevesDefaultsComponent
} from 'gung-standard-jeeves';
import { HlStepIndicatorComponent } from '../components/checkout/hl-step-indicator/hl-step-indicator.component';
import { HlCheckoutButtonsComponent } from '../components/checkout/hl-checkout-buttons/hl-checkout-buttons.component';
import { first, forkJoin, map, switchMap } from 'rxjs';
import { ShippingStepComponent } from '../components/checkout/steps/shipping-step/shipping-step.component';
import { CheckoutStepComponent, CartSalesStepComponent, RemoveBlockedProductsStepComponent } from 'gung-standard';
import { SalesTermsStepComponent } from '../components/checkout/steps/sales-terms-step/sales-terms-step.component';
import { HlSummarizeOrderStepComponent } from '../components/checkout/steps/hl-summarize-order-step/hl-summarize-order-step.component';
import { HlSalesCartStepComponent } from '../components/checkout/steps/hl-sales-cart-step/hl-sales-cart-step.component';
import { HlCartPrevalidationStepComponent } from '../components/checkout/steps/hl-cart-prevalidation-step/hl-cart-prevalidation-step.component';
import { environment } from '../../environments/environment';
import { Observable, of } from 'rxjs';
import { HlAssignDefaultsComponent } from '../components/checkout/hl-assign-defaults/hl-assign-defaults.component';
import { HlDisplayRemoveBlockedProductsStepComponent } from '../components/checkout/steps/hl-display-remove-blocked-products-step/hl-display-remove-blocked-products-step.component';
import { ShippingStepQuotesComponent } from '../components/checkout/steps/shipping-step-quotes/shipping-step-quotes.component';
import { HlDisplayAdditionalCostsStepComponent } from '../components/checkout/steps/hl-display-additional-costs-step/hl-display-additional-costs-step.component';

@Injectable({
  providedIn: 'root'
})
export class HlCheckoutConfigService extends JeevesCheckoutConfigService {
  getPreProcessSteps() {
    return super.getPreProcessSteps().pipe(
      map(steps => {
        const toReturn: Type<CheckoutStepComponent>[] = steps;

        if (environment.sales) {
          toReturn.push(HlCartPrevalidationStepComponent);
        }

        return toReturn;
      })
    );
  }

  getMainProcessSteps() {
    return super.getMainProcessSteps().pipe(
      switchMap(steps =>
        forkJoin({
          steps: of(steps),
          roles: this.authService.getRoles().pipe(first())
        })
      ),
      map(({ steps, roles }) => {
        const toReturn: Type<CheckoutStepComponent>[] = steps;

        // replace delivery locations
        let idx = steps.indexOf(DeliveryLocationsJeevesComponent);
        if (idx >= 0) {
          if (
            environment.sales &&
            (environment.mainCountry === 'fr' ||
              environment.mainCountry === 'de' ||
              environment.mainCountry === 'no' ||
              environment.mainCountry === 'fi' ||
              environment.mainCountry === 'se')
          ) {
            toReturn[idx] = ShippingStepQuotesComponent;
          } else {
            toReturn[idx] = ShippingStepComponent;
          }
        }

        // replace order terms
        idx = toReturn.indexOf(OrderTermsJeevesComponent);
        if (idx >= 0) {
          toReturn[idx] = SalesTermsStepComponent;

          //remove terms for managers sales
          if (environment.managers && !roles.includes('ADMIN')) {
            toReturn.splice(toReturn.indexOf(SalesTermsStepComponent), 1);
          }
        }

        idx = toReturn.indexOf(SummarizeOrderJeevesComponent);
        if (idx >= 0) {
          toReturn[idx] = HlSummarizeOrderStepComponent;
          toReturn.splice(idx, 0, EmailStepComponent);
        }

        idx = toReturn.indexOf(CartSalesStepComponent);
        if (idx >= 0) {
          toReturn[idx] = HlSalesCartStepComponent;
          toReturn.splice(idx + 1, 0, HlDisplayAdditionalCostsStepComponent);
        }
        return toReturn;
      })
    );
  }

  getInternalPreProcessSteps(): Observable<Type<CheckoutStepComponent>[]> {
    const result = [HlAssignDefaultsComponent] as Type<CheckoutStepComponent>[];

    return of(result);
  }

  getStepIndicator() {
    return HlStepIndicatorComponent;
  }

  getButtonsComponent() {
    return HlCheckoutButtonsComponent;
  }
}
