import { Component } from '@angular/core';
import { ListItemRendererComponent } from 'gung-list';
import { StatisticsListItem } from '../../models/statistics';
import { DateUtilService } from 'gung-common';

@Component({
  selector: 'lib-order-finder-card-list',
  templateUrl: './order-finder-card-list.component.html',
  styleUrls: ['./order-finder-card-list.component.css']
})
export class OrderFinderCardListComponent extends ListItemRendererComponent<StatisticsListItem> {
  constructor(public dateUtilService: DateUtilService) {
    super();
  }

  expandInfo = false;
}
