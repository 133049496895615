import { Component, OnInit } from '@angular/core';
import { ListItemRendererComponent } from 'gung-list';
import { Order } from '../../models/order';
import { QuotesConfigService } from '../../services/quotes-config/quotes-config.service';

@Component({
  selector: 'lib-quotes',
  template: '<lib-filter-list [configService]="config"></lib-filter-list>',
  styleUrls: ['./quotes.component.css']
})
export class QuotesComponent extends ListItemRendererComponent<Order[]> implements OnInit {
  constructor(public config: QuotesConfigService) {
    super();
  }

  ngOnInit() {}
}
