import { Component, OnInit } from '@angular/core';
import { ListItemRendererComponent } from 'gung-list';
import { Invoice } from '../../models/invoice';

@Component({
  selector: 'lib-invoice-card-list',
  templateUrl: './invoice-card-list.component.html',
  styleUrls: ['./invoice-card-list.component.css']
})
export class InvoiceCardListComponent extends ListItemRendererComponent<Invoice> {}
