import { ProductDetailComponent } from './../../components/product-detail/product-detail.component';
import { Injectable } from '@angular/core';
import { AssortmentTreeListComponent } from '../../components/assortment-tree-list/assortment-tree-list.component';
import { ConceptTreeListComponent } from '../../components/concept-tree-list/concept-tree-list.component';
import { ProductTreeListComponent } from '../../components/product-tree-list/product-tree-list.component';
import { ConceptDetailComponent } from '../../components/concept-detail/concept-detail.component';
import { BaseViewConfigService } from '../base-view-config/base-view-config.service';

@Injectable({
  providedIn: 'root'
})
export class AssortmentTreeNavigationConfigService {
  ignoreFilterOnConcept = false;

  constructor(protected baseViewConfigService: BaseViewConfigService) {}

  getAssortmentLayout() {
    return AssortmentTreeListComponent;
  }

  getConceptLayout() {
    return ConceptTreeListComponent;
  }

  getConceptDetailLayout() {
    return ConceptDetailComponent;
  }

  getProductsLayout() {
    return ProductTreeListComponent;
  }

  getProductDetailLayout() {
    return this.baseViewConfigService.getProductDetailComponent();
  }
}
