import { Component, OnInit } from '@angular/core';
import { ListItemRendererComponent } from 'gung-list';
import { CustomerContact } from '../../models/customerContact';
import { GungModalService } from '../../services/gung-modal/gung-modal.service';

@Component({
  selector: 'lib-customer-contacts-gung-card-list',
  templateUrl: './customer-contacts-gung-card-list.component.html',
  styleUrls: ['./customer-contacts-gung-card-list.component.css']
})
export class CustomerContactsGungCardListComponent extends ListItemRendererComponent<CustomerContact> {
  constructor(protected gungModalService: GungModalService) {
    super();
  }

  editContact(customerContact: CustomerContact) {
    this.gungModalService.openEditContactModal(customerContact);
  }
}
