import { Component, OnInit } from '@angular/core';
import { JeevesOrderActionConfigService, JeevesOrderCardListComponent } from 'gung-standard-jeeves';
import { LocationConfigService } from 'gung-standard';
import { environment } from './../../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-hl-display-jeeves-order-card-list',
  templateUrl: './hl-display-jeeves-order-card-list.component.html',
  styleUrls: ['./hl-display-jeeves-order-card-list.component.css']
})
export class HlDisplayJeevesOrderCardListComponent extends JeevesOrderCardListComponent implements OnInit {
  isSalesPortal = environment.sales;
  isPortalNo = environment.mainCountry === 'no';
  isSalesDePortal = environment.sales && environment.mainCountry === 'de';
  currentLang = '';

  constructor(public configService: JeevesOrderActionConfigService, locationConfigService: LocationConfigService, protected translate: TranslateService) {
    super(configService, locationConfigService);
  }

  ngOnInit() {
    this.currentLang = this.translate.currentLang;
  }
}
