<div class="edi-order" *ngIf="!!dispatchAdviceData">
  <div class="order-header">
    <div class="header-title">
      <h3>
        <span translate="DESPATCH_ADVICE"></span> {{ dispatchAdviceData.id }}
        <span style="float: right"><lib-edi-send-status [message]="dispatchAdviceData"></lib-edi-send-status></span>
      </h3>
    </div>
    <div class="row">
      <div class="col">
        <lib-action-btn class="float-right mb-2" [(item)]="dispatchAdviceData" [config]="actionConfig"></lib-action-btn>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-7">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label class="control-label" for="number" translate>DISPATCH_ADVICE_NUMBER</label>
              <input
                class="form-control"
                name="number"
                type="text"
                [(ngModel)]="dispatchAdviceData?.documentReferences?.despatchAdvice.id"
                [disabled]="configService.disable.dispatchAdviceNumber"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="control-label" for="dispatchDate" translate>DISPATCH_ADVICE_DATE</label>
              <input
                class="form-control"
                name="dispatchDate"
                type="text"
                [(ngModel)]="dispatchAdviceData?.documentReferences?.despatchAdvice.date"
                [disabled]="configService.disable.dispatchAdviceDate"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="control-label" for="shippingDate" translate>GUNG_EDI_MESSAGE_SEND_STATUS</label>
              <input
                *ngIf="!!dispatchAdviceData"
                class="form-control"
                name="shippingDate"
                type="text"
                [(ngModel)]="dispatchAdviceData.gungEdiMessageSendStatus"
                [disabled]="configService.disable.shippingDate"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="control-label" for="buyerOrderNumber"><span translate>BUYER_ORDER_NUMBER</span></label>
              <input
                class="form-control"
                name="buyerOrderNumber"
                type="text"
                [(ngModel)]="dispatchAdviceData?.documentReferences.buyerOrder.id"
                [disabled]="configService.disable.buyerOrdeNumber"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="control-label" for="buyerOrderNumber"><span translate>SUPPLIER_ORDER_NUMBER</span></label>
              <input
                class="form-control"
                name="buyerOrderNumber"
                type="text"
                [(ngModel)]="dispatchAdviceData?.documentReferences.supplierOrder.id"
                [disabled]="configService.disable.buyerOrdeNumber"
              />
            </div>
          </div>
          <div class="col-md-4" *ngIf="!!dispatchAdviceData.timestamp">
            <div class="form-group">
              <label class="control-label" for="delivery-date" translate>TIMESTAMP</label>
              <input
                class="form-control"
                name="timestamp"
                type="text"
                [(ngModel)]="dispatchAdviceData.timestamp"
                [disabled]="true"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="control-label" for="" translate>DELIVERED_TIMESTAMP</label>
              <input
                class="form-control"
                name="timestamp"
                type="text"
                [ngModel]="dispatchAdviceData?.sentTimestamp ? (dispatchAdviceData.sentTimestamp | date : dateUtilService.dateFormat) : ''"
                [disabled]="true"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-5">
        <lib-edi-gln-number-tabs [ediMessage]="dispatchAdviceData"></lib-edi-gln-number-tabs>
      </div>
    </div>
  </div>
  <lib-edi-detail-error-display [ediMessage]="dispatchAdviceData"></lib-edi-detail-error-display>
  <div class="header-title">BODY</div>
  <!-- <div class="order-itens row">
        <div class="col-12 table-responsive gung-responsive">
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th translate>ITEM_IDS</th>
                        <th translate>NAME</th>
                        <th translate>PACKAGE_SSCC</th>
                        <th translate>ORDER_NO</th>
                        <th translate>QUANTITY</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of dispatchAdviceData.items">
                        <td [attr.data-label]="'ITEM_IDS' | translate">
                            <div *ngIf="item.ids.gungId">{{'ID' | translate}}: {{item.ids.gungId}}</div>
                            <div *ngIf="item.ids.ean13">{{'EAN' | translate}}: {{item.ids.ean13}}</div>
                            <div *ngIf="item.ids.upca">{{'UPCA' | translate}}: {{item.ids.upca}}</div>
                            <div *ngIf="item.ids.buyerId">{{'BUYER_ID' | translate}}: {{item.ids.buyerId}}</div>
                        </td>
                        <td [attr.data-label]="'NAME' | translate">
                            {{item.name}}
                        </td>
                        <td [attr.data-label]="'PACKAGE_SSCC' | translate">
                            <div *ngFor="let parcel of item.parcels">
                                {{parcel.ginIdentifier}} : {{'QTY' | translate}}: {{parcel.quantity}}
                            </div>
                        </td>
                        <td [attr.data-label]="'ORDER_NO' | translate">
                            {{item.documentReferences.buyerOrder.id}}
                        </td>
                        <td [attr.data-label]="'QUANTITY' | translate">
                            {{item.quantities.actualQuantity}}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    -->
  <!-- NEW TABLE COMPNENT -->
  <lib-edi-row-table [data]="dispatchAdviceData.items" [columns]="itemsColumns"></lib-edi-row-table>
  <div class="dispatch-advice-errors">
    <div class="header-title" translate>ERROR</div>
    <lib-edi-error-messages [errors]="[]"></lib-edi-error-messages>
  </div>
</div>
