<!-- <span class="d-block" *ngFor="let variant of pimProductVariants">{{ variant.id }} - {{ variant.name }}</span> -->
<div class="table-responsive">
  <div class="float-right p-2">
    <button
      type="button"
      class="btn btn-primary"
      (click)="onSaveVariants()"
      [disabled]="isLoading ? true : null"
      translate
    >
      SAVE_VARIANTS<i *ngIf="isLoading" class="fas fa-spinner fa-spin"></i>
    </button>
  </div>
  <table class="table">
    <table class="table table-striped">
      <thead class="thead-light">
        <tr>
          <th scope="col" translate>VARIANT_ID</th>
          <th scope="col" translate>VARIANT_NAME</th>
          <th scope="col"><span translate>PIM_SEASON</span></th>
          <th scope="col"><span translate>GARP_SEASON</span></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let productVariant of pimProductVariants">
          <th scope="row">{{ productVariant.id }}</th>
          <th scope="row">{{ productVariant.name }} </th>         
          <td>
            <input class="form-control" [(ngModel)]="productVariant.extra.pim.season" /> 
          </td>
          <td>
            {{productVariant.erpReadOnlyData.AGA.SES}}
          </td>
        </tr>
      </tbody>
    </table>
  </table>
</div>
