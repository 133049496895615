import { Component, forwardRef, Input, OnInit, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { TranslateService } from '@ngx-translate/core';
import { AngularEditorConfigService } from '../../services/angular-editor-config/angular-editor-config.service';
import { BaseInputDirective } from '../base-input/base-input.directive';

@Component({
  selector: 'lib-gung-html-editor',
  templateUrl: './gung-html-editor.component.html',
  styleUrls: ['./gung-html-editor.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GungHtmlEditorComponent),
      multi: true
    }
  ]
})
export class GungHtmlEditorComponent extends BaseInputDirective<string> {
  @ViewChild('editor') editorComponent;

  @Input()
  public extraInputClass?: string;

  @Input()
  public labelClass?: string;

  @Input()
  public labelBold = false;

  @Input()
  public horizontal = false;

  @Input()
  public angularEditorConfig: AngularEditorConfig = this.angularEditorConfigService.getDefaultEditorConfig();

  // CKEDITOR
  /* eslint-disable-next-line ,  */
  // END CKEDITOR

  constructor(
    protected translateService: TranslateService,
    protected angularEditorConfigService: AngularEditorConfigService
  ) {
    super();
    if (this.labelBold) {
      this.labelClass = (this.labelClass || '') + ' font-weight-bold';
    }

    /* setTimeout(() => {
      this.isLoaded = true;
    }, 100); */
  }

  /*
  public onReadyEditor( editor ) {
    const plugins = Array.from( editor.ui.componentFactory.names() );
    // console.log('plugins', plugins); // show available plugins
    editor.ui.getEditableElement().parentElement.insertBefore(
        editor.ui.view.toolbar.element,
        editor.ui.getEditableElement()
    );
  }

  public onChangeEditor(editor) {
    const data = editor && editor.getData();
    this.value = data || '';
  }

  getDefaultCkEditorConfig() {
    const editorConfig = {
      placeholder: 'Type the content here!',
      language: this.translateService.currentLang,
      // plugins: [ Base64UploadAdapter, Table , TableToolbar, TableProperties, TableCellProperties ],
      toolbar: {
        items: [
          'heading',
          '|',
          'fontFamily',
          'fontSize',
          'fontColor',
          'fontBackgroundColor',
          'bold',
          'italic',
          'underline',
          'link',
          'bulletedList',
          'numberedList',
          '|',
          'alignment',
          'outdent',
          'indent',
          '|',
          'specialCharacters',
          'htmlEmbed',
          // 'imageUpload',
          'imageInsert',
          'blockQuote',
          'insertTable',
          'mediaEmbed',
          'undo',
          'redo'
        ]
      },
      image: {
        styles: [
          'alignLeft', 'alignCenter', 'alignRight'
        ],
        toolbar: [
          'imageStyle:alignLeft', 'imageStyle:alignCenter', 'imageStyle:alignRight',
          'imageTextAlternative',
          'imageStyle:full',
          'imageStyle:side',
          'linkImage'
        ]
      },
      table: {
        contentToolbar: [
          'tableColumn',
          'tableRow',
          'mergeTableCells',
          'tableCellProperties',
          'tableProperties'
        ]
      },
      mediaEmbed: {
        previewsInData: true
      },
      licenseKey: '',
    };
    return editorConfig;
  }
  */
}
