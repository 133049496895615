<div class="row">
  <div class="col-12 text-center">
    <h3>{{ 'UPLOAD_EXCEL' | translate | uppercase }}</h3>
  </div>
</div>
<!-- IMPORT FILE -->
<ng-container *ngIf="!result">
  <div class="row mt-4">
    <div class="col-12">
      <div class="alert alert-info" role="alert">
        <span class="d-flex justify-content-between align-items-center">
          <span translate="UPLOAD_PRODUCT_EXCEL_COLUMN_INFO"></span>
          <a [href]="'_gung_templates/gungOrderImportColumnFormat.xlsx' | gungDownloadUrl">
            <button class="btn btn-secondary" translate>DOWNLOAD_EXAMPLE_TEMPLATE</button>
          </a>
        </span>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 text-md-right">
      <label for="file-upload" translate>SELECT_FILES</label>
    </div>
    <div class="col-md-6">
      <input
        #inputFile
        type="file"
        class="_custom-file-input"
        id="file-upload"
        aria-describedby="file-upload"
        [multiple]="false"
        [disabled]="loading ? true : null"
      />
      <!-- (change)="onFileSelect($event)" -->
    </div>
  </div>
  <div class="row">
    <div class="col-12 text-right">
      <button
        type="button"
        class="btn btn-primary px-5"
        (click)="uploadFile(inputFile)"
        [disabled]="loading ? true : null"
        translate
      >
        UPLOAD <i *ngIf="loading" class="fa fa-spinner fa-spin fa-lg"></i>
      </button>
    </div>
  </div>
</ng-container>

<!-- IMPORT PRODUCT FROM FILE -->
<ng-container *ngIf="result">
  <div *ngIf="result.errors" class="row mt-4">
    <div class="col-12">
      <table class="table">
        <thead>
          <tr>
            <th scope="col" translate>ERRORS</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let error of result.errors">
            <td>{{ error }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <table class="table table-striped">
        <thead class="thead-dark">
          <tr>
            <th scope="col">#</th>
            <th scope="col" translate>ITEM_NO</th>
            <th scope="col" translate>PRODUCT</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of result.rows">
            <th scope="row">{{ row.rownr }}</th>
            <td>{{ row.product.id.includes('_GUNGDOT_') ? replaceGungDot(row.product.id) : row.product.id }}</td>
            <td>
              <a [routerLink]="['/product/', row.productId]" target="_blank">{{
                row.productId.includes('_GUNGDOT_') ? replaceGungDot(row.productId) : row.productId
              }}</a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <button type="button" class="btn btn-danger" translate (click)="uploadFormat = undefined; result = undefined">
        CANCEL
      </button>
      <div ngbDropdown placement="top-right" class="d-inline-block dropup">
        <button class="ml-2 btn btn-outline-primary export-area-buttons" id="dropdownBasic1" ngbDropdownToggle translate>
          DOWNLOADS
        </button>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-container dropdown-container-max-height">
          <button
            class="export-area-buttons"
            ngbDropdownItem
            *ngFor="let button of getSelectionActions"
            (click)="button.performAction(result.rows)"
            translate
          >
            {{ button.label }}
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-container>
