import { Component, OnInit } from '@angular/core';
import {
  ButtonsComponent,
  CartTotalsService,
  NavbarCartService,
  GungModalService,
  SaveCartModalConfigService,
  CartService,
  AuthService,
  CheckoutConfigService
} from 'gung-standard';
import { map } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-hl-checkout-buttons',
  templateUrl: './hl-checkout-buttons.component.html',
  styleUrls: ['./hl-checkout-buttons.component.scss']
})
export class HlCheckoutButtonsComponent extends ButtonsComponent implements OnInit {
  public tooLowValue = true;
  public loading = true;

  public get isLastStepHL() {
    return this.allSteps.indexOf(this.currentStep) === this.allSteps.length - 1;
  }

  public getShowTransportTime(): boolean {
    return environment.availability.showTransportTime;
  }

  constructor(
    protected cartTotalsService: CartTotalsService,
    navbarCartService: NavbarCartService,
    gungModalService: GungModalService,
    saveCartModalConfigService: SaveCartModalConfigService,
    cartService: CartService,
    public translationService: TranslateService,
    authService: AuthService,
    protected checkoutConfig: CheckoutConfigService
  ) {
    super(
      navbarCartService,
      gungModalService,
      saveCartModalConfigService,
      cartService,
      translationService,
      authService,
      checkoutConfig
    );
  }

  ngOnInit() {
    super.ngOnInit();
    /*
    if (!environment.sales) {
      this.cartTotalsService
        .getTotals()
        .pipe(
          map(totals => totals.totalValue),
          map(value => value <= 50)
        )
        .subscribe(tooLowValue => {
          this.tooLowValue = tooLowValue;
          this.loading = false;
        });
    } else {
      */
    this.tooLowValue = false;
    this.loading = false;
    //}
  }
}
