import { Injectable } from '@angular/core';
import { Customer, Product } from '../../models';
import { ConfigService, ConfigBaseFilter, ListSortOption, ListLayout, ListLayoutSingleComponent, SelectionAction } from 'gung-list';
import { ProductNameFilter } from '../product-list-config/product-list-config.service';
import { CustomerService } from '../customers/customer.service';
import { forkJoin, Observable, of, toArray } from 'rxjs';
import { CustomerListCardComponent } from '../../components/customer-list-card/customer-list-card.component';
import { UsersService } from '../users/users.service';
import { first, map, mergeMap, switchMap } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { CustomerSelectionActionConfigService } from '../customer-selection-action-config/customer-selection-action-config.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class CustomerListConfigService implements ConfigService<Customer> {
  constructor(
    public customerService: CustomerService,
    protected usersService: UsersService,
    protected authService: AuthService,
    protected customerSelectionActionConfigService: CustomerSelectionActionConfigService,
    protected translateService: TranslateService
  ) {}

  getItems(): Observable<Customer[]> {
    return this.authService.getHasRoles('ADMIN').pipe(
      switchMap(isAdmin => (isAdmin ? this.usersService.getAllUsers().pipe(first()) : of([]))),
      mergeMap(users => forkJoin([this.customerService.getCustomers().pipe(first()), of(users)])),
      map(([customers, users]) => {
        customers.forEach(c => (c.extra._user = users.filter(u => u.managedCompanyIds?.includes(c.id))));
        return customers;
      })
    );
  }

  getFilters(): ConfigBaseFilter<Customer>[] {
    return [];
  }

  getSortOptions(): ListSortOption<Customer>[] {
    // TODO: implement
    return [];
  }

  getBatchSizes(): number[] {
    return [24];
  }

  getLayouts(): ListLayout<Customer>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => CustomerListCardComponent,
        getListLayoutComponent: () => ListLayoutSingleComponent,
        getName: () => 'Customer list'
      }
    ];
  }

  getSearchTerms(item: Customer): string[] {
    return [item.id, item.name];
  }

  getItemId(item: Customer): string {
    return item.id;
  }

  getNothingFoundTranslateTag() {
    return 'NOTHING_FOUND';
  }

  getSelectionActions(): Observable<SelectionAction<Product>[]> {
    return forkJoin([
      this.customerSelectionActionConfigService.getSelectionActions().pipe(first()),
    ]).pipe(
      mergeMap(([staticActions]) => {
        const res = [];
        res.push(...staticActions);
        return res;
      }),
      toArray()
    );
  }

  getSelectionActionsButtonTitle(): string {
    return this.translateService.instant("ACTIONS");
  }
}
