<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title" translate>{{ title }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div class="col-12">
    <div class="form-group">
      <lib-gung-date-picker
        [label]="label"
        [currentselectedDate]="selectedDate"
        (selectedDate)="setDate($event)"
      ></lib-gung-date-picker>
    </div>
  </div>
</div>

<div class="modal-footer">
  <div class="row w-100">
    <div class="col-12">
      <div class="btn-group btn-group-justified btn-block">
        <button class="btn btn-sm btn-outline-secondary btn-default" (click)="close()" translate>CANCEL</button>
        <button class="btn btn-sm btn-primary btn-default" (click)="save()" translate>SAVE</button>
      </div>
    </div>
  </div>
</div>
