<div class="my-company-wrapper">
  <div class="row">
    <div class="col-12">
      <h3 translate>THE_COMPANY</h3>
    </div>
  </div>
  <div class="row" *ngIf="form; else loading" [formGroup]="form">
    <div class="col-12">
      <nav ngbNav #nav="ngbNav" class="nav-tabs">
        <ng-container ngbNavItem>
          <a (click)="selectedNavItem = 'information'" ngbNavLink translate>INFORMATION</a>
          <ng-template ngbNavContent>
            <div class="row">
              <div class="col-12 col-md-6">
                <lib-gung-text-input gungTranslate="COMPANY_NAME" formControlName="name"></lib-gung-text-input>
                <lib-gung-text-input gungTranslate="ADDRESS1" formControlName="address1"></lib-gung-text-input>
                <lib-gung-text-input gungTranslate="ADDRESS2" formControlName="address2"></lib-gung-text-input>
                <lib-gung-text-input gungTranslate="ZIP" formControlName="zipCode"></lib-gung-text-input>
                <lib-gung-text-input gungTranslate="CITY" formControlName="city"></lib-gung-text-input>
              </div>
              <div class="col-12 col-md-6">
                <lib-gung-text-input
                  gungTranslate="ORG_NUMBER"
                  formControlName="organizationNumber"
                ></lib-gung-text-input>
                <lib-gung-text-input gungTranslate="EMAIL_ADDRESS" formControlName="email"></lib-gung-text-input>
                <lib-gung-text-input gungTranslate="TELEPHONE" formControlName="telephone"></lib-gung-text-input>
                <lib-gung-text-input gungTranslate="WEBADDRESS" formControlName="webAddress"></lib-gung-text-input>
              </div>
            </div>
          </ng-template>
        </ng-container>
        <ng-container ngbNavItem>
          <a (click)="selectedNavItem = 'logo'" ngbNavLink translate>LOGO</a>
          <ng-template ngbNavContent>
            <h5 class="text-center">
              <span translate>CUSTOMIZE_NAVBAR_LOGO</span>
            </h5>
            <ng-template
              *ngTemplateOutlet="
                uploadImage;
                context: {
                  $implicit: objectFileImagePreview['logo'],
                  imgTag: 'logo'
                }
              "
            ></ng-template>
            <h5 class="text-center">
              <span translate>CUSTOMIZE_LOGIN_LOGO</span>
            </h5>
            <ng-template
              *ngTemplateOutlet="
                uploadImage;
                context: {
                  $implicit: objectFileImagePreview['logoLogin'],
                  imgTag: 'logoLogin'
                }
              "
            ></ng-template>
            <h5 class="text-center">
              <span translate>CUSTOMIZE_ORDER_CONFIRM_LOGO</span>
            </h5>
            <ng-template
              *ngTemplateOutlet="
                uploadImage;
                context: {
                  $implicit: objectFileImagePreview['logoOrderConfirm'],
                  imgTag: 'logoOrderConfirm'
                }
              "
            ></ng-template>
          </ng-template>
        </ng-container>
        <ng-container ngbNavItem>
          <a (click)="selectedNavItem = 'theme'" ngbNavLink translate>THEME</a>
          <ng-template ngbNavContent>
            <div class="row">
              <div class="col-12 col-md-6" formGroupName="colors">
                <h3 translate>COLOR</h3>

                <div class="d-flex align-items-end">
                  <lib-gung-text-input gungTranslate="PRIMARY_COLOR" formControlName="primary"></lib-gung-text-input>
                  <i
                    class="fas fa-square fa-3x ml-2"
                    (click)="colorPicker1.click()"
                    [ngStyle]="{ color: form.get('colors').get('primary').value }"
                  ></i>
                  <input #colorPicker1 type="color" formControlName="primary" style="height: 0px; width: 0px; visibility: hidden;" class="mt-5">
                </div>
                <div class="d-flex align-items-end">
                  <lib-gung-text-input
                    gungTranslate="SECONDARY_COLOR"
                    formControlName="secondary"
                  ></lib-gung-text-input>
                  <i
                    class="fas fa-square fa-3x ml-2"
                    (click)="colorPicker2.click()"
                    [ngStyle]="{ color: form.get('colors').get('secondary').value }"
                  ></i>
                  <input #colorPicker2 type="color" formControlName="secondary" style="height: 0px; width: 0px; visibility: hidden;" class="mt-5">
                </div>
                <button type="button" (click)="setupCustomization()" class="btn btn-primary" translate>TEST</button>
                <hr />
                <div class="d-flex">
                  <h3 translate class="m-0">LOGIN_BACKGROUND</h3>
                </div>
                <ng-template
                  *ngTemplateOutlet="
                    uploadImage;
                    context: {
                      $implicit: objectFileImagePreview['loginBackgroundImage'],
                      imgTag: 'loginBackgroundImage'
                    }
                  "
                ></ng-template>
              </div>
              <div class="col-12 col-md-6" formGroupName="extra">
                <h3 translate>SCRIPT</h3>
                <div formGroupName="customization">
                  <lib-gung-text-area-input
                    suppressLabel="true"
                    gungTranslate="INDEX_HEADER"
                    formControlName="indexHead"
                    rowsNumber="10"
                  >
                  </lib-gung-text-area-input>
                </div>
              </div>
            </div>
          </ng-template>


          <ng-container ngbNavItem>
            <a (click)="selectedNavItem = 'adminConfigurationWrapper'" ngbNavLink translate>CONFIGURATION</a>
            <ng-template ngbNavContent>
              <lib-admin-configuration-wrapper></lib-admin-configuration-wrapper>
            </ng-template>
          </ng-container>

          <ng-container *ngIf="featureActivatedGuardWrapper.isActivated('gung-company-templates', '1.0.0', true) | async" ngbNavItem>
            <a (click)="selectedNavItem = 'adminCompanyTemplates'" ngbNavLink translate>COMPANY_TEMPLATES</a>
            <ng-template ngbNavContent>
              <lib-company-templates-list></lib-company-templates-list>
            </ng-template>
          </ng-container>
        </ng-container>
      </nav>

      <div [ngbNavOutlet]="nav" class="mt-2"></div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 text-right" *ngIf="shouldDisplayButtons()">
      <button type="button" class="btn btn-secondary" (click)="initForm()" translate>RESET</button>
      <button type="button" class="btn btn-primary" (click)="save()" translate>SAVE</button>
    </div>
  </div>
</div>

<ng-template #loading> LOADING <i class="fas fa-spinner fa-spin ml-2"></i> </ng-template>

<ng-template #uploadImage let-fileImagePrev let-imgTag="imgTag">
  <div
    class="panel drop-box ng-pristine ng-untouched ng-valid ng-empty dragover"
    style="background: #ffffff; border: 5px dashed #ddd; text-align: center; padding: 50px 10px; margin-left: 10px"
    (dragover)="onDragOver($event)"
    (dragleave)="onDragLeave($event)"
    (drop)="onDrop($event)"
    (click)="fileInput.click()"
  >
    <label
      ><strong>Add file(s) by clicking on</strong><span class="box__dragndrop"> or dragging to this area</span>.</label
    >
    <br />
    <span
      ><small class="" *ngFor="let file of fileInput.files || fileInput.files; let last = last"
        >{{ file?.name }}{{ !last ? ', ' : '' }}</small
      ></span
    >
    <input
      hidden
      #fileInput
      type="file"
      name="files[]"
      id="file"
      (change)="onDrop($event, imgTag)"
      accept="image/*,application/pdf"
      multiple
    />
  </div>
  <div *ngIf="fileImagePrev" class="text-center">
    <span class="d-block" translate>PREVIEW</span>
    <img
      *ngIf="fileImagePrev.s3ETag; else imageTemp"
      class="img-fluid"
      [src]="fileImagePrev.s3Uri | gungImageUrl
        :'etag:'+fileImagePrev.s3ETag
      "
      alt="{{ fileImagePrev.description }}"
    />
    <ng-template #imageTemp>
      <i *ngIf="fileImagePrev && !fileImagePrev.s3Uri" class="fa fa-spinner fa-spin fa-7x"></i>
      <img *ngIf="fileImagePrev.s3Uri" class="img-fluid" [src]="fileImagePrev.s3Uri" alt="{{ fileImagePrev.name }}" />
    </ng-template>
  </div>
</ng-template>
